import React from "react";

import { AdminLayout } from "../../layouts";
import { InvestmentDespositsBody } from "./body";

export const InvestmentDepositsDesktop = () => {
  return (
    <AdminLayout
      title="View Investment Deposits"
      subTitle="Investigate possible issues related to investment deposits"
    >
      <InvestmentDespositsBody />
    </AdminLayout>
  );
};
