import { useContext } from "react";
import { ResponsiveContext } from "grommet";

import {
  JoinPublicInvestmentGroupDesktop,
  JoinPublicInvestmentGroupMobile
} from "./";

export const JoinPublicInvestmentGroup = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <JoinPublicInvestmentGroupMobile />
  ) : (
    <JoinPublicInvestmentGroupDesktop />
  );
};
