import React, { useCallback, useContext, useState } from "react";
import { Box, Paragraph, RadioButton, Text } from "grommet";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { format, addDays } from "date-fns";
import { db, useLoggedUser } from "../firebase";
import { FeedbackContext } from "./feedback";
import { useAppState, useFirebaseFunction } from "../hooks";
import { useAsync } from "react-use";
import { Customer } from "../types";
import { TRTThemeContext } from "../context";
import { LoadingButton } from "./button";
import { AlignType } from "grommet/utils";

type PaymentChooserProps = {
  align?: AlignType;
  onPay: (subscription: any) => void;
  subscription?: any;
  price?: any;
  cardRequired?: boolean;
};

const PRODUCTS = {
  yearly: process.env.REACT_APP_STRIPE_PRICE_YEARLY || "",
  monthly: process.env.REACT_APP_STRIPE_PRICE_MONTHLY || ""
};

export const PaymentChooser = ({
  align = "center",
  onPay,
  price,
  subscription,
  cardRequired
}: PaymentChooserProps) => {
  const [plan, setPlan] = useState(price?.id || PRODUCTS.monthly);
  const [paying, setPaying] = useState(false);
  const { sendFeedback } = useContext(FeedbackContext);
  const { theme } = useContext(TRTThemeContext);
  const { userRef } = useLoggedUser();
  const { user } = useAppState();

  const createSubscription = useFirebaseFunction({
    fnName: "createSubscription"
  });

  const updateSubscription = useFirebaseFunction({
    fnName: "updateSubscription"
  });

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  const onSelectPlan = useCallback((newPlan) => () => setPlan(newPlan), []);

  const customer = useAsync(async () => {
    try {
      const customerRef = await db.getCustomerByUserId(user?.uid || "");
      const customerData = await customerRef.get();
      const customer = customerData.data();
      return {
        ...customer
      };
    } catch (e) {
      sendFeedback({
        type: "error",
        message: "Failed to get customer data"
      });
    }
  }, [db, user]);

  const logStripeInteraction = useCallback(
    async (step, error) => {
      userRef.current?.collection("stripeInteractionLogs").add({
        step,
        error: JSON.stringify(error)
      });
    },
    [userRef]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { stripeId } = customer?.value as Customer;
      setPaying(true);

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements?.getElement(CardElement);

      // @ts-ignore
      if (cardElement && !cardElement?._empty) {
        // Use card Element to tokenize payment details
        const { error, paymentMethod } =
          (await stripe?.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: user?.name
            }
          })) || {};

        if (error) {
          logStripeInteraction("Create Payment Method", error);
          // show error and collect new card details.
          sendFeedback({
            type: "error",
            message: error.message || "Error creating payment method"
          });
          setPaying(false);
          return;
        }

        try {
          const { data } = await createSubscription({
            customerId: stripeId,
            priceId: plan,
            paymentMethodId: paymentMethod?.id
          });
          const { subError, subscription } = data;

          if (subError) {
            logStripeInteraction("Create Subscription", subError);
            // show error and collect new card details.
            sendFeedback({
              type: "error",
              message: subError.message || "Error creating subscription"
            });
            setPaying(false);
            return;
          }

          sendFeedback({
            type: "success",
            message: `Subscription created with status: ${subscription.status}`
          });

          switch (subscription.status) {
            case "active":
            case "trialing":
              // Redirect to account page
              sendFeedback({
                type: "success",
                message: "Subscription created successfully"
              });
              onPay(subscription);
              break;

            case "incomplete":
              const { error } =
                (await stripe?.confirmCardPayment(
                  subscription.latest_invoice.payment_intent.client_secret
                )) || {};

              if (error) {
                logStripeInteraction("Create Subscription Incomplete", error);
                sendFeedback({
                  type: "error",
                  message: error.message || "Error creating subscription"
                });
              } else {
                sendFeedback({
                  type: "success",
                  message: "Subscription created successfully"
                });
              }
              break;

            default:
              logStripeInteraction("Create Subscription Unknown", subscription);
              sendFeedback({
                type: "error",
                message: `Unknown Subscription status: ${subscription.status}`
              });
          }
          setPaying(false);
        } catch (e) {
          if (e instanceof Error) {
            logStripeInteraction("Create Subscription Unknown", {
              error: e,
              message: e?.message
            });
            sendFeedback({
              type: "error",
              message: e?.message || "Error creating subscription"
            });
          }

          setPaying(false);
        }
      } else {
        sendFeedback({
          type: "error",
          message: "Please, provide a valid Card number"
        });
        setPaying(false);
      }
    },
    [
      createSubscription,
      customer?.value,
      elements,
      logStripeInteraction,
      onPay,
      plan,
      sendFeedback,
      stripe,
      user?.name
    ]
  );

  const handleUpdateSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { stripeId } = customer?.value as Customer;
      setPaying(true);

      const cardElement = elements?.getElement(CardElement);

      let paymentMethodId;
      // looks like "_empty" is not available as a valid attribute
      // @ts-ignore
      if (cardElement && !cardElement?._empty) {
        const { error, paymentMethod } =
          (await stripe?.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              name: user?.name
            }
          })) || {};

        if (error) {
          logStripeInteraction("Updating Payment Method", error);
          // show error and collect new card details.
          sendFeedback({
            type: "error",
            message: error.message || "Error updating payment method"
          });
          setPaying(false);
          return;
        }

        paymentMethodId = paymentMethod?.id;
      } else if (cardRequired) {
        sendFeedback({
          type: "error",
          message: "Please, provide a valid Card number"
        });
        setPaying(false);
        return;
      }
      try {
        const { data } = await updateSubscription({
          paymentMethodId,
          subscriptionId: subscription?.id,
          priceId: plan,
          customerId: stripeId
        });

        const { updatedSubscription } = data as any;
        onPay(updatedSubscription);
      } catch (e) {
        if (e instanceof Error) {
          logStripeInteraction("Updating Subscription", {
            error: e,
            message: e.message
          });
          sendFeedback({
            type: "error",
            message: e.message || "Error updating payment method"
          });
        }
        console.log(e);
      }

      setPaying(false);
    },
    [
      customer?.value,
      elements,
      cardRequired,
      stripe,
      user?.name,
      logStripeInteraction,
      sendFeedback,
      updateSubscription,
      subscription?.id,
      plan,
      onPay
    ]
  );

  return (
    <form onSubmit={subscription ? handleUpdateSubmit : handleSubmit}>
      <Box align={align} gap="large">
        <Box flex width="420px" gap="medium" responsive={false}>
          <Box gap="small" align={align} responsive={false}>
            <RadioButton
              onChange={onSelectPlan(PRODUCTS.monthly)}
              checked={plan === PRODUCTS.monthly}
              name="plan"
              label={
                <Box direction="row" align="center" gap="xsmall">
                  <Text size="large">
                    <b>$7.99</b>
                  </Text>
                  <Text color="text-weak">per month</Text>
                </Box>
              }
            />
            <RadioButton
              onChange={onSelectPlan(PRODUCTS.yearly)}
              checked={plan === PRODUCTS.yearly}
              name="plan"
              label={
                <Box direction="row" align="center" gap="xsmall">
                  <Text size="large">
                    <b>$79.99</b>
                  </Text>
                  <Text color="text-weak">per year (save $15.89)</Text>
                </Box>
              }
            />
          </Box>
          <Box>
            <CardElement
              options={{
                style: {
                  base: {
                    color: theme === "light" ? "black" : "white",
                    "::placeholder": {
                      color: theme === "light" ? "black" : "white"
                    }
                  }
                }
              }}
            />
            <Box direction="row" align="center" justify="end">
              <Text size="small" color="text-xweak">
                powered by
              </Text>
              <img alt="strip logo" src="/stripe.svg" width="48px" />
            </Box>
          </Box>
          <Box gap="small">
            <LoadingButton
              type="submit"
              label={subscription ? "Update" : "Pay now"}
              primary
              isLoading={paying}
            />
            {!subscription && (
              <Box align="center" justify="end">
                <Text textAlign="center" color="text-xweak">
                  You won't be charged today, you will have until{" "}
                  {format(addDays(new Date(), 7), "MMMM do, yyyy")} to cancel
                  your subscription without any costs.
                </Text>
              </Box>
            )}
          </Box>
        </Box>
        {!subscription && (
          <Paragraph alignSelf="start" color="text-xweak">
            <i>
              After the trial period, all subscriptions are non-refundable and
              will automatically renew. If you decide to cancel your
              subscription future charges won't apply and you will have access
              until your membership expires.
            </i>
          </Paragraph>
        )}
      </Box>
    </form>
  );
};
