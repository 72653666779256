import { Box } from "grommet";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { InvestmentGroupDetailsBody } from "./";

export const InvestmentGroupDetailsDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout onClose={onNavigate("/")}>
      <Box flex>
        <InvestmentGroupDetailsBody />
      </Box>
    </AppLayout>
  );
};
