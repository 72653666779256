import {
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths,
  subWeeks,
  subYears
} from "date-fns";
import numeral from "numeral";

import { getNLastQuotes, getNLastSnapshots } from "../../firebase/db";
import { toArray } from "../../firebase/utils";
import { ActivePeriod, User } from "../../types";
import { getPreviousMonday } from "../../utils/date";

export const getInitialDate = (graphRange: ActivePeriod) => {
  const currentDate = new Date();
  if (graphRange === "weekly") {
    return subWeeks(currentDate, 1);
  } else if (graphRange === "monthly") {
    return subMonths(currentDate, 1);
  }
  return subYears(currentDate, 1);
};

export const getLabel = (graphRange: ActivePeriod) => {
  if (graphRange === "weekly") {
    return "Week";
  } else if (graphRange === "monthly") {
    return "Month";
  }
  return "Year";
};

export const getAttributeForRange = (graphRange: ActivePeriod) => {
  if (graphRange === "weekly") {
    return "weekIncrease";
  } else if (graphRange === "monthly") {
    return "monthIncrease";
  }
  return "yearIncrease";
};

export const calcInterval = (graphRange: ActivePeriod) => {
  if (graphRange === "monthly") {
    return 5;
  } else if (graphRange === "yearly") {
    return 100;
  }

  return undefined;
};

export const tableDataWeek = async (user?: User) => {
  const currentDate = startOfDay(new Date());
  const previousMonday = getPreviousMonday(currentDate);
  const quotesSnapshots = await getNLastQuotes({
    initialDate: previousMonday,
    sort: "asc"
  });
  const quotes = toArray(quotesSnapshots);

  const userSnapshots = await getNLastSnapshots({
    initialDate: previousMonday,
    userId: user?.uid,
    sort: "asc"
  });
  const snaps = toArray(userSnapshots);

  let totalBeats = 0;
  snaps.forEach((s, index) => {
    if (quotes[index] && s.netWorthPercentage > quotes[index].percent) {
      totalBeats += 1;
    }
  });

  const spyPercentages = quotes.map((s) =>
    numeral(s.percent).format("+0,0.00")
  );
  const snapPercentages = snaps.map((s) =>
    numeral(s.netWorthPercentage).format("+0,0.00")
  );
  return {
    totalBeats,
    performancePeriod: { user: snapPercentages, spy: spyPercentages },
    names: ["Mon", "Tue", "Wed", "Thu", "Fri"]
  };
};

export const tableDataMonth = async (user?: User) => {
  const today = startOfDay(new Date());
  const initialDate = startOfMonth(today);

  const quotesSnapshots = await getNLastQuotes({
    initialDate,
    sort: "asc",
    type: "weekly"
  });
  const quotes = toArray(quotesSnapshots);

  const spyPercentages = quotes.map((s) =>
    numeral(s.percent).format("+0,0.00")
  );

  const userSnapshots = await getNLastSnapshots({
    initialDate,
    userId: user?.uid,
    sort: "asc",
    type: "weekAggregate"
  });
  let snaps = toArray(userSnapshots);

  const snapPercentages = snaps.map((s) =>
    numeral(s.percent).format("+0,0.00")
  );

  return {
    totalBeats: 0,
    performancePeriod: { user: snapPercentages, spy: spyPercentages },
    names: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"]
  };
};

export const tableDataYear = async (user?: User) => {
  const today = startOfDay(new Date());
  const initialDate = startOfYear(today);

  const quotesSnapshots = await getNLastQuotes({
    initialDate,
    sort: "asc",
    type: "monthly"
  });
  const quotes = toArray(quotesSnapshots);

  const spyPercentages = quotes.map((s) =>
    numeral(s.percent).format("+0,0.00")
  );

  const userSnapshots = await getNLastSnapshots({
    initialDate,
    userId: user?.uid,
    sort: "asc",
    type: "monthAggregate"
  });
  let snaps = toArray(userSnapshots);

  const snapPercentages = snaps.map((s) =>
    numeral(s.percent).format("+0,0.00")
  );

  return {
    totalBeats: 0,
    performancePeriod: { user: snapPercentages, spy: spyPercentages },
    names: [
      "Jan",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Set",
      "Oct",
      "Nov",
      "Dec"
    ]
  };
};
