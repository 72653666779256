import { Anchor, Box, ResponsiveContext, Text } from "grommet";
import { PLANS } from "../constants";
import { PlanCardButton } from "./";
import { useContext } from "react";

type SubscriptionProps = {
  onChange: (plan: string) => void;
  currentPlan: string;
  renewDate?: React.ReactNode;
  hasPaidPlan?: boolean;
  loading?: boolean;
  price?: { interval: string; unit_amount: number };
};

export const Subscription = ({
  onChange,
  renewDate,
  currentPlan,
  price,
  loading
}: SubscriptionProps) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      style={{ flex: "1 0 auto" }}
      align="center"
      pad={size !== "small" ? { left: "large", top: "small", bottom: "small" } : undefined}
      gap="large"
    >
      <Box flex align="start" gap="medium">
        <Box direction={size === "large" ? "row" : "column"} align="start">
          <PlanCardButton
            loading={loading}
            planPricePeriod="Forever"
            planPrice="Free"
            planType={PLANS.BASIC}
            planBackground={currentPlan === PLANS.BASIC ? "status-ok" : "dark-3"}
            planFeatures={[
              {
                title: (
                  <Text>
                    Access to <Anchor href="#">Restimate</Anchor>
                  </Text>
                ),
                description: "Know how much you need to save"
              },
              {
                title: "Unlimited manual institutions",
                description: "You have to manually update each account balance"
              },
              {
                title: (
                  <span>
                    Only <b>one</b> automated institution
                  </span>
                ),
                description: "All account balance will automatically update"
              },
              {
                title: (
                  <span>
                    No investment group access
                  </span>
                ),
                description: "Socialize with friends, see what they buy and sell"
              }
            ]}
            selected={currentPlan === PLANS.BASIC}
            onSelect={() => {
              onChange(PLANS.BASIC);
            }}
            footer={currentPlan === PLANS.BASIC ? "Selected" : ""}
          />
          <PlanCardButton
            loading={loading}
            planPrice={price ? `$${price.unit_amount / 100}` : "$7.99/month"}
            footer={
              renewDate
                ? renewDate
                : currentPlan === PLANS.PRO
                ? "Selected"
                : ""
            }
            planPricePeriod={
              price ? `per ${price.interval}` : "or $79.99 per year"
            }
            planType={PLANS.PRO}
            planBackground={currentPlan === PLANS.PRO ? "status-ok" : "dark-3"}
            planFeatures={[
              {
                title: (
                  <Text>
                    Access to <Anchor href="#">Restimate</Anchor>
                  </Text>
                ),
                description: "Know how much you need to save"
              },
              {
                title: "Unlimited manual institutions",
                description: "You have to manually update each account balance"
              },
              {
                title: (
                  <span>
                    Unlimited automated institutions
                  </span>
                ),
                description: "All account balance will automatically update"
              },
              {
                title: (
                  <span>
                    Unlimited investment groups
                  </span>
                ),
                description: "Socialize with friends, see what they buy and sell"
              }
            ]}
            selected={currentPlan === PLANS.PRO}
            onSelect={() => {
              onChange(PLANS.PRO);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
