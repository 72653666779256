import React from "react";

import { PlaidTokensBody } from "./";
import { AdminLayoutMobile } from "../../layouts";

export const PlaidTokensMobile = () => (
  <AdminLayoutMobile title="Plaid Tokens">
    <PlaidTokensBody />
  </AdminLayoutMobile>
);
