import React, { useCallback, useContext, useState } from "react";
import { Box, Button, TextInput } from "grommet";
import app from "firebase/app";
import { FeedbackContext } from "./feedback";
import { useLoggedUser } from "../firebase";

export const ChangePassword = () => {
  const { sendFeedback } = useContext(FeedbackContext);
  const { googleUser } = useLoggedUser();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const onConfirm = useCallback(async () => {
    try {
      const credential = app.auth.EmailAuthProvider.credential(
        googleUser.email,
        oldPassword
      );

      // user needs to reauthenticate in order to change the
      // password
      await googleUser.reauthenticateWithCredential(credential);
      await googleUser.updatePassword(newPassword);
      sendFeedback({
        message: "Password has been successfully reset",
        type: "success"
      });
      setOldPassword("");
      setNewPassword("");
    } catch (e) {
      if (e instanceof Error) {
        sendFeedback({
          message: e.message,
          type: "error"
        });
      }
    }
  }, [googleUser, newPassword, oldPassword, sendFeedback]);
  return (
    <Box
      pad="small"
      margin={{ top: "medium", bottom: "medium" }}
      width="medium"
      gap="medium"
      alignSelf="center"
      justify="center"
    >
      <TextInput
        type="password"
        value={oldPassword}
        placeholder="current password"
        onChange={({ currentTarget }) => {
          const { value } = currentTarget;
          setOldPassword(value);
        }}
      />
      <TextInput
        type="password"
        value={newPassword}
        placeholder="new password"
        onChange={({ currentTarget }) => {
          const { value } = currentTarget;
          setNewPassword(value);
        }}
      />
      <Button primary label="Change Password" onClick={onConfirm} />
    </Box>
  );
};
