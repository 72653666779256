import { Box, Text } from "grommet";
import { StatusCritical } from "grommet-icons";

import { ConfirmationModal } from "./";

export const CancelTheCanceling = ({
  loading,
  onCancel,
  onConfirm
}: {
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  return (
    <ConfirmationModal
      loading={loading}
      color="brand"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title="Are you sure you want to get your Pro account back?"
      actionLabel="Confirm"
    >
      <Box gap="medium" responsive={false}>
        <Text color="text-weak">
          By getting your Pro account back you will be able to
          have unlimited institutions and investment groups.
        </Text>
      </Box>
    </ConfirmationModal>
  );
};
