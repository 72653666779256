import React, { ReactNode } from "react";
import { Box } from "grommet";
import { Header, PageHeader } from "../../components";

export interface AppLayoutProps {
  children: any;
  title?: string;
  subTitle?: string;
  onClose?: () => void;
  header?: ReactNode;
}

export const AppLayout = ({
  children,
  onClose,
  subTitle,
  title,
  header
}: AppLayoutProps) => (
  <Box direction="row" fill>
    {header || <Header />}
    <Box flex>
      {title && (
        <PageHeader title={title} subTitle={subTitle} onClose={onClose} />
      )}
      {children}
    </Box>
  </Box>
);
