export const setQuery = ({
  history,
  pathname,
  params = {},
  replace = false
}: {
  history: any;
  pathname: string;
  params: any;
  replace?: boolean;
}) => {
  history[replace ? "replace" : "push"](
    `${pathname}?${new URLSearchParams(params).toString()}`
  );
};
