import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { RealEstate, UserThemes } from "../../types";
import { TRTThemeContext, useAppState } from "../../context";
import { Box, Heading, Menu, ResponsiveContext, Text } from "grommet";
import { useFirebaseFunction, useNavigation } from "../../hooks";
import { useHistory, useLocation } from "react-router-dom";
import { FeedbackContext } from "../feedback";
import { MenuItemLabel } from "../menuItemLabel";
import { Edit, More, StatusCritical, Trash } from "grommet-icons";
import { Loading } from "../loading";
import { getRealEstateName, NumberText } from "../../utils";
import { PropertyLogo } from "./propertyLogo";
import { ConfirmationModal } from "../confirmationModal";

export interface RealEstatePropertyProps {
  realEstate: RealEstate;
}

interface RealEstateConfirmationProps {
  realEstate: RealEstate;
  onCancel: () => void;
  onConfirm: () => void;
}

export const DeleteRealEstateConfirmation = ({
  realEstate,
  onCancel,
  onConfirm
}: RealEstateConfirmationProps) => {
  const size = useContext(ResponsiveContext);
  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to remove ${getRealEstateName(
        realEstate
      )}?`}
      actionLabel="Remove"
    >
      <Box direction="row" justify="start" align="center">
        <PropertyLogo
          size={size === "small" ? "72px" : "86px"}
          iconSize="48px"
        />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            You cannot revert this action and your net worth will be{" "}
            {realEstate.netWorth >= 0 ? "decreased" : "increased"} by{" "}
            <NumberText value={Math.abs(realEstate.netWorth)} weight="bold" />.
          </Text>
          <Text color="text-weak">
            Your past transactions and performance will not change, but your
            today's performance will be affected by removing this property.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const RealEstateProperty = ({ realEstate }: RealEstatePropertyProps) => {
  const { theme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  const { user, refreshData } = useAppState();
  const realEstateRef = useRef<HTMLDivElement>(null);
  const [processing, setProcessing] = useState(false);
  const deleteRealEstate = useFirebaseFunction({
    fnName: "deleteRealEstate"
  });
  const location = useLocation();
  const { navigate } = useNavigation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const activeRealEstateId = params.get("activeRealEstate");
  const [
    showDeleteRealEstateConfirmation,
    setShowDeleteRealEstateConfirmation
  ] = useState(false);
  const { sendFeedback } = useContext(FeedbackContext);

  useEffect(() => {
    // give a little time for the user to read things before scrolling to the active institution
    setTimeout(() => {
      if (realEstateRef.current && realEstate.id === activeRealEstateId) {
        realEstateRef.current.scrollIntoView();
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete("activeRealEstate");
        history.replace({ search: queryParams.toString() });
      }
    }, 300);
  }, [activeRealEstateId, realEstate, history, location]);

  const onEditRealEstate = useCallback(() => {
    navigate(`/update-real-estate/${realEstate.id}`);
  }, [realEstate, navigate]);

  const onRequestToDeleteRealEstate = useCallback(
    () => setShowDeleteRealEstateConfirmation(true),
    [setShowDeleteRealEstateConfirmation]
  );

  const onCancelDeleteRealEstate = useCallback(
    () => setShowDeleteRealEstateConfirmation(false),
    [setShowDeleteRealEstateConfirmation]
  );

  const onConfirmDeleteRealEstate = useCallback(async () => {
    setShowDeleteRealEstateConfirmation(false);

    setProcessing(true);

    const { data } = await deleteRealEstate({
      realEstateId: realEstate.id,
      userId: user?.uid
    });

    if (data.success) {
      await refreshData({ skipUpdate: true });
      sendFeedback({
        message: "Property successfully deleted",
        type: "success"
      });
    } else {
      sendFeedback({
        message: `An error happened while trying to delete your property.`,
        type: "error"
      });
    }
    setProcessing(false);
  }, [deleteRealEstate, sendFeedback, realEstate, user, refreshData]);

  const menuOptions = [
    {
      label: (
        <MenuItemLabel
          icon={<Edit color="menu-icon" size="small" />}
          label="Edit"
        />
      ),
      onClick: onEditRealEstate
    },
    {
      label: (
        <MenuItemLabel
          icon={<Trash color="menu-icon" size="small" />}
          label="Remove"
        />
      ),
      onClick: onRequestToDeleteRealEstate
    }
  ];

  const renderActions = () => {
    if (realEstate.processing || processing) {
      return (
        <Box pad={{ horizontal: "small" }}>
          <Loading color={size !== "small" ? "white" : undefined} />
        </Box>
      );
    }

    return (
      <Menu
        icon={<More />}
        dropAlign={{ right: "right", top: "bottom" }}
        // @ts-ignore
        dropBackground={{ color: "drop", dark: theme === UserThemes.DARK }}
        items={menuOptions}
      />
    );
  };
  const realEstateName = useMemo(
    () => getRealEstateName(realEstate),
    [realEstate]
  );
  return (
    <Box
      ref={realEstateRef}
      border="top"
      pad={
        size === "small"
          ? { horizontal: "medium", vertical: "small" }
          : undefined
      }
    >
      <Box direction="row" align="center" justify="between">
        <Box
          direction="row"
          align="center"
          justify="center"
          gap="small"
          margin={{ left: "small" }}
          pad={{ top: "small" }}
        >
          <PropertyLogo />
          <Box align="start">
            <Box direction="row" align="center" justify="center" gap="small">
              <Text
                size="large"
                truncate
                style={{ maxWidth: "144px" }}
                title={realEstateName}
              >
                {realEstateName}
              </Text>
            </Box>
            <Text
              style={{ maxWidth: "220px" }}
              truncate
              size={size === "medium" ? "small" : undefined}
              color="text-weak"
            >
              <NumberText
                color="text"
                size={size === "medium" ? "small" : undefined}
                value={{
                  value: realEstate.netWorth,
                  percentage: user?.netWorth
                    ? (realEstate.netWorth / user?.netWorth) * 100
                    : 0
                }}
                weight="bold"
              />
              <span> in net worth</span>
            </Text>
          </Box>
        </Box>
        {renderActions()}
      </Box>
      <Heading
        level={5}
        margin={{ top: "small", bottom: "none", left: "small" }}
      >
        Details
      </Heading>
      <Box gap="small" pad={{ top: "small", bottom: "medium" }}>
        <Box
          direction="row"
          align="center"
          justify="between"
          margin={{ left: "medium", right: "small" }}
        >
          <Box>
            <Text>Property Value</Text>
          </Box>
          <NumberText value={realEstate.value} weight="bold" color="text" />
        </Box>
        <Box
          direction="row"
          align="center"
          justify="between"
          margin={{ left: "medium", right: "small" }}
        >
          <Box>
            <Text>Loans total</Text>
          </Box>
          <NumberText
            value={
              realEstate.loans.length
                ? (realEstate.value - realEstate.netWorth) * -1
                : 0
            }
            weight="bold"
            color="text"
          />
        </Box>
      </Box>
      {showDeleteRealEstateConfirmation && (
        <DeleteRealEstateConfirmation
          realEstate={realEstate}
          onCancel={onCancelDeleteRealEstate}
          onConfirm={onConfirmDeleteRealEstate}
        />
      )}
    </Box>
  );
};
