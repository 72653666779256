import React, { useCallback, useContext } from "react";
import { Box, Heading, Paragraph, RadioButton } from "grommet";

import { TRTThemeContext } from "../context";
import { useLoggedUser } from "../firebase";

export const ThemeChooser = () => {
  const { theme, setTheme } = useContext(TRTThemeContext);
  const { userRef } = useLoggedUser();
  const onThemeChange = useCallback(
    (newTheme) => () => {
      setTheme(newTheme);
      userRef.current?.set(
        {
          theme: newTheme
        },
        { merge: true }
      );
      // auto-persist theme during onboarding...
      localStorage.setItem("trt-theme", newTheme);
    },
    [setTheme, userRef]
  );
  return (
    <Box align="center">
      <Heading level={2} margin="none">
        Theme
      </Heading>
      <Paragraph
        textAlign="center"
        color="text-weak"
        margin={{ top: "xsmall" }}
      >
        Light or dark? We have got you covered
      </Paragraph>
      <Box
        margin={{ top: "small" }}
        direction="row"
        align="center"
        gap="medium"
      >
        <RadioButton
          name="light"
          checked={theme === "light"}
          label="Light"
          onChange={onThemeChange("light")}
        />

        <RadioButton
          name="dark"
          checked={theme === "dark"}
          label="Dark"
          onChange={onThemeChange("dark")}
        />
      </Box>
    </Box>
  );
};
