import React, { useContext } from "react";
import { format } from "date-fns";
import { Box, Heading, ResponsiveContext, Text } from "grommet";

import { DashboardHeader } from "../../components";
import { dayOfWeekInEnglish } from "../../utils";

import { AppLayout } from "../layouts";

import { DashboardBody } from ".";

export const DashboardDesktop = () => {
  const size = useContext(ResponsiveContext);
  return (
    <AppLayout>
      <Box
        flex={false}
        direction="row"
        border="bottom"
        background="dashboard-header"
        height="app-header"
      >
        <DashboardHeader flex />
        {size === "large" && (
          <Box
            border="left"
            align="center"
            justify="center"
            pad={{ horizontal: "medium", vertical: "small" }}
          >
            <Heading level={3} margin="none">
              {dayOfWeekInEnglish(new Date())}
            </Heading>
            <Text color="text-weak">{format(new Date(), "MMM do, yyyy")}</Text>
          </Box>
        )}
      </Box>
      <DashboardBody />
    </AppLayout>
  );
};
