import React from "react";
import { isEmpty, orderBy } from "lodash";
import { Box, Text } from "grommet";
import { useAppState } from "../../hooks";
import { RealEstateProperty } from "./realEstateProperty";

export const Properties = () => {
  const { realEstate } = useAppState();
  if (isEmpty(realEstate))
    return (
      <Box
        pad={{ vertical: "medium" }}
        align="center"
        justify="center"
        fill
        gap="small"
      >
        <Text>No properties yet</Text>
      </Box>
    );
  return (
    <Box flex overflow="auto">
      <Box flex={false}>
        {orderBy(realEstate, "netWorth", "desc").map((realEstateProperty) => {
          return (
            <RealEstateProperty
              key={realEstateProperty.id}
              realEstate={realEstateProperty}
            />
          );
        })}
      </Box>
    </Box>
  );
};
