import React from "react";
import { Anchor, Box, Button, Heading, Image, Paragraph } from "grommet";
import { LogoFull } from "../components";
import { useNavigation } from "../hooks";

export const Error = () => {
  const { onNavigate } = useNavigation();
  return (
    <Box fill align="center" pad={{ top: "xlarge" }} background="light-4">
      <LogoFull color="logo" />
      <Heading
        level={2}
        margin={{ top: "large", bottom: "small" }}
        responsive={false}
      >
        <b>Oh no, an error happened</b>
      </Heading>
      <Paragraph textAlign="center" color="text-weak" margin="none">
        An unexpected error happened, we are sorry about it. If the issue
        persists, reach out to us at{" "}
        <Anchor target="_blank" href="mailto: contact@theretirementtracker.com">
          contact@theretirementtracker.com
        </Anchor>
        .
      </Paragraph>
      <Image
        alt="error"
        src="/error.svg"
        width="300px"
        margin={{ vertical: "large" }}
      />
      <Button
        primary
        label="Take me to safety"
        margin={{ top: "medium", bottom: "large" }}
        onClick={onNavigate("/")}
      />
    </Box>
  );
};
