import React, { createContext, useCallback, useState } from "react";
import { get } from "lodash";
import { Box, Button, Keyboard, Layer, Text } from "grommet";
import { FormClose, StatusCritical, StatusGood } from "grommet-icons";

let autoDismissTimer: any;

interface FeedbackProps {
  children: React.ReactElement | React.ReactElement[];
}

type StatusType = "warning" | "error" | "success";

interface FeedbackType {
  type: StatusType;
  message: string;
}

type SendFeedbackProps = (props: FeedbackType) => void;

interface FeedbackContextType {
  sendFeedback: SendFeedbackProps;
}

export const FeedbackContext = createContext<FeedbackContextType>({
  sendFeedback: () => {}
});

const getColor = (status: StatusType) => {
  const isError = status === "error";
  const isSuccess = status === "success";

  if (isError) {
    return "status-critical";
  } else if (isSuccess) {
    return "status-ok";
  }

  return "status-warning";
};

const getIcon = (status: StatusType) => {
  const isError = status === "error";
  const isSuccess = status === "success";

  if (isError) {
    return StatusCritical;
  } else if (isSuccess) {
    return StatusGood;
  }

  return StatusGood;
};

export const Feedback = ({ children }: FeedbackProps) => {
  const [activeFeedback, setActiveFeedback] = useState<FeedbackType>();

  const sendFeedback: SendFeedbackProps = useCallback(
    (feedback) => {
      clearTimeout(autoDismissTimer);
      setActiveFeedback(feedback);

      autoDismissTimer = setTimeout(
        () => setActiveFeedback(undefined),
        5000 // 5s
      );
    },
    [setActiveFeedback]
  );

  const onDismiss = useCallback(
    () => setActiveFeedback(undefined),
    [setActiveFeedback]
  );

  const color = getColor(get(activeFeedback, "type") as StatusType);
  const Icon = getIcon(get(activeFeedback, "type") as StatusType);

  return (
    <FeedbackContext.Provider value={{ sendFeedback }}>
      {children}
      {activeFeedback && (
        <Keyboard onEsc={onDismiss} target="document">
          <Layer modal={false} plain position="top-right" responsive={false}>
            <Box
              margin="medium"
              background={color}
              pad={{ left: "medium", right: "small" }}
              round="24px"
              direction="row"
              align="center"
              justify="between"
            >
              <Box
                direction="row"
                align="center"
                justify="center"
                gap="xsmall"
                margin={{ right: "small" }}
              >
                <Icon color="white" />
                <Text color="white" truncate style={{ maxWidth: "400px" }}>
                  {activeFeedback.message}
                </Text>
              </Box>
              <Button onClick={onDismiss} icon={<FormClose color="white" />} />
            </Box>
          </Layer>
        </Keyboard>
      )}
    </FeedbackContext.Provider>
  );
};
