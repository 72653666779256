import React, { useContext } from "react";
import { ResponsiveContext } from "grommet";

import { UpdateRealEstateDesktop, UpdateRealEstateMobile } from "./";

export const UpdateRealEstate = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <UpdateRealEstateMobile />
  ) : (
    <UpdateRealEstateDesktop />
  );
};
