import { useContext } from "react";
import { ResponsiveContext } from "grommet";

import { UpdateInvestmentGroupDesktop, UpdateInvestmentGroupMobile } from "./";

export const UpdateInvestmentGroup = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <UpdateInvestmentGroupMobile />
  ) : (
    <UpdateInvestmentGroupDesktop />
  );
};
