import React from "react";

import { AddInstitutionBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const AddInstitutionMobile = () => (
  <AppLayoutMobile title="Add Institution">
    <AddInstitutionBody />
  </AppLayoutMobile>
);
