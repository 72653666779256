import React, { useCallback, useContext } from "react";
import { Button, ResponsiveContext } from "grommet";
import { useAsyncFn } from "react-use";

import {
  FeedbackContext,
  CallToAction,
  OnboardingHeading,
  OnboardingLayout
} from "../";
import {
  useAppState,
  useFirebaseFunction,
  useStepChangeContext
} from "../../hooks";
import { useLoggedUser } from "../../firebase";
import { User } from "../../types";

export const ValidateEmail = () => {
  const { user, userRef } = useLoggedUser();
  const { updateUser } = useAppState();
  const [, onStepChange] = useStepChangeContext();
  const { sendFeedback } = useContext(FeedbackContext);
  const size = useContext(ResponsiveContext);
  const resendVerificationEmail = useFirebaseFunction({
    fnName: "resendVerificationEmail"
  });

  const [resend, resendEmail] = useAsyncFn(async () => {
    try {
      await resendVerificationEmail({ userId: user?.uid });
      sendFeedback({
        type: "success",
        message: "Sent, remember to check your spam folder"
      });
    } catch (e) {
      sendFeedback({
        message: "Failed to send email validation.",
        type: "error"
      });
    }
  }, []);

  const checkValidate = useCallback(async () => {
    const doc = await userRef.current?.get();
    const { validated } = doc.data() || {};
    if (validated) {
      const updatedUser = { ...(user as User), validated: true };
      updateUser(updatedUser);
      onStepChange("completeProfile", updatedUser);
    } else {
      sendFeedback({
        message: "You haven't validated your email yet.",
        type: "error"
      });
    }
  }, [onStepChange, sendFeedback, updateUser, user, userRef]);

  return (
    <OnboardingLayout>
      <OnboardingHeading
        heading="Validate your Email"
        description="Welcome to The Retirement Tracker, we can't wait to start helping
            you with your retirement plans. But before we move forward, head
            over to your email inbox and click on the link we sent you so that
            we can validate your email address"
      />
      <Button
        size={size !== "small" ? "large" : undefined}
        label="Done"
        primary
        onClick={checkValidate}
      />
      <CallToAction
        disabled={resend.loading}
        text="Haven't received our email?"
        linkText={resend.loading ? "Sending ... " : "Send again"}
        onClick={resendEmail}
      />
    </OnboardingLayout>
  );
};
