import React from "react";
import { Box } from "grommet";

import { DashboardHeader } from "../../components";

import { AppLayoutMobile } from "../layouts";

import { DashboardBody } from "./";

export const DashboardMobile = () => (
  <AppLayoutMobile title="Dashboard">
    <Box flex>
      <DashboardHeader />
      <DashboardBody />
    </Box>
  </AppLayoutMobile>
);
