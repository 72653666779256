import { useCallback, useEffect, useMemo, useRef } from "react";
import { firebase } from "../firebase";

interface UseFunctionsProps {
  fnName: string;
  config?: { execute: "once" | "multiple" };
}

type ReturnFn = (args: any) => any;

export const useFirebaseFunction = ({
  fnName,
  config = { execute: "multiple" }
}: UseFunctionsProps): ReturnFn => {
  useEffect(() => {
    if (process?.env?.REACT_APP_EMULATE_FUNCTIONS) {
      console.warn("[TRT] Using firebase emulator");
      firebase.functions.useEmulator("localhost", 5001);
    }
  }, []);
  const memoedFn = useMemo(
    () => firebase.functions.httpsCallable(fnName),
    [fnName]
  );
  const hasExecuted = useRef<boolean>(false);
  const { execute } = config;

  return useCallback(
    (args: any) => {
      if (
        execute === "multiple" ||
        (execute === "once" && !hasExecuted.current)
      ) {
        hasExecuted.current = true;
        return memoedFn(args);
      }
    },
    [memoedFn, execute]
  );
};
