import React, { ChangeEvent, useCallback } from "react";
import { Box, Button, Select, TextInput } from "grommet";
import { Trash } from "grommet-icons";

import { AccountRow, DepositsAndFeesRow } from "../../types";

import {
  BaseCard,
  FormLabel,
  InstitutionAccountOption,
  CurrencyInput
} from "../";

import { renderInstitutionOption } from "./";

export interface DepositFeeCardProps {
  depositsAndFeesRow: DepositsAndFeesRow;
  availableInstitutionAccounts: AccountRow[];
  onRemove: (depositsAndFeesRow: DepositsAndFeesRow) => void;
  onUpdate: (
    depositsAndFeesRow: DepositsAndFeesRow,
    existingAccountRow: AccountRow
  ) => void;
}

export const DepositFeeCard = ({
  availableInstitutionAccounts = [],
  depositsAndFeesRow,
  onRemove,
  onUpdate
}: DepositFeeCardProps) => {
  const { depositsAndFeesItem, accountRow } = depositsAndFeesRow;
  const isFee = depositsAndFeesItem.type === "fee";

  const onRemoveDepositFee = useCallback(() => onRemove(depositsAndFeesRow), [
    onRemove,
    depositsAndFeesRow
  ]);

  const onUpdateDepositFee = useCallback(
    (field: string, options?: { uppercase?: boolean }) => ({
      target: { value }
    }: ChangeEvent<HTMLInputElement>) => {
      const newDepositsAndFeesRow = { ...depositsAndFeesRow };
      newDepositsAndFeesRow.depositsAndFeesItem = {
        ...depositsAndFeesRow.depositsAndFeesItem,
        [field]: options?.uppercase ? value.toUpperCase() : value
      };
      onUpdate(newDepositsAndFeesRow, depositsAndFeesRow.accountRow);
    },
    [onUpdate, depositsAndFeesRow]
  );

  const onChangeAccountRow = useCallback(
    ({ option: accountRow }: { option: AccountRow }) => {
      const newDepositsAndFeesRow = { ...depositsAndFeesRow };
      newDepositsAndFeesRow.accountRow = { ...accountRow };
      onUpdate(newDepositsAndFeesRow, depositsAndFeesRow.accountRow);
    },
    [onUpdate, depositsAndFeesRow]
  );

  return (
    <BaseCard color={isFee ? "status-critical" : "status-ok"}>
      <Box direction="row" align="center" justify="between">
        <Box align="start" gap="xsmall">
          <FormLabel
            label="Account"
            htmlFor={`deposit-fee-account-${depositsAndFeesItem.id}`}
          />
          <Select
            id={`deposit-fee-account-${depositsAndFeesItem.id}`}
            options={availableInstitutionAccounts}
            value={accountRow}
            valueKey={(accountRow) => accountRow.account.id}
            valueLabel={
              <InstitutionAccountOption
                institution={accountRow.institution}
                account={accountRow.account}
              />
            }
            onChange={onChangeAccountRow}
          >
            {renderInstitutionOption}
          </Select>
        </Box>
        <Button onClick={onRemoveDepositFee}>
          <Trash color="status-critical" />
        </Button>
      </Box>
      <Box>
        <Box align="start" gap="xsmall">
          <FormLabel
            label={isFee ? "Fee Amount*" : "Deposit Amount*"}
            htmlFor={`deposit-fee-amount-${depositsAndFeesItem.id}`}
          />
          <CurrencyInput
            id={`deposit-fee-amount-${depositsAndFeesItem.id}`}
            value={depositsAndFeesItem.amount}
            onChange={onUpdateDepositFee("amount")}
          />
        </Box>
      </Box>
      <Box direction="row" align="center" gap="small" responsive={false}>
        <Box align="start" gap="xsmall" width="90px">
          <FormLabel
            label="Ticker Symbol"
            htmlFor={`deposit-fee-tickerSymbol-${depositsAndFeesItem.id}`}
          />
          <TextInput
            id={`deposit-fee-tickerSymbol-${depositsAndFeesItem.id}`}
            value={depositsAndFeesItem.tickerSymbol}
            onChange={onUpdateDepositFee("tickerSymbol", { uppercase: true })}
          />
        </Box>
        <Box align="start" gap="xsmall" flex>
          <FormLabel
            label="Detail*"
            htmlFor={`deposit-fee-detail-${depositsAndFeesItem.id}`}
          />
          <TextInput
            id={`deposit-fee-detail-${depositsAndFeesItem.id}`}
            value={depositsAndFeesItem.detail}
            onChange={onUpdateDepositFee("detail")}
          />
        </Box>
      </Box>
    </BaseCard>
  );
};
