import { useMemo } from "react";
import { useAppState } from "../context";

import {
  Account,
  AccountSnapshotWithInstitution,
  ActivePeriod,
  BalanceChange,
  HoldingSnapshot,
  Institution,
  DepositsAndFeesSnapshot,
  ActivitySnapshot
} from "../types";
import { orderBy } from "lodash";

export interface GetGroupedHoldingKeyProps {
  institution: Institution;
  account: Account;
  holding: HoldingSnapshot;
}

export interface GroupedHolding {
  id: string;
  weight: number;
  totalBalance: BalanceChange;
  activePeriodBalance: BalanceChange;
  holdings: HoldingSnapshot[];
  tickerSymbol: string;
  institution: Institution;
  account: Account;
}

export interface InvestmentDetails {
  [holdingType: string]: number;
}

export interface GroupedDepositsAndFees {
  transaction: DepositsAndFeesSnapshot;
  institution: Institution;
  account: Account;
}

export interface GroupedActivity {
  activity: ActivitySnapshot;
  institution: Institution;
  account: Account;
}

export interface Deposits {
  total: {
    cash: number;
    dividends: number;
  };
  transactions: GroupedDepositsAndFees[];
}

export interface Fees {
  total: {
    [institutionId: string]: number;
  };
  transactions: GroupedDepositsAndFees[];
}

const getGroupedHoldingId = ({
  institution: { id: iId },
  account: { id: aId },
  holding: { tickerSymbol: hId }
}: GetGroupedHoldingKeyProps) => `${iId}-${aId}-${hId}`;

export const useSnapshot = (activePeriod: ActivePeriod) => {
  const { institutions, snapshots } = useAppState();
  const snapshotTotal = snapshots?.[activePeriod]?.data?.total;
  const {
    accountSnapshots,
    groupedHoldings,
    deposits,
    fees,
    activity,
    investmentDetails
  } = useMemo(() => {
    const groupedHoldings = [] as GroupedHolding[];
    const investmentDetails = {} as InvestmentDetails;
    const deposits = {
      total: {
        cash: 0,
        dividends: 0
      },
      transactions: []
    } as Deposits;
    const fees = {
      total: {},
      transactions: []
    } as Fees;
    let activity = [] as GroupedActivity[];
    let accountSnapshots = institutions.reduce(
      (balances: AccountSnapshotWithInstitution[], institution) => {
        const institutionBalances = institution.accounts
          .filter(
            ({ hidden, customType, type }) =>
              (customType === "investment" || type === "investment") && !hidden
          )
          .reduce(
            (accountSnapshots: AccountSnapshotWithInstitution[], account) => {
              const accountSnapshot =
                snapshots?.[activePeriod]?.data?.institutions?.[institution.id]
                  ?.accounts?.[account.id];
              if (accountSnapshot) {
                accountSnapshots.push({
                  institution,
                  account,
                  snapshot: accountSnapshot
                });

                if (accountSnapshot.depositsAndFees) {
                  accountSnapshot.depositsAndFees.forEach((transaction) => {
                    if (transaction.type === "deposit") {
                      deposits.total[
                        transaction.dividend ? "dividends" : "cash"
                      ] += Number(transaction.amount);
                      deposits.transactions.push({
                        institution,
                        account,
                        transaction
                      });
                    } else {
                      if (!fees.total[institution.id]) {
                        fees.total[institution.id] = 0;
                      }
                      fees.total[institution.id] += Number(transaction.amount);
                      fees.transactions.push({
                        institution,
                        account,
                        transaction
                      });
                    }
                  });
                }

                if (accountSnapshot.activity) {
                  accountSnapshot.activity.forEach((a) =>
                    activity.push({ institution, account, activity: a })
                  );
                }

                if (
                  accountSnapshot.holdings &&
                  Object.keys(accountSnapshot.holdings).length
                ) {
                  Object.keys(accountSnapshot.holdings).forEach(
                    (holdingKey) => {
                      const holding = accountSnapshot.holdings[holdingKey];

                      if (investmentDetails[holding.type]) {
                        investmentDetails[holding.type] += holding.currentValue;
                      } else {
                        investmentDetails[holding.type] = holding.currentValue;
                      }

                      const groupedHoldingId = getGroupedHoldingId({
                        institution,
                        account,
                        holding
                      });
                      const groupedHolding = groupedHoldings.find(
                        ({ id }) => id === groupedHoldingId
                      );
                      if (groupedHolding) {
                        groupedHolding.holdings.push(holding);
                        groupedHolding.totalBalance.from += holding.costBasis;
                        groupedHolding.totalBalance.to += holding.currentValue;
                        const totalChange =
                          groupedHolding.totalBalance.to -
                          groupedHolding.totalBalance.from;
                        groupedHolding.totalBalance.change.value = totalChange;
                        groupedHolding.totalBalance.change.percentage =
                          totalChange !== 0
                            ? (groupedHolding.totalBalance.to /
                                groupedHolding.totalBalance.from -
                                1) *
                              100
                            : 0;

                        groupedHolding.activePeriodBalance.from += Number(
                          holding.balance?.from
                        );
                        groupedHolding.activePeriodBalance.to += Number(
                          holding.balance?.to
                        );
                        groupedHolding.weight = snapshotTotal?.investmentBalance
                          .to
                          ? (Math.abs(groupedHolding.totalBalance.to) /
                              snapshotTotal?.investmentBalance.to) *
                            100
                          : 0;
                        const activePeriodChange =
                          groupedHolding.activePeriodBalance.to -
                          groupedHolding.activePeriodBalance.from;
                        groupedHolding.activePeriodBalance.change.value =
                          activePeriodChange;
                        groupedHolding.activePeriodBalance.change.percentage =
                          activePeriodChange !== 0
                            ? (groupedHolding.activePeriodBalance.to /
                                groupedHolding.activePeriodBalance.from -
                                1) *
                              100
                            : 0;
                      } else {
                        groupedHoldings.push({
                          id: groupedHoldingId,
                          holdings: [holding],
                          tickerSymbol: holding.tickerSymbol,
                          institution,
                          account,
                          weight: snapshotTotal?.investmentBalance.to
                            ? (Math.abs(holding.currentValue) /
                                snapshotTotal?.investmentBalance.to) *
                              100
                            : 0,
                          totalBalance: {
                            from: holding.costBasis,
                            to: holding.currentValue,
                            change: {
                              value: holding.currentValue - holding.costBasis,
                              percentage: holding.currentPercentage ?? 0
                            }
                          },
                          activePeriodBalance: {
                            from: Number(holding.balance?.from),
                            to: Number(holding.balance?.to),
                            change: {
                              value: Number(holding.balance?.change.value),
                              percentage: Number(
                                holding.balance?.change.percentage
                              )
                            }
                          }
                        });
                      }

                      if (groupedHolding) {
                        if (groupedHolding.totalBalance.from < 0) {
                          groupedHolding.totalBalance.change.percentage *= -1;
                        }
                        if (groupedHolding.activePeriodBalance.from < 0) {
                          groupedHolding.activePeriodBalance.change.percentage *=
                            -1;
                        }
                        groupedHolding.holdings = orderBy(
                          groupedHolding.holdings,
                          ["currentValue"],
                          ["desc"]
                        );
                      }
                    }
                  );
                }
              }

              return accountSnapshots;
            },
            [] as AccountSnapshotWithInstitution[]
          );
        return [...balances, ...institutionBalances];
      },
      [] as AccountSnapshotWithInstitution[]
    );

    accountSnapshots = orderBy(
      accountSnapshots,
      ["snapshot.balance.to", "institution.name"],
      ["desc", "asc"]
    );

    deposits.transactions = orderBy(
      deposits.transactions,
      ["transaction.date", "transaction.amount", "institution.name"],
      ["desc", "desc", "asc"]
    );

    fees.transactions = orderBy(
      fees.transactions,
      ["transaction.date", "transaction.amount", "institution.name"],
      ["desc", "desc", "asc"]
    );

    activity = orderBy(
      activity,
      [
        "activity.date",
        "institution.name",
        "activity.tickerSymbol",
        "activity.totalPrice"
      ],
      ["desc", "asc", "asc", "asc"]
    );

    return {
      groupedHoldings: orderBy(
        groupedHoldings,
        [
          "activePeriodBalance.change.percentage",
          "totalBalance.change.percentage"
        ],
        ["desc", "desc"]
      ),
      deposits,
      fees,
      accountSnapshots,
      activity,
      investmentDetails
    };
  }, [
    activePeriod,
    institutions,
    snapshots,
    snapshotTotal?.investmentBalance.to
  ]);

  return {
    total: snapshotTotal,
    accountSnapshots,
    groupedHoldings,
    deposits,
    fees,
    activity,
    investmentDetails
  };
};
