import React from "react";
import { Box } from "grommet";
import { Help, Home } from "grommet-icons";

export interface PropertyLogoProps {
  type?: "REAL_ESTATE" | "VESSEL";
  size?: string;
  iconSize?: string;
}

export const PropertyLogo = ({
  type = "REAL_ESTATE",
  size = "36px",
  iconSize = "small"
}: PropertyLogoProps) => {
  let Logo;
  switch (type) {
    case "REAL_ESTATE":
      Logo = Home;
      break;
    default:
      Logo = Help;
  }
  return (
    <Box
      flex={false}
      background="light-2"
      align="center"
      justify="center"
      width={size}
      height={size}
      round="full"
    >
      <Logo size={iconSize} />
    </Box>
  );
};
