import React from "react";

import { UpdateRealEstateBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const UpdateRealEstateMobile = () => (
  <AppLayoutMobile title="Update Real Estate">
    <UpdateRealEstateBody />
  </AppLayoutMobile>
);
