import { useCallback, useContext, useState } from "react";
import { Box, Text, TextInput } from "grommet";
import { StatusCritical } from "grommet-icons";
import app from "firebase/app";
import { ConfirmationModal } from "./";
import { useFirebaseContext, useLoggedUser } from "../firebase";
import { FeedbackContext } from "./feedback";
import { useAppState, useNavigation } from "../hooks";
import { User } from "../types";

const GOOGLE = "google.com";
export const CancelTRTAccount = ({ onCancel }: { onCancel: () => void }) => {
  const { updateUser } = useAppState();
  const { auth } = useFirebaseContext();
  const { userRef, signOut, googleUser } = useLoggedUser();
  const { sendFeedback } = useContext(FeedbackContext);
  const { navigate } = useNavigation();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { providerId } = googleUser?.providerData?.[0] || {};
  const onConfirm = useCallback(async () => {
    setLoading(true);
    try {
      if (providerId === GOOGLE) {
        await auth.signInWithGoogle();
      } else {
        const credential = app.auth.EmailAuthProvider.credential(
          googleUser.email,
          password
        );

        // user needs to reauthenticate in order to delete the user
        await googleUser.reauthenticateWithCredential(credential);
      }

      // delete TRT user
      await userRef.current?.delete();
      // delete Google user
      await googleUser.delete();
      setLoading(false);
      signOut();
      updateUser({} as User);
      navigate("/login");
    } catch (e) {
      if (e instanceof Error) {
        setLoading(false);
        sendFeedback({
          message: e.message,
          type: "error"
        });
      }
    }
  }, [
    auth,
    googleUser,
    navigate,
    password,
    providerId,
    sendFeedback,
    signOut,
    userRef,
    updateUser
  ]);

  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want delete your TRT account?`}
      actionLabel={"Confirm"}
      loading={loading}
    >
      <Box gap="medium" responsive={false}>
        <Text color="text-weak">
          You cannot revert this action and your data will be lost, no refund
          will be processed. To confirm, type your password:
        </Text>
        {providerId !== GOOGLE && (
          <TextInput
            type="password"
            value={password}
            placeholder="password"
            onChange={({ currentTarget }) => {
              const { value } = currentTarget;
              setPassword(value);
            }}
          />
        )}
      </Box>
    </ConfirmationModal>
  );
};
