import { createContext } from "react";

export type FirebaseContextValue = {
  auth: any;
  db: any;
  storage: any;
};

export const FirebaseContext = createContext<FirebaseContextValue | undefined>(
  undefined
);
