import React from "react";
import { Box, Text } from "grommet";
import numeral from "numeral";
import {
  ACCOUNT_TYPE_DISPLAY_NAME,
  AccountType,
  Account as AccountDefinition
} from "../../types";

export const ACCOUNT_TYPE_OPTIONS = Object.entries(
  ACCOUNT_TYPE_DISPLAY_NAME
).map(([value, label]) => ({ value, label }));

interface AccountProps {
  account: AccountDefinition;
}

export const Account = ({ account }: AccountProps) => {
  if (account.hidden) {
    return null;
  }

  const type = account.customType || account.type;
  const accountBalance =
    type === "loan" || type === "credit-card"
      ? -account.balance
      : account.balance;

  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      margin={{ left: "medium", right: "small" }}
    >
      <Box>
        <Text>{account.alias || account.name}</Text>
        <Text size="small" color="text-weak">
          {ACCOUNT_TYPE_DISPLAY_NAME[type as AccountType] || [type]}
        </Text>
      </Box>
      <Text weight="bold">{numeral(accountBalance).format("-$0.[00]a")}</Text>
    </Box>
  );
};
