import React, { useCallback, useContext, useState } from "react";
import { Box, Button, ResponsiveContext, Text } from "grommet";
import { Add } from "grommet-icons";

export const AddAccountButton = (props: any) => {
  const size = useContext(ResponsiveContext);
  const [hover, setHover] = useState(false);

  const onHoverOver = useCallback(() => setHover(true), [setHover]);
  const onHoverLeave = useCallback(() => setHover(false), [setHover]);

  return (
    <Button {...props}>
      <Box
        justify="center"
        align="center"
        gap="xsmall"
        height="156px"
        width="156px"
        round="full"
        background={hover ? "light-2" : undefined}
        onMouseOver={onHoverOver}
        onMouseLeave={onHoverLeave}
      >
        <Add color="add-account" size={size === "small" ? "large" : "72px"} />
        <Text color="add-account" weight="bold">
          Add Account
        </Text>
      </Box>
    </Button>
  );
};
