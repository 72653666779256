import React from "react";
import { Box, BoxProps, Heading, Text } from "grommet";

export interface DashboardCardProps {
  children: React.ReactElement | React.ReactElement[];
  description?: string;
  highlight?: React.ReactElement;
  title: string;
}

export const DashboardCard = ({
  children,
  description,
  highlight,
  title,
  ...rest
}: BoxProps & DashboardCardProps) => {
  return (
    <Box
      background="card"
      round="medium"
      basis="40%"
      height="400px"
      style={{ minWidth: "380px", maxWidth: "500px" }}
      margin="small"
      elevation="xsmall"
      responsive={false}
      overflow="hidden"
      {...rest}
    >
      <Box
        border="bottom"
        direction="row"
        justify="between"
        align="center"
        height="68px"
        pad="small"
        flex={false}
      >
        <Box
          margin={{ left: "small" }}
          height="60px"
          justify="center"
          responsive={false}
        >
          <Heading level={4} margin="none">
            {title}
          </Heading>
          {description && (
            <Text color="text-weak" size="small">
              {description}
            </Text>
          )}
        </Box>
        {highlight}
      </Box>
      {children}
    </Box>
  );
};
