import { useCallback, useEffect, useMemo, useState } from "react";
import { Anchor, Box, Button, Heading, Layer, Paragraph, Text } from "grommet";
import { Close } from "grommet-icons";
import { useUserActiveSubscription } from "../hooks/useUserActiveSubscription";
import { format } from "timeago.js";
import { Subscription } from "./subscription";
import { useAsync } from "react-use";
import { PaymentChooser } from "./payment-chooser";
import { CancelTRTAccount, DowngradeSubscription } from "./";
import { PLANS } from "../constants";
import { Institution, User } from "../types";
import { updateInstitution as updateInstitutionOnDB } from "../firebase/db";
import { useAppState, useFirebaseFunction } from "../hooks";
import { CancelTheCanceling } from "./cancelTheCanceling";

export const UserSubscription = () => {
  const { user, updateUser, reloadUser } = useAppState();
  const updateSubscription = useFirebaseFunction({
    fnName: "updateSubscription"
  });
  const [isDowngrading, setIsDowngrading] = useState(false);
  const [isGoingBackToPro, setIsGoingBackToPro] = useState(false);
  const [showPaymentLayer, setShowPaymentLayer] = useState(false);
  const [showCancelTRTLayer, setShowCancelTRTLayer] = useState(false);
  const [showCancelationLayer, setShowCancelationLayer] = useState(false);
  const [showDeCancelationLayer, setShowDeCancelationLayer] = useState(false);
  const [internalSubscription, setInternalSubscription] = useState<any>();
  const [currentPlan, setCurrentPlan] = useState(PLANS.BASIC);
  const { loading, value: subscription } = useUserActiveSubscription(user);
  const { current_period_end, cancel_at_period_end } =
    internalSubscription || subscription || {};

  const price = useAsync(async () => {
    if (internalSubscription) {
      return {
        interval: internalSubscription?.plan?.interval,
        unit_amount: internalSubscription?.plan?.amount_decimal
      };
    }
    if (subscription) {
      const priceValue = await subscription.price.get();
      return { id: subscription.price.id, ...priceValue.data() };
    }
  }, [internalSubscription, subscription]);

  const togglePaymentLayer = useCallback(
    (e?) => {
      if (e) {
        e.preventDefault();
      }
      setShowPaymentLayer(!showPaymentLayer);
    },
    [showPaymentLayer]
  );

  const toggleCancellationLayer = useCallback(
    (e?) => {
      if (e) {
        e.preventDefault();
      }
      setShowCancelationLayer(!showCancelationLayer);
    },
    [showCancelationLayer]
  );

  const toggleDeCancellationLayer = useCallback(() => {
    setShowDeCancelationLayer(!showDeCancelationLayer);
  }, [showDeCancelationLayer]);

  const toggleCancelTRTLayer = useCallback(
    (e?) => {
      if (e) {
        e.preventDefault();
      }
      setShowCancelTRTLayer(!showCancelTRTLayer);
    },
    [showCancelTRTLayer]
  );

  const onPlanChange = useCallback(
    (plan: string) => {
      if (plan === PLANS.PRO && subscription?.current_period_end) {
        toggleDeCancellationLayer();
      } else if (plan === PLANS.PRO) {
        togglePaymentLayer();
      } else {
        toggleCancellationLayer();
      }
    },
    [
      subscription?.current_period_end,
      toggleCancellationLayer,
      toggleDeCancellationLayer,
      togglePaymentLayer
    ]
  );

  const onPay = useCallback(
    (subscription) => {
      setInternalSubscription(subscription);
      setCurrentPlan(PLANS.PRO);
      togglePaymentLayer();

      // makes sure we update the user so we get the correct badge
      updateUser({
        ...(user as User),
        isPro: true
      });
    },
    [togglePaymentLayer, updateUser, user]
  );

  const onDowngradeSubscription = useCallback(
    async (defaultInstitution: Institution) => {
      setIsDowngrading(true);
      try {
        const { data } = await updateSubscription({
          subscriptionId: internalSubscription?.id || subscription?.id,
          cancelAtPeriodEnd: true
        });
        const { updatedSubscription } = data as any;
        setInternalSubscription(updatedSubscription);
        await updateInstitutionOnDB(
          { ...defaultInstitution, default: true },
          user?.uid
        );
        toggleCancellationLayer();
        setCurrentPlan(PLANS.BASIC);
        setIsDowngrading(false);
        setTimeout(() => reloadUser(), 1000);
      } catch (e) {
        setIsDowngrading(false);
        console.log(e);
      }
    },
    [
      internalSubscription?.id,
      subscription?.id,
      toggleCancellationLayer,
      updateSubscription,
      reloadUser,
      user
    ]
  );

  const onGoingBackToPro = useCallback(async () => {
    try {
      setIsGoingBackToPro(true);
      const { data } = await updateSubscription({
        subscriptionId: subscription?.id,
        cancelAtPeriodEnd: false
      });

      const { updatedSubscription } = data as any;
      setInternalSubscription(updatedSubscription);

      toggleDeCancellationLayer();
      setCurrentPlan(PLANS.PRO);
      setIsGoingBackToPro(false);
      updateUser({
        ...(user as User),
        isPro: true
      });
    } catch (e) {
      setIsGoingBackToPro(false);
      console.log(e);
    }
  }, [
    subscription?.id,
    toggleDeCancellationLayer,
    updateSubscription,
    updateUser,
    user
  ]);

  useEffect(() => {
    if (subscription && subscription.id && !subscription.cancel_at_period_end) {
      setCurrentPlan(PLANS.PRO);
    }
  }, [subscription]);

  const renewCancelLabel = useMemo(() => {
    const label = cancel_at_period_end ? "Cancels" : "Renews";
    return current_period_end
      ? `${label} ${format(
          new Date((current_period_end?.seconds || current_period_end) * 1000)
        )}`
      : "";
  }, [cancel_at_period_end, current_period_end]);

  const hasPaidPlan = cancel_at_period_end === false;
  return (
    <Box flex align="center" overflow="auto">
      <Box pad="small">
        <Subscription
          loading={loading || price.loading}
          renewDate={renewCancelLabel}
          hasPaidPlan={hasPaidPlan}
          price={hasPaidPlan ? price.value : ""}
          currentPlan={currentPlan}
          onChange={onPlanChange}
        />
        <Box pad={{ left: "large" }} gap="small">
          {hasPaidPlan && (
            <Anchor
              margin={{ left: "small" }}
              href="#"
              label="Update Payment Method"
              onClick={togglePaymentLayer}
            />
          )}
          <Anchor
            color="red"
            margin={{ left: "small" }}
            href="#"
            label="Cancel Account"
            onClick={toggleCancelTRTLayer}
          />
        </Box>
        {showCancelationLayer && (
          <DowngradeSubscription
            loading={isDowngrading}
            onCancel={toggleCancellationLayer}
            onConfirm={onDowngradeSubscription}
          />
        )}
        {showDeCancelationLayer && (
          <CancelTheCanceling
            loading={isGoingBackToPro}
            onCancel={toggleDeCancellationLayer}
            onConfirm={onGoingBackToPro}
          />
        )}
        {showCancelTRTLayer && (
          <CancelTRTAccount onCancel={toggleCancelTRTLayer} />
        )}
        {showPaymentLayer && (
          <Layer onEsc={togglePaymentLayer} onClickOutside={togglePaymentLayer}>
            <Box direction="row" align="center" justify="between" pad="medium">
              <Heading level={3} margin="none" responsive={false}>
                {hasPaidPlan ? "Update Payment Method" : "Add Payment Method"}
              </Heading>
              <Button icon={<Close />} onClick={togglePaymentLayer} />
            </Box>
            <Box pad="medium">
              <PaymentChooser
                price={price.value}
                subscription={internalSubscription || subscription}
                onPay={onPay}
              />
            </Box>
          </Layer>
        )}
      </Box>
      <Box pad={{ left: "large" }} alignSelf="start">
        <Text color="text-xweak">
          <i>
            All paid subscriptions will automatically renew after the trial
            period ends.
          </i>
        </Text>
        <Paragraph color="text-xweak">
          <i>
            All paid subscriptions are currently offered through a promotional
            price. This price will be locked for one year as an incentive to
            early subscribers.
          </i>
        </Paragraph>
      </Box>
    </Box>
  );
};
