import React, { useCallback } from "react";
import { Anchor, Box, Text } from "grommet";

type CallToActionProps = {
  href?: string;
  disabled?: boolean;
  target?: string;
  text: string;
  linkText: string;
  onClick?: (e: React.MouseEvent) => void;
};

export const CallToAction = ({
  text,
  linkText,
  onClick,
  ...rest
}: CallToActionProps) => {
  const onCTAClick = useCallback(
    (e) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );
  return (
    <Box
      direction="row"
      align="center"
      justify="center"
      gap="small"
      responsive={false}
    >
      <Text color="text-weak">{text}</Text>
      <Anchor
        href="#"
        label={linkText}
        onClick={onClick ? onCTAClick : undefined}
        {...rest}
      />
    </Box>
  );
};
