import { Box, Button } from "grommet";
import { AppsRounded, Home, Organization, Group } from "grommet-icons";

import { useNavigation } from "../../hooks";

import { UserAvatar, HeaderButton } from "../";

export const FooterMobile = () => {
  const { onNavigate } = useNavigation();
  return (
    <Box
      background="app-header"
      pad="large"
      elevation="footer"
      direction="row"
      justify="between"
      style={{ position: "relative" }}
    >
      <HeaderButton
        label="Net Worth"
        icon={<AppsRounded />}
        onClick={onNavigate("/net-worth")}
      />
      <HeaderButton
        label="Institutions"
        icon={<Organization />}
        onClick={onNavigate("/institutions")}
      />
      <HeaderButton
        label="Properties"
        icon={<Home />}
        onClick={onNavigate("/properties")}
      />
      <>
        <HeaderButton
          label="Groups"
          icon={<Group />}
          onClick={onNavigate("/investment-groups")}
        />
      </>
      <Button onClick={onNavigate("/user-menu")}>
        <UserAvatar />
      </Button>
    </Box>
  );
};
