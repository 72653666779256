import React, { useCallback, useContext, useMemo } from "react";
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  ResponsiveContext,
  Text
} from "grommet";
import { useHistory, useLocation } from "react-router-dom";

import { setQuery } from "../utils";
import { useAppState, useNavigation } from "../hooks";

import {
  Institutions,
  LogoFull,
  HeaderOverview,
  NetWorthDetails,
  ManageAssetsLiabilitiesButton,
  InvestmentDetails,
  UserHeaderFooter,
  Properties,
  Loading
} from ".";
import { InvestmentGroups } from "./investment-group";

export const Header = () => {
  const history = useHistory();
  const { onNavigate } = useNavigation();
  const location = useLocation();
  const size = useContext(ResponsiveContext);
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const { institutions, status } = useAppState();

  const onActivePanelChange = useCallback(
    (newActivePanels) => {
      const newActivePanel = newActivePanels ? newActivePanels[0] : undefined;
      const newParams = new URLSearchParams(location.search);
      newParams.delete("activePanel");
      setQuery({
        history,
        pathname: location.pathname,
        params: {
          ...Object.fromEntries(newParams),
          ...(newActivePanel !== undefined
            ? { activePanel: newActivePanel }
            : {})
        },
        replace: true
      });
    },
    [history, location]
  );

  return (
    <Box
      background="app-header"
      width={size === "large" ? "medium" : "280px"}
      elevation="medium"
      style={{ position: "relative" }}
    >
      <Box
        border="bottom"
        height="app-header"
        pad={{ left: "small" }}
        align="center"
        direction="row"
        justify="between"
      >
        <Button onClick={onNavigate("/")}>
          <LogoFull />
        </Button>
        <Box
          background="accent-4"
          width="40px"
          align="center"
          margin={{ right: "small" }}
          responsive={false}
          round="small"
        >
          <Text size="small" weight="bold">
            Beta
          </Text>
        </Box>
      </Box>
      <Box flex overflow="auto" style={{ scrollBehavior: "smooth" }}>
        <HeaderOverview />
        {status !== "done" ? (
          <Box
            direction="row"
            align="center"
            gap="xsmall"
            alignSelf="center"
            margin={{ top: "small" }}
          >
            <Loading color={size !== "small" ? "white" : undefined} />
            <Text color="text-weak">Loading...</Text>
          </Box>
        ) : (
          <Accordion
            activeIndex={
              params.get("activePanel")
                ? parseInt(params.get("activePanel") || "")
                : undefined
            }
            onActive={onActivePanelChange}
            animate={false}
          >
            <AccordionPanel
              label={
                <Box pad={{ horizontal: "small", vertical: "medium" }}>
                  <Text weight="bold">NET WORTH DETAILS</Text>
                </Box>
              }
            >
              <NetWorthDetails />
            </AccordionPanel>
            <AccordionPanel
              label={
                <Box pad={{ horizontal: "small", vertical: "medium" }}>
                  <Text weight="bold">HOLDINGS DETAILS</Text>
                </Box>
              }
            >
              <InvestmentDetails />
            </AccordionPanel>
            <AccordionPanel
              label={
                <Box pad={{ horizontal: "small", vertical: "medium" }}>
                  <Text weight="bold">INSTITUTIONS</Text>
                </Box>
              }
            >
              <Institutions institutions={institutions} />
            </AccordionPanel>
            <AccordionPanel
              label={
                <Box pad={{ horizontal: "small", vertical: "medium" }}>
                  <Text weight="bold">PROPERTIES</Text>
                </Box>
              }
            >
              <Properties />
            </AccordionPanel>
            <AccordionPanel
              label={
                <Box pad={{ horizontal: "small", vertical: "medium" }}>
                  <Text weight="bold">INVESTMENT GROUPS</Text>
                </Box>
              }
            >
              <InvestmentGroups />
            </AccordionPanel>
          </Accordion>
        )}
      </Box>
      <Box pad={{ vertical: "small", horizontal: "small" }}>
        <ManageAssetsLiabilitiesButton />
      </Box>
      <UserHeaderFooter />
    </Box>
  );
};
