import { Redirect, Route } from "react-router-dom";
import { useAppState, useNavigation } from "../hooks";
import { Onboarding, NotFound } from "../pages";
import { useLoggedUser } from "../firebase";

export const PrivateRoute = ({ children, error, ...rest }: any) => {
  const { loading } = useLoggedUser();
  const { user } = useAppState();
  const { navigate } = useNavigation();
  return (
    <Route
      exact
      {...rest}
      render={({ location, match: { isExact } }) => {
        if (!isExact) {
          return <NotFound />;
        }
        const whitelist = ["/access", "/login"];
        if ((user && !error) || whitelist.includes(location.pathname)) {
          if (user && !loading) {
            if (
              !error &&
              (!user.validated || user.currentStep !== "completed")
            ) {
              return <Onboarding />;
            } else if (location.pathname.includes("admin") && !user.admin) {
              navigate("/");
              return null;
            }
          }
          return children;
        }
        if (loading) {
          return null;
        }
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};
