import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";

import {
  AccountRow,
  ActivityAction,
  ActivityPosition,
  ActivityRow,
  ActivitySnapshot,
  DepositsAndFeesRow,
  DepositsAndFeesSnapshot,
  DepositsAndFeesType,
  Insights,
  Institution
} from "../../types";
import { InstitutionAccountOption } from "../institutionAccountOption";
import React from "react";

export const getNewActivity = (sequence: number): ActivitySnapshot => {
  return {
    id: uuidv4(),
    sequence,
    date: format(new Date().getTime(), "MM/dd/yyyy"),
    position: ActivityPosition.OPEN,
    action: ActivityAction.BUY,
    totalPrice: "",
    price: "",
    detail: "",
    quantity: 1,
    tickerSymbol: "",
    type: "equity",
    optionDetail: null
  };
};

export const getNewDepositsAndFees = (
  type: DepositsAndFeesType,
  sequence: number
): DepositsAndFeesSnapshot => {
  return {
    id: uuidv4(),
    sequence,
    date: format(new Date().getTime(), "MM/dd/yyyy"),
    detail: "",
    tickerSymbol: "",
    type,
    amount: "",
    dividend: false
  };
};

export const getActivityRowsFromInsights = (
  insights: Insights,
  institutions: Institution[]
): ActivityRow[] => {
  return Object.keys(insights.institutions).reduce(
    (activityRows, institutionKey) => {
      const institution = institutions.find(({ id }) => id === institutionKey);
      Object.keys(insights.institutions[institutionKey].accounts).forEach(
        (accountKey) => {
          const { activity } = insights.institutions[institutionKey].accounts[
            accountKey
          ];
          const account = institution?.accounts.find(
            ({ id }) => id === accountKey
          );
          if (activity && activity.length) {
            activityRows = activityRows.concat(
              (activity || []).map(
                (activityItem) =>
                  ({
                    activityItem,
                    accountRow: {
                      institution,
                      account
                    }
                  } as ActivityRow)
              )
            );
          }
        }
      );
      return activityRows;
    },
    [] as ActivityRow[]
  );
};

export const getDepositsAndFeesRowsFromInsights = (
  insights: Insights,
  institutions: Institution[]
): DepositsAndFeesRow[] => {
  return Object.keys(insights.institutions).reduce(
    (depositsAndFeesRows, institutionKey) => {
      const institution = institutions.find(({ id }) => id === institutionKey);
      Object.keys(insights.institutions[institutionKey].accounts).forEach(
        (accountKey) => {
          const { depositsAndFees } = insights.institutions[
            institutionKey
          ].accounts[accountKey];
          const account = institution?.accounts.find(
            ({ id }) => id === accountKey
          );
          if (depositsAndFees && depositsAndFees.length) {
            depositsAndFeesRows = depositsAndFeesRows.concat(
              (depositsAndFees || []).map(
                (depositsAndFeesItem) =>
                  ({
                    depositsAndFeesItem,
                    accountRow: {
                      institution,
                      account
                    }
                  } as DepositsAndFeesRow)
              )
            );
          }
        }
      );
      return depositsAndFeesRows;
    },
    [] as DepositsAndFeesRow[]
  );
};

export const renderInstitutionOption = (option: AccountRow) => (
  <InstitutionAccountOption
    institution={option.institution}
    account={option.account}
  />
);

export const getActivityAmountLabel = (activity: ActivitySnapshot) => {
  if (activity.action === "sell") {
    return "Credit";
  }
  return "Debit";
};

export enum PossibleActivityTypeOptions {
  "shares",
  "call option",
  "put option",
  "etf",
  "mutual fund",
  "fixed income"
}

export const ActivityTypes = {
  [PossibleActivityTypeOptions[PossibleActivityTypeOptions["shares"]]]: {
    type: "equity",
    optionDetail: null
  } as Partial<ActivitySnapshot>,
  [PossibleActivityTypeOptions[PossibleActivityTypeOptions["call option"]]]: {
    type: "derivative",
    optionDetail: {
      optionType: "C"
    }
  } as Partial<ActivitySnapshot>,
  [PossibleActivityTypeOptions[PossibleActivityTypeOptions["put option"]]]: {
    type: "derivative",
    optionDetail: {
      optionType: "P"
    }
  } as Partial<ActivitySnapshot>,
  [PossibleActivityTypeOptions[PossibleActivityTypeOptions["etf"]]]: {
    type: "etf",
    optionDetail: null
  } as Partial<ActivitySnapshot>,
  [PossibleActivityTypeOptions[PossibleActivityTypeOptions["mutual fund"]]]: {
    type: "mutual fund",
    optionDetail: null
  } as Partial<ActivitySnapshot>,
  [PossibleActivityTypeOptions[PossibleActivityTypeOptions["fixed income"]]]: {
    type: "fixed income",
    optionDetail: null
  } as Partial<ActivitySnapshot>
};

export const activityTypeOptions = Object.keys(
  PossibleActivityTypeOptions
).filter((k) => typeof PossibleActivityTypeOptions[k as any] === "number");

export const getSelectedTypeOption = (
  activitySnapshot: ActivitySnapshot
): string => {
  const { type } = activitySnapshot;
  if (type === "equity") {
    return PossibleActivityTypeOptions[PossibleActivityTypeOptions["shares"]];
  }
  if (type === "mutual fund") {
    return PossibleActivityTypeOptions[
      PossibleActivityTypeOptions["mutual fund"]
    ];
  }
  if (type === "etf") {
    return PossibleActivityTypeOptions[PossibleActivityTypeOptions["etf"]];
  }
  if (type === "fixed income") {
    return PossibleActivityTypeOptions[
      PossibleActivityTypeOptions["fixed income"]
    ];
  }
  if (activitySnapshot.optionDetail?.optionType === "P") {
    return PossibleActivityTypeOptions[
      PossibleActivityTypeOptions["put option"]
    ];
  }
  return PossibleActivityTypeOptions[
    PossibleActivityTypeOptions["call option"]
  ];
};
