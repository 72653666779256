import React from "react";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { AddInstitutionBody } from "./";

export const AddInstitutionDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout
      title="Add Institution"
      subTitle="Choose from one of our supported institutions and provide your account balance"
      onClose={onNavigate("/")}
    >
      <AddInstitutionBody />
    </AppLayout>
  );
};
