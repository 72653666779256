import { useEffect } from "react";
import { useAppState } from "./useAppState";
import { useNavigation } from "./useNavigation";

export const useLoginRedirect = () => {
  const { navigate } = useNavigation();
  const { user } = useAppState();
  useEffect(() => {
    if (user?.name) {
      // already logged in redirecting
      navigate("/");
    }
  }, [user, navigate]);
};
