import { auth, googleProvider } from "./firebase";

// Sign Up
export const createUser = (email: string, password: string) =>
  auth.createUserWithEmailAndPassword(email, password);

// User logged event
export const onAuthStateChanged = (cb: any) => auth.onAuthStateChanged(cb);

// Sign In
export const signIn = (email: string, password: string) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const signOut = () => auth.signOut();

// Password Reset Email
export const resetPassword = (email: string) =>
  auth.sendPasswordResetEmail(email);

// Verify Password Reset Code
export const verifyResetPasswordCode = (code: string) =>
  auth.verifyPasswordResetCode(code);

// Confirm Password Reset
export const confirmPasswordReset = (code: string, newPassword: string) =>
  auth.confirmPasswordReset(code, newPassword);

// Fetch provider
export const fetchSignInMethodsForEmail = (email: string) =>
  auth.fetchSignInMethodsForEmail(email);

// Password Change
export const updatePassword = async (password: string) => {
  if (auth.currentUser) {
    await auth.currentUser.updatePassword(password);
  }
  throw new Error("No auth.currentUser!");
};

export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);
