import React, { useContext } from "react";
import { Box, Meter, ResponsiveContext, Text } from "grommet";
import numeral from "numeral";

import { useAppState } from "../../hooks";

export const HeaderOverview = () => {
  const size = useContext(ResponsiveContext);
  const { user } = useAppState();
  const meterMax = (user?.assets || 0) + (user?.liabilities || 0);

  return (
    <Box flex={false}>
      <Box pad={size === "small" ? "medium" : "small"}>
        <Box align="center" justify="center" direction="row" gap="2px">
          <Text size="21px">
            {!user?.netWorth || user?.netWorth >= 0 ? "$" : "-$"}
          </Text>
          <Text size="36px">
            {numeral(Math.trunc(Math.abs(user?.netWorth!))).format("0,0")}
          </Text>
          <Text size="21px">
            {numeral(Math.abs(user?.netWorth!)).format(".00")}
          </Text>
        </Box>
        <Text size="small" textAlign="center">
          Total Net Worth
        </Text>
      </Box>
      <Box
        align="center"
        pad={{
          bottom: size === "small" ? "large" : "medium",
          horizontal: "small"
        }}
        border="bottom"
        gap="small"
      >
        <Box>
          <Box direction="row" align="center" justify="between">
            <Text size="small">Assets</Text>
            <Text weight="bold">
              {numeral(user?.assets || 0).format("$0.0a")}
            </Text>
          </Box>
          <Meter
            background="meter-background"
            round
            thickness="small"
            max={meterMax}
            values={[
              {
                value: user?.assets || 0,
                color: "assets"
              }
            ]}
          />
        </Box>
        <Box>
          <Box direction="row" align="center" justify="between">
            <Text size="small">Liabilities</Text>
            <Text weight="bold">
              {numeral(user?.liabilities || 0).format("$0.0a")}
            </Text>
          </Box>
          <Meter
            background="meter-background"
            round
            thickness="small"
            max={meterMax}
            values={[
              {
                value: user?.liabilities || 0,
                color: "liabilities"
              }
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};
