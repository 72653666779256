import React, { useCallback, useMemo } from "react";

import { TextInput, TextInputProps } from "grommet";

export interface CurrencyInputProps {
  numberFormatOptions?: object;
}

export const CurrencyInput = ({
  value,
  onChange,
  numberFormatOptions = {},
  ...rest
}: TextInputProps & CurrencyInputProps) => {
  const locale = "en";
  const currency = "USD";
  const valueString = useMemo(() => `${value || ""}`, [value]);

  const decimalSeparator = useMemo(() => {
    const { format } = new Intl.NumberFormat(locale, {
      style: "currency",
      currency
    });
    return format(0.1).replace(/[^,.]/g, "");
  }, [locale, currency]);

  const minimumFractionDigits = useMemo(
    () => (new RegExp(`\\${decimalSeparator}\\d`).test(valueString) ? 1 : 0),
    [decimalSeparator, valueString]
  );

  const { format } = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits,
        maximumFractionDigits: 2,
        ...numberFormatOptions
      }),
    [locale, numberFormatOptions, currency, minimumFractionDigits]
  );

  const getFormattedValue = useCallback(
    (v: string) => {
      if (!v || v === "-") {
        return v;
      }
      const lastChar = v.slice(-1);
      if (lastChar === "." || lastChar === ",") {
        return `${format(Number(v.slice(0, -1)))}${lastChar}`;
      }
      return format(Number(v.replace(decimalSeparator, ".")));
    },
    [format, decimalSeparator]
  );

  const maskedValue = useMemo(
    () => getFormattedValue(valueString),
    [valueString, getFormattedValue]
  );

  const onInputChange = useCallback(
    (event) => {
      let newValue = event.target.value.replace(
        new RegExp(`[^0-9${decimalSeparator}-]`, "g"),
        ""
      );

      if (newValue.endsWith("-") && newValue.length > 1) {
        newValue = newValue.slice(0, -1);
      }
      if (
        newValue.endsWith(decimalSeparator) &&
        newValue.split(decimalSeparator).length > 2
      ) {
        newValue = newValue.slice(0, -1);
      }

      onChange &&
        onChange({
          ...event,
          target: {
            ...event.target,
            value: newValue
          }
        });

      // maintain cursor position at the same place as we format the input value
      const offset =
        getFormattedValue(newValue).replace(/\d/g, "").length -
        maskedValue.replace(/\d/g, "").length;
      const selectionStart = event.target.selectionStart + Math.max(0, offset);
      const element = event.target;
      window.requestAnimationFrame(() => {
        element.selectionStart = selectionStart;
        element.selectionEnd = selectionStart;
      });
    },
    [onChange, decimalSeparator, getFormattedValue, maskedValue]
  );

  const onFormat = useCallback(
    (event) => {
      if (valueString.endsWith(decimalSeparator)) {
        onChange &&
          onChange({
            ...event,
            target: {
              ...event.target,
              value: valueString.slice(0, -1)
            }
          });
      }
    },
    [onChange, decimalSeparator, valueString]
  );

  return (
    <TextInput
      value={maskedValue}
      onChange={onInputChange}
      placeholder="$1,000"
      onBlur={onFormat}
      {...rest}
    />
  );
};
