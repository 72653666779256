import { useCallback, useContext } from "react";
import { Google } from "grommet-icons";
import { useFirebaseContext, useLoggedUser } from "../firebase";
import { useAccessCodeContext, useNavigation } from "../hooks";
import { LoadingButton } from "./button";
import { FeedbackContext } from "./feedback";
import { useState } from "react";

export const SignUpWithGoogle = ({
  signin,
}: {
  signin?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { signOut, updateTrtUser } = useLoggedUser();
  const { sendFeedback } = useContext(FeedbackContext);
  const { auth, db } = useFirebaseContext();
  const [accessCodeContext] = useAccessCodeContext();
  const { navigate } = useNavigation();

  const signUpWithGoogle = useCallback(async () => {
    setLoading(true);
    try {
      const authUser = await auth.signInWithGoogle();
      const { user, additionalUserInfo } = authUser;
      const { profile, isNewUser } = additionalUserInfo;

      const userRef = await db.getUserById(user.uid);
      const userData = await userRef.get();

      if (isNewUser && signin) {
        setLoading(false);
        signOut();
        user.delete();
        sendFeedback({
          message: "User doesn't have an account, use the Sign Up flow",
          type: "error"
        });
        return;
      }

      if (!userData.exists) {
        await db.createUser({
          id: user.uid,
          email: user.email,
          name: profile.name,
          accessCode: accessCodeContext || ''
        });
        await updateTrtUser(user);
        navigate('/');
      }
    } catch (e) {
      setLoading(false);
    }
  }, [
    accessCodeContext,
    auth,
    db,
    sendFeedback,
    signOut,
    signin,
    updateTrtUser,
    navigate
  ]);

  const label = signin ? "Sign In" : "Sign Up";
  return (
    <LoadingButton
      isLoading={loading}
      size="small"
      icon={<Google color="plain" />}
      label={`${label} with Google`}
      onClick={signUpWithGoogle}
    />
  );
};
