import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, FormField, TextInput, Text, Anchor } from "grommet";
import { useFirebaseContext, useLoggedUser } from "../firebase";
import { SignUpWithGoogle } from "./signUpInWithGoogle";
import { LoadingButton } from "./button";
import { useNavigation } from "../hooks";
import { FeedbackContext } from "./feedback";

export const SignIn = ({ from }: { from?: string }) => {
  const { sendFeedback } = useContext(FeedbackContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { auth } = useFirebaseContext();
  const { navigate } = useNavigation();
  const { user, error: userError } = useLoggedUser();
  const signIn = useCallback(async () => {
    try {
      setIsLoading(true);
      await auth.signIn(email, password);
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  }, [auth, email, password]);

  const onChangeHandler = (event: any) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const onForgotPassword = useCallback(
    (event) => {
      event.preventDefault();
      if (!email) {
        sendFeedback({
          message: "Please provide a valid email address",
          type: "error"
        });
        return;
      }

      auth
        .resetPassword(email)
        .then(() =>
          sendFeedback({
            message: "Check your email for reset password instructions",
            type: "success"
          })
        )
        .catch((error: Error) => {
          console.log(error);
          sendFeedback({
            message: error.message,
            type: "error"
          });
        });
    },
    [auth, email, sendFeedback]
  );

  useEffect(() => {
    if (user && !userError) {
      navigate(from || "/");
    }
  }, [from, navigate, user, userError]);

  useEffect(() => {
    if (error?.message) {
      sendFeedback({
        message: error?.message.replace(".", ""),
        type: "error"
      });
    }
  }, [error, sendFeedback]);

  return (
    <Box gap="small">
      <form
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          return signIn();
        }}
      >
        <FormField label="Username">
          <TextInput
            type="email"
            name="email"
            placeholder="username"
            onChange={(event) => onChangeHandler(event)}
          />
        </FormField>
        <FormField label="Password">
          <TextInput
            name="password"
            type="password"
            placeholder="password"
            onChange={(event) => onChangeHandler(event)}
          />
        </FormField>
        <Box
          direction="row"
          align="center"
          justify="center"
          gap="small"
          margin={{ vertical: "medium" }}
        >
          <LoadingButton
            type="submit"
            primary
            label="Sign In"
            isLoading={isLoading}
          />
          <Text color="text-xweak">or</Text>
          <SignUpWithGoogle signin />
        </Box>
        <Box align="center">
          <Text>
            Forgot Password?{" "}
            <Anchor href="#" onClick={onForgotPassword} label="Reset" />
          </Text>
        </Box>
      </form>
    </Box>
  );
};
