import React from "react";
import { Box } from "grommet";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { AddAssetsBody } from "./";

export const AddAssetsDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout
      title="Add Assets & Liabilities"
      subTitle="Choose from your options below to automatically or manually connect your assets"
      onClose={onNavigate("/")}
    >
      <Box
        flex
        overflow="auto"
        pad={{ vertical: "medium", horizontal: "large" }}
      >
        <AddAssetsBody />
      </Box>
    </AppLayout>
  );
};
