import React, { useContext } from "react";
import { Avatar, Box, ResponsiveContext, Text } from "grommet";
import md5 from "md5";

import { useAppState } from "../hooks";
import { User } from "../types";

export interface UserAvatarProps {
  user?: User;
  showName?: boolean;
}

export const UserAvatar = ({ user, showName = true }: UserAvatarProps) => {
  const { user: loggedUser } = useAppState();
  const size = useContext(ResponsiveContext);
  const avatarUser = user || loggedUser;
  return (
    <Box
      direction={size === "small" ? "column" : "row"}
      align="center"
      justify="center"
      gap={size === "small" ? "xsmall" : "small"}
      pad={size !== "small" ? { left: "small" } : undefined}
    >
      <Avatar
        size={size !== "large" ? "avatar-small" : undefined}
        src={
          avatarUser?.avatarUrl ||
          `//s.gravatar.com/avatar/${md5(avatarUser?.email ?? "")}?s=140`
        }
      />
      {showName && (
        <Text
          size={size === "small" ? "small" : undefined}
          style={{
            maxWidth: size === "small" ? "98px" : "130px"
          }}
          truncate
          title={avatarUser?.name ?? avatarUser?.email}
          textAlign={size === "small" ? "center" : undefined}
        >
          {avatarUser?.name ?? avatarUser?.email}
        </Text>
      )}
    </Box>
  );
};
