import { AdminLayout } from "../../layouts";
import { UserSearchBody } from "./body";

export const UserSearchDesktop = () => {
  return (
    <AdminLayout title="User Search" subTitle="Search users and attributes">
      <UserSearchBody />
    </AdminLayout>
  );
};
