import { useContext } from "react";
import { ResponsiveContext } from "grommet";

import {
  InvestmentGroupDetailsDesktop,
  InvestmentGroupDetailsMobile
} from "./";

export const InvestmentGroupDetails = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <InvestmentGroupDetailsMobile />
  ) : (
    <InvestmentGroupDetailsDesktop />
  );
};
