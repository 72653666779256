import { useContext } from "react";
import {
  AccessCodeContextValue,
  AccessCodeContext
} from "../context/access-code-context";

export const useAccessCodeContext = (): AccessCodeContextValue => {
  const code = useContext(AccessCodeContext);
  if (!code) {
    return ["", () => {}];
  }
  return code;
};
