import React, { useCallback, useContext, useState } from "react";
import ReactJson from "react-json-view";
import {
  Box,
  DateInput,
  Heading,
  ResponsiveContext,
  Text,
  TextInput
} from "grommet";
import {
  FeedbackContext,
  FormLabel,
  LoadingButton
} from "../../../../components";
import { useFirebaseFunction } from "../../../../hooks";
import { TRTThemeContext } from "../../../../context";
import { UserThemes } from "../../../../types";
import { startOfDay, subDays } from "date-fns";

export const BalanceUpdateBody = () => {
  const { theme } = useContext(TRTThemeContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const [userId, setUserId] = useState("");
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState<any>(null);
  const today = startOfDay(new Date());
  const [range, setRange] = useState([
    subDays(today, 1).toDateString(),
    today.toDateString()
  ]);

  const size = useContext(ResponsiveContext);
  const onRangeChange = useCallback(({ value }) => setRange(value), []);
  const onUserIdChange = useCallback(
    ({ target: { value } }) => setUserId(value),
    []
  );
  const updateBalance = useFirebaseFunction({
    fnName: "updateBalance"
  });
  const onUpdateBalance = useCallback(
    async (e) => {
      e.preventDefault();
      if (!userId) {
        return sendFeedback({
          message: "User id is required",
          type: "error"
        });
      }
      setFetching(true);
      try {
        const { data } = await updateBalance({
          userId,
          range
        });
        setData(data);
      } catch (e) {
        if (e instanceof Error) {
          console.error(e);
          setData({ error: e.toString() });
          sendFeedback({
            message: e.message,
            type: "error"
          });
        }
      }
      setFetching(false);
    },
    [userId, updateBalance, sendFeedback, range]
  );
  return (
    <Box flex>
      <Box
        flex={false}
        border="bottom"
        pad={{ horizontal: "medium", top: "small", bottom: "medium" }}
        background="card"
      >
        <form onSubmit={onUpdateBalance}>
          <Heading level={6} margin="none" color="text-xweak">
            OPTIONS
          </Heading>
          <Box
            margin={{ top: "small" }}
            justify="start"
            direction="row"
            align="center"
            gap="small"
            responsive={false}
          >
            <Box width={size !== "small" ? "medium" : undefined}>
              <FormLabel label="User Id" htmlFor="user-id" />
              <TextInput
                id="user-id"
                placeholder="User id"
                value={userId}
                onChange={onUserIdChange}
              />
            </Box>
            <Box>
              <FormLabel label="Range" htmlFor="date-range" />
              <DateInput
                id="date-range"
                value={range}
                onChange={onRangeChange}
                format="mm/dd/yyyy-mm/dd/yyyy"
              />
            </Box>
          </Box>
          <Box
            direction="row"
            align="center"
            gap="small"
            responsive={false}
            justify="end"
            margin={{ top: "small" }}
          >
            <LoadingButton
              isLoading={fetching}
              primary
              label="Update Balance"
              size="small"
              type="submit"
            />
          </Box>
        </form>
      </Box>
      <Box flex overflow="auto">
        {fetching && (
          <Text color="text-weak" alignSelf="center" margin={{ top: "medium" }}>
            Updating user balance...
          </Text>
        )}
        {!fetching && !data && (
          <Text color="text-weak" alignSelf="center" margin={{ top: "medium" }}>
            No data yet
          </Text>
        )}
        {!fetching && data && (
          <ReactJson
            theme={theme === UserThemes.DARK ? "twilight" : undefined}
            src={data!}
          />
        )}
      </Box>
    </Box>
  );
};
