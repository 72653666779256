import React from "react";
import { Box, Text } from "grommet";

export const ProBadge = () => (
  <Box
    border={{ color: "brand", size: "2px" }}
    pad="xsmall"
    round="small"
    width="48px"
    align="center"
    margin={{ right: "small" }}
  >
    <Text size="small" weight="bold">
      PRO
    </Text>
  </Box>
);
