import * as React from "react";
import { Box, ResponsiveContext, Text } from "grommet";
import { CaretDownFill, CaretUpFill } from "grommet-icons";
import numeral from "numeral";
import { useContext } from "react";

interface PercentagePillProps {
  cadence: string;
  value: number;
  splitter?: boolean;
}

export const PercentagePill = ({
  cadence,
  value,
  splitter = true
}: PercentagePillProps) => {
  const positive = value >= 0;
  const size = useContext(ResponsiveContext);
  let color = "status-ok";
  let Icon = CaretUpFill;
  if (!positive) {
    color = "status-critical";
    Icon = CaretDownFill;
  }
  return (
    <Box
      direction="row"
      align="center"
      border={splitter && "right"}
      gap="xsmall"
      pad={{ right: "small" }}
    >
      <Box direction="row" align="center">
        {size === "large" && <Icon color={color} />}
        <Text color={color} weight="bold" size="small">
          {numeral(value).format("+0,0.00")}%
        </Text>
      </Box>
      {size === "large" ? (
        <Text color="text-xweak" size="small">
          this {cadence}
        </Text>
      ) : (
        <Box
          round="12px"
          pad={{ horizontal: "xsmall" }}
          background="tab-active"
          responsive={false}
        >
          <Text size="xsmall">{cadence.charAt(0).toUpperCase()}</Text>
        </Box>
      )}
    </Box>
  );
};
