import React, { useContext } from "react";
import { TRTThemeContext } from "../context";
import { UserThemes } from "../types";

export interface LoadingProps {
  color?: string | undefined;
}

export const Loading = ({ color }: LoadingProps) => {
  const { theme } = useContext(TRTThemeContext);
  return (
    <svg
      width="18px"
      height="18px"
      stroke={color || theme === UserThemes.DARK ? "#fff" : "#000"}
      viewBox="0 0 38 38"
    >
      <g
        transform="translate(1 1)"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  );
};
