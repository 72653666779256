import { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryParams } from "./useQueryParams";
import { setQuery } from "../utils";
import { useHistory, useLocation } from "react-router-dom";

export interface UseActiveTabProps {
  paramName?: string;
  maxIndex: number;
}

export const useActiveTab = ({
  paramName = "activeTab",
  maxIndex
}: UseActiveTabProps) => {
  const history = useHistory();
  const location = useLocation();
  const params = useQueryParams();
  const activeTabParam = Number(params.get(paramName) || 0);
  const defaultTabIndex = Math.min(activeTabParam, maxIndex);
  const [activeIndex, setActiveIndex] = useState(defaultTabIndex);
  useEffect(() => {
    if (activeIndex !== activeTabParam) {
      setQuery({
        history,
        pathname: location.pathname,
        params: {
          ...Object.fromEntries(new URLSearchParams(location.search)),
          [paramName]: activeIndex
        }
      });
    }
  }, [activeIndex, history, location, paramName, activeTabParam]);
  const onActive = useCallback(
    (newActiveIndex) => setActiveIndex(newActiveIndex),
    []
  );
  return useMemo(
    () => ({
      activeIndex,
      onActive
    }),
    [activeIndex, onActive]
  );
};
