import React, { useCallback, useMemo } from "react";
import { Box, Paragraph } from "grommet";
import { Download, Upload } from "grommet-icons";

import {
  AccountRow,
  DepositsAndFeesRow,
  DepositsAndFeesType,
  InsightsProps
} from "../../types";
import { useAppState } from "../../context";
import { getActiveInstitutionAccountRows } from "../../utils";

import {
  CallOutButton,
  DepositFeeCard,
  getDepositsAndFeesRowsFromInsights,
  getNewDepositsAndFees
} from "../";
import { orderBy } from "lodash";

export const DepositFeeTab = ({ insights, onUpdate }: InsightsProps) => {
  const { institutions, manualInstitutions } = useAppState();

  const availableInstitutionAccounts = useMemo(
    () => getActiveInstitutionAccountRows(manualInstitutions, true),
    [manualInstitutions]
  );

  const depositsAndFeesRows: DepositsAndFeesRow[] = orderBy(
    getDepositsAndFeesRowsFromInsights(insights, institutions) || [],
    ["depositsAndFeesItem.sequence"],
    ["asc"]
  );

  const onRemoveDepositsAndFees = useCallback(
    (depositsAndFeesRow: DepositsAndFeesRow) => {
      const newInsights = { ...insights };
      const {
        institution: { id: institutionId },
        account: { id: accountId }
      } = depositsAndFeesRow.accountRow;
      newInsights.institutions[institutionId].accounts[
        accountId
      ].depositsAndFees =
        newInsights.institutions[institutionId].accounts[
          accountId
        ].depositsAndFees?.filter(
          ({ id }) => id !== depositsAndFeesRow.depositsAndFeesItem.id
        ) || [];
      onUpdate(newInsights);
    },
    [insights, onUpdate]
  );

  const onAddNewDepositsAndFees = useCallback(
    (
      institutionId: string,
      accountId: string,
      type: DepositsAndFeesType
    ) => () => {
      const newInsights = { ...insights };
      newInsights.institutions[institutionId].accounts[
        accountId
      ].depositsAndFees = [
        ...(newInsights.institutions[institutionId].accounts[accountId]
          .depositsAndFees || []),
        getNewDepositsAndFees(type, depositsAndFeesRows.length + 1)
      ];
      onUpdate(newInsights);
    },
    [insights, onUpdate, depositsAndFeesRows.length]
  );

  const onUpdateDepositsAndFees = useCallback(
    (
      depositsAndFeesRow: DepositsAndFeesRow,
      existingAccountRow: AccountRow
    ) => {
      const newInsights = { ...insights };
      const {
        institution: { id: institutionId },
        account: { id: accountId }
      } = depositsAndFeesRow.accountRow;
      if (existingAccountRow.account.id !== accountId) {
        onRemoveDepositsAndFees({
          ...depositsAndFeesRow,
          accountRow: { ...existingAccountRow }
        });
        newInsights.institutions[institutionId].accounts[
          accountId
        ].depositsAndFees = [
          ...(newInsights.institutions[institutionId].accounts[accountId]
            .depositsAndFees || []),
          depositsAndFeesRow.depositsAndFeesItem
        ];
      }
      newInsights.institutions[institutionId].accounts[
        accountId
      ].depositsAndFees =
        newInsights.institutions[institutionId].accounts[
          accountId
        ].depositsAndFees?.map((depositsAndFeeSnapshot) => {
          if (
            depositsAndFeeSnapshot.id ===
            depositsAndFeesRow.depositsAndFeesItem.id
          ) {
            return depositsAndFeesRow.depositsAndFeesItem;
          }
          return depositsAndFeeSnapshot;
        }) || [];
      onUpdate(newInsights);
    },
    [insights, onUpdate, onRemoveDepositsAndFees]
  );

  const {
    institution: { id: institutionId },
    account: { id: accountId }
  } = availableInstitutionAccounts[0];

  return (
    <Box fill align="center" responsive={false}>
      <Box
        direction="row"
        responsive={false}
        pad={{ top: "small" }}
        flex={false}
      >
        <Box margin={{ right: "small" }}>
          <CallOutButton
            icon={<Download size="large" color="tab-active" />}
            label="Add Deposit"
            onClick={onAddNewDepositsAndFees(
              institutionId,
              accountId,
              "deposit"
            )}
          />
        </Box>

        <Box border="left" pad={{ left: "small" }}>
          <CallOutButton
            icon={<Upload size="large" color="tab-active" />}
            label="Add Fee"
            onClick={onAddNewDepositsAndFees(institutionId, accountId, "fee")}
          />
        </Box>
      </Box>
      <Box
        direction="row"
        align="center"
        justify="center"
        wrap
        style={{ maxWidth: "1360px" }}
        responsive={false}
        pad={{ top: "small", bottom: "large", horizontal: "small" }}
        flex={false}
      >
        {depositsAndFeesRows.length ? (
          depositsAndFeesRows.map((depositsAndFeesRow) => (
            <DepositFeeCard
              key={`deposit-fee-card${depositsAndFeesRow.depositsAndFeesItem.id}`}
              depositsAndFeesRow={depositsAndFeesRow}
              availableInstitutionAccounts={availableInstitutionAccounts}
              onRemove={onRemoveDepositsAndFees}
              onUpdate={onUpdateDepositsAndFees}
            />
          ))
        ) : (
          <Paragraph color="text-weak" textAlign="center">
            Add one or more deposit/fee item by clicking on the buttons above
          </Paragraph>
        )}
      </Box>
    </Box>
  );
};
