import { useCallback, useContext, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import {
  Box,
  Button,
  ResponsiveContext,
  TextInput,
  Text,
  TextArea,
  Heading
} from "grommet";
import { Link, Mail } from "grommet-icons";
import { useAsyncFn } from "react-use";
import { omit, set } from "lodash";

import { FeedbackContext, LoadingButton } from "../../components";
import { db } from "../../firebase";
import { useAppState, useNavigation } from "../../hooks";
import { InvestmentGroupRoles, User } from "../../types";
import { validateInvestmentGroup } from "../../components/investment-group/utils";
import { GroupAvatarUploader } from "../../components/investment-group/avatarUploader";

export const AddInvestmentGroupBody = () => {
  const { user, updateUser } = useAppState();
  const { navigate } = useNavigation();
  const defaultInvestmentGroup = useMemo(() => {
    const groupId = uuidv4();
    const token = uuidv4();
    return {
      id: groupId,
      loading: true,
      name: "",
      avatarUrl: "",
      description: "",
      shareSettings: {
        token,
        public: false
      },
      links: {
        discord: "",
        slack: "",
        email: "",
        website: ""
      },
      members: [
        {
          id: user?.uid,
          email: user?.email || "",
          role: InvestmentGroupRoles.ADMIN,
          pending: false,
          invitedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          joinedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }
      ]
    };
  }, [user]);

  const [investmentGroup, setInvestmentGroup] = useState(
    defaultInvestmentGroup
  );
  const { sendFeedback } = useContext(FeedbackContext);
  const size = useContext(ResponsiveContext);

  const onUpdateInvestmentGroup = useCallback(
    (field) =>
      ({
        target: { value }
      }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInvestmentGroup = { ...investmentGroup };
        set(newInvestmentGroup, field, value);
        setInvestmentGroup(newInvestmentGroup);
      },
    [investmentGroup]
  );

  const updateAvatarUrl = useCallback(
    (avatarUrl) => {
      setInvestmentGroup({ ...investmentGroup, avatarUrl });
    },
    [investmentGroup]
  );

  const [newInvestmentGroup, addInvestimentGroup] = useAsyncFn(async () => {
    const error = validateInvestmentGroup(investmentGroup);
    if (error) {
      sendFeedback({
        message: error,
        type: "error"
      });
      return;
    }

    const investmentGroups = [
      ...(user?.investmentGroups || []),
      investmentGroup
    ];
    try {
      updateUser({
        ...(user as User),
        investmentGroups
      });

      const group = await db.createInvestmentGroup(
        omit(investmentGroup, ["loading"])
      );
      updateUser({
        ...(user as User),
        investmentGroupIds: [
          ...(user?.investmentGroupIds || []),
          investmentGroup.id
        ],
        investmentGroups: [
          ...investmentGroups.filter((group) => !group.loading),
          { ...investmentGroup, loading: false }
        ]
      });
      sendFeedback({
        message: "Investment Group created successfully",
        type: "success"
      });
      navigate(`/investment-group/${investmentGroup.id}`);
      return group;
    } catch (e) {
      sendFeedback({
        message: "Error creating Investment Group",
        type: "success"
      });
    }
  }, [investmentGroup]);

  const linkIconSize = size === "small" ? "24px" : "36px";

  return (
    <>
      <Box
        flex
        overflow="auto"
        align="start"
        pad={{ vertical: "medium", horizontal: size }}
        responsive={false}
      >
        <Box flex={false} fill>
          <Box direction="row" gap="medium" responsive={false} width="large">
            <Box
              align="center"
              gap="small"
              width={size === "small" ? "120px" : "180px"}
            >
              <GroupAvatarUploader
                groupId={investmentGroup.id}
                groupName={investmentGroup.name}
                initialAvatarUrl={investmentGroup.avatarUrl}
                onSaveComplete={updateAvatarUrl}
              />
              <Box>
                <Text size="small" color="text-xweak" textAlign="center">
                  Click anywhere on the image to change the group avatar
                </Text>
              </Box>
            </Box>
            <Box fill="horizontal" height="150px" gap="small">
              <TextInput
                placeholder="Group name*"
                value={investmentGroup.name}
                onChange={onUpdateInvestmentGroup("name")}
              />
              <TextArea
                placeholder="Group description"
                resize={false}
                fill
                value={investmentGroup.description}
                onChange={onUpdateInvestmentGroup("description")}
              />
            </Box>
          </Box>
          <Heading level={3} margin={{ left: "medium", vertical: "medium" }}>
            Links
          </Heading>
          <Box pad={{ left: "large", top: "small" }} width="large" gap="24px">
            <Box direction="row" align="center" gap="medium">
              <Link size={linkIconSize} />
              <TextInput
                placeholder="group website url"
                value={investmentGroup.links.website}
                onChange={onUpdateInvestmentGroup("links.website")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <Mail size={linkIconSize} />
              <TextInput
                placeholder="group email distribution"
                value={investmentGroup.links.email}
                onChange={onUpdateInvestmentGroup("links.email")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <img alt="slack logo" src="/slack.svg" width={linkIconSize} />
              <TextInput
                placeholder="group slack url"
                value={investmentGroup.links.slack}
                onChange={onUpdateInvestmentGroup("links.slack")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <img alt="slack logo" src="/discord.svg" width={linkIconSize} />
              <TextInput
                placeholder="group discord url"
                value={investmentGroup.links.discord}
                onChange={onUpdateInvestmentGroup("links.discord")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        tag="footer"
        justify="end"
        direction="row"
        pad="medium"
        border="top"
        gap="small"
      >
        <Button secondary label="Cancel" onClick={() => navigate("/")} />
        <LoadingButton
          isLoading={newInvestmentGroup.loading}
          label="Save"
          primary
          onClick={addInvestimentGroup}
        />
      </Box>
    </>
  );
};
