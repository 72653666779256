import React from "react";
import { Box, Button, Text } from "grommet";

import { useNavigation } from "../../hooks";
import { Logo, ManageAssetsLiabilitiesButton } from "../";

export interface HeaderMobileProps {
  title: string;
  icon?: React.ReactElement;
  onLogoClick?: () => void;
}

export const HeaderMobile = ({
  title,
  icon,
  onLogoClick,
  ...rest
}: HeaderMobileProps) => {
  const { onNavigate } = useNavigation();
  let rightNode = icon;
  if (!rightNode) {
    rightNode = <ManageAssetsLiabilitiesButton />;
  }
  return (
    <Box
      direction="row"
      align="center"
      justify="between"
      pad="small"
      border="bottom"
      {...rest}
    >
      <Button icon={<Logo />} onClick={onLogoClick || onNavigate("/")} />
      <Text size="large" weight="bold">
        {title}
      </Text>
      <Box width="44px" align="center" justify="center">
        {rightNode}
      </Box>
    </Box>
  );
};
