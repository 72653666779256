import React, { useCallback, useContext, useState } from "react";
import {
  Box,
  CheckBox,
  Heading,
  ResponsiveContext,
  Text,
  TextInput
} from "grommet";
import { OnboardingHeading, OnboardingLayout, ThemeChooser } from "../";
import { useAppState, useStepChangeContext } from "../../hooks";
import { useLoggedUser } from "../../firebase";
import { FeedbackContext } from "../feedback";
import { useDebounce, useFirstMountState } from "react-use";
import { AvatarUploader } from "../avatarUploader";
import { User } from "../../types";

export const CompleteProfile = () => {
  const { userRef } = useLoggedUser();
  const { user, updateUser } = useAppState();
  const isFirstMount = useFirstMountState();
  const { sendFeedback } = useContext(FeedbackContext);
  const [, onStepChange] = useStepChangeContext();
  const size = useContext(ResponsiveContext);
  const [name, setName] = useState(user?.name || "");
  const [receiveRecommendations, setReceiveRecommendations] = useState(
    user?.receiveRecommendations || false
  );

  const onNextStep = useCallback(() => {
    if (name) {
      onStepChange("restimate", user!);
    } else {
      sendFeedback({
        message: "Please, provide your name.",
        type: "error"
      });
    }
  }, [name, onStepChange, sendFeedback, user]);

  const onNameChange = useCallback(({ currentTarget }) => {
    const { value } = currentTarget;
    setName(value);
  }, []);

  const onRecommendationChange = useCallback(({ currentTarget }) => {
    const { checked } = currentTarget;
    setReceiveRecommendations(checked);
  }, []);

  useDebounce(
    () => {
      if (!isFirstMount) {
        // update firebase
        userRef.current?.set(
          {
            name,
            receiveRecommendations
          },
          { merge: true }
        );

        // update user state
        updateUser({
          ...(user as User),
          name,
          receiveRecommendations
        });
      }
    },
    300,
    [name, receiveRecommendations]
  );

  return (
    <OnboardingLayout onNextStep={onNextStep} step="1 of 3 steps">
      <OnboardingHeading
        heading="Complete Profile"
        description="Tell more about you so that we can customize your application"
      />
      <Box
        direction="row-responsive"
        align="center"
        justify="center"
        gap="medium"
      >
        <Box gap="small" align="center">
          <AvatarUploader />
          <Text
            size="small"
            color="text-xweak"
            textAlign="center"
            style={{ width: size === "small" ? "180px" : "200px" }}
          >
            Click anywhere on the image to change your avatar
          </Text>
        </Box>
        <Box
          align="center"
          width="240px"
          margin={size !== "small" ? { top: "-42px" } : { top: "small" }}
        >
          <TextInput
            value={name}
            onChange={onNameChange}
            size={size}
            placeholder="Your Name"
          />
        </Box>
      </Box>
      <ThemeChooser />
      <Box align="center" gap="medium">
        <Heading level={2} margin="none">
          Recommendations
        </Heading>
        <CheckBox
          checked={receiveRecommendations}
          onChange={onRecommendationChange}
          label={
            <Text textAlign="center">
              Receive product recommendations to help with retirement plans
            </Text>
          }
        />
      </Box>
    </OnboardingLayout>
  );
};
