import React, { useContext, useMemo, useState } from "react";
import { useSnapshot } from "../../hooks";
import { Box, Meter, ResponsiveContext, Stack, Text } from "grommet";
import { NumberText } from "../../utils";
import { orderBy } from "lodash";

interface MeterValue {
  value: number;
  color: string;
  onHover: (over: boolean) => void;
}

const investmentColorMap: { [type: string]: string } = {
  "mutual fund": "graph-1",
  equity: "graph-4",
  derivative: "accent-1",
  etf: "neutral-4"
};

const investmentLabelMap: { [label: string]: string } = {
  "mutual fund": "Mutual Funds",
  equity: "Stocks",
  derivative: "Options",
  etf: "ETFs"
};

interface InvestmentDetailLegendProps {
  value: number;
  label: string;
  color: string;
}

const getInvestmentDetailLabel = (label: string) =>
  investmentLabelMap[label] || label;

const InvestmentDetailLegend = ({
  color,
  value,
  label
}: InvestmentDetailLegendProps) => {
  return (
    <Box direction="row" align="center" gap="xsmall">
      <Box width="9px" height="9px" round="full" background={color} />
      <Text size="small">
        <NumberText
          color="text"
          value={value}
          type="percentage"
          weight="bold"
          size="small"
        />{" "}
        <Text color="text-weak" size="small">
          in {getInvestmentDetailLabel(label).toLowerCase()}
        </Text>
      </Text>
    </Box>
  );
};

export const InvestmentDetails = () => {
  const size = useContext(ResponsiveContext);
  const [activeInvestmentDetail, setActiveInvestmentDetail] = useState<
    string | undefined
  >();
  const { investmentDetails } = useSnapshot("weekly");
  const { total, values } = useMemo(
    () =>
      Object.keys(investmentDetails).reduce(
        (row, investmentDetailsKey) => {
          const value = Math.abs(investmentDetails[investmentDetailsKey]);
          row.total += value;
          row.values.push({
            value,
            color: investmentColorMap[investmentDetailsKey],
            onHover: (over) =>
              setActiveInvestmentDetail(over ? investmentDetailsKey : undefined)
          });
          return row;
        },
        { total: 0, values: [] as MeterValue[] }
      ),
    [investmentDetails]
  );

  const sortedValues = useMemo(
    () => orderBy(values, ["value"], ["desc"]),
    [values]
  );

  const investmentDetailLegends = useMemo(
    () =>
      orderBy(
        Object.keys(investmentDetails).map((investmentDetailKey) => ({
          label: investmentDetailKey,
          value:
            (Math.abs(investmentDetails[investmentDetailKey]) / total) * 100,
          color: investmentColorMap[investmentDetailKey]
        })),
        ["value"],
        ["desc"]
      ),
    [investmentDetails, total]
  );

  return !total ? (
    <Box fill align="center" margin={{ bottom: "medium" }}>
      <Text color="text-weak">No active holdings yet</Text>
    </Box>
  ) : (
    <Box
      align="center"
      justify="center"
      direction="row"
      pad={{ horizontal: "medium", bottom: "medium" }}
      gap="small"
    >
      <Stack anchor="center">
        <Meter
          type="circle"
          values={sortedValues}
          max={total}
          size={size !== "large" ? "90px" : "120px"}
          thickness={size !== "large" ? "6px" : "12px"}
        />
        <Box align="center" justify="center">
          <NumberText
            color="text"
            weight="bold"
            value={
              activeInvestmentDetail
                ? Math.abs(investmentDetails[activeInvestmentDetail || ""] ?? 0)
                : total
            }
          />
          <Text
            color="text-weak"
            size="small"
            textAlign="center"
            truncate
            style={{ maxWidth: "80px" }}
          >
            {activeInvestmentDetail
              ? getInvestmentDetailLabel(activeInvestmentDetail ?? "")
              : "Total"}
          </Text>
        </Box>
      </Stack>
      <Box gap="xsmall">
        {investmentDetailLegends.map((legend) => (
          <InvestmentDetailLegend
            key={`investment_detail_${legend.label}`}
            {...legend}
          />
        ))}
      </Box>
    </Box>
  );
};
