import React from "react";
import { Box, BoxProps } from "grommet";

export interface AccountCardProps {
  color?: string;
  children: any;
}

export const BaseCard = ({
  color = "tab-active",
  ...rest
}: AccountCardProps & BoxProps) => {
  return (
    <Box
      margin="small"
      background="card"
      elevation="xsmall"
      round="small"
      pad={{ horizontal: "medium", top: "small", bottom: "medium" }}
      width="medium"
      border={{
        side: "top",
        size: "4px",
        color
      }}
      gap="small"
      responsive={false}
      {...rest}
    />
  );
};
