import { useCallback, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import {
  Avatar,
  Box,
  Button,
  Heading,
  Layer,
  ResponsiveContext,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  TextInput
} from "grommet";
import { format } from "timeago.js";
import { Close, Trash } from "grommet-icons";
import styled from "styled-components";
import md5 from "md5";
import { orderBy, pick, uniq } from "lodash";
import { useAsyncFn } from "react-use";
import { FeedbackContext } from "../feedback";
import { TRTThemeContext } from "../../context";
import {
  InvestmentGroupMember,
  InvestmentGroupRoles,
  InvestmentGroupType,
  MembersMap,
  UserThemes
} from "../../types";
import { useAppState, useFirebaseFunction } from "../../hooks";
import { LoadingButton } from "../button";
import {
  getInvestmentGroupKey,
  removeInvestmentGroupFromCache
} from "../../cache";
const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PlainTextInput = styled(TextInput)`
  &:focus {
    box-shadow: none;
  }
`;

export const ManageMembers = ({
  onClose,
  getInvestmentGroups,
  investmentGroup,
  membersMap
}: {
  onClose: () => void;
  getInvestmentGroups?: () => void;
  investmentGroup?: InvestmentGroupType;
  membersMap: MembersMap;
}) => {
  const updateGroupMembers = useFirebaseFunction({
    fnName: "updateGroupMembers"
  });

  const deleteGroupMembers = useFirebaseFunction({
    fnName: "deleteGroupMembers"
  });

  const { theme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  const { user } = useAppState();
  const { sendFeedback } = useContext(FeedbackContext);
  const [newMembers, setNewMembers] = useState("");
  const [members, setMembers] = useState(investmentGroup?.members || []);
  const [membersToDelete, setMembersToDelete] = useState<
    InvestmentGroupMember[]
  >([]);

  const appBackground = theme === UserThemes.DARK ? "dark-1" : "light-1";

  useEffect(
    () => setMembers(investmentGroup?.members || []),
    [investmentGroup?.members]
  );
  const onUpdateNewMembers = useCallback(
    ({ target: { value } }) => setNewMembers(value),
    []
  );
  const onRemoveMember = useCallback(
    (memberToRemove: InvestmentGroupMember) => () => {
      if (
        memberToRemove.role === InvestmentGroupRoles.ADMIN &&
        members.filter(
          ({ role }: { role: InvestmentGroupRoles }) =>
            role === InvestmentGroupRoles.ADMIN
        ).length === 1
      ) {
        sendFeedback({
          message: "You can't remove the last Administrator",
          type: "error"
        });
      } else {
        setMembers(
          members.filter(
            ({ email }: { email?: InvestmentGroupMember["email"] }) =>
              email !== memberToRemove.email
          )
        );

        if (!memberToRemove.temp) {
          setMembersToDelete([...membersToDelete, memberToRemove]);
        }
      }
    },
    [members, membersToDelete, sendFeedback]
  );

  const onUpdateMemberRole = useCallback(
    (memberToUpdate) =>
      ({ option: { value: role } }: any) => {
        if (
          memberToUpdate.role === InvestmentGroupRoles.ADMIN &&
          members.filter(
            ({ role }: { role: InvestmentGroupRoles }) =>
              role === InvestmentGroupRoles.ADMIN
          ).length === 1 &&
          role !== InvestmentGroupRoles.ADMIN
        ) {
          sendFeedback({
            message: "You can't remove the last Administrator",
            type: "error"
          });
        } else {
          setMembers(
            members.map((member: InvestmentGroupMember) => {
              if (memberToUpdate.email === member.email) {
                return {
                  ...member,
                  role
                };
              }
              return member;
            })
          );
        }
      },
    [members, sendFeedback]
  );

  const onAddMembers = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const existingMembers = members.map((member) => member.email);
      const memberEmails = newMembers
        .split(",")
        .map((email) => email.trim().toLowerCase())
        .filter(
          (email) =>
            emailRegexp.test(email) &&
            !members.find(
              ({ id }: { id?: InvestmentGroupMember["id"] }) => email === id
            )
        );

      if (!memberEmails.length) {
        sendFeedback({
          message: "No valid email to add",
          type: "error"
        });
      } else {
        const filteredMemberEmails = uniq(memberEmails).filter(
          (email) => !existingMembers.includes(email)
        );
        const updatedMembers = [
          ...members,
          ...filteredMemberEmails.map((email) => ({
            email,
            name: "",
            pending: true,
            role: InvestmentGroupRoles.VIEWER,
            temp: true,
            invitedAt: firebase.firestore.Timestamp.fromDate(new Date())
          }))
        ];
        setMembers(updatedMembers);
      }
      setNewMembers("");
    },
    [newMembers, members, sendFeedback]
  );

  const [savingMembers, onSaveMembers] = useAsyncFn(async () => {
    const updatedMembers = members.map((member) => ({
      ...member,
      temp: false
    }));

    try {
      await updateGroupMembers({
        members,
        invitee: pick(user, ["name", "email"]),
        groupId: investmentGroup?.id,
        groupName: investmentGroup?.name
      });

      await deleteGroupMembers({
        members: membersToDelete,
        groupId: investmentGroup?.id
      });

      sendFeedback({
        message: `Successfully updated members for ${investmentGroup?.name}`,
        type: "success"
      });

      setMembers(updatedMembers);
      removeInvestmentGroupFromCache(
        getInvestmentGroupKey(investmentGroup?.id ?? "", user?.uid ?? "")
      );
      getInvestmentGroups?.();
    } catch (e) {
      sendFeedback({
        message: `Failed to update members for ${investmentGroup?.name}`,
        type: "error"
      });
    }
  }, [members, updateGroupMembers, investmentGroup, sendFeedback, user]);

  return (
    <Layer onEsc={onClose} onClickOutside={onClose} margin="large">
      <Box
        width="xlarge"
        height={size === "small" ? "full" : "xlarge"}
        background={{ color: appBackground, dark: theme === UserThemes.DARK }}
        round="small"
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          pad="medium"
          border="bottom"
        >
          <Box gap="xsmall">
            <Heading level={3} responsive={false} margin="none">
              Manage Members
            </Heading>
            <Text color="text-xweak">
              Add or remove members for <b>{investmentGroup?.name}</b>
            </Text>
          </Box>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flex overflow="auto" pad="medium">
          <form onSubmit={onAddMembers}>
            <Box
              direction="row"
              align="center"
              border="all"
              round="small"
              responsive={false}
              pad={{ right: "small", vertical: "4px" }}
            >
              <PlainTextInput
                placeholder="one@test.com, two@test.com"
                plain
                value={newMembers}
                onChange={onUpdateNewMembers}
              />
              <Button size="small" primary label="Add" type="submit" />
            </Box>
          </form>
          <Heading level={4}>Members</Heading>
          <Table>
            <TableBody>
              {orderBy(members, "pending", ["desc"]).map((member) => {
                return (
                  <TableRow key={`table-${member.email}`}>
                    <TableCell>
                      <Box
                        flex
                        direction="row"
                        align="center"
                        justify="between"
                      >
                        <Box direction="row" align="center" gap="small">
                          <Avatar
                            size="32px"
                            src={
                              membersMap[member.id || ""]?.avatarUrl
                                ? membersMap[member.id || ""]?.avatarUrl
                                : `//s.gravatar.com/avatar/${md5(
                                    member?.email || ""
                                  )}?s=80`
                            }
                          />
                          <Box>
                            <Text
                              truncate
                              style={{ maxWidth: "144px" }}
                              title={
                                membersMap[member.id || ""]?.name ||
                                member.email
                              }
                            >
                              {membersMap[member.id || ""]?.name ||
                                member.email}
                            </Text>
                            {member.temp ? (
                              <Text color="text-xweak" size="small">
                                Invitation pending save
                              </Text>
                            ) : (
                              <Text color="text-xweak" size="small">
                                {member.pending ? "Invited" : "Joined"}{" "}
                                {format(
                                  // TODO - FIX ME
                                  new Date(
                                    (member.pending
                                      ? member.invitedAt.seconds ||
                                        // @ts-ignore
                                        member.invitedAt._seconds
                                      : member?.joinedAt?.seconds ||
                                        // @ts-ignore
                                        member?.joinedAt?._seconds) * 1000 || 0
                                  )
                                )}
                              </Text>
                            )}
                          </Box>
                        </Box>
                        {member.pending && (
                          <Text
                            color="status-critical"
                            weight="bold"
                            size="small"
                          >
                            Pending
                          </Text>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell size="150px" align="center">
                      <Select
                        options={[
                          { label: "Admin", value: InvestmentGroupRoles.ADMIN },
                          {
                            label: "Player",
                            value: InvestmentGroupRoles.PLAYER
                          },
                          {
                            label: "Viewer",
                            value: InvestmentGroupRoles.VIEWER
                          }
                        ]}
                        labelKey="label"
                        valueKey={{ key: "value", reduce: true }}
                        value={member.role}
                        size="small"
                        onChange={onUpdateMemberRole(member)}
                      />
                    </TableCell>
                    <TableCell size="40px" align="center">
                      <Button
                        icon={<Trash color="status-critical" size="small" />}
                        onClick={onRemoveMember(member)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Box
          tag="footer"
          justify="end"
          direction="row"
          pad="medium"
          border="top"
          gap="small"
          flex={false}
          responsive={false}
        >
          <Button secondary label="Cancel" onClick={onClose} />
          <LoadingButton
            isLoading={savingMembers.loading}
            label="Save"
            primary
            onClick={onSaveMembers}
          />
        </Box>
      </Box>
    </Layer>
  );
};
