import { dbConnection, getUserInstitutions } from "../../firebase/db";
import {
  getFirstDocument,
  toArrayIncludeIds
} from "../../shared/firebaseUtils";
import { Institution, User } from "../../types";

export const getInstitutionTotal = (institution: Institution) => {
  return institution.accounts.reduce((sum, acc) => {
    const type = acc.customType || acc.type;
    return type === "loan" || type === "credit-card"
      ? sum - acc.balance
      : sum + acc.balance;
  }, 0);
};

export const mergeBalanceWithAccountInfo = (
  institutions: Institution[],
  snapshot: any
) => {
  const institutionsWithBalance = institutions.map((institution) => {
    return {
      ...institution,
      accounts: institution.accounts.map((acc) => {
        const id = acc.id;
        const balanceInfo = snapshot?.accounts.find((b: any) => b.id === id);
        const originalBalanceInfo = acc.balance;
        const _balance = balanceInfo?.balance || originalBalanceInfo;

        let balance;
        if (institution.type === "manual") {
          balance = acc.balance;
        } else {
          balance = _balance;
        }

        return {
          ...acc,
          balance
        };
      })
    };
  });

  return institutionsWithBalance.map((i) => ({
    ...i,
    netWorth: getInstitutionTotal(i)
  }));
};

export const getInstitutionList = async (user: User) => {
  if (!user) return [];
  const ref = await getUserInstitutions(user.uid);
  const [_institutions, institutionIds] = toArrayIncludeIds(ref);
  const institutions = _institutions.map((i: Institution, index: number) => ({
    ...i,
    id: institutionIds[index]
  }));

  const ref2 = await dbConnection
    .collection("users")
    .doc(user.uid)
    .collection("snapshots")
    .where("type", "==", "institution")
    .orderBy("date", "desc")
    .limit(1)
    .get();

  // @ts-ignore function defined in JS
  const institutionDoc = getFirstDocument(ref2)?.data();
  const institutionsWithBalance = mergeBalanceWithAccountInfo(
    institutions,
    institutionDoc
  );

  return institutionsWithBalance.sort((a, b) => b.netWorth - a.netWorth);
};
