import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAsyncFn } from "react-use";
import { useLocation } from "react-router-dom";
import { Anchor, Box, Heading, Paragraph, Text, TextInput } from "grommet";

import { FeedbackContext, LoadingButton, LogoFull } from "../components";
import { useFirebaseContext } from "../firebase";
import { State } from "../types";
import {
  useAccessCodeContext,
  useFirebaseFunction,
  useLoginRedirect,
  useNavigation,
  useQueryParams
} from "../hooks";

export const Landing = () => {
  useLoginRedirect();
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [futureUsers, setFutureUsers] = useState(false);
  const [, setAccessCodeContext] = useAccessCodeContext();
  const { sendFeedback } = useContext(FeedbackContext);
  const { db } = useFirebaseContext();
  const sendRecaptcha = useFirebaseFunction({
    fnName: "sendRecaptcha",
    config: { execute: "once" }
  });
  const { navigate, onNavigate } = useNavigation();
  const { state = {} } = useLocation<State>();
  const query = useQueryParams();

  // the browser URL convert spaces into + sign, so when decoding it converts
  // + sign into spaces, so converting it back.
  const username = query.get("username")?.replaceAll(" ", "+");
  const googleSignUp = query.get("googleSignUp") === "true";

  const toggleFutureUsersForm = useCallback(
    (e) => {
      e.preventDefault();
      setName("");
      setEmail("");
      setCode("");
      setFutureUsers(!futureUsers);
    },
    [futureUsers]
  );

  const [futureUserState, storeFutureUser] = useAsyncFn(async () => {
    try {
      if (!name || !email) {
        return {
          error: "Name and email is required",
          success: false
        };
      }
      await db.createFutureUser({
        email,
        name
      });
      setName("");
      setEmail("");
      return {
        error: false,
        success:
          "Done. A new code will be sent to you as soon as new spots are available"
      };
    } catch (error) {
      return {
        error:
          "An unexpected error happened while trying to save your information",
        success: false
      };
    }
  }, [name, email]);

  const [accessCodeState, fetchAccessCode] = useAsyncFn(async () => {
    if (!code) {
      return {
        error: "Code is required"
      };
    }
    const ref = await db.getAccessCode(code);
    const accessCodeData = await ref.get();
    const accessCode = accessCodeData.data();
    const today = new Date().getTime() / 1000;

    if (accessCode) {
      if (today < accessCode.expireIn.seconds) {
        const accessCodeCountSnapshot = await db.getAccessCodeCount(code);
        const usedCodeCount = accessCodeCountSnapshot.size;
        if (usedCodeCount < accessCode.limit) {
          setAccessCodeContext(code);
          navigate("/login", {
            ...state,
            username,
            googleSignUp,
            allowSignUp: true
          });
          return {
            accessCode
          };
        } else {
          return {
            error: "This code already reached the usage limit!"
          };
        }
      } else {
        return {
          error: "Code has expired"
        };
      }
    }
    return {
      error: "Code is invalid"
    };
  }, [db, code]);

  useEffect(() => {
    const bypassToken = async () => {
      const props = await db.getFastProperties();

      if (!props.requireAccessCode) {
        navigate("/login", {
          ...state,
          username,
          googleSignUp,
          allowSignUp: true
        });
      } else {
        setShowForm(true);
      }
    };

    bypassToken();
  }, [db, navigate, state, username, googleSignUp]);

  useEffect(() => {
    if (accessCodeState?.value?.error) {
      sendFeedback({
        message: accessCodeState?.value?.error,
        type: "error"
      });
      accessCodeState.value.error = "";
    }
  }, [accessCodeState?.value, sendFeedback]);

  useEffect(() => {
    if (futureUserState?.value?.error) {
      sendFeedback({
        message: `${futureUserState.value.error}`,
        type: "error"
      });
      futureUserState.value.error = "";
    } else if (futureUserState?.value?.success) {
      sendFeedback({
        message: `${futureUserState?.value?.success}`,
        type: "success"
      });
      futureUserState.value.success = "";
    }
  }, [futureUserState?.value, sendFeedback]);

  return (
    <Box fill align="center" justify="center">
      <Box width="medium" align="center" gap="small">
        {!showForm && (
          <>
            <LogoFull color="plain" size="large" />
            <Heading
              textAlign="center"
              level={2}
              margin={{ top: "small", bottom: "none" }}
            ></Heading>
          </>
        )}
        {showForm && (
          <>
            <LogoFull color="plain" size="large" />
            <Heading
              textAlign="center"
              level={2}
              margin={{ top: "small", bottom: "none" }}
            >
              <b>Access Code Required</b>
            </Heading>
            <Paragraph textAlign="center" color="text-weak" margin="none">
              Our application isn't ready for the public yet. To have access now
              you need to provide us with your access code.
            </Paragraph>
            {!futureUsers && (
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  fetchAccessCode();
                }}
              >
                <Box margin={{ top: "small" }} alignSelf="stretch" gap="medium">
                  <TextInput
                    value={code}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      setCode(value);
                    }}
                    size="xlarge"
                    placeholder="access code"
                  />
                  <LoadingButton
                    size="large"
                    isLoading={accessCodeState.loading}
                    label="Submit"
                    primary
                    type="submit"
                    alignSelf="stretch"
                  />
                  <Box
                    margin={{ top: "small" }}
                    justify="center"
                    direction="row"
                    gap="xsmall"
                  >
                    <Anchor
                      href="#"
                      onClick={toggleFutureUsersForm}
                      label="Don't have a code?"
                    />
                  </Box>
                  <Box
                    margin={{ top: "small" }}
                    justify="center"
                    direction="row"
                    gap="xsmall"
                  >
                    <Text color="text-xweak">Already have an account?</Text>
                    <Anchor
                      href="#"
                      onClick={onNavigate("/login", state)}
                      label="Sign In"
                    />
                  </Box>
                </Box>
              </form>
            )}
            {futureUsers && (
              <form
                style={{ width: "100%" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  // @ts-ignore
                  window.grecaptcha.ready(async () => {
                    // @ts-ignore
                    const token: string = await window.grecaptcha.execute(
                      "6LeVXuwZAAAAAHj6_Ga6f_2fLVEBF0rQiDFHvOPB",
                      {
                        action: "form"
                      }
                    );

                    try {
                      const response = await sendRecaptcha({ token });
                      const data = response?.data;
                      if (!data || data.score > 0.5) {
                        storeFutureUser();
                      }
                    } catch (error) {}
                  });
                }}
              >
                <Box
                  flex
                  direction="column"
                  margin={{ top: "small" }}
                  alignSelf="stretch"
                  gap="medium"
                >
                  <Box
                    flex
                    direction="column"
                    margin={{ top: "small" }}
                    alignSelf="stretch"
                    gap="medium"
                  >
                    <TextInput
                      value={name}
                      onChange={(e) => {
                        const { value } = e.currentTarget;
                        setName(value);
                      }}
                      placeholder="Name"
                    />
                    <TextInput
                      value={email}
                      onChange={(e) => {
                        const { value } = e.currentTarget;
                        setEmail(value);
                      }}
                      placeholder="E-mail"
                    />
                    <LoadingButton
                      type="submit"
                      size="large"
                      isLoading={
                        futureUserState.loading ||
                        !!futureUserState?.value?.success
                      }
                      label="Join Waitlist"
                      primary
                      alignSelf="stretch"
                    />
                  </Box>
                  <Box justify="center" direction="row" gap="xsmall">
                    <Anchor
                      href="#"
                      onClick={toggleFutureUsersForm}
                      label="Go back"
                    />
                  </Box>
                </Box>
              </form>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
