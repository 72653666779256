import React, { useContext } from "react";
import { Box, BoxProps, Heading, ResponsiveContext, Text } from "grommet";
import numeral from "numeral";

import { useAppState } from "../../hooks";

import { PercentagePill } from "../";

export const DashboardHeader = ({ flex }: BoxProps) => {
  const { snapshots, user } = useAppState();
  const size = useContext(ResponsiveContext);
  return (
    <Box
      justify="between"
      direction="row"
      align="center"
      pad={{ horizontal: "medium", vertical: "small" }}
      flex={flex}
    >
      <Box>
        <Heading
          level={3}
          margin="none"
          size={size !== "large" ? "xsmall" : undefined}
        >
          {numeral(user?.netWorth || 0).format("$0.[00]a")}
        </Heading>
        <Heading
          level={5}
          size={size !== "large" ? "small" : undefined}
          margin="none"
          color="text-weak"
        >
          Current Net Worth
        </Heading>
        <Box direction="row" align="center" gap="xsmall">
          <PercentagePill
            value={
              snapshots?.weekly?.data?.total?.balance.change.percentage || 0
            }
            cadence="week"
          />
          <PercentagePill
            value={
              snapshots?.monthly?.data?.total?.balance.change.percentage || 0
            }
            cadence="month"
          />
          <PercentagePill
            value={
              snapshots?.yearly?.data?.total?.balance.change.percentage || 0
            }
            cadence="year"
            splitter={false}
          />
        </Box>
      </Box>
      <Box align="end">
        <Heading
          level={3}
          margin="none"
          size={size !== "large" ? "xsmall" : undefined}
        >
          {numeral((user?.retireWith || 0) * 200).format("$0.[00]a")}
        </Heading>
        <Heading
          level={5}
          size={size !== "large" ? "small" : undefined}
          margin="none"
          color="text-weak"
        >
          Target Net Worth
        </Heading>
        <Box
          direction="row"
          justify="end"
          align="center"
          gap="xsmall"
          pad={{ vertical: "xsmall" }}
        >
          <Box
            background="status-ok"
            round="small"
            pad={{ horizontal: "xsmall" }}
            responsive={false}
          >
            <Text color="white" size="small" weight="bold">
              on track
            </Text>
          </Box>
          {size === "large" && (
            <Text color="text-xweak" size="small">
              for retirement in <b>{user?.retireIn || 0} years</b>
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};
