import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef
} from "react";
import { Box, Button, ButtonType, DropButton, Text } from "grommet";
import { FormDown, FormUp } from "grommet-icons";
import { UserThemes } from "../types";
import { TRTThemeContext } from "../context";

export interface SplitButtonProps {
  label: string;
  items: ButtonType[];
}

export const SplitButton = ({ label, items }: SplitButtonProps) => {
  const { theme } = useContext(TRTThemeContext);
  const [open, setOpen] = useState(false);
  const dropButtonRef = useRef<HTMLButtonElement>(null);
  const Icon = open ? FormDown : FormUp;
  const onSplitButtonClick = useCallback(() => setOpen(!open), [open]);
  const onSplitButtonClose = useCallback(() => setOpen(false), []);
  const onClickOutside = useCallback(
    (e) => {
      if (dropButtonRef.current && !dropButtonRef.current?.contains(e.target)) {
        onSplitButtonClose();
      }
    },
    [onSplitButtonClose]
  );
  const renderDropContent = useMemo(
    () => (
      <>
        {items.map(({ icon, label, ...rest }, index) => (
          <Button
            {...rest}
            key={`split_button_item${index}`}
            plain
            hoverIndicator
          >
            <Box
              direction="row"
              align="center"
              gap="small"
              pad="12px"
              responsive={false}
            >
              {icon}
              {label}
            </Box>
          </Button>
        ))}
      </>
    ),
    [items]
  );
  return (
    <DropButton
      ref={dropButtonRef}
      open={open}
      onClick={onSplitButtonClick}
      dropProps={{
        align: { bottom: "top" },
        background: { color: "drop", dark: theme === UserThemes.DARK },
        onEsc: onSplitButtonClose,
        onClickOutside
      }}
      dropContent={renderDropContent}
    >
      <Box flex direction="row" align="center" background="brand" round="8px">
        <Box
          flex
          align="center"
          pad={{ vertical: "small" }}
          border={{ side: "right", color: "dark-4" }}
        >
          <Text weight="bold">{label}</Text>
        </Box>
        <Box pad={{ horizontal: "small" }}>
          <Icon color="text" />
        </Box>
      </Box>
    </DropButton>
  );
};
