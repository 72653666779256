import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from "react";
import { useEffectReducer, EffectReducer } from "use-effect-reducer";
import { orderBy } from "lodash";
import {
  AppState,
  AppContextState,
  InitialLoadStatus,
  Institution,
  User,
  UserSnapshots,
  Insights,
  RealEstate,
  AccountRow,
  RestimateDataWithRates
} from "../types";
import {
  LoadInitialDataResponse,
  useFirebaseFunction,
  useLoadInitialData
} from "../hooks";
import {
  addElementToArray,
  getAccountName,
  replaceElementInArray
} from "../utils";
import { getAssetsAndLiabilities } from "../shared/accounts";
import { PLANS } from "../constants";
import { useLoggedUser } from "../firebase";
import { removeLoadInitialDataCache, setLoadInitialDataCache } from "../cache";

const initialDataPoint = {
  from: 0,
  to: 0,
  changePercentage: 0,
  value: 0,
  changeValue: 0
};

export const initialHistoricalData = {
  weekly: {
    data: [],
    label: "",
    number: 0,
    goal: 0,
    summary: {
      investments: initialDataPoint,
      netWorth: initialDataPoint,
      spy: initialDataPoint,
      cash: initialDataPoint,
      loan: initialDataPoint,
      creditCard: initialDataPoint,
      name: ""
    }
  },
  monthly: {
    data: [],
    label: "",
    number: 0,
    goal: 0,
    summary: {
      investments: initialDataPoint,
      netWorth: initialDataPoint,
      spy: initialDataPoint,
      cash: initialDataPoint,
      loan: initialDataPoint,
      creditCard: initialDataPoint,
      name: ""
    }
  },
  yearly: {
    data: [],
    label: "",
    number: 0,
    goal: 0,
    summary: {
      investments: initialDataPoint,
      netWorth: initialDataPoint,
      spy: initialDataPoint,
      cash: initialDataPoint,
      loan: initialDataPoint,
      creditCard: initialDataPoint,
      name: ""
    }
  }
};

const initialState = {
  dispatch: () => {},
  signOut: () => {},
  status: "initial" as InitialLoadStatus,
  institutions: [] as Institution[],
  realEstate: [] as RealEstate[],
  automatedInstitutionsCount: 0,
  projections: [],
  hasInstitutionsProcessing: false,
  hasManualInstitutions: false,
  subscription: {
    name: PLANS.BASIC,
    totalAutomatedInstitutionsAllowed: 1
  },
  historicalData: initialHistoricalData,
  restimate: {
    projections: {
      weekly: [],
      monthly: [],
      yearly: []
    },
    weekRate: 0,
    monthRate: 0,
    yearRate: 0
  }
};

const normalizeInstitutions = (
  institutions: Institution[],
  recentlyAddedInstitutionId?: string
) => {
  const normalized = institutions.map((institution) => ({
    ...institution,
    visibleAccounts:
      institution?.accounts?.filter((account) => !account.hidden) || []
  }));

  if (recentlyAddedInstitutionId) {
    const index = institutions.findIndex(
      (i) => i.id === recentlyAddedInstitutionId
    );

    if (index !== -1) {
      return [
        normalized[index],
        ...normalized.slice(0, index),
        ...normalized.slice(index + 1)
      ];
    } else {
      return normalized;
    }
  }

  return normalized;
};

export const AppContext = createContext<AppState>(initialState);

export type AppEvent =
  | { type: "SET_INITIAL_DATA"; data: AppState }
  | { type: "REFRESH_DATA"; data: AppState; user?: User }
  | { type: "ADD_INSTITUTION"; metadata: any }
  | { type: "UPDATE_INSTITUTION"; institution: Institution }
  | { type: "ADD_REAL_ESTATE"; realEstate: RealEstate }
  | { type: "UPDATE_REAL_ESTATE"; realEstate: RealEstate }
  | {
      type: "UPDATE_INSTITUTIONS";
      institutions: Institution[];
      data: Partial<Institution>;
    }
  | { type: "REMOVE_INSTITUTION"; id: string }
  | { type: "SET_TOKEN"; token: string }
  | { type: "UPDATE_USER"; user: User }
  | { type: "UPDATE_SNAPSHOTS"; snapshots: UserSnapshots }
  | { type: "SET_RESTIMATE"; restimate: RestimateDataWithRates }
  | { type: "SET_TOKEN"; token: string }
  | { type: "RESET_STATE" };

const getUserNetWorthAndAssets = (
  newInstitutions: Institution[],
  realEstate: RealEstate[]
) => {
  const assetsAndLiabilities = getAssetsAndLiabilities(
    newInstitutions,
    realEstate
  );

  return {
    netWorth: assetsAndLiabilities.assets - assetsAndLiabilities.liabilities,
    ...assetsAndLiabilities
  };
};

const reducer: EffectReducer<AppState, AppEvent> = (state, action, exec) => {
  if (action.type === "SET_INITIAL_DATA") {
    const newInstitutions: Institution[] = action.data.institutions || [];
    exec({ type: "loadToken", uid: action.data.user?.uid });
    exec({ type: "loadRestimate", uid: action.data.user?.uid });

    return {
      ...state,
      ...action.data,
      institutions: normalizeInstitutions(newInstitutions)
    };
  } else if (action.type === "REFRESH_DATA") {
    const newInstitutions: Institution[] = action.data.institutions || [];
    const user = {
      ...(action.user || state.user)
    } as User;

    return {
      ...state,
      ...action.data,
      institutions: normalizeInstitutions(
        newInstitutions,
        state.recentlyAddedInstitutionId
      ),
      user
    };
  } else if (action.type === "ADD_INSTITUTION") {
    const newInstitutions = addElementToArray(
      state.institutions,
      0,
      action.metadata
    );

    const userAutomatedInstitutionsInUse =
      action.metadata.type === "automated"
        ? state.user!.automatedInstitutionsInUse + 1
        : state.user!.automatedInstitutionsInUse;

    const institutions = normalizeInstitutions(newInstitutions);

    return {
      ...state,
      institutions,
      recentlyAddedInstitutionId: action.metadata.id,
      user: {
        ...state.user,
        automatedInstitutionsInUse: userAutomatedInstitutionsInUse
      } as User
    };
  } else if (action.type === "ADD_REAL_ESTATE") {
    const newRealEstate = addElementToArray(
      state.realEstate,
      0,
      action.realEstate
    );

    return {
      ...state,
      realEstate: newRealEstate
    };
  } else if (action.type === "UPDATE_REAL_ESTATE") {
    const index = state.realEstate.findIndex(
      (i) => i.id === action.realEstate.id
    );

    const newRealEstate = replaceElementInArray(
      state.realEstate,
      index,
      action.realEstate
    );

    return {
      ...state,
      realEstate: newRealEstate
    };
  } else if (action.type === "REMOVE_INSTITUTION") {
    const newInstitutions = state.institutions.filter(
      (i) => i.id !== action.id
    );
    const userAutomatedInstitutionsInUse = newInstitutions.reduce(
      (acc, i) => (i.type === "automated" ? acc + 1 : acc),
      0
    );
    const institutions = normalizeInstitutions(newInstitutions);
    return {
      ...state,
      institutions,
      user: {
        ...state.user,
        ...getUserNetWorthAndAssets(newInstitutions, state.realEstate),
        automatedInstitutionsInUse: userAutomatedInstitutionsInUse
      } as User
    };
  } else if (
    action.type === "UPDATE_INSTITUTION" ||
    action.type === "UPDATE_INSTITUTIONS"
  ) {
    let newInstitutions;
    if (action.type === "UPDATE_INSTITUTION") {
      const index = state.institutions.findIndex(
        (i) => i.id === action.institution.id
      );

      newInstitutions = replaceElementInArray(
        state.institutions,
        index,
        action.institution
      );
    } else {
      newInstitutions = state.institutions.map((institution) => {
        const newInstitution = action.institutions.find(
          (i) => institution.id === i.id
        );
        if (newInstitution) {
          return { ...institution, ...action.data };
        }
        return institution;
      });
    }

    const userAutomatedInstitutionsInUse = newInstitutions.reduce(
      (acc, i) => (i.type === "automated" ? acc + 1 : acc),
      0
    );

    const institutions = normalizeInstitutions(newInstitutions);

    return {
      ...state,
      institutions,
      user: {
        ...state.user,
        ...getUserNetWorthAndAssets(newInstitutions, state.realEstate),
        userAutomatedInstitutionsInUse: userAutomatedInstitutionsInUse
      } as User
    };
  } else if (action.type === "UPDATE_USER") {
    return {
      ...state,
      user: action.user
    };
  } else if (action.type === "UPDATE_SNAPSHOTS") {
    return {
      ...state,
      snapshots: action.snapshots
    };
  } else if (action.type === "SET_TOKEN") {
    return {
      ...state,
      token: action.token
    };
  } else if (action.type === "SET_RESTIMATE") {
    return {
      ...state,
      restimate: action.restimate
    };
  } else if (action.type === "RESET_STATE") {
    return initialState;
  }

  return state;
};

export const AppContextDispatcher = createContext<any>(undefined);

export const useAppState = (): AppContextState => {
  const appContext = useContext(AppContext);
  const { refetchUser } = useLoggedUser();
  const dispatch = useContext(AppContextDispatcher);

  if (appContext === undefined || dispatch === undefined) {
    throw new Error(
      `Please make sure you're using useAppState inside a AppContextProvider`
    );
  }

  const actions = useMemo(
    () => ({
      resetState: () => {
        dispatch({ type: "RESET_STATE" });
      },
      refreshData: (data: Partial<AppState>, user: User) => {
        dispatch({ type: "REFRESH_DATA", data, user });
      },
      addInstitution: (metadata: any) => {
        dispatch({ type: "ADD_INSTITUTION", metadata });
      },
      updateInstitution: (institution: Institution) => {
        dispatch({ type: "UPDATE_INSTITUTION", institution });
      },
      removeInstitution: (id: string) => {
        dispatch({ type: "REMOVE_INSTITUTION", id });
      },
      updateRealEstate: (realEstate: RealEstate) => {
        dispatch({ type: "UPDATE_REAL_ESTATE", realEstate });
      },
      addRealEstate: (realEstate: RealEstate) => {
        dispatch({ type: "ADD_REAL_ESTATE", realEstate });
      },
      removeRealEstate: (id: string) => {
        dispatch({ type: "REMOVE_REAL_ESTATE", id });
      },
      updateUser: (user: User) => {
        dispatch({ type: "UPDATE_USER", user });
      },
      reloadUser: () => {
        const { user } = appContext;
        dispatch({ type: "UPDATE_USER", user });
      },
      updateSnapshots: (snapshots: UserSnapshots) => {
        dispatch({ type: "UPDATE_SNAPSHOTS", snapshots });
      },
      updateInstitutions: (
        institutions: Institution[],
        data: Partial<Institution>
      ) => {
        dispatch({ type: "UPDATE_INSTITUTIONS", institutions, data });
      },
      setRestimate: (restimate: RestimateDataWithRates) => {
        dispatch({ type: "SET_RESTIMATE", restimate });
      }
    }),
    [appContext, dispatch]
  );

  const updateBalanceFn = useFirebaseFunction({
    fnName: "updateBalance"
  });
  const loadInitialData = useFirebaseFunction({ fnName: "loadInitialData" });
  const loadRestimateData = useFirebaseFunction({
    fnName: "loadRestimateData"
  });

  const refreshData = useCallback(
    async ({ skipUpdate = false, skipPlaidUpdate = false } = {}) => {
      removeLoadInitialDataCache(appContext.user?.uid ?? "");
      const { data }: { data: Partial<AppState> } = await loadInitialData({
        userId: appContext.user?.uid,
        skipUpdate,
        skipPlaidUpdate
      });
      const { data: restimateData } = await loadRestimateData({
        userId: appContext.user?.uid
      });
      dispatch({ type: "SET_RESTIMATE", restimate: restimateData });
      setLoadInitialDataCache(
        appContext.user?.uid ?? "",
        data as LoadInitialDataResponse
      );
      const user = await refetchUser();

      actions.refreshData(data, user);
    },
    [
      loadInitialData,
      appContext,
      actions,
      refetchUser,
      dispatch,
      loadRestimateData
    ]
  );

  const updateBalance = useCallback(
    async ({ userId, insights }: { userId: string; insights?: Insights }) => {
      await updateBalanceFn({ userId, insights });
      await refreshData({ skipUpdate: true });
    },
    [refreshData, updateBalanceFn]
  );

  const hasInstitutionsProcessing = useMemo(
    () =>
      (appContext.institutions || [])
        .filter(Boolean)
        .some(({ processing }) => Boolean(processing)),
    [appContext.institutions]
  );

  const manualInstitutions = useMemo(
    () =>
      (appContext.institutions || [])
        .filter(Boolean)
        .filter(({ type }) => type === "manual"),
    [appContext.institutions]
  );

  const hasManualInstitutions = useMemo(
    () => Boolean(manualInstitutions.length),
    [manualInstitutions]
  );

  const manualRealEstate = useMemo(
    () => (appContext.realEstate || []).filter(({ type }) => type === "manual"),
    [appContext.realEstate]
  );

  const hasManualRealEstate = useMemo(
    () => Boolean(manualRealEstate.length),
    [manualRealEstate]
  );

  const hasSomeManualInvestmentAccount = useMemo(
    () =>
      manualInstitutions.some(({ accounts }) =>
        accounts.some(({ type }) => type === "investment")
      ),
    [manualInstitutions]
  );

  const loansInUse = useMemo(
    () =>
      (appContext.realEstate || []).reduce(
        (loansInUse, realEstate) => [...loansInUse, ...realEstate.loans],
        [] as string[]
      ),
    [appContext.realEstate]
  );

  const getAvailableLoans = useCallback(
    (existingLoans?: string[]) =>
      orderBy(
        (appContext.institutions || []).reduce((loans, institution) => {
          const institutionLoans = institution.accounts.filter(
            (account) =>
              account.type === "loan" &&
              !account.hidden &&
              (!loansInUse.includes(account.id) ||
                (existingLoans || []).includes(account.id))
          );
          if (institutionLoans.length) {
            return [
              ...loans,
              ...institutionLoans.map(
                (account) =>
                  ({
                    institution,
                    account,
                    label: getAccountName(account),
                    id: account.id
                  } as AccountRow)
              )
            ] as AccountRow[];
          }
          return loans;
        }, [] as AccountRow[]),
        "account.balance",
        "desc"
      ),
    [appContext.institutions, loansInUse]
  );

  const getAccountRowByAccountId = useCallback(
    (accountId: string) => {
      let accountRow = undefined;
      appContext.institutions.some((institution) => {
        const account = institution.accounts.find(({ id }) => id === accountId);

        if (account) {
          accountRow = {
            label: getAccountName(account),
            id: account.id,
            institution,
            account
          } as AccountRow;
          return true;
        }
        return false;
      });
      return accountRow;
    },
    [appContext.institutions]
  );

  return useMemo(
    () => ({
      ...appContext,
      ...actions,
      refreshData,
      hasManualInstitutions,
      hasInstitutionsProcessing,
      manualInstitutions,
      hasSomeManualInvestmentAccount,
      updateBalance,
      getAvailableLoans,
      getAccountRowByAccountId,
      manualRealEstate,
      hasManualRealEstate
    }),
    [
      appContext,
      actions,
      refreshData,
      hasInstitutionsProcessing,
      hasManualInstitutions,
      manualInstitutions,
      hasSomeManualInvestmentAccount,
      updateBalance,
      getAvailableLoans,
      getAccountRowByAccountId,
      manualRealEstate,
      hasManualRealEstate
    ]
  );
};

export const AppContextProvider = ({
  children
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  const data = useLoadInitialData();
  const loadTokenFn = useFirebaseFunction({ fnName: "loadToken" });
  const loadRestimateFn = useFirebaseFunction({ fnName: "loadRestimateData" });
  const effectMap = useMemo(
    () => ({
      // @ts-ignore - useEffect does not provide types
      loadToken: (state, effect, dispatch) => {
        if (effect.uid && state.status === "done" && !state.token) {
          loadTokenFn({ userId: effect.uid }).then((response: any) => {
            dispatch({ type: "SET_TOKEN", token: response.data });
          });
        }
      },
      // @ts-ignore - useEffect does not provide types
      loadRestimate: (state, effect, dispatch) => {
        if (
          effect.uid &&
          state.status === "done" &&
          state.restimate.projections.weekly.length === 0
        ) {
          loadRestimateFn({ userId: effect.uid }).then((response: any) => {
            dispatch({ type: "SET_RESTIMATE", restimate: response.data });
          });
        }
      }
    }),
    [loadTokenFn, loadRestimateFn]
  );

  const [state, dispatch] = useEffectReducer(reducer, initialState, effectMap);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_INITIAL_DATA", data });
    }
  }, [data, dispatch]);

  return (
    <AppContext.Provider value={state}>
      <AppContextDispatcher.Provider value={dispatch}>
        {children}
      </AppContextDispatcher.Provider>
    </AppContext.Provider>
  );
};
