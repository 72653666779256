import React, { useCallback, useContext, useState } from "react";
import { Box, DataTable, ResponsiveContext, Text, TextArea } from "grommet";
import { useAsyncFn } from "react-use";
import {
  FeedbackContext,
  FormLabel,
  LoadingButton
} from "../../../../components";
import { useFirebaseFunction } from "../../../../hooks";

const COLUMNS = [
  {
    property: "token",
    header: "Invalid Tokens",
    primary: true
  }
];

export const PlaidTokensBody = () => {
  const { sendFeedback } = useContext(FeedbackContext);
  const [tokens, setTokens] = useState("");
  const size = useContext(ResponsiveContext);

  const validateTokens = useFirebaseFunction({
    fnName: "validateTokens"
  });

  const deleteTokensFn = useFirebaseFunction({
    fnName: "deleteInstitutionsByIds"
  });

  const onTokensChange = useCallback(({ target: { value } }) => {
    setTokens(value);
  }, []);

  const [invalidTokens, validate] = useAsyncFn(async () => {
    try {
      const response = await validateTokens({ tokens });
      return response.data;
    } catch (e) {
      sendFeedback({
        message: "Failed to validate tokens",
        type: "error"
      });
    }
  }, [tokens]);

  const [deletedTokens, deleteTokens] = useAsyncFn(async () => {
    try {
      const response = await deleteTokensFn({
        tokens: invalidTokens.value
      });
      sendFeedback({
        message: "Tokens deleted successfully",
        type: "success"
      });
      return response.data;
    } catch (e) {
      sendFeedback({
        message: "Failed to delete tokens",
        type: "error"
      });
    }
  }, [invalidTokens]);

  return (
    <Box flex>
      <Box
        flex={false}
        border="bottom"
        pad={{ horizontal: "medium", top: "small", bottom: "medium" }}
        background="card"
      >
        <Box
          width={size !== "small" ? "xlarge" : undefined}
          height={size !== "small" ? "medium" : undefined}
        >
          <FormLabel label="Access Tokens" htmlFor="plaid-access-tokens" />
          <TextArea
            fill
            id="plaid-access-tokens"
            size="medium"
            placeholder="Paste all the tokens you would like to validate, one per line."
            value={tokens}
            onChange={onTokensChange}
          />
        </Box>
        <Box
          direction="row"
          align="center"
          gap="small"
          responsive={false}
          justify="start"
          margin={{ top: "small" }}
        >
          <LoadingButton
            isLoading={invalidTokens.loading}
            primary
            label="Validate"
            size="small"
            onClick={validate}
          />
        </Box>
      </Box>
      <Box flex overflow="auto" pad="medium">
        {invalidTokens.loading && (
          <Text color="text-weak" alignSelf="center" margin={{ top: "medium" }}>
            Loading Tokens data
          </Text>
        )}
        {!invalidTokens.loading && !invalidTokens.value?.length && (
          <Text color="text-weak" alignSelf="center" margin={{ top: "medium" }}>
            No Invalid tokens
          </Text>
        )}
        {invalidTokens.value?.length > 0 && (
          <Box flex direction="column">
            <DataTable
              size="medium"
              columns={COLUMNS}
              data={
                invalidTokens.value?.map((token: string) => ({ token })) || []
              }
            />
            <Box pad={{ top: "medium" }} width="small">
              <LoadingButton
                isLoading={deletedTokens.loading}
                primary
                label={`Delete ${invalidTokens.value?.length} tokens`}
                size="small"
                onClick={deleteTokens}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
