import React, { useCallback, useMemo } from "react";
import { Box, Paragraph } from "grommet";
import { Add } from "grommet-icons";
import { orderBy } from "lodash";

import { AccountRow, ActivityRow, InsightsProps } from "../../types";
import { useAppState } from "../../context";
import { getActiveInstitutionAccountRows } from "../../utils";

import {
  ActivityCard,
  CallOutButton,
  getActivityRowsFromInsights,
  getNewActivity
} from "../";

export const ActivityTab = ({ insights, onUpdate }: InsightsProps) => {
  const { manualInstitutions, institutions } = useAppState();

  const availableInstitutionAccounts = useMemo(
    () => getActiveInstitutionAccountRows(manualInstitutions, true),
    [manualInstitutions]
  );

  const activityRows: ActivityRow[] = orderBy(
    getActivityRowsFromInsights(insights, institutions) || [],
    ["activityItem.sequence"],
    ["asc"]
  );

  const onRemoveActivity = useCallback(
    (activityRow: ActivityRow) => {
      const newInsights = { ...insights };
      const {
        institution: { id: institutionId },
        account: { id: accountId }
      } = activityRow.accountRow;
      newInsights.institutions[institutionId].accounts[accountId].activity =
        newInsights.institutions[institutionId].accounts[
          accountId
        ].activity?.filter(({ id }) => id !== activityRow.activityItem.id) ||
        [];
      onUpdate(newInsights);
    },
    [insights, onUpdate]
  );

  const onAddNewActivity = useCallback(
    (institutionId: string, accountId: string) => () => {
      const newInsights = { ...insights };
      newInsights.institutions[institutionId].accounts[accountId].activity = [
        ...(newInsights.institutions[institutionId].accounts[accountId]
          .activity || []),
        getNewActivity(activityRows.length + 1)
      ];
      onUpdate(newInsights);
    },
    [insights, onUpdate, activityRows.length]
  );

  const onUpdateActivity = useCallback(
    (activityRow: ActivityRow, existingAccountRow: AccountRow) => {
      const newInsights = { ...insights };
      const {
        institution: { id: institutionId },
        account: { id: accountId }
      } = activityRow.accountRow;
      if (existingAccountRow.account.id !== accountId) {
        onRemoveActivity({
          ...activityRow,
          accountRow: { ...existingAccountRow }
        });
        newInsights.institutions[institutionId].accounts[accountId].activity = [
          ...(newInsights.institutions[institutionId].accounts[accountId]
            .activity || []),
          activityRow.activityItem
        ];
      }
      newInsights.institutions[institutionId].accounts[accountId].activity =
        newInsights.institutions[institutionId].accounts[
          accountId
        ].activity?.map((activitySnapshot) => {
          if (activitySnapshot.id === activityRow.activityItem.id) {
            return activityRow.activityItem;
          }
          return activitySnapshot;
        }) || [];
      onUpdate(newInsights);
    },
    [insights, onUpdate, onRemoveActivity]
  );

  const {
    institution: { id: institutionId },
    account: { id: accountId }
  } = availableInstitutionAccounts[0];

  return (
    <Box fill align="center" responsive={false}>
      <Box pad={{ top: "small" }} flex={false}>
        <CallOutButton
          icon={<Add size="large" color="tab-active" />}
          label="Add Activity"
          onClick={onAddNewActivity(institutionId, accountId)}
        />
      </Box>
      <Box
        direction="row"
        align="center"
        justify="center"
        wrap
        style={{ maxWidth: "1360px" }}
        responsive={false}
        pad={{ top: "small", bottom: "large", horizontal: "small" }}
        flex={false}
      >
        {activityRows.length ? (
          activityRows.map((activityRow) => (
            <ActivityCard
              key={`activity-card${activityRow.activityItem.id}`}
              activityRow={activityRow}
              availableInstitutionAccounts={availableInstitutionAccounts}
              onRemove={onRemoveActivity}
              onUpdate={onUpdateActivity}
            />
          ))
        ) : (
          <Paragraph color="text-weak">
            Add one or more activity item by clicking on the button above
          </Paragraph>
        )}
      </Box>
    </Box>
  );
};
