import { Box, Button, ButtonExtendedProps, Spinner } from "grommet";

interface LoadingButtonProps {
  isLoading?: boolean;
}

export const LoadingButton = ({
  isLoading,
  label,
  disabled,
  ...rest
}: LoadingButtonProps & ButtonExtendedProps) => {
  return (
    <Button
      {...rest}
      disabled={disabled || isLoading}
      label={
        <Box flex justify="center" align="center">
          <span
            style={
              isLoading
                ? {
                    visibility: "hidden",
                    height: 0,
                    maxHeight: 0
                  }
                : {}
            }
          >
            {label}
          </span>
          {isLoading && <Spinner color="dark-1" size="xsmall" />}
        </Box>
      }
    />
  );
};
