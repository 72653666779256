import { useCallback, useContext, useState } from "react";
import { Box, Text } from "grommet";
import { StatusCritical } from "grommet-icons";
import { useAppState } from "../context";
import { useFirebaseFunction } from "../hooks";
import { FeedbackContext } from "../components";
import { ConfirmationModal } from "./";
import { removeAllInvestmentGroupsFromCache } from "../cache";

export const ConfirmResetRestimate = ({ onClose }: { onClose: () => void }) => {
  const { user, refreshData } = useAppState();
  const { sendFeedback } = useContext(FeedbackContext);
  const resetRestimate = useFirebaseFunction({
    fnName: "resetRestimate"
  });
  const [resetting, setResetting] = useState(false);

  const onConfirm = useCallback(async () => {
    setResetting(true);
    try {
      if (user) {
        removeAllInvestmentGroupsFromCache(user);

        await resetRestimate({ userId: user?.uid });
        await refreshData({ skipUpdate: true });
        sendFeedback({
          message: "Your data has been reset",
          type: "success"
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        sendFeedback({
          message: e.message,
          type: "error"
        });
      }
    } finally {
      setResetting(false);
    }

    onClose();
  }, [resetRestimate, user, onClose, sendFeedback, refreshData]);

  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onClose}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to reset your data?`}
      actionLabel="Confirm"
      loading={resetting}
    >
      <Box gap="medium" responsive={false}>
        <Text color="text-weak">
          Resetting your data will remove your history and performance and this
          action cannot be reverted.
        </Text>
      </Box>
    </ConfirmationModal>
  );
};
