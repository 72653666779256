import React from "react";
import { Box, Text } from "grommet";

import { OnboardingFooter } from "./";

type OnboardingLayoutProps = {
  onPreviousStep?: () => void;
  onNextStep?: () => void;
  step?: string;
};

export const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  children,
  onPreviousStep,
  onNextStep,
  step
}) => {
  return (
    <Box flex>
      <Box align="end" pad={{ vertical: "small", horizontal: "medium" }}>
        <Text color="text-xweak">{step}</Text>
      </Box>
      <Box flex overflow="auto">
        <Box
          flex={false}
          pad={{ horizontal: "large", vertical: "medium" }}
          align="center"
          gap="large"
        >
          {children}
        </Box>
      </Box>
      {(onPreviousStep || onNextStep) && (
        <OnboardingFooter
          onPreviousStep={onPreviousStep}
          onNextStep={onNextStep}
        />
      )}
    </Box>
  );
};
