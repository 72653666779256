import React, { useEffect, useState } from "react";
import { Grommet } from "grommet";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./app";
import { trtTheme } from "./themes";
import { auth, db, storage, FirebaseContext } from "./firebase";
import UserProvider from "./firebase/user-context";
import { AccessCodeContext, AppContextProvider } from "./context";
import { useQueryParams } from "./hooks";

const RouteWrapper = () => {
  const [code, setCode] = useState<string>();
  const params = useQueryParams();
  const acquisitionSource = params.get('utm_source');
  useEffect(() => {
    if (code !== acquisitionSource) {
      setCode(acquisitionSource || '');
    }
  }, [acquisitionSource, code]);
  return (
    <AccessCodeContext.Provider value={[code, setCode]}>
      <FirebaseContext.Provider value={{ auth, db, storage }}>
        <UserProvider>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </UserProvider>
      </FirebaseContext.Provider>
    </AccessCodeContext.Provider>
  );
};

const Root = () => (
  <Grommet full theme={trtTheme}>
    <Router>
      <RouteWrapper />
    </Router>
  </Grommet>
);

export default Root;
