import React from "react";

import { AdminLayout } from "../../";

export const AdminDesktop = () => {
  return (
    <AdminLayout>
      <div>hi</div>
    </AdminLayout>
  );
};
