import { Box } from "grommet";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { UpdateInvestmentGroupBody } from "./";

export const UpdateInvestmentGroupDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout
      title="Update Investment Group"
      subTitle="Update Investment Group and add new members"
      onClose={onNavigate("/")}
    >
      <Box flex overflow="auto" pad={{ vertical: "medium" }}>
        <UpdateInvestmentGroupBody />
      </Box>
    </AppLayout>
  );
};
