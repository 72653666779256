import React, { useContext } from "react";
import { Box, ResponsiveContext, Tab, Tabs, Text } from "grommet";

import { RetirementEstimator } from "../../components";
import { UserProfile } from "../../components/userProfile";
import { ChangePassword } from "../../components/changePassword";
import { useLoggedUser } from "../../firebase";
import { UserSubscription } from "../../components/userSubscription";
import { useActiveTab } from "../../hooks";

export const SettingsBody = () => {
  const size = useContext(ResponsiveContext);
  const { googleUser } = useLoggedUser();
  const { providerId } = googleUser?.providerData?.[0] || {};

  const userProfileTab = (
    <Tab title="Personal Info">
      <Box pad="large">
        <UserProfile />
      </Box>
    </Tab>
  );

  const changePasswordTab = (
    <Tab title="Password">
      <ChangePassword />
    </Tab>
  );

  const restimateTab = (
    <Tab title="Restimate">
      <Box align="center" pad="large" gap="large">
        <Text textAlign="center" color="text-weak">
          Set up your retirement goals and know how much you need to save this
          week, month, and years to come
        </Text>
        <RetirementEstimator />
      </Box>
    </Tab>
  );

  const subscriptionTab = (
    <Tab title="Subscription">
      <UserSubscription />
    </Tab>
  );

  const tabs =
    providerId === "password"
      ? [userProfileTab, changePasswordTab, restimateTab, subscriptionTab]
      : [userProfileTab, restimateTab, subscriptionTab];

  const activeSettingProps = useActiveTab({
    paramName: "activeSetting",
    maxIndex: tabs.length - 1
  });

  return (
    <>
      <Box
        flex
        align="center"
        overflow="auto"
        pad={
          size === "small"
            ? "large"
            : { vertical: "medium", horizontal: "large" }
        }
      >
        <Box flex={false} gap="large">
          {/* Grommet typing issue */}
          {/* @ts-ignore */}
          <Tabs flex overflow="auto" {...activeSettingProps}>
            {tabs}
          </Tabs>
        </Box>
      </Box>
    </>
  );
};
