import { useCallback, useContext, useState } from "react";
import ReactJson from "react-json-view";
import { useFirstMountState } from "react-use";
import {
  Box,
  DateInput,
  Heading,
  ResponsiveContext,
  Select,
  Text,
  TextInput
} from "grommet";
import {
  FeedbackContext,
  FormLabel,
  LoadingButton
} from "../../../../components";
import { startOfDay, subDays } from "date-fns";
import {
  useFirebaseFunction,
  useNavigation,
  useQueryParams
} from "../../../../hooks";
import { TRTThemeContext } from "../../../../context";
import { UserThemes } from "../../../../types";
import { useEffect } from "react";

export const PlaidDebuggerBody = () => {
  const { theme } = useContext(TRTThemeContext);
  const isFirstMount = useFirstMountState();
  const { navigate } = useNavigation();
  const today = startOfDay(new Date());
  const { sendFeedback } = useContext(FeedbackContext);
  const params = useQueryParams();
  const [accessToken, setAccessToken] = useState(
    params.get("accessToken") || ""
  );
  const [range, setRange] = useState([
    subDays(today, 1).toDateString(),
    today.toDateString()
  ]);
  const [api, setApi] = useState("Investments");
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(null);

  const size = useContext(ResponsiveContext);
  const onRangeChange = useCallback(({ value }) => setRange(value), []);
  const onAccessTokenChange = useCallback(
    ({ target: { value } }) => {
      setAccessToken(value);
      navigate(`?accessToken=${value}`);
    },
    [navigate]
  );
  const plaidDebugger = useFirebaseFunction({
    fnName: "plaidDebugger"
  });

  const onApiChange = useCallback(({ value }) => setApi(value), []);
  const onPlaidDebug = useCallback(
    async (e?) => {
      e?.preventDefault();
      if (!accessToken) {
        return sendFeedback({
          message: "Access code is required",
          type: "error"
        });
      }
      setFetching(true);
      try {
        const { data } = await plaidDebugger({
          accessToken,
          range,
          api
        });
        setData(data);
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          sendFeedback({
            message: e.message,
            type: "error"
          });
        }
      }
      setFetching(false);
    },
    [accessToken, plaidDebugger, range, api, sendFeedback]
  );

  useEffect(() => {
    if (isFirstMount && accessToken) {
      onPlaidDebug();
    }
  }, [accessToken, isFirstMount, onPlaidDebug]);

  return (
    <Box flex>
      <Box
        flex={false}
        border="bottom"
        pad={{ horizontal: "medium", top: "small", bottom: "medium" }}
        background="card"
      >
        <form onSubmit={onPlaidDebug}>
          <Heading level={6} margin="none" color="text-xweak">
            PLAID OPTIONS
          </Heading>
          <Box
            margin={{ top: "small" }}
            justify="start"
            direction="row"
            align="center"
            gap="small"
            responsive={false}
          >
            <Box width={size !== "small" ? "medium" : undefined}>
              <FormLabel label="Access Token" htmlFor="plaid-access-token" />
              <TextInput
                id="plaid-access-token"
                placeholder="Plaid access token"
                value={accessToken}
                onChange={onAccessTokenChange}
              />
            </Box>
            <Box>
              <FormLabel label="Range" htmlFor="date-range" />
              <DateInput
                id="date-range"
                value={range}
                onChange={onRangeChange}
                format="mm/dd/yyyy-mm/dd/yyyy"
              />
            </Box>
            <Box>
              <FormLabel label="API" htmlFor="api-type" />
              <Select
                id="api-type"
                value={api}
                options={["Balance", "Investments"]}
                onChange={onApiChange}
              />
            </Box>
          </Box>
          <Box
            direction="row"
            align="center"
            gap="small"
            responsive={false}
            justify="end"
            margin={{ top: "small" }}
          >
            <LoadingButton
              isLoading={fetching}
              primary
              label="Debug"
              size="small"
              type="submit"
            />
          </Box>
        </form>
      </Box>
      <Box flex overflow="auto">
        {fetching && (
          <Text color="text-weak" alignSelf="center" margin={{ top: "medium" }}>
            Loading Plaid data
          </Text>
        )}
        {!fetching && !data && (
          <Text color="text-weak" alignSelf="center" margin={{ top: "medium" }}>
            No data yet
          </Text>
        )}
        {!fetching && data && (
          <ReactJson
            theme={theme === UserThemes.DARK ? "twilight" : undefined}
            src={data!}
          />
        )}
      </Box>
    </Box>
  );
};
