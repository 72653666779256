import React, { useCallback, useContext, useState } from "react";
import { Avatar, Box, Button, Layer, ResponsiveContext } from "grommet";
import { Camera } from "grommet-icons";
import AvatarEdit from "react-avatar-edit";
import { useFirebaseContext, useLoggedUser } from "../firebase";
import { useAppState } from "../context";
import { User } from "../types";

const AvatarUploaderComponent = () => {
  const size = useContext(ResponsiveContext);
  const { userRef } = useLoggedUser();
  const { user, updateUser } = useAppState();
  const [avatarUrl, setAvatarUrl] = useState(user?.avatarUrl || "");
  const [show, setShow] = useState<boolean>(false);
  const [showFallback, setShowFallback] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>();
  const { storage } = useFirebaseContext();
  const storageRef = storage.storageRef(user?.uid);
  const toggleLayer = useCallback(() => setShow(!show), [show, setShow]);

  const onSave = useCallback(async () => {
    const avatarRef = storageRef.child("avatar.jpg");
    const snapshot = await avatarRef.putString(preview, "data_url");
    const downloadUrl = await snapshot.ref.getDownloadURL();
    // waiting some time for the image to persist.
    setAvatarUrl(downloadUrl);
    setTimeout(() => {
      userRef.current?.set(
        {
          avatarUrl: downloadUrl
        },
        { merge: true }
      );
      updateUser({ ...(user as User), avatarUrl: downloadUrl });
      setShow(false);
    }, 500);
  }, [preview, storageRef, updateUser, user, userRef]);

  const useFallback = useCallback(() => {
    if (user?.email) {
      setShowFallback(true);
    }
  }, [user]);

  let circleSize = "140px";
  if (size === "medium") {
    circleSize = "96px";
  } else if (size === "small") {
    circleSize = "72px";
  }

  return (
    <>
      <Box round="full">
        {!showFallback ? (
          <Avatar
            size={circleSize}
            src={avatarUrl || `//s.gravatar.com/avatar/${user?.email}?s=140`}
            onError={useFallback}
            onClick={toggleLayer}
          />
        ) : (
          <Camera
            size={size === "small" ? "medium" : "large"}
            onClick={toggleLayer}
          />
        )}
      </Box>
      {show && (
        <Layer onEsc={() => setShow(false)} onClickOutside={toggleLayer}>
          <Box pad="medium">
            <AvatarEdit width={390} height={295} onCrop={setPreview} />
            <Box pad="small" flex gap="small" direction="row" justify="center">
              <Button
                primary
                disabled={!preview}
                label="Save"
                onClick={onSave}
              />
              <Button label="Cancel" onClick={toggleLayer} />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export const AvatarUploader = React.memo(AvatarUploaderComponent);
