import * as React from "react";
import {
  Box,
  Button,
  Heading,
  ResponsiveContext,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text
} from "grommet";
import {
  Atm,
  CreditCard,
  Currency,
  Dislike,
  Home,
  LinkUp,
  Optimize
} from "grommet-icons";
import numeral from "numeral";
import { capitalize } from "lodash";

import { RestimateFullPlan } from "../restimateFullPlan";
import {
  AssetClassPeriod,
  ActivePeriod,
  PerformanceDetails,
  HistoricalDataForPeriod,
  User,
  ActivePeriodProps,
  UserSnapshots
} from "../../types";

// delete these utils fn?
// import {
//   getAttributeForRange,
//   getLabel,
//   tableDataWeek,
//   tableDataMonth,
//   tableDataYear
// } from "./utils";

import {
  getActivePeriodLabel,
  getInverseNumberTextColor,
  getNumberTextColor,
  NumberText
} from "../../utils";

import { DashboardCard } from "../";
import { useAppState } from "../../hooks";

interface CardProps {
  graphRange: ActivePeriod;
  spyPerformance?: PerformanceDetails;
  user?: User;
  snapshots?: UserSnapshots;
}

interface RestimateCardProps {
  historicalData?: HistoricalDataForPeriod;
  snapshots?: UserSnapshots;
  user?: User;
}

export const NetWorthPerformanceCard = (
  props: CardProps & ActivePeriodProps
) => {
  const { activePeriod, snapshots } = props;
  const { user } = useAppState();
  const size = React.useContext(ResponsiveContext);

  return (
    <DashboardCard
      title="NET WORTH"
      description={`How your net worth changed this ${getActivePeriodLabel(
        activePeriod
      )}`}
      highlight={
        <Box align="end" margin={{ right: "small" }} responsive={false}>
          <NumberText
            value={user?.netWorth ?? 0}
            weight="bold"
            color="text-weak"
          />
          <NumberText
            color={getNumberTextColor(
              snapshots?.[activePeriod]?.data?.total.balance.change.value || 0
            )}
            size={size !== "large" ? "small" : undefined}
            value={{
              value:
                snapshots?.[activePeriod]?.data?.total.balance.change.value ??
                0,
              percentage:
                snapshots?.[activePeriod]?.data?.total.balance.change
                  .percentage ?? 0
            }}
          />
        </Box>
      }
    >
      <Box flex>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  From
                </Heading>
              </TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  To
                </Heading>
              </TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  Change
                </Heading>
              </TableCell>
            </TableRow>
            {(Boolean(
              snapshots?.[activePeriod]?.data?.total.cashBalance.from || 0
            ) ||
              Boolean(
                snapshots?.[activePeriod]?.data?.total.cashBalance.to || 0
              )) && (
              <TableRow>
                <TableCell>
                  <Box align="center">
                    <Currency size="24px" color="assets" />
                    <Text size="small">Cash</Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.cashBalance.from
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.cashBalance.to
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text
                      color={getNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.cashBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.cashBalance
                          .change.value
                      ).format("-$0.[00]a")}
                    </Text>
                    <Text
                      color={getNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.cashBalance
                          .change.percentage || 0
                      )}
                      size="small"
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.cashBalance
                          .change.percentage
                      ).format("0,0.00")}
                      %
                    </Text>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {(Boolean(
              snapshots?.[activePeriod]?.data?.total.investmentBalance.from
            ) ||
              Boolean(
                snapshots?.[activePeriod]?.data?.total.investmentBalance.to
              )) && (
              <TableRow>
                <TableCell>
                  <Box align="center">
                    <Optimize size="24px" color="assets" />
                    <Text size="small">Investments</Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.investmentBalance
                        .from
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.investmentBalance
                        .to
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text
                      color={getNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.investmentBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.investmentBalance
                          .change.value
                      ).format("-$0.[00]a")}
                    </Text>
                    <Text
                      size="small"
                      color={getNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.investmentBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.investmentBalance
                          .change.percentage
                      ).format("0,0.00")}
                      %
                    </Text>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {(Boolean(
              snapshots?.[activePeriod]?.data?.total.creditCardBalance.from
            ) ||
              Boolean(
                snapshots?.[activePeriod]?.data?.total.creditCardBalance.to
              )) && (
              <TableRow>
                <TableCell>
                  <Box align="center">
                    <CreditCard size="24px" color="liabilities" />
                    <Text size="small">Credit Cards</Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.creditCardBalance
                        .from
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.creditCardBalance
                        .to
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text
                      color={getInverseNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.creditCardBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.creditCardBalance
                          .change.value
                      ).format("-$0.[00]a")}
                    </Text>
                    <Text
                      size="small"
                      color={getInverseNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.creditCardBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.creditCardBalance
                          .change.percentage
                      ).format("0,0.00")}
                      %
                    </Text>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {(Boolean(
              snapshots?.[activePeriod]?.data?.total?.loanBalance.from
            ) ||
              Boolean(
                snapshots?.[activePeriod]?.data?.total?.loanBalance.to
              )) && (
              <TableRow>
                <TableCell>
                  <Box align="center">
                    <Atm size="24px" color="liabilities" />
                    <Text size="small">Loans</Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.loanBalance.from
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.loanBalance.to
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text
                      color={getNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.loanBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.loanBalance
                          .change.value
                      ).format("-$0.[00]a")}
                    </Text>
                    <Text
                      size="small"
                      color={getNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.loanBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.loanBalance
                          .change.percentage
                      ).format("0,0.00")}
                      %
                    </Text>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {(Boolean(
              snapshots?.[activePeriod]?.data?.total.realEstateBalance?.from
            ) ||
              Boolean(
                snapshots?.[activePeriod]?.data?.total.realEstateBalance?.to
              )) && (
              <TableRow>
                <TableCell>
                  <Box align="center">
                    <Home size="24px" color="assets" />
                    <Text size="small">Real Estate</Text>
                  </Box>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.realEstateBalance
                        .from
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text color="text-weak">
                    {numeral(
                      snapshots?.[activePeriod]?.data?.total.realEstateBalance
                        .to
                    ).format("-$0.[00]a")}
                  </Text>
                </TableCell>
                <TableCell>
                  <Box>
                    <Text
                      color={getNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.realEstateBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.realEstateBalance
                          .change.value
                      ).format("-$0.[00]a")}
                    </Text>
                    <Text
                      size="small"
                      color={getNumberTextColor(
                        snapshots?.[activePeriod]?.data?.total.realEstateBalance
                          .change.value || 0
                      )}
                    >
                      {numeral(
                        snapshots?.[activePeriod]?.data?.total.realEstateBalance
                          .change.percentage
                      ).format("0,0.00")}
                      %
                    </Text>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </DashboardCard>
  );
};

const getLabelForPeriod = (period: AssetClassPeriod) => {
  const map = {
    weekly: "week",
    monthly: "month",
    yearly: "year"
  };

  return map[period] || "week";
};

const getNumberIcon = (value: number) => {
  if (value >= 0) {
    return (
      <Box background="status-ok" round="large" pad="small" responsive={false}>
        <LinkUp size="small" color="white" />
      </Box>
    );
  }

  return (
    <Box
      background="status-critical"
      round="large"
      pad="small"
      responsive={false}
    >
      <Dislike size="small" color="white" />
    </Box>
  );
};

export const RestimateCard = (
  props: RestimateCardProps & ActivePeriodProps
) => {
  const { activePeriod, snapshots } = props;
  const { user, restimate } = useAppState();
  const [showFullPlan, setShowFullPlan] = React.useState(false);
  const onCloseFullPlan = React.useCallback(() => setShowFullPlan(false), []);
  const onShowFullPlan = React.useCallback(() => setShowFullPlan(true), []);

  const { projections } = restimate || {
    projections: { weekly: [], monthly: [], yearly: [] }
  };

  const projectionsAndRates = {
    projections,
    weekRate: user?.weekRate || 0,
    monthRate: user?.monthRate || 0,
    yearRate: user?.yearRate || 0
  };

  const periodLabel = getLabelForPeriod(activePeriod as AssetClassPeriod);
  const activePeriodCount =
    user?.performance?.netWorth?.[activePeriod as AssetClassPeriod].count;

  return (
    <>
      {showFullPlan && (
        <RestimateFullPlan
          onClose={onCloseFullPlan}
          projectionsAndRates={projectionsAndRates}
        />
      )}
      <DashboardCard
        title="Restimate"
        description={`${capitalize(
          activePeriod
        )} performance compared to your goals`}
        highlight={
          <Box align="end" margin={{ right: "small" }} responsive={false}>
            <Text color="text-weak" weight="bold">
              <NumberText
                value={
                  (activePeriodCount &&
                    projections[activePeriod as AssetClassPeriod]?.[
                      activePeriodCount - 1
                    ]?.goal) ??
                  0
                }
              />
            </Text>
            <Text color="text-xweak" size="small">
              Goal
            </Text>
          </Box>
        }
      >
        <Box flex justify="center" direction="row" wrap>
          <Box
            align="center"
            justify="center"
            basis="40%"
            gap="xsmall"
            responsive={false}
          >
            <Box direction="row" align="center" gap="xsmall" responsive={false}>
              {getNumberIcon(
                snapshots?.[activePeriod]?.data?.total.balance.change.value || 0
              )}
              <Heading
                level={3}
                margin="none"
                color={getNumberTextColor(
                  snapshots?.[activePeriod]?.data?.total.balance.change.value ||
                    0
                )}
                responsive={false}
              >
                <b>
                  {numeral(
                    snapshots?.[activePeriod]?.data?.total.balance.change.value
                  ).format("-$0.[00]a")}
                </b>
              </Heading>
            </Box>
            <Text color="text-xweak" size="small">
              this <b>{periodLabel}</b>
            </Text>
          </Box>
          <Box
            align="center"
            justify="center"
            basis="40%"
            gap="xsmall"
            responsive={false}
          >
            <Box direction="row" align="center" gap="xsmall" responsive={false}>
              <Box
                direction="row"
                align="center"
                gap="xsmall"
                responsive={false}
              >
                {getNumberIcon(
                  user?.performance?.netWorth[activePeriod as AssetClassPeriod]
                    ?.average || 0
                )}
                <Heading
                  level={3}
                  margin="none"
                  color={getNumberTextColor(
                    user?.performance?.netWorth[
                      activePeriod as AssetClassPeriod
                    ]?.average || 0
                  )}
                  responsive={false}
                >
                  <b>
                    {numeral(
                      user?.performance?.netWorth[
                        activePeriod as AssetClassPeriod
                      ]?.average
                    ).format("-$0.[00]a")}
                  </b>
                </Heading>
              </Box>
            </Box>
            <Text color="text-weak" size="small">
              <b>{activePeriod}</b> avg
            </Text>
          </Box>
          <Box
            align="center"
            justify="center"
            basis="40%"
            gap="xsmall"
            responsive={false}
          >
            <Box direction="row" align="center" gap="xsmall" responsive={false}>
              <Box
                direction="row"
                align="center"
                gap="xsmall"
                responsive={false}
              >
                {getNumberIcon(
                  user?.performance?.netWorth[activePeriod as AssetClassPeriod]
                    ?.best || 0
                )}
                <Heading
                  level={3}
                  margin="none"
                  color={getNumberTextColor(
                    user?.performance?.netWorth[
                      activePeriod as AssetClassPeriod
                    ]?.best || 0
                  )}
                  responsive={false}
                >
                  <b>
                    {numeral(
                      user?.performance?.netWorth[
                        activePeriod as AssetClassPeriod
                      ]?.best
                    ).format("-$0.[00]a")}
                  </b>
                </Heading>
              </Box>
            </Box>
            <Text color="text-weak" size="small">
              best <b>{periodLabel}</b>
            </Text>
          </Box>
          <Box
            align="center"
            justify="center"
            basis="40%"
            gap="xsmall"
            responsive={false}
          >
            <Box direction="row" align="center" gap="xsmall" responsive={false}>
              <Box
                direction="row"
                align="center"
                gap="xsmall"
                responsive={false}
              >
                {getNumberIcon(
                  user?.performance?.netWorth[activePeriod as AssetClassPeriod]
                    ?.worst || 0
                )}
                <Heading
                  level={3}
                  margin="none"
                  color={getNumberTextColor(
                    user?.performance?.netWorth[
                      activePeriod as AssetClassPeriod
                    ]?.worst || 0
                  )}
                  responsive={false}
                >
                  <b>
                    {numeral(
                      user?.performance?.netWorth[
                        activePeriod as AssetClassPeriod
                      ]?.worst
                    ).format("-$0.[00]a")}
                  </b>
                </Heading>
              </Box>
            </Box>
            <Text color="text-weak" size="small">
              worst <b>{periodLabel}</b>
            </Text>
          </Box>
        </Box>
        <Box
          border="top"
          pad="medium"
          direction="row"
          align="center"
          justify={activePeriod === "yearly" ? "end" : "between"}
          height="70px"
          responsive={false}
        >
          {activePeriod !== "yearly" &&
          user?.performance?.netWorth[activePeriod as AssetClassPeriod]
            ?.recentGains?.length ? (
            <Box gap="xsmall">
              <Box direction="row" align="center" gap="small">
                {user?.performance?.netWorth[
                  activePeriod as AssetClassPeriod
                ].recentGains.map((gain, index) => (
                  <Box
                    key={`recentGain_${index}`}
                    width="12px"
                    height="12px"
                    round="full"
                    background={gain >= 0 ? "status-ok" : "status-critical"}
                  />
                ))}
              </Box>
              <Text size="small">Last {periodLabel}s</Text>
            </Box>
          ) : (
            <span />
          )}
          <Button
            primary
            label="View Projections"
            size="small"
            onClick={onShowFullPlan}
          />
        </Box>
      </DashboardCard>
    </>
  );
};
