import { useContext, useState } from "react";
import {
  Anchor,
  Box,
  Button,
  Heading,
  InfiniteScroll,
  Layer,
  Paragraph,
  ResponsiveContext,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Tabs,
  Text
} from "grommet";
import numeral from "numeral";
import { Close } from "grommet-icons";
import { upperFirst } from "lodash";
import Skeleton from "react-loading-skeleton";
import { getNumberTextColor } from "../utils";
import {
  RestimateDataWithRates,
  RestimateDataForPeriod,
  RestimatePeriod,
  User
} from "../types";

interface RestimateFullPlanProps {
  onClose: () => void;
  projectionsAndRates: RestimateDataWithRates;
  isLoading?: boolean;
}

const getPeriodRate = (rates: any, period: RestimatePeriod) => {
  const periodRateMap = {
    weekly: "weekRate",
    monthly: "monthRate",
    yearly: "yearRate"
  };

  const rateAttribute = periodRateMap[period] as keyof Partial<User>;
  return (rates[rateAttribute] as number) * 100;
};

export const RestimateFullPlan = ({
  projectionsAndRates: projectionsAndRatesProp,
  onClose,
  isLoading = false
}: RestimateFullPlanProps) => {
  const size = useContext(ResponsiveContext);
  const tabs: RestimatePeriod[] = ["weekly", "monthly", "yearly"];
  const [period, setPeriod] = useState<RestimatePeriod>("weekly");
  const {
    weekRate,
    monthRate,
    yearRate,
    projections: rows
  } = projectionsAndRatesProp || {
    rates: {},
    projections: []
  };

  const rates = { weekRate, monthRate, yearRate };

  const renderLoadingRows = (numberOfRows: number) => {
    const rows = new Array(numberOfRows).fill(0);
    return rows.map((_, i) => (
      <TableRow key={`loading-${i}`}>
        <TableCell>
          <Skeleton height="38px" />
        </TableCell>
        <TableCell>
          <Skeleton height="38px" />
        </TableCell>
        <TableCell>
          <Skeleton height="38px" />
        </TableCell>
        <TableCell>
          <Skeleton height="38px" />
        </TableCell>
      </TableRow>
    ));
  };

  const renderTable = (projections: RestimateDataForPeriod[]) => (
    <InfiniteScroll key={period} items={projections}>
      {(rowData: RestimateDataForPeriod) => (
        <TableRow key={`${period}.${rowData.interval}`}>
          <TableCell>
            <Text weight="bold">{rowData.interval}</Text>
          </TableCell>
          <TableCell>
            <Text color="text-weak">
              {numeral(rowData.start).format("$0,0.00")}
            </Text>
          </TableCell>
          <TableCell>
            <Text color="text-weak">
              {numeral(rowData.end).format("$0,0.00")}
            </Text>
          </TableCell>
          <TableCell>
            <Box>
              <Text color="text-xweak">
                {numeral(rowData.goal).format("$0,0.00")}
              </Text>
              <Text
                size="small"
                color={getNumberTextColor(rowData.actual)}
                weight="bold"
              >
                {rowData.actual
                  ? numeral(rowData.actual).format("$0,0.00")
                  : "-"}
              </Text>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </InfiniteScroll>
  );

  const renderTab = () => {
    return (
      <>
        <Box
          flex={false}
          margin={{ vertical: "medium" }}
          direction={size === "small" ? "column" : "row"}
          gap="small"
          align="center"
          justify="center"
        >
          <Text color="text-weak">
            <b>{numeral(getPeriodRate(rates, period)).format("0.00")}%</b>{" "}
            {upperFirst(period)} Return Rate
          </Text>
        </Box>
        <Box height="560px" overflow="scroll">
          <Table style={{ tableLayout: "fixed" }}>
            <TableHeader>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell>Goal/Actual</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {rows[period].length && !isLoading
                ? renderTable(rows[period])
                : renderLoadingRows(4)}
            </TableBody>
          </Table>
        </Box>
      </>
    );
  };

  return (
    <Layer onClickOutside={onClose} onEsc={onClose}>
      <Box fill style={{ height: size !== "small" ? "70vh" : undefined }}>
        <Box direction="row" align="center" justify="between" pad="medium">
          <Heading level={3} margin="none" responsive={false}>
            Restimate Plan
          </Heading>
          <Box direction="row" align="center" gap="small">
            <Anchor
              size="small"
              target="_blank"
              href="https://medium.com/@theretirementtracker/all-about-restimate-f40c222330b4"
              label="Learn more"
            />
            <Button icon={<Close />} onClick={onClose} />
          </Box>
        </Box>
        <Box flex width="large" pad="medium" overflow="auto">
          <Tabs onActive={(tabIndex) => setPeriod(tabs[tabIndex])}>
            <Tab title="Weekly">{renderTab()}</Tab>
            <Tab title="Monthly">{renderTab()}</Tab>
            <Tab title="Yearly">{renderTab()}</Tab>
          </Tabs>
        </Box>
        <Paragraph
          color="text-xweak"
          margin={{ horizontal: "medium", bottom: "large" }}
        >
          <i>
            The numbers represent high level goals and are not to be followed to
            the exact amount. These numbers can be used as a north star during
            your retirement progress.
          </i>
        </Paragraph>
      </Box>
    </Layer>
  );
};
