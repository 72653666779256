import React from "react";

import { InvestmentDespositsBody } from ".";
import { AdminLayoutMobile } from "../../layouts";

export const InvestmentDepositsMobile = () => (
  <AdminLayoutMobile title="Investment Deposits">
    <InvestmentDespositsBody />
  </AdminLayoutMobile>
);
