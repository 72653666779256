import { Box } from "grommet";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { JoinInvestmentGroupBody } from "./";

export const JoinInvestmentGroupDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout onClose={onNavigate("/")}>
      <Box flex pad="medium">
        <JoinInvestmentGroupBody />
      </Box>
    </AppLayout>
  );
};
