import React, { useCallback, useContext } from "react";
import {
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text
} from "grommet";
import Skeleton from "react-loading-skeleton";
import { BaseCard } from "../baseCard";
import { useNavigation } from "../../hooks";

interface AssetCardProps {
  icon: React.ReactElement;
  mode: string;
  type: string;
  comingSoon?: boolean;
  description: React.ReactElement;
  remaining?: number;
  loading?: boolean;
  onModeChange?: (mode: string) => void;
  onChoose?: () => void;
}

export const AssetCard = ({
  icon,
  mode,
  type,
  comingSoon = false,
  description,
  remaining,
  loading,
  onModeChange,
  onChoose
}: AssetCardProps) => {
  const size = useContext(ResponsiveContext);
  const { navigate } = useNavigation();
  const onModeClick = useCallback(
    (targetMode) => () =>
      type !== targetMode && onModeChange && onModeChange(targetMode),
    [type, onModeChange]
  );

  const automatedActive = mode.toLowerCase() === "automated";

  return (
    <BaseCard
      align="center"
      pad="medium"
      width={size === "large" ? "medium" : "300px"}
    >
      <Box
        height={size === "large" ? "96px" : undefined}
        justify="center"
        margin={{ top: "small" }}
        flex={false}
        responsive={false}
      >
        {icon}
      </Box>
      <Box align="center" justify="center" gap="small" flex={false}>
        <Box align="center" flex={false}>
          <Heading
            level={3}
            size={size !== "large" ? "small" : undefined}
            margin="none"
            responsive={false}
          >
            <b>{type}</b>
          </Heading>
          <Box
            direction="row"
            align="center"
            round="small"
            pad={{ vertical: "small" }}
            style={{ opacity: comingSoon ? 0.5 : 1 }}
          >
            {onModeChange ? (
              <>
                <Button onClick={onModeClick("automated")}>
                  <Box
                    width="80px"
                    border={{
                      side: "all",
                      color: automatedActive ? "neutral-3" : "border"
                    }}
                    background={automatedActive ? "neutral-3" : undefined}
                    round={{ corner: "left", size: "small" }}
                    pad="xsmall"
                    align="center"
                    justify="center"
                    responsive={false}
                  >
                    <Text size="small">AUTOMATED</Text>
                  </Box>
                </Button>
                <Button onClick={onModeClick("manual")}>
                  <Box
                    width="80px"
                    border={{
                      side: "all",
                      color: !automatedActive ? "neutral-3" : "border"
                    }}
                    background={!automatedActive ? "neutral-3" : undefined}
                    pad="xsmall"
                    round={{ corner: "right", size: "small" }}
                    align="center"
                    justify="center"
                    responsive={false}
                  >
                    <Text size="small">MANUAL</Text>
                  </Box>
                </Button>
              </>
            ) : (
              <Box
                width="80px"
                border={{
                  side: "all",
                  color: "neutral-3"
                }}
                background="neutral-3"
                pad="xsmall"
                round="small"
                align="center"
                justify="center"
                responsive={false}
              >
                <Text size="small">{mode.toUpperCase()}</Text>
              </Box>
            )}
          </Box>
        </Box>
        <Paragraph textAlign="center" color="text-weak" margin="none">
          {description}
        </Paragraph>
        {comingSoon && (
          <Paragraph color="text-xweak" margin="none">
            <Text color="red" weight="bold" style={{ fontStyle: "italic" }}>
              Coming Soon
            </Text>
          </Paragraph>
        )}
        {!comingSoon && (
          <Paragraph color="text-xweak" margin="none">
            {!loading && (
              <i>
                {remaining === undefined ? (
                  "Unlimited"
                ) : (
                  <span>
                    <b>{remaining}</b> remaining
                  </span>
                )}
              </i>
            )}
            {loading && <Skeleton width="100px" />}
          </Paragraph>
        )}
      </Box>
      {remaining !== 0 && (
        <Button
          primary
          label="Choose"
          disabled={remaining === 0 || loading}
          margin={{ top: "small" }}
          size={size !== "large" ? "small" : undefined}
          onClick={onChoose}
        />
      )}
      {remaining === 0 && (
        <Button
          primary
          label="Become a PRO"
          margin={{ top: "small" }}
          size={size !== "large" ? "small" : undefined}
          onClick={() => navigate("/settings?activePanel=4&activeSetting=3")}
        />
      )}
    </BaseCard>
  );
};
