import React, { ChangeEvent, useCallback, useMemo } from "react";
import { Box, Button, DateInput, Select, TextInput } from "grommet";
import { deepMerge } from "grommet/utils";
import { Trash } from "grommet-icons";

import {
  AccountRow,
  ActivityAction,
  ActivityPosition,
  ActivityRow
} from "../../types";

import {
  activityTypeOptions,
  ActivityTypes,
  BaseCard,
  CurrencyInput,
  FormLabel,
  getActivityAmountLabel,
  getSelectedTypeOption,
  InstitutionAccountOption,
  PossibleActivityTypeOptions
} from "../";

import { renderInstitutionOption } from "./";

export interface ActivityCardProps {
  activityRow: ActivityRow;
  availableInstitutionAccounts: AccountRow[];
  onRemove: (activityRow: ActivityRow) => void;
  onUpdate: (activityRow: ActivityRow, existingAccountRow: AccountRow) => void;
}

export const ActivityCard = ({
  availableInstitutionAccounts = [],
  activityRow,
  onRemove,
  onUpdate
}: ActivityCardProps) => {
  const { activityItem, accountRow } = activityRow;

  const onRemoveActivity = useCallback(() => onRemove(activityRow), [
    onRemove,
    activityRow
  ]);

  const onUpdateInputActivity = useCallback(
    (field: string, options?: { uppercase?: boolean }) => ({
      target: { value }
    }: ChangeEvent<HTMLInputElement>) => {
      const newActivityRow = { ...activityRow };
      newActivityRow.activityItem = {
        ...activityRow.activityItem,
        [field]: options?.uppercase ? value.toUpperCase() : value
      };
      onUpdate(newActivityRow, activityRow.accountRow);
    },
    [onUpdate, activityRow]
  );

  const onChangeAccountRow = useCallback(
    ({ option: accountRow }: { option: AccountRow }) => {
      const newActivityRow = { ...activityRow };
      newActivityRow.accountRow = { ...accountRow };
      onUpdate(newActivityRow, activityRow.accountRow);
    },
    [onUpdate, activityRow]
  );

  const onChangeStrikePrice = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const newActivityRow = { ...activityRow };
      if (newActivityRow.activityItem.optionDetail) {
        newActivityRow.activityItem.optionDetail.strikePrice = +value;
      }
      onUpdate(newActivityRow, activityRow.accountRow);
    },
    [onUpdate, activityRow]
  );

  const onChangeExpiration = useCallback(
    ({ value }: { value: string | string[] }) => {
      const newActivityRow = { ...activityRow };
      if (newActivityRow.activityItem.optionDetail) {
        newActivityRow.activityItem.optionDetail.expirationDate = value as string;
      }
      onUpdate(newActivityRow, activityRow.accountRow);
    },
    [onUpdate, activityRow]
  );

  const onUpdateSelectActivity = useCallback(
    (field: string) => ({ option }: { option: any }) => {
      const newActivityRow = { ...activityRow };
      newActivityRow.activityItem = {
        ...activityRow.activityItem,
        [field]: option
      };
      if (newActivityRow.activityItem.action === ActivityAction.SELL) {
        newActivityRow.activityItem.quantity =
          Math.abs(+newActivityRow.activityItem.quantity) * -1;
      } else {
        newActivityRow.activityItem.quantity = Math.abs(
          +newActivityRow.activityItem.quantity
        );
      }
      onUpdate(newActivityRow, activityRow.accountRow);
    },
    [onUpdate, activityRow]
  );

  const onActivityActionChange = useCallback(
    ({ option }: { option: PossibleActivityTypeOptions }) => {
      const newActivityRow = { ...activityRow };
      newActivityRow.activityItem = deepMerge(
        activityRow.activityItem,
        ActivityTypes[option]
      );
      onUpdate(newActivityRow, activityRow.accountRow);
    },
    [onUpdate, activityRow]
  );

  const selectedActionOption = useMemo(
    () => getSelectedTypeOption(activityRow.activityItem),
    [activityRow.activityItem]
  );

  return (
    <BaseCard height="371px">
      <Box direction="row" align="center" justify="between">
        <Box align="start" gap="xsmall">
          <FormLabel
            label="Account"
            htmlFor={`activity-account-${activityItem.id}`}
          />
          <Select
            id={`activity-account-${activityItem.id}`}
            options={availableInstitutionAccounts}
            value={accountRow}
            valueKey={(accountRow) => accountRow.account.id}
            valueLabel={
              <InstitutionAccountOption
                institution={accountRow.institution}
                account={accountRow.account}
              />
            }
            onChange={onChangeAccountRow}
          >
            {renderInstitutionOption}
          </Select>
        </Box>
        <Button onClick={onRemoveActivity}>
          <Trash color="status-critical" />
        </Button>
      </Box>
      <Box direction="row" align="center" gap="small" responsive={false}>
        <Box align="start" gap="xsmall" basis="40%">
          <FormLabel
            label="Position"
            htmlFor={`activity-position-${activityItem.id}`}
          />
          <Select
            id={`activity-position-${activityItem.id}`}
            options={[ActivityPosition.OPEN, ActivityPosition.CLOSE]}
            value={activityItem.position}
            onChange={onUpdateSelectActivity("position")}
          />
        </Box>
        <Box align="start" gap="xsmall" flex>
          <FormLabel
            label="Action"
            htmlFor={`activity-action-${activityItem.id}`}
          />
          <Select
            id={`activity-action-${activityItem.id}`}
            options={[ActivityAction.BUY, ActivityAction.SELL]}
            value={activityItem.action}
            onChange={onUpdateSelectActivity("action")}
          />
        </Box>
      </Box>

      <Box direction="row" align="center" gap="small" responsive={false}>
        <Box align="start" gap="xsmall" basis="40%">
          <FormLabel
            label="Ticker Symbol*"
            htmlFor={`activity-tickerSymbol-${activityItem.id}`}
          />
          <TextInput
            id={`activity-tickerSymbol-${activityItem.id}`}
            value={activityItem.tickerSymbol}
            onChange={onUpdateInputActivity("tickerSymbol", {
              uppercase: true
            })}
          />
        </Box>
        <Box align="start" gap="xsmall" flex>
          <FormLabel
            label="Type"
            htmlFor={`activity-type-${activityItem.id}`}
          />
          <Select
            id={`activity-type-${activityItem.id}`}
            options={activityTypeOptions}
            value={selectedActionOption}
            onChange={onActivityActionChange}
          />
        </Box>
      </Box>
      <Box direction="row" align="center" gap="small" responsive={false}>
        <Box align="start" gap="xsmall" basis="40%">
          <FormLabel
            label="Quantity"
            htmlFor={`activity-quantity-${activityItem.id}`}
          />
          <TextInput
            id={`activity-quantity-${activityItem.id}`}
            value={+activityItem.quantity}
            onChange={onUpdateInputActivity("quantity")}
            type="number"
            max={activityItem.action === "sell" ? "-1" : undefined}
            min={activityItem.action === "sell" ? undefined : "1"}
          />
        </Box>
        <Box align="start" gap="xsmall" flex>
          <FormLabel
            label={`${getActivityAmountLabel(activityItem)} per item*`}
            htmlFor={`activity-price-${activityItem.id}`}
          />
          <CurrencyInput
            id={`activity-price-${activityItem.id}`}
            value={activityItem.price}
            onChange={onUpdateInputActivity("price")}
          />
        </Box>
      </Box>
      {activityItem.optionDetail && (
        <>
          <Box direction="row" align="center" gap="small" responsive={false}>
            <Box align="start" gap="xsmall" basis="40%">
              <FormLabel
                label="Strike Price*"
                htmlFor={`activity-strikePrice-${activityItem.id}`}
              />
              <TextInput
                id={`activity-strikePrice-${activityItem.id}`}
                type="number"
                min="1"
                value={activityItem.optionDetail?.strikePrice ?? ""}
                onChange={onChangeStrikePrice}
              />
            </Box>
            <Box align="start" gap="xsmall" flex>
              <FormLabel
                label="Expiration*"
                htmlFor={`activity-expirationDate-${activityItem.id}`}
              />
              <DateInput
                id={`activity-expirationDate-${activityItem.id}`}
                value={activityItem.optionDetail?.expirationDate ?? ""}
                format="yyyy-mm-dd"
                onChange={onChangeExpiration}
              />
            </Box>
          </Box>
        </>
      )}
    </BaseCard>
  );
};
