import React from "react";
import { Box } from "grommet";

import { AppLayoutMobile } from "../layouts";

import { SettingsBody } from "./";

export const SettingsMobile = () => (
  <AppLayoutMobile title="Settings">
    <Box flex overflow="auto">
      <SettingsBody />
    </Box>
  </AppLayoutMobile>
);
