import React from "react";

import { HeaderOverview, NetWorthDetails } from "../components";

import { AppLayoutMobile } from "./layouts";

export const NetWorthMobile = () => {
  return (
    <AppLayoutMobile title="Net Worth">
      <HeaderOverview />
      <NetWorthDetails />
    </AppLayoutMobile>
  );
};
