import React, { useContext } from "react";
import { Box, Button, Layer, ResponsiveContext, Text } from "grommet";
import { UserThemes } from "../types";
import { TRTThemeContext } from "../context";
import { LoadingButton } from "./button";

interface ConfirmationModalProps {
  actionLabel: string;
  children: React.ReactElement | React.ReactElement[];
  color: string;
  icon: React.ReactElement;
  footer?: any;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  loading?: boolean;
}

export const ConfirmationModal = ({
  actionLabel,
  children,
  color,
  icon,
  footer,
  onCancel,
  onConfirm,
  title,
  loading
}: ConfirmationModalProps) => {
  const { theme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  return (
    <Layer
      position={size === "small" ? "bottom" : undefined}
      responsive={false}
      onEsc={onCancel}
    >
      <Box width="540px">
        <Box
          direction="row"
          align="center"
          gap="small"
          background={color}
          round={
            size !== "small" ? { size: "small", corner: "top" } : undefined
          }
          pad={{ horizontal: "medium", vertical: "small" }}
          responsive={false}
        >
          {icon}
          <Text size={size !== "small" ? "large" : "medium"} weight="bold">
            {title}
          </Text>
        </Box>
        <Box
          responsive={false}
          pad={{ horizontal: "medium", vertical: "medium" }}
          background={{
            color: "confirmation-modal",
            dark: theme === UserThemes.DARK
          }}
        >
          {children}
        </Box>
        <Box
          responsive={false}
          tag="footer"
          pad="medium"
          border={{
            side: "top",
            color: theme === UserThemes.DARK ? "border" : "border-light"
          }}
          justify="between"
          direction="row"
          align="center"
          round={
            size !== "small" ? { size: "small", corner: "bottom" } : undefined
          }
          background={{
            color: "confirmation-modal",
            dark: theme === UserThemes.DARK
          }}
        >
          {footer ? footer : <span />}
          <Box justify="end" direction="row" gap="small" responsive={false}>
            <Button secondary label="Cancel" color={color} onClick={onCancel} />
            <LoadingButton
              isLoading={loading}
              label={actionLabel}
              color={color}
              primary
              onClick={onConfirm}
            />
          </Box>
        </Box>
      </Box>
    </Layer>
  );
};
