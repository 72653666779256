import React from "react";

import { useAppState } from "../hooks";
import { Institutions } from "../components";

import { AppLayoutMobile } from "./layouts";

export const InstitutionsMobile = () => {
  const { institutions } = useAppState();

  return (
    <AppLayoutMobile title="Institutions">
      <Institutions institutions={institutions} />
    </AppLayoutMobile>
  );
};
