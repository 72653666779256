import React from "react";
import { Box, Text } from "grommet";
import { getHoldingTypeLabel, NumberText } from "../utils";
import { format, parseISO } from "date-fns";
import { HoldingTypes, OptionDetail } from "../types";

export interface HoldingBadgeProps {
  optionDetail: OptionDetail | null;
  quantity: number | string;
  type: HoldingTypes;
}

export const HoldingBadge = ({
  quantity,
  optionDetail,
  type
}: HoldingBadgeProps) => {
  return (
    <Box
      direction="row"
      align="center"
      round="small"
      width="150px"
      border="all"
      responsive={false}
    >
      <Box
        align="center"
        justify="center"
        round={{ corner: "left", size: "small" }}
        height="21px"
        border="right"
        width="40px"
        responsive={false}
      >
        <NumberText value={quantity} type="plain" size="small" />
      </Box>
      <Box direction="row" height="21px" justify="center" flex>
        {!optionDetail && (
          <Box align="start" justify="center" flex>
            <Text margin={{ left: "small" }} size="small">
              {getHoldingTypeLabel(type)}
            </Text>
          </Box>
        )}
        {optionDetail && (
          <>
            <Box
              align="center"
              justify="center"
              pad={{ horizontal: "xsmall" }}
              background="option-date"
              flex
            >
              <Text size="small">
                {format(parseISO(optionDetail.expirationDate), "MMM d, yy")}
              </Text>
            </Box>
            <Box
              border="left"
              width="40px"
              align="center"
              justify="center"
              round={{ corner: "right", size: "small" }}
              responsive={false}
            >
              <Text size="small">
                <NumberText
                  value={optionDetail.strikePrice}
                  type="plain"
                  size="small"
                />
                {optionDetail.optionType.toLowerCase()}
              </Text>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
