import React from "react";

import { PlaidDebuggerBody } from "./";
import { AdminLayoutMobile } from "../../layouts";

export const PlaidDebuggerMobile = () => (
  <AdminLayoutMobile title="Plaid Debugger">
    <PlaidDebuggerBody />
  </AdminLayoutMobile>
);
