import React, { useCallback } from "react";
import { useAppState, useStepChangeContext } from "../../hooks";

import { OnboardingHeading, OnboardingLayout } from "./";
import { PaymentChooser } from "../payment-chooser";
import { User } from "../../types";

export const OnboardingAddPayment = () => {
  const [, onStepChange] = useStepChangeContext();
  const { user, updateUser } = useAppState();
  const onPreviousStep = useCallback(
    () => onStepChange("choosePlan", user!),
    [onStepChange, user]
  );
  const onNextStep = useCallback(() => {
    // makes sure we update the user so we get the correct badge
    const updatedUser = {
      ...(user as User),
      isPro: true
    };
    updateUser(updatedUser);
    onStepChange("addInstitution", updatedUser);
  }, [onStepChange, updateUser, user]);
  return (
    <OnboardingLayout onPreviousStep={onPreviousStep}>
      <OnboardingHeading
        heading="Almost There"
        description="Thanks for choosing to go Pro. We need to get your payment information"
      />
      <PaymentChooser onPay={onNextStep} />
    </OnboardingLayout>
  );
};
