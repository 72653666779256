import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { orderBy } from "lodash";
import {
  Box,
  Button,
  Heading,
  Menu,
  ResponsiveContext,
  Text,
  Tip
} from "grommet";
import {
  Action,
  Edit,
  Link,
  CircleAlert,
  More,
  StatusCritical,
  StatusWarning,
  Trash,
  Update
} from "grommet-icons";
import { useLocation } from "react-router-dom";
import { InstitutionLogo } from "../institutionLogo";
import { InstitutionTypeBadge } from "./institutionTypeBadge";
import { Account } from "./account";
import { ConfirmationModal } from "../confirmationModal";
import { Institution as InstitutionType, UserThemes } from "../../types";
import {
  useAddPlaidInstitution,
  useAppState,
  useFirebaseFunction,
  useNavigation
} from "../../hooks";
import { FeedbackContext } from "../feedback";
import { TRTThemeContext } from "../../context";
import { Loading, MenuItemLabel } from "../";
import { NumberText } from "../../utils";
import { useMemo } from "react";
import { TOTAL_AUTOMATED_INSTITUTIONS_ALLOWED } from "../../constants";

interface InstitutionProps {
  institution: InstitutionType;
}

interface InstitutionConfirmationProps {
  institution: InstitutionType;
  onCancel: () => void;
  onConfirm: () => void;
}

export const DeleteInstitutionConfirmation = ({
  institution,
  isPro,
  onCancel,
  onConfirm
}: InstitutionConfirmationProps & { isPro: boolean }) => {
  const size = useContext(ResponsiveContext);
  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to remove ${institution.name}?`}
      actionLabel="Remove"
    >
      <Box direction="row" justify="start" align="center">
        <InstitutionLogo
          institution={institution}
          size={size === "small" ? "72px" : "86px"}
        />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            You cannot revert this action and your net worth will be{" "}
            {institution.netWorth >= 0 ? "decreased" : "increased"} by{" "}
            <NumberText value={Math.abs(institution.netWorth)} weight="bold" />.
          </Text>
          <Text color="text-weak">
            Your past transactions and performance will not change, but your
            today's performance will be affected by removing this institution.
          </Text>
          {!isPro && institution.type === "automated" && (
            <Text color="text-weak">
              After removing this institution, you will not be able to add
              another automated one unless you upgrade to a paid plan.
            </Text>
          )}
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const AutomateInstitutionConfirmation = ({
  institution,
  onCancel,
  onConfirm
}: InstitutionConfirmationProps) => {
  const size = useContext(ResponsiveContext);
  const { user } = useAppState();
  const totalAutomatedInstitutionsAllowed = user?.isPro
    ? TOTAL_AUTOMATED_INSTITUTIONS_ALLOWED
    : 1;
  return (
    <ConfirmationModal
      color="dark-2"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusWarning color="text" size="large" />}
      title={`Are you sure you want to automate ${institution.name}?`}
      actionLabel="Automate"
      footer={
        <Text color="text-xweak">
          {totalAutomatedInstitutionsAllowed -
            (user?.automatedInstitutionsInUse || 0)}{" "}
          remaining
        </Text>
      }
    >
      <Box direction="row" justify="start" align="center">
        <InstitutionLogo
          institution={institution}
          size={size === "small" ? "72px" : "86px"}
        />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            You cannot revert this action and your accounts will automatically
            update, no need to manually update the transactions anymore.
          </Text>
          <Text color="text-weak">
            All existing manual accounts will be replaced by the automated
            accounts.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const ManualInstitutionConfirmation = ({
  institution,
  onCancel,
  onConfirm
}: InstitutionConfirmationProps) => {
  const size = useContext(ResponsiveContext);
  return (
    <ConfirmationModal
      color="dark-2"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusWarning color="text" size="large" />}
      title={`Are you sure about converting ${institution.name} to manual?`}
      actionLabel="Convert"
      footer={<Text color="text-xweak">Unlimited</Text>}
    >
      <Box direction="row" justify="start" align="center">
        <InstitutionLogo
          institution={institution}
          size={size === "small" ? "72px" : "86px"}
        />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            You cannot revert this action and your accounts will stop syncing
            daily. You will have to manually update in the future.
          </Text>
          <Text color="text-weak">
            Any hidden accounts will be removed. Also, you will loose tracking
            of your investment holdings, if any.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

const isStatusError = (status: string) =>
  ["login_error", "error"].includes(status);

const Institution = ({ institution }: InstitutionProps) => {
  const { theme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  const institutionRef = useRef<HTMLDivElement>(null);
  const { refreshData, updateInstitution, user, token } = useAppState();
  const [processing, setProcessing] = useState(false);
  const convertInstitutionToManual = useFirebaseFunction({
    fnName: "convertInstitutionToManual"
  });
  const deleteInstitution = useFirebaseFunction({
    fnName: "deleteInstitution"
  });
  const location = useLocation();
  const { navigate } = useNavigation();
  const params = new URLSearchParams(location.search);
  const activeInstitutionId = params.get("activeInstitution");
  const [
    showDeleteInstitutionConfirmation,
    setShowDeleteInstitutionConfirmation
  ] = useState(false);
  const [
    showAutomateInstitutionConfirmation,
    setShowAutomateInstitutionConfirmation
  ] = useState(false);
  const [
    showManualInstitutionConfirmation,
    setShowManualInstitutionConfirmation
  ] = useState(false);
  const { open, ready } = useAddPlaidInstitution({
    replaceInstitutionId: institution.id,
    user,
    token,
    reconnectToken: isStatusError(institution.status)
      ? institution.reconnectToken
      : undefined
  });
  const { sendFeedback } = useContext(FeedbackContext);

  useEffect(() => {
    // give a little time for the user to read things before scrolling to the active institution
    setTimeout(() => {
      if (
        institutionRef.current &&
        institution.institutionId === activeInstitutionId
      ) {
        institutionRef.current.scrollIntoView();
      }
    }, 300);
  }, [activeInstitutionId, institution]);

  const onEditInstitution = useCallback(() => {
    navigate(`/update-institution/${institution.id}`);
  }, [institution, navigate]);

  const onRequestToDeleteInstitution = useCallback(
    () => setShowDeleteInstitutionConfirmation(true),
    [setShowDeleteInstitutionConfirmation]
  );

  const onRequestToAutomateInstitution = useCallback(
    () => setShowAutomateInstitutionConfirmation(true),
    [setShowAutomateInstitutionConfirmation]
  );

  const onRequestToManualInstitution = useCallback(
    () => setShowManualInstitutionConfirmation(true),
    [setShowManualInstitutionConfirmation]
  );

  const onCancelDeleteInstitution = useCallback(
    () => setShowDeleteInstitutionConfirmation(false),
    [setShowDeleteInstitutionConfirmation]
  );

  const onCancelAutomateInstitution = useCallback(
    () => setShowAutomateInstitutionConfirmation(false),
    [setShowAutomateInstitutionConfirmation]
  );

  const onCancelManualInstitution = useCallback(
    () => setShowManualInstitutionConfirmation(false),
    [setShowManualInstitutionConfirmation]
  );

  const onConfirmDeleteInstitution = useCallback(async () => {
    setShowDeleteInstitutionConfirmation(false);

    setProcessing(true);

    updateInstitution({ ...institution, processing: true });

    const { data } = await deleteInstitution({
      institutionId: institution.id,
      userId: user?.uid
    });

    if (data.success) {
      await refreshData({ skipUpdate: true });
      sendFeedback({
        message: `${institution.name} successfully deleted`,
        type: "success"
      });

      // kicks the user out of the institution page if it is the current page
      if (location.pathname.includes(institution.id)) {
        navigate("/");
      }
    } else {
      sendFeedback({
        message: `An error happened while trying to delete ${institution.name}. Please try again or reach out to our support for additional help.`,
        type: "error"
      });
    }
    setProcessing(false);
  }, [
    sendFeedback,
    deleteInstitution,
    institution,
    location,
    navigate,
    refreshData,
    updateInstitution,
    user
  ]);

  const onConfirmManualInstitution = useCallback(async () => {
    setShowManualInstitutionConfirmation(false);

    updateInstitution({ ...institution, type: "manual", processing: true });
    await convertInstitutionToManual({
      institutionId: institution.id,
      userId: user?.uid
    });

    sendFeedback({
      message: "Institution converted to manual successfully.",
      type: "success"
    });

    updateInstitution({
      ...institution,
      type: "manual",
      processing: false
    });
    navigate(`/add-institution?id=${institution.id}`);
  }, [
    convertInstitutionToManual,
    institution,
    navigate,
    sendFeedback,
    updateInstitution,
    user
  ]);

  const showPlaidModal = useCallback(() => {
    if (ready) {
      open();
    } else {
      console.error("Could not initialize plaid");
      // TODO: show error
    }
  }, [open, ready]);

  const onConfirmAutomateInstitution = () => {
    setShowAutomateInstitutionConfirmation(false);
    showPlaidModal();
  };

  const menuOptions = useMemo(() => {
    const commonInstitutionActions = [
      {
        label: (
          <MenuItemLabel
            icon={<Edit color="menu-icon" size="small" />}
            label="Edit"
          />
        ),
        onClick: onEditInstitution
      },
      {
        label: (
          <MenuItemLabel
            icon={<Trash color="menu-icon" size="small" />}
            label="Remove"
          />
        ),
        onClick: onRequestToDeleteInstitution
      }
    ];

    const manualInstitutionActions = [
      ...commonInstitutionActions,
      {
        label: (
          <MenuItemLabel
            icon={<Link color="menu-icon" size="small" />}
            label="Convert to Automated"
          />
        ),
        onClick: onRequestToAutomateInstitution
      }
    ];

    const automatedInstitutionActions = [
      ...commonInstitutionActions,
      {
        label: (
          <MenuItemLabel
            icon={<Update color="menu-icon" size="small" />}
            label="Convert to Manual"
          />
        ),
        onClick: onRequestToManualInstitution
      }
    ];

    const errorInstitutionActions = [
      {
        label: (
          <MenuItemLabel
            icon={<Action color="menu-icon" size="small" />}
            label="Reconnect Institution"
          />
        ),
        onClick: showPlaidModal
      },
      {
        label: (
          <MenuItemLabel
            icon={<Update color="menu-icon" size="small" />}
            label="Convert to Manual"
          />
        ),
        onClick: onRequestToManualInstitution
      },
      {
        label: (
          <MenuItemLabel
            icon={<Trash color="menu-icon" size="small" />}
            label="Remove"
          />
        ),
        onClick: onRequestToDeleteInstitution
      }
    ];

    if (isStatusError(institution.status)) {
      return errorInstitutionActions;
    } else if (institution.type === "manual") {
      return manualInstitutionActions;
    }
    return automatedInstitutionActions;
  }, [
    institution,
    onEditInstitution,
    onRequestToManualInstitution,
    onRequestToAutomateInstitution,
    onRequestToDeleteInstitution,
    showPlaidModal
  ]);

  const renderActions = () => {
    if (institution.processing || processing) {
      return (
        <Box pad={{ horizontal: "small" }}>
          <Loading color={size !== "small" ? "white" : undefined} />
        </Box>
      );
    }

    return (
      <Menu
        icon={<More />}
        dropAlign={{ right: "right", top: "bottom" }}
        // @ts-ignore
        dropBackground={{ color: "drop", dark: theme === UserThemes.DARK }}
        items={menuOptions}
      />
    );
  };

  return (
    <Box
      ref={institutionRef}
      border="top"
      pad={
        size === "small"
          ? { horizontal: "medium", vertical: "small" }
          : undefined
      }
    >
      <Box direction="row" align="center" justify="between">
        <Box
          direction="row"
          align="center"
          justify="center"
          gap="small"
          margin={{ left: "small" }}
          pad={{ top: "small" }}
        >
          <InstitutionLogo institution={institution} />
          <Box align="start">
            <Box direction="row" align="center" justify="center" gap="small">
              <Text
                size="large"
                truncate
                style={{ maxWidth: "144px" }}
                title={institution.name}
              >
                {institution.name}
              </Text>
              {isStatusError(institution.status) && (
                <Tip
                  plain
                  dropProps={
                    size === "small"
                      ? { align: { right: "left", bottom: "top" } }
                      : { align: { left: "right", top: "top" } }
                  }
                  content={
                    <Box pad="small" round="small" background="light-3">
                      <Text size="small">
                        There was an issue communicating with the institution.
                        Click to fix.
                      </Text>
                    </Box>
                  }
                >
                  <Button
                    plain
                    onClick={showPlaidModal}
                    icon={<CircleAlert size="small" color="liabilities" />}
                  />
                </Tip>
              )}

              {!isStatusError(institution.status) && (
                <InstitutionTypeBadge type={institution.type} />
              )}
            </Box>
            <Text
              size={size === "medium" ? "small" : undefined}
              color="text-weak"
              style={{ maxWidth: "220px" }}
              truncate
            >
              <NumberText
                color="text"
                size={size === "medium" ? "small" : undefined}
                value={{
                  value: institution.netWorth,
                  percentage: user?.netWorth
                    ? (institution.netWorth / user?.netWorth) * 100
                    : 0
                }}
                weight="bold"
              />
              <span> in net worth</span>
            </Text>
          </Box>
        </Box>
        {renderActions()}
      </Box>
      <Heading
        level={5}
        margin={{ top: "small", bottom: "none", left: "small" }}
      >
        Accounts ({institution?.visibleAccounts.length})
      </Heading>
      <Box gap="small" pad={{ top: "small", bottom: "medium" }}>
        {orderBy(institution.visibleAccounts, ["balance"], ["desc"]).map(
          (account) => (
            <Account
              key={`${institution.id}_${account.id}`}
              account={account}
            />
          )
        )}
      </Box>
      {showDeleteInstitutionConfirmation && (
        <DeleteInstitutionConfirmation
          institution={institution}
          isPro={!!user?.isPro}
          onCancel={onCancelDeleteInstitution}
          onConfirm={onConfirmDeleteInstitution}
        />
      )}
      {showAutomateInstitutionConfirmation && (
        <AutomateInstitutionConfirmation
          institution={institution}
          onCancel={onCancelAutomateInstitution}
          onConfirm={onConfirmAutomateInstitution}
        />
      )}
      {showManualInstitutionConfirmation && (
        <ManualInstitutionConfirmation
          institution={institution}
          onCancel={onCancelManualInstitution}
          onConfirm={onConfirmManualInstitution}
        />
      )}
    </Box>
  );
};

interface InstitutionsProps {
  institutions: InstitutionType[];
}

export const Institutions = ({ institutions }: InstitutionsProps) => {
  if (!institutions?.length)
    return (
      <Box fill align="center" margin={{ bottom: "medium" }}>
        <Text color="text-weak">No institutions yet</Text>
      </Box>
    );

  return (
    <Box flex overflow="auto">
      <Box flex={false}>
        {institutions.map((institution) => {
          return <Institution key={institution.id} institution={institution} />;
        })}
      </Box>
    </Box>
  );
};
