import React, { createContext, useCallback, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { db, useFirebaseContext } from ".";
import { User } from "../types";
import { isPro } from "../utils/subscription";

export const UserContext = createContext<any>(null);

const UserProvider: React.FC = ({ children }) => {
  const [googleUser, setGoogleUser] = useState<any>(null);
  const [trtUser, setTrtUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const { auth } = useFirebaseContext();
  const userRef = useRef<any>(null);
  const [error, setError] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged((userAuth: any) => {
      if (!userAuth) {
        setError("no user present");
        setLoading(false);
        setGoogleUser(null);
      } else {
        setLoading(true);
        setError("");
        setGoogleUser(userAuth);
      }
    });
  }, [auth]);

  const fetchUser = useCallback(async (gUser = googleUser) => {
    if (gUser) {
      const userId = gUser?.uid;
      const ref = db.getUserById(userId);
      userRef.current = ref;

      const userData = (await ref.get()).data();

      if (!userData) {
        return null;
      }

      const subscription = await db.getUserSubscription(userId);

      return {
        ...userData,
        isPro: isPro(subscription),
        avatarUrl: userData?.avatarUrl || (gUser.photoURL as string),
        uid: gUser.uid
      } as User;
    }

    return null;
  }, [googleUser]);

  const updateTrtUser = useCallback(async (gUser) => {
    setLoading(true);
    const trtUser = await fetchUser(gUser);
    if (trtUser) {
      setTrtUser(trtUser);
      setLoading(false);
    }
  }, [fetchUser]);

  useEffect(() => {
    const loadTrtUser = async () => {
      const trtUser = await fetchUser();
      if (trtUser) {
        setTrtUser(trtUser);
        setLoading(false);
      }
    };

    if (googleUser) {
      loadTrtUser();
    }
  }, [googleUser, fetchUser]);

  const signOut = useCallback(() => {
    setTrtUser(null);
    setGoogleUser(null);
    auth.signOut();
  }, [auth]);

  return (
    <UserContext.Provider
      value={{
        loading,
        user: trtUser,
        userRef,
        error,
        googleUser,
        signOut,
        refetchUser: fetchUser,
        updateTrtUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
