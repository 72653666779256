import {
  Account,
  Institution,
  RealEstate,
  UserAssetsAndLiabilitiesTypes
} from "../types";

export const getUserAssetsAndLiabilities = (accounts: Account[] = []) =>
  accounts.reduce(
    (assetsAndLiabilities, account) => {
      const {
        type: originalType,
        customType,
        balance = 0,
        hidden = false
      } = account;
      const type = customType || originalType;
      if (!hidden) {
        if (type === "checking" || type === "savings") {
          assetsAndLiabilities.assets += balance;
          assetsAndLiabilities.cash += balance;
        } else if (
          type === "investment" ||
          type === "cd" ||
          type === "money-market"
        ) {
          assetsAndLiabilities.assets += balance;
          assetsAndLiabilities.investments += balance;
        } else if (type === "loan") {
          assetsAndLiabilities.liabilities += balance;
          assetsAndLiabilities.loans += balance;
        } else if (type === "credit-card") {
          assetsAndLiabilities.liabilities += balance;
          assetsAndLiabilities.creditCards += balance;
        }
      }
      return assetsAndLiabilities;
    },
    {
      assets: 0,
      liabilities: 0,
      cash: 0,
      investments: 0,
      loans: 0,
      creditCards: 0
    }
  );

export const getAssetsAndLiabilities = (
  institutions: Institution[],
  realEstate: RealEstate[]
) => {
  const totals: Record<UserAssetsAndLiabilitiesTypes, number> = {
    assets: 0,
    liabilities: 0,
    cash: 0,
    investments: 0,
    loans: 0,
    creditCards: 0
  };
  institutions.forEach((institution) => {
    const assetsAndLiabitilies = getUserAssetsAndLiabilities(
      institution.accounts
    );
    const keys = Object.keys(
      assetsAndLiabitilies
    ) as UserAssetsAndLiabilitiesTypes[];
    keys.forEach((key) => {
      totals[key] += assetsAndLiabitilies[key];
    });
  });

  const realEstateValue = realEstate.reduce((acc, re) => acc + re.value, 0);

  return {
    ...totals,
    assets: totals.assets + realEstateValue
  };
};

export const isInstitutionLinked = (
  institutions: Institution[],
  institution: Institution
) => {
  return institutions.some(
    (i) => i.institutionId === institution.institutionId
  );
};
