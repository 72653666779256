import ls from "localstorage-ttl";

import { InvestmentGroupType, User } from "../types";

const maxAge = 3600000; // 1 hour

export const getInvestmentGroupKey = (id: string, userId: string) =>
  `investmentGroup_${id}_${userId}`;

export const setInvestmentGroupInCache = (
  investmentGroupKey: string,
  data: InvestmentGroupType
) => ls.set(investmentGroupKey, data, maxAge);

export const getInvestmentGroupFromCache = (
  investmentGroupKey: string
): InvestmentGroupType => ls.get(investmentGroupKey) as InvestmentGroupType;

export const removeInvestmentGroupFromCache = (investmentGroupKey: string) =>
  localStorage.removeItem(investmentGroupKey);

export const removeAllInvestmentGroupsFromCache = (user: User) => {
  user?.investmentGroupIds?.forEach((id) => {
    removeInvestmentGroupFromCache(getInvestmentGroupKey(id, user.uid));
  });
};
