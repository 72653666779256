import React from "react";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { UpdateInstitutionBody } from "./";

export const UpdateInstitutionDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout
      title="Update Institution"
      subTitle="Make updates to your institution"
      onClose={onNavigate("/")}
    >
      <UpdateInstitutionBody />
    </AppLayout>
  );
};
