import React from "react";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { SettingsBody } from "./";

export const SettingsDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout
      title="Settings"
      subTitle="Change profile picture, password, upgrade plans"
      onClose={onNavigate("/")}
    >
      <SettingsBody />
    </AppLayout>
  );
};
