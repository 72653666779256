import { useContext } from "react";
import { Avatar, Box, ResponsiveContext } from "grommet";
import md5 from "md5";

import { InstitutionLogo } from "..";

export const UserActivityLogo = ({
  user,
  holding,
  background = "card"
}: any) => {
  const size = useContext(ResponsiveContext);
  const imgSize = size === "small" ? "40px" : "48px";
  return (
    <Box direction="row" align="center" flex={false}>
      <Box
        width={imgSize}
        height={imgSize}
        round="full"
        border={{
          side: "all",
          size: "2px",
          color: background
        }}
        style={{
          overflow: "hidden"
        }}
      >
        <Avatar
          size={imgSize}
          src={
            user?.avatarUrl ||
            `//s.gravatar.com/avatar/${md5(user?.email || "")}?s=80`
          }
        />
      </Box>
      <InstitutionLogo
        institution={holding}
        size={imgSize}
        margin={{ left: "-12px" }}
        border={{ side: "all", size: "2px", color: background }}
      />
    </Box>
  );
};
