import { useContext } from "react";
import { FirebaseContext, FirebaseContextValue } from "./firebase-context";

export const useFirebaseContext = (): FirebaseContextValue => {
  const contextValue = useContext(FirebaseContext);
  if (!contextValue) {
    throw new Error(
      "FirebaseContext not available in component hierarchy. FirebaseContext.Provider must be declared above this component."
    );
  }

  return contextValue;
};
