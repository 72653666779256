import { useCallback, useState } from "react";
import { Box, Button, Text } from "grommet";
import { ClearOption, Logout, SettingsOption, Terminal } from "grommet-icons";

import { AppLayoutMobile } from "./layouts";
import { useAppState, useNavigation } from "../hooks";
import { useLoggedUser } from "../firebase";
import { ConfirmResetRestimate } from "../components/confirmResetRestimate";

export const UserMenuMobile = () => {
  const { signOut } = useLoggedUser();
  const { onNavigate } = useNavigation();
  const { user } = useAppState();
  const [showConfirmResetRestimate, setShowConfirmResetRestimate] =
    useState(false);
  const onResetRestimate = useCallback(() => {
    setShowConfirmResetRestimate(true);
  }, [setShowConfirmResetRestimate]);

  const onCloseResetRestimateModal = useCallback(() => {
    setShowConfirmResetRestimate(false);
  }, [setShowConfirmResetRestimate]);

  return (
    <AppLayoutMobile title="User Menu">
      <Box flex pad={{ horizontal: "large" }}>
        {user?.admin && (
          <Button onClick={onNavigate("/admin")}>
            <Box
              direction="row"
              align="center"
              justify="center"
              gap="medium"
              border="bottom"
              pad="large"
            >
              <Terminal color="menu-icon" />
              <Text size="large">Admin Portal</Text>
            </Box>
          </Button>
        )}
        <Button onClick={onNavigate("/settings")}>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="medium"
            border="bottom"
            pad="large"
          >
            <SettingsOption color="menu-icon" />
            <Text size="large">Settings</Text>
          </Box>
        </Button>
        <Button onClick={onResetRestimate}>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="medium"
            border="bottom"
            pad="large"
          >
            <ClearOption color="menu-icon" />
            <Text size="large">Reset Data</Text>
          </Box>
        </Button>
        <Button onClick={signOut}>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="medium"
            border="bottom"
            pad="large"
          >
            <Logout color="menu-icon" />
            <Text size="large">Sign out</Text>
          </Box>
        </Button>
        {showConfirmResetRestimate && (
          <ConfirmResetRestimate onClose={onCloseResetRestimateModal} />
        )}
      </Box>
    </AppLayoutMobile>
  );
};
