import { useCallback, useContext, useMemo, useState } from "react";
import { Box, ResponsiveContext, Text } from "grommet";
import { Home, Organization } from "grommet-icons";

import {
  useAddPlaidInstitution,
  useAppState,
  useNavigation
} from "../../hooks";
import { AssetCard } from "../../components";

const realEstateOptions = {
  manual: {
    mode: "manual",
    description: (
      <Text>Add your real estate by entering your address and value.</Text>
    ),
    remaining: undefined
  }
};

export const AddAssetsBody = () => {
  const { status, user, token } = useAppState();
  const size = useContext(ResponsiveContext);
  const { navigate, onNavigate } = useNavigation();
  const institutionOptions = useMemo(
    () => ({
      automated: {
        mode: "automated",
        description: (
          <Text>
            Accounts will connect and update automatically through Plaid.
          </Text>
        ),
        remaining: user?.isPro
          ? undefined
          : 1 - (user?.automatedInstitutionsInUse || 0)
      },
      manual: {
        mode: "manual",
        description: <Text>Manually enter and update your account data</Text>,
        remaining: undefined
      }
    }),
    [user]
  );

  const onExit = (success: boolean, institutionId?: string) => {
    if (success) {
      if (size === "small") {
        navigate("/institutions");
      } else {
        navigate(`/add?activePanel=2&activeInstitution=${institutionId ?? ""}`);
      }
    }
  };
  const { open, ready } = useAddPlaidInstitution({ user, onExit, token });
  const [institutionMode, setInstitutionMode] = useState(
    institutionOptions.automated.mode
  );

  const onInstitutionModeChange = useCallback(
    (newMode) => setInstitutionMode(newMode),
    []
  );
  const onInstitutionChoose = useCallback(() => {
    if (institutionMode === "automated") {
      if (ready) {
        open();
      } else {
        console.error("Could not initialize plaid");
        // TODO: show error
      }
    } else {
      navigate("/add-institution");
    }
  }, [navigate, open, ready, institutionMode]);

  return (
    <Box flex={false} direction="row" align="center" justify="center" wrap>
      <AssetCard
        icon={
          <Organization
            size={size === "large" ? "xlarge" : "large"}
            color="status-ok"
          />
        }
        {...institutionOptions[institutionMode as "automated" | "manual"]}
        type="Institution"
        onModeChange={onInstitutionModeChange}
        onChoose={onInstitutionChoose}
        loading={status === "loading"}
      />
      <AssetCard
        icon={
          <Home size={size === "large" ? "xlarge" : "large"} color="accent-2" />
        }
        {...realEstateOptions["manual"]}
        type="Real Estate"
        onChoose={onNavigate("/add-real-estate")}
      />
    </Box>
  );
};
