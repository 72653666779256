import { SyntheticEvent, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

export const useNavigation = () => {
  const history = useHistory();
  const getNewPath = useCallback(
    (path: string) => {
      const pathGroup = path.split("?");
      const url = pathGroup[0];
      const params = new URLSearchParams(history.location.search);
      if (pathGroup.length > 1) {
        const newParams = new URLSearchParams(pathGroup[1]);
        newParams.forEach((value, key) => params.set(key, value));
      }
      const search = params.toString();
      return `${url}${search ? `?${search}` : ""}`;
    },
    [history]
  );
  const navigate = useCallback(
    (path: string, state?: any) => {
      history.push(getNewPath(path), state);
    },
    [history, getNewPath]
  );

  const onNavigate = useCallback(
    (path: string, state?: any) => (event?: SyntheticEvent) => {
      // so that it can be used with Anchor + onClick
      event?.preventDefault();
      history.push(getNewPath(path), state);
    },
    [history, getNewPath]
  );

  const navigateBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const onNavigateBack = useCallback(
    (event?: SyntheticEvent) => {
      // so that it can be used with Anchor + onClick
      event?.preventDefault();
      history.goBack();
    },
    [history]
  );

  return useMemo(
    () => ({ navigate, navigateBack, onNavigate, onNavigateBack }),
    [navigate, navigateBack, onNavigate, onNavigateBack]
  );
};
