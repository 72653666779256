import React, { useCallback, useMemo } from "react";
import {
  Box,
  Heading,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  TextInputProps
} from "grommet";

import {
  getAccountName,
  getActiveInstitutionAccountRows,
  getRealEstateName,
  NumberText
} from "../../utils";
import { CurrencyInput, InstitutionLogo, PropertyLogo } from "../index";
import { AccountRow, InsightsProps, RealEstate } from "../../types";
import { useAppState } from "../../context";

interface InstitutionAccountRowProps {
  row: AccountRow;
  onUpdate: (balance: number | string) => void;
}

interface RealEstateRowProps {
  realEstate: RealEstate;
  onUpdate: (value: number | string) => void;
}

const InstitutionAccountBalanceRow = ({
  row,
  onUpdate,
  ...rest
}: InstitutionAccountRowProps & TextInputProps) => {
  const onBalanceChange = useCallback(
    ({ target: { value: balance } }) => onUpdate(balance),
    [onUpdate]
  );
  return (
    <TableRow>
      <TableCell>
        <Box direction="row" gap="small">
          <InstitutionLogo institution={row.institution} />
          <Box>
            <Text size="small" color="text-xweak">
              {row.institution.name}
            </Text>
            <Text>{getAccountName(row.account)}</Text>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Heading level={5} margin="none">
          <NumberText value={row.account.balance} />
        </Heading>
      </TableCell>
      <TableCell>
        <CurrencyInput {...rest} onChange={onBalanceChange} />
      </TableCell>
    </TableRow>
  );
};

const RealEstateRow = ({
  realEstate,
  onUpdate,
  ...rest
}: RealEstateRowProps & TextInputProps) => {
  const onBalanceChange = useCallback(
    ({ target: { value: balance } }) => onUpdate(balance),
    [onUpdate]
  );
  return (
    <TableRow>
      <TableCell>
        <Box direction="row" gap="small">
          <PropertyLogo />
          <Box>
            <Text size="small" color="text-xweak">
              Real Estate
            </Text>
            <Text>{getRealEstateName(realEstate)}</Text>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Heading level={5} margin="none">
          <NumberText value={realEstate.value} />
        </Heading>
      </TableCell>
      <TableCell>
        <CurrencyInput {...rest} onChange={onBalanceChange} />
      </TableCell>
    </TableRow>
  );
};

export const BalanceTab = ({ insights, onUpdate }: InsightsProps) => {
  const { manualInstitutions, manualRealEstate } = useAppState();
  const onBalanceUpdate = useCallback(
    (institutionId: string, accountId: string) => (balance: string | number) => {
      const newInsights = { ...insights };
      newInsights.institutions[institutionId].accounts[accountId].balance =
        balance;
      onUpdate(newInsights);
    },
    [insights, onUpdate]
  );
  const onRealEstateUpdate = useCallback(
    (realEstateId: string) => (value: string | number) => {
      const newInsights = { ...insights };
      newInsights.realEstate[realEstateId].value = value;
      onUpdate(newInsights);
    },
    [insights, onUpdate]
  );
  const manualAccountRows = useMemo(() => {
    return getActiveInstitutionAccountRows(manualInstitutions).map((row) => (
      <InstitutionAccountBalanceRow
        key={`institution-row-${row.institution.id}-${row.account.id}`}
        row={row}
        value={
          insights.institutions[row.institution.id]?.accounts[row.account.id]
            ?.balance
        }
        onUpdate={onBalanceUpdate(row.institution.id, row.account.id)}
      />
    ));
  }, [manualInstitutions, insights, onBalanceUpdate]);
  const manualRealEstateRows = useMemo(() => {
    return manualRealEstate.map((realEstate) => (
      <RealEstateRow
        key={`real-estate-row-${realEstate.id}`}
        realEstate={realEstate}
        value={insights.realEstate[realEstate.id]?.value}
        onUpdate={onRealEstateUpdate(realEstate.id)}
      />
    ));
  }, [manualRealEstate, insights, onRealEstateUpdate]);
  return (
    <Box fill align="center">
      <Box pad="medium" width="large">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell>
                <Heading level={5} margin="none">
                  Current
                </Heading>
              </TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  New
                </Heading>
              </TableCell>
            </TableRow>
            {manualAccountRows}
            {manualRealEstateRows}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
