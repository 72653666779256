import { useCallback, useContext, useState } from "react";
import {
  Anchor,
  Box,
  Button,
  CheckBox,
  Heading,
  Layer,
  Paragraph,
  ResponsiveContext,
  Text
} from "grommet";
import { Close, Copy } from "grommet-icons";
import { useAsyncFn, useCopyToClipboard } from "react-use";
import { v4 as uuidv4 } from "uuid";

import { FeedbackContext } from "../feedback";
import { TRTThemeContext } from "../../context";
import { db } from "../../firebase";
import { InvestmentGroupType, UserThemes } from "../../types";
import { LoadingButton } from "..";

export const ShareSettings = ({
  onClose,
  investmentGroup
}: {
  onClose: () => void;
  investmentGroup?: InvestmentGroupType;
}) => {
  const { theme } = useContext(TRTThemeContext);
  const [isPublic, setIsPublic] = useState(
    investmentGroup?.shareSettings?.public
  );

  const [token, setToken] = useState(investmentGroup?.shareSettings?.token);
  const [, copyToClipboard] = useCopyToClipboard();
  const size = useContext(ResponsiveContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const appBackground = theme === UserThemes.DARK ? "dark-1" : "light-1";

  const [updating, updateInvestimentGroup] = useAsyncFn(async () => {
    const updatedGroup = {
      ...investmentGroup,
      shareSettings: {
        public: isPublic,
        token
      }
    } as InvestmentGroupType;
    try {
      const group = await db.updateInvestmentGroup(updatedGroup);
      sendFeedback({
        message: `Successfully updated share settings for ${investmentGroup?.name}`,
        type: "success"
      });
      return group;
    } catch (e) {
      sendFeedback({
        message: `Error updating share settings for ${investmentGroup?.name}`,
        type: "error"
      });
    }
  }, [investmentGroup, isPublic, token]);

  const onCopy = useCallback(async () => {
    copyToClipboard(`${window.location.origin}/group/join/${token}`);
    sendFeedback({
      message: `Successfully copied share URL for ${investmentGroup?.name}`,
      type: "success"
    });
  }, [copyToClipboard, investmentGroup, sendFeedback, token]);

  return (
    <Layer onEsc={onClose} onClickOutside={onClose} margin="large">
      <Box
        width="xlarge"
        height={size === "small" ? "full" : "xlarge"}
        background={{ color: appBackground, dark: theme === UserThemes.DARK }}
        round="small"
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          pad="medium"
          border="bottom"
        >
          <Box gap="xsmall">
            <Heading level={3} responsive={false} margin="none">
              Share Settings
            </Heading>
            <Text color="text-xweak">
              Change share settings for <b>{investmentGroup?.name}</b>
            </Text>
          </Box>
          <Button icon={<Close />} onClick={onClose} />
        </Box>
        <Box flex overflow="auto" pad="medium" gap="large">
          <Box gap="xsmall">
            <CheckBox
              toggle
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setIsPublic(e.target.checked)
              }
              checked={isPublic}
              label="Publicly available?"
              reverse
            />
            <Text color="text-xweak">
              If this investment group should be available for any viewer to
              join using the public URL below
            </Text>
          </Box>
          <Box gap="small" flex>
            <Box
              round="small"
              pad="small"
              direction="row"
              align="center"
              border="all"
              alignSelf="start"
              gap="small"
            >
              <Text color="text-xweak" style={{ maxWidth: "600px" }} truncate>
                {`${window.location.origin}/group/join/${token}`}
              </Text>
              <Button onClick={onCopy}>
                <Copy />
              </Button>
            </Box>
            <Anchor
              onClick={() => setToken(uuidv4())}
              label="Generate new url"
            />
          </Box>
          <Paragraph color="text-xweak">
            <i>
              Users joining using this URL will be added as <b>viewers</b> and
              administrators should manually upgrade them to players if
              necessary
            </i>
          </Paragraph>
        </Box>
        <Box
          tag="footer"
          justify="end"
          direction="row"
          pad="medium"
          border="top"
          gap="small"
          flex={false}
          responsive={false}
        >
          <Button secondary label="Cancel" onClick={onClose} />
          <LoadingButton
            isLoading={updating.loading}
            label="Save"
            primary
            onClick={updateInvestimentGroup}
          />
        </Box>
      </Box>
    </Layer>
  );
};
