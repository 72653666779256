import React from "react";

import { BalanceUpdateBody } from "./";
import { AdminLayoutMobile } from "../../layouts";

export const BalanceUpdateMobile = () => (
  <AdminLayoutMobile title="Balance Update">
    <BalanceUpdateBody />
  </AdminLayoutMobile>
);
