import * as React from "react";
import { Redirect } from "react-router";
import { useLoggedUser } from "../firebase";
import { useNavigation } from "../hooks";

export const Logout = () => {
  const { signOut } = useLoggedUser();
  const { navigate } = useNavigation();
  React.useEffect(() => {
    signOut();
    navigate("/login");
  }, [navigate, signOut]);

  return <Redirect to={{ pathname: "/login" }} />;
};
