import app from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  appMeasurementId: process.env.REACT_APP_APP_MEASUREMENT_ID
};

if (!app.apps.length) {
  app.initializeApp(config);
  app.analytics();
}

export const auth = app.auth();
export const db = app.firestore();
// TODO: research firebase offline access
// db.enablePersistence();
export const storage = app.storage();
export const googleProvider = new app.auth.GoogleAuthProvider();
export const functions = app.functions();
