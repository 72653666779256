import React from "react";
import { Text, TextProps } from "grommet";

export interface FormLabelProps {
  label: string;
  htmlFor?: string;
}

export const FormLabel = ({ label, ...rest }: FormLabelProps & TextProps) => (
  <Text tag="label" size="small" weight="bold" {...rest}>
    {label}
  </Text>
);
