const getFirstDocument = (results) => {
  let first;

  results.forEach((result) => {
    if (!first) {
      first = result;
      return false;
    }
  });

  return first;
};

const toArray = (snapshot) => {
  const arr = [];
  snapshot.forEach((item) => {
    arr.push(item.data());
  });

  return arr;
};

const toArrayIncludeIds = (snapshot) => {
  const arr = [];
  const arrIds = [];
  snapshot.forEach((item) => {
    arr.push(item.data());
    arrIds.push(item.id);
  });

  return [arr, arrIds];
};

module.exports = {
  getFirstDocument,
  toArray,
  toArrayIncludeIds
};
