import { useCallback, useContext, useEffect, useState } from "react";
import { Box, TextInput, ResponsiveContext, Text } from "grommet";
import { useAsyncFn, useFirstMountState } from "react-use";
import ReactJson from "react-json-view";
import {
  FeedbackContext,
  FormLabel,
  LoadingButton
} from "../../../../components";
import { useFirebaseContext } from "../../../../firebase";
import { TRTThemeContext } from "../../../../context";
import { UserThemes } from "../../../../types";
import { useNavigation, useQueryParams } from "../../../../hooks";

export const UserSearchBody = () => {
  const { db } = useFirebaseContext();
  const { navigate } = useNavigation();
  const isFirstMount = useFirstMountState();
  const size = useContext(ResponsiveContext);
  const { theme } = useContext(TRTThemeContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const params = useQueryParams();
  const [userEmail, setUserEmail] = useState(params.get("email") || "");

  const onUserEmailChange = useCallback(
    ({ target: { value } }) => {
      setUserEmail(value);
      navigate(`?email=${value}`);
    },
    [navigate]
  );

  const [userData, getUserData] = useAsyncFn(
    async (e?) => {
      e?.preventDefault();
      if (!userEmail) {
        sendFeedback({
          message: "Provide an email",
          type: "error"
        });
        return;
      }
      try {
        const userRef = await db.getUserByEmail(userEmail);

        if (userRef.docs.length > 0) {
          const user = userRef.docs[0];
          const userData = user.data();
          const institutions: any[] = [];
          const institutionDocs = await user.ref
            .collection("institutions")
            .get();
          institutionDocs.docs.forEach((instDoc: any) => {
            institutions.push(instDoc.data());
          });

          return {
            automatedInstitutions: institutions.filter(
              (institution) => institution.type === "automated"
            ),
            manualInstitutions: institutions.filter(
              (institution) => institution.type !== "automated"
            ),
            ...userData
          };
        } else {
          const error = "User not found";
          sendFeedback({
            message: error,
            type: "error"
          });

          return { error };
        }
      } catch (e) {
        console.log(e);
        sendFeedback({
          message: "Error getting user",
          type: "error"
        });
      }
    },
    [userEmail]
  );

  useEffect(() => {
    if (isFirstMount && userEmail) {
      getUserData();
    }
  }, [userEmail, isFirstMount, getUserData]);

  return (
    <Box flex>
      <Box
        flex={false}
        border="bottom"
        pad={{ horizontal: "medium", top: "small", bottom: "medium" }}
        background="card"
      >
        <form onSubmit={getUserData}>
          <Box width={size !== "small" ? "xlarge" : undefined}>
            <FormLabel label="User email" htmlFor="user-email" />
            <TextInput
              type="email"
              id="user-email"
              placeholder="User email"
              value={userEmail}
              onChange={onUserEmailChange}
            />
          </Box>
          <Box
            direction="row"
            align="center"
            gap="small"
            responsive={false}
            margin={{ top: "small" }}
          >
            <LoadingButton
              isLoading={userData.loading}
              primary
              label="Get user data"
              size="small"
              type="submit"
            />
          </Box>
        </form>
      </Box>
      <Box flex overflow="auto">
        {userData.loading && (
          <Text color="text-weak" alignSelf="center" margin={{ top: "medium" }}>
            Loading user data
          </Text>
        )}
        {!userData.loading && userData.value && (
          <Text color="text-weak" alignSelf="center" margin={{ top: "medium" }}>
            {userData.value.error}
          </Text>
        )}
        {!userData.loading && userData.value && !userData.value.error && (
          <ReactJson
            collapsed={1}
            name="user"
            displayDataTypes={false}
            theme={theme === UserThemes.DARK ? "twilight" : undefined}
            src={userData.value!}
            onSelect={(select) => {
              const { name, value } = select;
              if (name === "accessToken") {
                navigate(`/admin/plaid-debugger?accessToken=${value}`);
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};
