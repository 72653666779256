import React, { useCallback } from "react";
import { get } from "lodash";
import { Box, Button, CheckBox, Select, Text, TextInput } from "grommet";
import { Trash } from "grommet-icons";
import numeral from "numeral";
import { ACCOUNT_TYPE_OPTIONS } from "../header/account";
import { Account, ACCOUNT_TYPE_DISPLAY_NAME } from "../../types";
import { CurrencyInput } from "../currencyInput";
import { BaseCard } from "../baseCard";
import { FormLabel } from "../formLabel";

interface AccountCardProps {
  account: Account;
  onChange: (id: string, key: string, value: any) => void;
  onRemove: (id: string) => void;
  automated: boolean;
}

interface OnAccountFieldChangeProps {
  option?: {
    value: string;
  };
  target: {
    checked: boolean;
    value: string;
  };
}

export const AccountCard = ({
  account,
  onChange,
  onRemove,
  automated
}: AccountCardProps) => {
  const onAccountFieldChange = useCallback(
    (key, bool = null) =>
      ({ option, target: { checked, value } }: OnAccountFieldChangeProps) => {
        onChange(account.id, key, get(option, "value", bool ? checked : value));
      },
    [account, onChange]
  );
  const onRemoveAccount = useCallback(
    () => onRemove(account.id),
    [onRemove, account.id]
  );
  return (
    <BaseCard>
      {automated ? (
        <Box flex gap="small" pad={{ top: "small" }} responsive={false}>
          <Box
            direction="row"
            align="center"
            justify="between"
            pad={{ horizontal: "xsmall", bottom: "small" }}
            border="bottom"
            responsive={false}
          >
            <Box>
              <Text weight="bold">{account.name}</Text>
              <Text size="small" color="text-weak">
                {ACCOUNT_TYPE_DISPLAY_NAME[account.type] || [account.type]}
              </Text>
            </Box>
            <Text
              weight="bold"
              size="large"
              color={account.balance >= 0 ? "status-ok" : "status-critical"}
            >
              {numeral(account.balance).format("-$0.[00]a")}
            </Text>
          </Box>
          <Box pad={{ horizontal: "xsmall", vertical: "xsmall" }} gap="medium">
            <Box
              direction="row"
              align="center"
              justify="between"
              gap="medium"
              responsive={false}
            >
              <Box gap="xsmall" flex>
                <FormLabel
                  htmlFor={`account-alias-input${account.id}`}
                  label="Account Alias"
                />
                <TextInput
                  id={`account-alias-input${account.id}`}
                  value={get(account, "alias", "")}
                  onChange={onAccountFieldChange("alias")}
                  size={"small"}
                  placeholder="(e.g My Favorite Account)"
                />
              </Box>
              <Box gap="xsmall">
                <FormLabel
                  htmlFor={`account-visibility-input${account.id}`}
                  label="Account Visibility"
                />
                <Box height="36px" justify="center">
                  <CheckBox
                    id={`account-visibility-input${account.id}`}
                    checked={get(account, "hidden", false)}
                    label="Hidden"
                    onChange={onAccountFieldChange("hidden", true)}
                  />
                </Box>
              </Box>
            </Box>
            <Box gap="xsmall" flex>
              <FormLabel
                htmlFor={`custom-account-type-input${account.id}`}
                label="Custom Account Type"
              />
              <Select
                id={`custom-account-type-input${account.id}`}
                size="small"
                labelKey="label"
                valueKey={{ key: "value", reduce: true }}
                options={ACCOUNT_TYPE_OPTIONS}
                value={get(account, "customType") || get(account, "type")}
                onChange={onAccountFieldChange("customType")}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box gap="medium" pad={{ vertical: "xsmall" }} responsive={false}>
          <Box direction="row" align="center" justify="between">
            <Box align="start" gap="xsmall">
              <FormLabel
                htmlFor={`account-name-input${account.id}`}
                label="Account Name"
              />
              <TextInput
                id={`account-name-input${account.id}`}
                value={get(account, "name", "")}
                onChange={onAccountFieldChange("name")}
                size="small"
              />
            </Box>
            <Button onClick={onRemoveAccount}>
              <Trash color="status-critical" />
            </Button>
          </Box>
          <Box direction="row" align="center" justify="between" gap="small">
            <Box gap="xsmall" basis="50%">
              <FormLabel
                htmlFor={`account-balance-input${account.id}`}
                label="Account Balance"
              />
              <CurrencyInput
                id={`account-balance-input${account.id}`}
                value={get(account, "balance", "0")}
                onChange={onAccountFieldChange("balance")}
                size="small"
              />
            </Box>

            <Box gap="xsmall">
              <FormLabel
                htmlFor={`account-type-input${account.id}`}
                label="Account Type"
              />
              <Select
                id={`account-type-input${account.id}`}
                size="small"
                labelKey="label"
                valueKey={{ key: "value", reduce: true }}
                options={ACCOUNT_TYPE_OPTIONS}
                value={get(account, "type")}
                onChange={onAccountFieldChange("type")}
              />
            </Box>
          </Box>
        </Box>
      )}
    </BaseCard>
  );
};
