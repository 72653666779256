import { useContext, useEffect, useState } from "react";
import { Box, FormField, Text, TextInput } from "grommet";
import { useCallback } from "react";
import { useFirebaseContext } from "../firebase";
import { SignUpWithGoogle } from "./signUpInWithGoogle";
import { useAccessCodeContext, useFirebaseFunction } from "../hooks";
import { LoadingButton } from "./button";
import { FeedbackContext } from "./feedback";

type SignUpProps = {
  username?: string;
};

export const SignUp = ({ username = "" }: SignUpProps) => {
  const { sendFeedback } = useContext(FeedbackContext);
  const [accessCodeContext] = useAccessCodeContext();
  const [isLoading, setIsloading] = useState(false);
  const [email, setEmail] = useState(username);
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any>(null);
  const { auth, db } = useFirebaseContext();
  const sendRecaptcha = useFirebaseFunction({
    fnName: "sendRecaptcha",
    config: { execute: "once" }
  });
  const signUp = useCallback(async () => {
    if (!email || !password) {
      setError({ message: "Email and password are required" });
      setIsloading(false);
      return;
    }
    try {
      const authUser = await auth.createUser(email.toLowerCase(), password);
      await db.createUser({
        id: authUser.user.uid,
        email: email.toLowerCase(),
        name: "",
        accessCode: accessCodeContext || "",
        automatedInstitutionsInUse: 0,
        hasAddedAutomatedAccount: false
      });
      setIsloading(false);
    } catch (error: any) {
      setIsloading(false);
      setError(error);
    }
  }, [accessCodeContext, auth, db, email, password]);

  useEffect(() => {
    if (error?.message) {
      sendFeedback({
        message: error?.message.replace(".", ""),
        type: "error"
      });
    }
  }, [error, sendFeedback]);

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        setIsloading(true);
        // @ts-ignore
        window.grecaptcha.ready(async () => {
          // @ts-ignore
          const token: string = await window.grecaptcha.execute(
            "6LeVXuwZAAAAAHj6_Ga6f_2fLVEBF0rQiDFHvOPB",
            {
              action: "form"
            }
          );

          try {
            const response = await sendRecaptcha({ token });
            const data = response?.data;
            if (!data || data.score > 0.5) {
              signUp();
            } else {
              setIsloading(false);
              setError({ message: "You are a Robot! 🤖" });
            }
          } catch (error) {
            setIsloading(false);
            setError({ message: "Problems signing up" });
          }
        });
      }}
    >
      <FormField label="Username">
        <TextInput
          value={email || ""}
          type="email"
          placeholder="username"
          onChange={(e) => {
            const { value } = e.currentTarget;
            setEmail(value);
          }}
        />
      </FormField>
      <FormField label="Password">
        <TextInput
          type="password"
          placeholder="password"
          onChange={(e) => {
            const { value } = e.currentTarget;
            setPassword(value);
          }}
        />
      </FormField>

      <Box
        direction="row"
        align="center"
        justify="center"
        gap="small"
        margin={{ top: "medium" }}
      >
        <LoadingButton
          primary
          isLoading={isLoading}
          label="Sign up"
          type="submit"
        />
        <Text color="text-xweak">or</Text>
        <SignUpWithGoogle />
      </Box>
    </form>
  );
};
