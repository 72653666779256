import { useContext, useCallback, useRef, useMemo, useEffect } from "react";
import {
  Box,
  ResponsiveContext,
  Text,
  Heading,
  Anchor,
  Paragraph,
  Spinner
} from "grommet";
import { Link, Mail, StatusWarning } from "grommet-icons";
import { useAsync } from "react-use";
import {
  FeedbackContext,
  InvestmentGroupLogo,
  PaymentChooser
} from "../../components";
import { db } from "../../firebase";
import { useAppState, useNavigation, useFirebaseFunction } from "../../hooks";
import {
  InvestmentGroupMember,
  InvestmentGroupRoles,
  InvestmentGroupType,
  User
} from "../../types";
import { useParams } from "react-router";

type Params = {
  token: string;
};

export const JoinPublicInvestmentGroupBody = () => {
  const { user, updateUser } = useAppState();
  const called = useRef(false);
  const { sendFeedback } = useContext(FeedbackContext);
  const { token } = useParams<Params>();
  const { navigate } = useNavigation();
  const joinPublicGroup = useFirebaseFunction({
    fnName: "joinPublicGroup"
  });
  const investmentGroup = useAsync(async () => {
    const groupRef = await db.getInvestmentGroupByToken(token);
    return groupRef.docs?.[0]?.data() as InvestmentGroupType;
  }, [token]);

  const size = useContext(ResponsiveContext);
  const members = useMemo(
    () =>
      (investmentGroup
        ? investmentGroup.value?.members || []
        : []) as InvestmentGroupMember[],
    [investmentGroup]
  );
  const { administrators, players, viewers } = useMemo(
    () =>
      members.reduce(
        (
          groupedMembers: {
            administrators: InvestmentGroupMember[];
            players: InvestmentGroupMember[];
            viewers: InvestmentGroupMember[];
          },
          member: InvestmentGroupMember
        ) => {
          if (!member.pending) {
            if (member.role === InvestmentGroupRoles.ADMIN) {
              groupedMembers.administrators.push(member);
            } else if (member.role === InvestmentGroupRoles.PLAYER) {
              groupedMembers.players.push(member);
            } else {
              groupedMembers.viewers.push(member);
            }
          }
          return groupedMembers;
        },
        {
          administrators: [],
          players: [],
          viewers: []
        }
      ),
    [members]
  );

  const onPay = useCallback(() => {
    updateUser({
      ...(user as User),
      isPro: true
    });
  }, [updateUser, user]);

  const join = useAsync(async () => {
    const group = investmentGroup.value;
    if (group?.shareSettings?.public && user?.isPro && !called.current) {
      called.current = true;
      try {
        const response = await joinPublicGroup({
          email: user?.email,
          groupId: group.id
        });
        if (response.data.success) {
          const groups: InvestmentGroupType[] = [];
          const groupsRefs = await db.getInvestmentGroups(
            response.data.investmentGroupIds
          );
          groupsRefs.docs.forEach((groupRef) => {
            groups.push(groupRef.data() as InvestmentGroupType);
          });

          await new Promise((resolve) =>
            setTimeout(() => {
              updateUser({
                ...(user as User),
                investmentGroups: groups,
                investmentGroupIds: response.data.investmentGroupIds
              });
              sendFeedback({
                message: `Successfully joined ${group.name}`,
                type: "success"
              });
              resolve(true);
              navigate(`/investment-group/${group.id}`);
              // gotta wait until the user data is available
            }, 2000)
          );
        }
      } catch (e) {
        sendFeedback({
          message: `Failed to join ${group.name}`,
          type: "error"
        });
      }
    }
  }, [token, joinPublicGroup, investmentGroup, user]);

  const totalPlayers = administrators.length + players.length;
  const totalViewers = viewers.length;
  const investmentGroupIconSize = size === "small" ? "52px" : "72px";

  useEffect(() => {
    if (
      (!investmentGroup.value && !investmentGroup.loading) ||
      (investmentGroup.value && !investmentGroup.value?.shareSettings?.public)
    ) {
      console.log(investmentGroup.value);
      navigate("/not-found");
    }
  }, [investmentGroup, navigate]);

  if (investmentGroup.loading) {
    return (
      <Box flex overflow="auto" pad="medium">
        <Box flex overflow="auto">
          <Box
            direction="row"
            justify="center"
            align="center"
            pad={{ top: "medium" }}
            gap="small"
          >
            <Spinner color="dark-1" />
            <Text color="text-weak">Loading Group info ...</Text>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box flex overflow="auto">
      <Box flex={false}>
        <Box direction="row" align="start" justify="between">
          <Box direction="row" flex>
            <Box direction="row" align="center" gap="small" responsive={false}>
              <InvestmentGroupLogo
                investmentGroup={investmentGroup.value}
                size={investmentGroupIconSize}
              />
              <Box>
                <Heading level={2} margin="none">
                  {investmentGroup.value?.name}
                </Heading>
                <Text color="text-xweak">
                  {totalViewers
                    ? `${totalPlayers} players • ${totalViewers} viewers`
                    : `${totalPlayers} players`}
                </Text>
              </Box>
            </Box>
            <Box
              direction="row"
              align="start"
              flex
              justify={size === "small" ? "end" : undefined}
              margin={{ top: "xsmall", left: "small" }}
            >
              {investmentGroup.value?.links.website && (
                <Anchor
                  href={investmentGroup.value?.links.website}
                  target="_blank"
                  icon={<Link size="18px" />}
                />
              )}
              {investmentGroup.value?.links.email && (
                <Anchor
                  href={`mailto: ${investmentGroup.value?.links.email}`}
                  target="_blank"
                  icon={<Mail size="18px" />}
                />
              )}
              {investmentGroup.value?.links.slack && (
                <Anchor
                  href={investmentGroup.value?.links.slack}
                  target="_blank"
                  icon={<img alt="slack logo" src="/slack.svg" width="18px" />}
                />
              )}
              {investmentGroup.value?.links?.discord && (
                <Anchor
                  href={investmentGroup.value?.links.discord}
                  target="_blank"
                  icon={
                    <img alt="discord logo" src="/discord.svg" width="18px" />
                  }
                />
              )}
            </Box>
          </Box>
        </Box>
        {investmentGroup.value?.description && (
          <Paragraph
            color="text-xweak"
            size="large"
            margin={{ bottom: "none", top: "small" }}
          >
            {investmentGroup.value?.description}
          </Paragraph>
        )}
        {join.loading && (
          <Box flex overflow="auto">
            <Box
              direction="row"
              justify="center"
              align="center"
              pad={{ top: "medium" }}
              gap="small"
            >
              <Spinner color="dark-1" />
              <Text color="text-weak">Joining Investment Group...</Text>
            </Box>
          </Box>
        )}
        {!user?.isPro && (
          <Box
            pad={{ vertical: "medium" }}
            align="start"
            gap="medium"
            responsive={false}
          >
            <Box
              round="small"
              responsive={false}
              background="status-warning"
              direction="row"
              align="center"
              gap="xsmall"
              pad={{ vertical: "xsmall", right: "medium", left: "small" }}
            >
              <StatusWarning />
              <Text>
                You will need to upgrade to PRO to join this investment group
              </Text>
            </Box>
            <Heading level={3} margin="none" responsive={false}>
              Add Payment Method
            </Heading>
            <PaymentChooser onPay={onPay} align="start" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
