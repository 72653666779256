import React, { useCallback, useContext, useState } from "react";
import { Anchor, Box, Button, ResponsiveContext, Text } from "grommet";
import { useLocation } from "react-router";

import { LogoFull, SignIn, SignUp } from "../components";
import { State } from "../types";
import { useLoginRedirect, useNavigation } from "../hooks";

export const Login = () => {
  useLoginRedirect();
  const { onNavigate } = useNavigation();
  const { state } = useLocation<State>();
  const size = useContext(ResponsiveContext);
  const {
    from,
    allowSignUp,
    username = "",
  } = state || {
    from: { pathname: "" }
  };

  const [isSigningIn, setIsSigningIn] = useState(!allowSignUp);

  const toggleIsSigningIn = useCallback(
    (e) => {
      e.preventDefault();
      setIsSigningIn(!isSigningIn);
    },
    [isSigningIn]
  );

  const anchorLabel = isSigningIn ? "Sign up" : "Sign in";
  const textLabel = isSigningIn
    ? "Don't have an account?"
    : "Already have an account?";

  const loginCard = (
    <Box align="center" gap="medium" width="medium">
      <Button href="https://theretirementtracker.com" target="_blank">
        <LogoFull color="plain" size="large" />
      </Button>
      <Box alignSelf="stretch" gap="small">
        {isSigningIn && <SignIn from={from?.pathname ?? ""} />}
        {!isSigningIn && (
          <SignUp username={username} />
        )}
        {!isSigningIn && (
          <Text size="small" textAlign="center">
            By continuing to sign up you accept our{" "}
            <Anchor
              href="https://theretirementtracker.com/legal#tou"
              target="_blank"
            >
              Terms of Use
            </Anchor>{" "}
            and{" "}
            <Anchor
              href="https://theretirementtracker.com/legal#pp"
              target="_blank"
            >
              Privacy Policy
            </Anchor>
            .
          </Text>
        )}
      </Box>
      {allowSignUp && (
        <Box align="center">
          <Text>
            {textLabel}{" "}
            <Anchor href="#" onClick={toggleIsSigningIn} label={anchorLabel} />
          </Text>
        </Box>
      )}
      {!allowSignUp && (
        <Box align="center">
          <Text>
            Don't have an account?{" "}
            <Anchor
              href="#"
              onClick={onNavigate("/access")}
              label={anchorLabel}
            />
          </Text>
        </Box>
      )}
    </Box>
  );
  return size === "small" ? (
    <Box fill align="center" justify="center">
      {loginCard}
    </Box>
  ) : (
    <Box
      fill
      align="center"
      justify="center"
      style={{
        background:
          "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.3), rgba(255,255,255,0.1)), url(/lake.jpg) no-repeat center center",
        backgroundSize: "cover"
      }}
    >
      <Box
        round="small"
        pad="large"
        elevation="large"
        align="center"
        gap="small"
        background={{ color: "white", opacity: "strong" }}
      >
        {loginCard}
      </Box>
    </Box>
  );
};
