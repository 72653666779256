import React from "react";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { UpdateAssetsBody } from "./";

export const UpdateAssetsDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout
      title="Update Balance & Holdings"
      subTitle="Update changes in your existing manual institutions & properties"
      onClose={onNavigate("/")}
    >
      <UpdateAssetsBody />
    </AppLayout>
  );
};
