import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  Box,
  Button,
  Heading,
  ResponsiveContext,
  Text,
  TextInput
} from "grommet";
import { v4 as uuidv4 } from "uuid";
import { useAsyncFn } from "react-use";
import { debounce, remove } from "lodash";
import { useAppState, useFirebaseFunction, useNavigation } from "../../hooks";
import { Institution } from "../../types";
import {
  AccountCard,
  AddAccountButton,
  FeedbackContext,
  InstitutionLogo,
  LoadingButton
} from "../../components";
import { useLoggedUser } from "../../firebase";
import { getUserAssetsAndLiabilities } from "../../shared/accounts";
import {
  getDefaultInstitution,
  generatePlaceholderAccount,
  validateInstitution
} from "../../utils";

export const AddInstitutionBody = () => {
  const { addInstitution, refreshData } = useAppState();
  const { user } = useLoggedUser();
  const size = useContext(ResponsiveContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const { navigate, onNavigate } = useNavigation();
  const [institutionName, setInstitutionName] = useState("");
  const [internalInstitution, setInternalInstitution] = useState<Institution>(
    getDefaultInstitution
  );
  const fetchInstitutions = useFirebaseFunction({
    fnName: "fetchInstitutions"
  });
  const createManualInstitution = useFirebaseFunction({
    fnName: "createManualInstitution"
  });
  const [status, setStatus] = React.useState("initial");

  const [rawInstitutionSuggestions, fetchInstitutionSuggestions] = useAsyncFn(
    async (query) => {
      if (!query) return undefined;
      const {
        data: { institutions }
      } = await fetchInstitutions({ institutionQuery: query });
      return institutions;
    },
    []
  );

  const debouncedFetchInstitutionSuggestions = useMemo(
    () => debounce(fetchInstitutionSuggestions, 500),
    [fetchInstitutionSuggestions]
  );

  const onInstitutionNameChange = ({ target }: { target: any }) => {
    const { value } = target;
    setInstitutionName(value || "");
    setInternalInstitution({
      ...getDefaultInstitution(),
      accounts: internalInstitution.accounts
    });

    const trimmed = value.trim();

    debouncedFetchInstitutionSuggestions(trimmed);
  };

  const institutionSuggestions = useMemo(() => {
    // value will be undefined if async search hasn't happened yet
    if (institutionName && !rawInstitutionSuggestions?.value) {
      return [
        {
          value: -1,
          label: (
            <Box direction="row" align="center" pad="medium" gap="small">
              <Text color="text-weak">
                Searching for supported institutions...
              </Text>
            </Box>
          )
        }
      ];
    } else if (
      institutionName &&
      !rawInstitutionSuggestions?.loading &&
      rawInstitutionSuggestions?.value &&
      !rawInstitutionSuggestions?.value.length
    ) {
      return [
        {
          value: -1,
          label: (
            <Box direction="row" align="center" pad="medium" gap="small">
              <Text color="text-weak">Institution is not supported...</Text>
            </Box>
          )
        }
      ];
    }
    return (rawInstitutionSuggestions?.value || []).map(
      (institution: Institution) => {
        const { id, name, url } = institution;
        let maxWidth = "240px";
        if (size === "large") {
          maxWidth = "400px";
        } else if (size === "small") {
          maxWidth = "210px";
        }
        return {
          value: id,
          label: (
            <Box direction="row" align="center" pad="medium" gap="small">
              <InstitutionLogo institution={institution} />
              <Box>
                <Text
                  size={size === "large" ? "large" : undefined}
                  truncate
                  style={{ maxWidth }}
                  title={name}
                >
                  {name}
                </Text>
                <Text
                  size="small"
                  color="text-weak"
                  truncate
                  style={{ maxWidth }}
                  title={url}
                >
                  {url}
                </Text>
              </Box>
            </Box>
          ),
          institution
        };
      }
    );
  }, [rawInstitutionSuggestions, size, institutionName]);

  const onInstitutionSelect = useCallback(
    ({ suggestion: { institution: i } }) => {
      if (!i || i.value === -1) return;
      setInstitutionName(i.name);
      setInternalInstitution({
        ...internalInstitution,
        ...i,
        institutionId: i.id
      });
    },
    [setInternalInstitution, internalInstitution]
  );

  const onAccountChange = useCallback(
    (id, key, value) => {
      const newInstitution = { ...internalInstitution };

      newInstitution.accounts = newInstitution.accounts.map((a) =>
        a.id === id ? { ...a, [key]: value } : a
      );

      setInternalInstitution(newInstitution);
    },
    [internalInstitution]
  );

  const tempInstitution = useMemo(
    () => ({
      ...internalInstitution,
      name: institutionName
    }),
    [internalInstitution, institutionName]
  );

  const onAddAccount = useCallback(() => {
    const newInstitution = { ...internalInstitution };
    newInstitution.accounts = [...internalInstitution.accounts];
    newInstitution.accounts.push(
      generatePlaceholderAccount(newInstitution.accounts.length + 1)
    );

    setInternalInstitution(newInstitution);
  }, [setInternalInstitution, internalInstitution]);

  const onRemoveAccount = useCallback(
    (idToBeRemoved) => {
      const newInstitution = { ...internalInstitution };
      if (newInstitution.accounts.length - 1 > 0) {
        remove(newInstitution.accounts, ({ id }) => id === idToBeRemoved);

        setInternalInstitution(newInstitution);
      } else {
        sendFeedback({
          message: "You need at least one account per institution",
          type: "error"
        });
      }
    },
    [setInternalInstitution, internalInstitution, sendFeedback]
  );

  const onInstitutionSave = useCallback(async () => {
    const error = validateInstitution(internalInstitution);
    setStatus("saving");

    if (error) {
      sendFeedback({ message: error, type: "error" });
      setStatus("error");
      return;
    }

    const accounts = internalInstitution.accounts.map((a) => ({
      ...a,
      balance: +(a.balance ? a.balance : 0)
    }));

    const { assets, liabilities } = getUserAssetsAndLiabilities(accounts);
    const netWorth = assets - liabilities;
    let institutionToSave = { ...internalInstitution, accounts, netWorth };

    institutionToSave = { ...institutionToSave, id: uuidv4() };
    addInstitution({
      ...institutionToSave,
      processing: true
    });

    await createManualInstitution({
      userId: user?.uid,
      institution: institutionToSave
    });

    await refreshData({ skipUpdate: true });

    setStatus("saved");
    sendFeedback({
      message: `${institutionName} successfully created`,
      type: "success"
    });

    navigate("/add");
  }, [
    addInstitution,
    createManualInstitution,
    institutionName,
    internalInstitution,
    navigate,
    sendFeedback,
    refreshData,
    user
  ]);

  return (
    <>
      <Box
        flex
        overflow="auto"
        align="start"
        pad={{ vertical: "medium", horizontal: size }}
        responsive={false}
      >
        <Box flex={false}>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="medium"
            width="large"
            margin={{ top: "small" }}
          >
            <InstitutionLogo
              size={size !== "small" ? "large" : "72px"}
              institution={tempInstitution}
            />
            <TextInput
              placeholder="Institution name"
              size={size}
              suggestions={institutionSuggestions || []}
              value={institutionName}
              onChange={onInstitutionNameChange}
              // @ts-ignore // grommet types are missing this prop
              onSuggestionSelect={onInstitutionSelect}
              type="search"
            />
          </Box>
          <Box pad={{ horizontal: "small" }}>
            <Heading level={3}>Accounts</Heading>
            <Box
              direction="row"
              align={size !== "large" ? "center" : "start"}
              justify={size !== "large" ? "center" : "start"}
              wrap
              style={{ maxWidth: "1360px" }}
            >
              {(internalInstitution.accounts || []).map((account) => (
                <AccountCard
                  key={account.id}
                  account={account}
                  onChange={onAccountChange}
                  onRemove={onRemoveAccount}
                  automated={internalInstitution.type === "automated"}
                />
              ))}
              {internalInstitution.type === "manual" && (
                <Box
                  width="medium"
                  margin={{
                    top: size !== "small" ? "medium" : undefined,
                    left: size === "large" ? "small" : undefined
                  }}
                  align={size !== "large" ? "center" : "start"}
                >
                  <AddAccountButton onClick={onAddAccount} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        tag="footer"
        justify="end"
        direction="row"
        pad="medium"
        border="top"
        gap="small"
      >
        <Button secondary label="Cancel" onClick={onNavigate("/add")} />
        <LoadingButton
          type="submit"
          primary
          label="Save"
          onClick={onInstitutionSave}
          isLoading={status === "saving"}
        />
      </Box>
    </>
  );
};
