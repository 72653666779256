import React from "react";

import { AppLayoutMobile } from "../layouts";

import { UpdateAssetsBody } from "./";

export const UpdateAssetsMobile = () => (
  <AppLayoutMobile title="Update Assets">
    <UpdateAssetsBody />
  </AppLayoutMobile>
);
