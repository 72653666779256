import React from "react";
import { Box, Text } from "grommet";

import { InstitutionLogo } from "./";
import { Institution } from "../types";

export const InstitutionOption = ({
  institution,
  state = {}
}: {
  institution: Institution;
  state?: any;
}) => (
  <Box
    direction="row"
    align="center"
    pad="small"
    gap="small"
    background={!state.active && state.selected ? "tab-active" : undefined}
  >
    <InstitutionLogo institution={institution} />
    <Box>
      <Text truncate style={{ maxWidth: "280px" }} title={institution.name}>
        {institution.name}
      </Text>
    </Box>
  </Box>
);
