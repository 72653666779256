import React from "react";
import { Box, Text } from "grommet";
import { InstitutionType } from "../../types";

interface InstitutionTypeBadgeProps {
  type: InstitutionType;
  size?: string;
}

export const InstitutionTypeBadge = ({
  type,
  size = "xsmall"
}: InstitutionTypeBadgeProps) => (
  <Box
    background={type === "manual" ? "neutral-4" : "neutral-1"}
    flex={false}
    round="large"
    pad={{
      horizontal: "6px",
      vertical: "1px"
    }}
  >
    <Text size={size}>
      <b>{type?.toUpperCase()}</b>
    </Text>
  </Box>
);
