import React, { useCallback, useContext } from "react";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnEventMetadata,
  PlaidLinkStableEvent,
  usePlaidLink
} from "react-plaid-link";
import { v4 as uuidv4 } from "uuid";

import { useAppState } from "../context";
import { User } from "../types";
import { FeedbackContext } from "../components";

import { useFirebaseFunction } from "./";
import { useLoggedUser } from "../firebase";
import { removeLoadInitialDataCache } from "../cache/loadInitialData";

export interface useAddPlaidInstitutionProps {
  replaceInstitutionId?: string;
  onConnect?: (metadata: any) => void;
  onExit?: (success: boolean, institutionId?: string) => void;
  user?: User;
  token?: string; // link token
  reconnectToken?: string; // institution access token to fix authentication issues
}

export const useAddPlaidInstitution = ({
  replaceInstitutionId,
  onConnect,
  onExit,
  user,
  token,
  reconnectToken
}: useAddPlaidInstitutionProps) => {
  const { userRef } = useLoggedUser();
  const connectInstitution = useFirebaseFunction({
    fnName: "connectInstitution"
  });
  const fetchInstitutionMetadata = useFirebaseFunction({
    fnName: "fetchInstitutionMetadata"
  });
  const { addInstitution, removeInstitution, updateInstitution } =
    useAppState();
  const { sendFeedback } = useContext(FeedbackContext);
  const { refreshData } = useAppState();

  const onSuccess = useCallback(
    async (_, metadata) => {
      // adds institution optmistically with a fake id
      const institution = {
        ...metadata,
        institutionId: metadata.institution.institution_id,
        name: metadata.institution.name,
        type: "automated",
        id: uuidv4(),
        processing: true
      };

      if (replaceInstitutionId) {
        removeInstitution(replaceInstitutionId);
      }
      addInstitution(institution);

      const { data: institutionMetadata } = await fetchInstitutionMetadata({
        institutionId: metadata.institution.institution_id
      });
      updateInstitution({ ...institution, logo: institutionMetadata.logo });

      let response;
      try {
        response = await connectInstitution({
          userId: user?.uid,
          metadata: { ...metadata, id: institution.id },
          // used when a manual institution is converted to automated
          replaceInstitutionId
        });
      } catch (e) {
        if (e instanceof Error) {
          sendFeedback({
            message: e.message,
            type: "error"
          });
        }

        removeLoadInitialDataCache(user?.uid || "");

        updateInstitution({
          ...institution,
          status: "error",
          processing: false
        });
      }

      if (response) {
        const { data } = response;
        await refreshData();

        sendFeedback({
          message: "Institution added successfully.",
          type: "success"
        });

        if (onConnect) {
          onConnect(institution);
        }

        const newInstitution = data?.institution;
        updateInstitution(newInstitution);
      }
    },
    [
      addInstitution,
      connectInstitution,
      removeInstitution,
      fetchInstitutionMetadata,
      refreshData,
      replaceInstitutionId,
      onConnect,
      sendFeedback,
      user,
      updateInstitution
    ]
  );

  const logPlaidInteraction = useCallback(
    async (eventName, metadata) => {
      userRef.current?.collection("plaidInteractionLogs").add({
        eventName,
        metadata,
        token,
        reconnectToken: reconnectToken || ""
      });
    },
    [reconnectToken, token, userRef]
  );

  const onEvent = useCallback<PlaidLinkOnEvent>(
    async (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata
    ) => {
      if (onExit) {
        if (!token) {
          return;
        }
        if (eventName === PlaidLinkStableEvent.EXIT) {
          logPlaidInteraction(eventName, metadata);
          onExit(false);
        } else if (eventName === PlaidLinkStableEvent.HANDOFF) {
          if (metadata.error_code) {
            logPlaidInteraction(eventName, metadata);
          }
          onExit(!metadata.error_code, metadata.institution_id ?? "");
        }
      }
    },
    [logPlaidInteraction, onExit, token]
  );

  const { open, ready, error } = usePlaidLink({
    onSuccess: onSuccess,
    token: reconnectToken || token || "",
    onEvent
  });

  return React.useMemo(() => ({ open, ready, error }), [open, ready, error]);
};
