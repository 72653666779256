import React from "react";

import { AddRealEstateBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const AddRealEstateMobile = () => (
  <AppLayoutMobile title="Add Real Estate">
    <AddRealEstateBody />
  </AppLayoutMobile>
);
