import { Box } from "grommet";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { JoinPublicInvestmentGroupBody } from "./";

export const JoinPublicInvestmentGroupDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout onClose={onNavigate("/")}>
      <Box flex pad="medium">
        <JoinPublicInvestmentGroupBody />
      </Box>
    </AppLayout>
  );
};
