import numeral from "numeral";
import { Text, TextProps } from "grommet";

import {
  Account,
  AccountRow,
  ActivePeriod,
  HoldingRow,
  HoldingTypes,
  Institution,
  UserSnapshots
} from "../types";
import { orderBy } from "lodash";

const CURRENCY_FORMAT = "-$0.[00]a";
const PLAIN_FORMAT = "0.[00]a";
const PERCENTAGE_FORMAT = "-0,0.00%";
const ACTIVE_PERIOD_LABEL_MAP = {
  weekly: "week",
  yearly: "year",
  monthly: "month",
  daily: "today"
};
const FORMAT_TYPE_MAP = {
  percentage: PERCENTAGE_FORMAT,
  currency: CURRENCY_FORMAT,
  plain: PLAIN_FORMAT
};

export const getAccountName = (account: Account): string =>
  account?.alias || account?.name;

export const getActivePeriodLabel = (activePeriod: ActivePeriod): string =>
  ACTIVE_PERIOD_LABEL_MAP[activePeriod];

export interface PercentageValue {
  value: number;
  percentage: number;
}

export interface NumberTextProps {
  value: PercentageValue | number | string;
  type?: "percentage" | "currency" | "plain";
  color?: string;
}

export const getNumberTextColor = (value: number): string => {
  let textColor = "text-weak";
  if (value > 0) {
    textColor = "status-ok";
  } else if (value < 0) {
    textColor = "status-critical";
  }
  return textColor;
};

export const getInverseNumberTextColor = (value: number): string => {
  let textColor = "text-weak";
  if (value > 0) {
    textColor = "status-critical";
  } else if (value < 0) {
    textColor = "status-ok";
  }
  return textColor;
};

export const NumberText = ({
  value = 0,
  type = "currency",
  color = "text-weak",
  ...rest
}: NumberTextProps & TextProps) => {
  const currentValue = (
    value ? (value as PercentageValue).value || value : value
  ) as number;
  const currentPercentage = value
    ? (value as PercentageValue).percentage
    : undefined;

  return (
    <Text color={color} {...rest}>
      {numeral(
        type === "percentage" ? currentValue / 100 : currentValue
      ).format(FORMAT_TYPE_MAP[type])}
      {typeof currentPercentage !== "undefined" &&
        ` (${numeral(currentPercentage / 100).format(
          FORMAT_TYPE_MAP.percentage
        )})`}
    </Text>
  );
};

export const getInstitutionHoldingRows = (
  institutions: Institution[],
  snapshots: UserSnapshots | undefined
): HoldingRow[] =>
  orderBy(
    (institutions || []).reduce((holdingRows, institution) => {
      const { accounts = [] } = institution;
      accounts
        .filter(({ hidden, type: originalType, customType, id }) => {
          const type = customType || originalType;
          return (
            !hidden &&
            type === "investment" &&
            Object.keys(
              snapshots?.weekly?.data?.institutions?.[institution.id]
                ?.accounts?.[id]?.holdings || {}
            ).length
          );
        })
        .forEach((account) => {
          const holdings =
            snapshots?.weekly?.data?.institutions?.[institution.id]?.accounts?.[
              account.id
            ]?.holdings;
          Object.keys(holdings ?? {}).forEach((holdingKey) => {
            if (holdings?.[holdingKey]) {
              holdingRows.push({
                accountRow: { institution, account },
                holdingItem: holdings?.[holdingKey]
              });
            }
          });
        });
      return holdingRows;
    }, [] as HoldingRow[]),
    ["institution.name", "account.name", "holdingItem.currentValue"],
    ["asc", "asc", "desc"]
  );

export const getActiveInstitutionAccountRows = (
  institutions: Institution[],
  filterInvestmentAccounts?: boolean
): AccountRow[] =>
  orderBy(
    (institutions || []).reduce((accountRows, institution) => {
      const { accounts = [] } = institution;
      accounts
        .filter(({ hidden, type: originalType, customType }) => {
          const type = customType || originalType;
          let shouldBeUsed = true;
          if (filterInvestmentAccounts) {
            shouldBeUsed = type === "investment";
          }
          return !hidden && shouldBeUsed;
        })
        .forEach((account) => accountRows.push({ institution, account }));
      return accountRows;
    }, [] as AccountRow[]),
    ["institution.name", "account.balance"],
    ["asc", "desc"]
  );

export const HOLDING_TYPE_LABELS = {
  equity: "shares",
  derivative: "options",
  "mutual fund": "shares",
  "fixed income": "shares",
  etf: "shares"
};

export const getHoldingTypeLabel = (type: HoldingTypes) =>
  HOLDING_TYPE_LABELS[type] || type;
