import React, { useContext } from "react";
import { Box, Button, ResponsiveContext, Text } from "grommet";
import { LogoFull } from "../logo";
import { UserHeaderFooter } from "../userHeaderFooter";
import { useNavigation } from "../../hooks";

export const AdminHeader = () => {
  const { onNavigate } = useNavigation();
  const size = useContext(ResponsiveContext);
  return (
    <Box
      background="app-header"
      width={size === "large" ? "medium" : "280px"}
      elevation="medium"
      style={{ position: "relative" }}
    >
      <Box
        border="bottom"
        height="app-header"
        pad={{ left: "small" }}
        align="center"
        direction="row"
        justify="between"
      >
        <Button onClick={onNavigate("/")}>
          <LogoFull />
        </Button>
        <Box
          background="status-warning"
          width="48px"
          align="center"
          margin={{ right: "small" }}
          responsive={false}
          round="small"
        >
          <Text size="small" weight="bold">
            Admin
          </Text>
        </Box>
      </Box>
      <Box flex overflow="auto">
        <Button href="#">
          <Box pad="medium" responsive={false} border="bottom">
            <Text weight="bold">User Insights</Text>
          </Box>
        </Button>
        <Button onClick={onNavigate("/admin/user-search")}>
          <Box pad="medium" responsive={false} border="bottom">
            <Text weight="bold">User Search</Text>
          </Box>
        </Button>
        <Button onClick={onNavigate("/admin/plaid-debugger")}>
          <Box pad="medium" responsive={false} border="bottom">
            <Text weight="bold">Plaid Debugger</Text>
          </Box>
        </Button>
        <Button onClick={onNavigate("/admin/balance-update")}>
          <Box pad="medium" responsive={false} border="bottom">
            <Text weight="bold">Balance Update</Text>
          </Box>
        </Button>
        <Button onClick={onNavigate("/admin/plaid-tokens")}>
          <Box pad="medium" responsive={false} border="bottom">
            <Text weight="bold">Plaid Tokens</Text>
          </Box>
        </Button>
        <Button onClick={onNavigate("/admin/investment-deposits")}>
          <Box pad="medium" responsive={false} border="bottom">
            <Text weight="bold">Investment Deposits</Text>
          </Box>
        </Button>
      </Box>
      <UserHeaderFooter />
    </Box>
  );
};
