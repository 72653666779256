import React, { useContext } from "react";
import { Button, Menu, ResponsiveContext, Text } from "grommet";

import { useAppState, useNavigation } from "../hooks";
import { MenuItemLabel, SplitButton } from ".";
import { Add, Refresh } from "grommet-icons";
import { UserThemes } from "../types";
import { TRTThemeContext } from "../context";

export const ManageAssetsLiabilitiesButton = () => {
  const { theme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  const { onNavigate } = useNavigation();
  const { hasManualInstitutions, hasManualRealEstate } = useAppState();

  if (size === "small") {
    return hasManualInstitutions || hasManualRealEstate ? (
      <Menu
        icon={<Add />}
        dropProps={{
          align: { right: "right", top: "bottom" },
          background: { color: "drop", dark: theme === UserThemes.DARK }
        }}
        items={[
          {
            onClick: onNavigate("/add"),
            label: (
              <MenuItemLabel
                icon={<Add color="menu-icon" size="small" />}
                label="Add Assets"
              />
            )
          },
          {
            onClick: onNavigate("/update"),
            label: (
              <MenuItemLabel
                icon={<Refresh color="menu-icon" size="small" />}
                label="Update Balance"
              />
            )
          }
        ]}
      />
    ) : (
      <Button icon={<Add />} onClick={onNavigate("/add")} />
    );
  }

  return hasManualInstitutions ? (
    <SplitButton
      items={[
        {
          onClick: onNavigate("/add"),
          icon: <Add color="menu-icon" size="small" />,
          label: <Text>Add Assets & Liabilities</Text>
        },
        {
          onClick: onNavigate("/update"),
          icon: <Refresh color="menu-icon" size="small" />,
          label: <Text>Update Balance & Holdings</Text>
        }
      ]}
      label="Manage Assets & Liabilities"
    />
  ) : (
    <Button
      primary
      color="brand"
      label="Add Assets & Liabilities"
      onClick={onNavigate("/add")}
    />
  );
};
