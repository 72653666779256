import React, { useContext } from "react";
import { Box, Button, Heading, ResponsiveContext, Text } from "grommet";
import { Close } from "grommet-icons";

interface PageHeaderProps {
  title: string;
  subTitle?: string;
  onClose?: () => void;
}

export const PageHeader = ({ title, subTitle, onClose }: PageHeaderProps) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: "medium" }}
      border="bottom"
      height="app-header"
    >
      <Box gap="xsmall">
        <Heading
          level={3}
          margin="none"
          size={size !== "large" ? "small" : undefined}
        >
          {title}
        </Heading>
        <Text size={size !== "large" ? "small" : "medium"} color="text-xweak">
          {subTitle}
        </Text>
      </Box>
      {onClose && <Button icon={<Close />} onClick={onClose} />}
    </Box>
  );
};
