import { Paragraph, Text } from "grommet";
import React, { useCallback } from "react";

import { OnboardingHeading, OnboardingLayout, RetirementEstimator } from "../";
import { useAppState, useStepChangeContext } from "../../hooks";

export const Restimate = () => {
  const { user } = useAppState();
  const [, onStepChange] = useStepChangeContext();
  const onPreviousStep = useCallback(
    () => onStepChange("completeProfile", user!),
    [onStepChange, user]
  );
  const onNextStep = useCallback(
    () => onStepChange("choosePlan", user!),
    [onStepChange, user]
  );

  return (
    <OnboardingLayout
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      step="2 of 3 steps"
    >
      <OnboardingHeading
        heading="Restimate"
        description="Define your retirement goals so we can calculate how much you will need to save*."
      />
      <RetirementEstimator />
      <Paragraph textAlign="center">
        <Text size="small">
          *Restimate assumes $1k as your initial Net Worth and no recurring
          deposits. Calculations will be automatically updated as new
          institutions are added and deposits detected.
        </Text>
      </Paragraph>
    </OnboardingLayout>
  );
};
