import ls from "localstorage-ttl";

import { LoadInitialDataResponse } from "../hooks";

// TODO: figure out a better dynamic value for this
const maxAge = 3600000; // 1 hour

export const setLoadInitialDataCache = (
  userId: string,
  data: LoadInitialDataResponse
) => ls.set(`loadInitialData_${userId}`, data, maxAge);

export const getLoadInitialDataCache = (
  userId: string
): LoadInitialDataResponse =>
  ls.get(`loadInitialData_${userId}`) as LoadInitialDataResponse;

export const removeLoadInitialDataCache = (userId: string) =>
  localStorage.removeItem(`loadInitialData_${userId}`);
