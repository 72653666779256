import { createContext } from "react";
import { UserThemes } from "../types";

export interface TRTThemeContextValue {
  theme: UserThemes;
  setTheme: (theme: UserThemes) => void;
}

export const TRTThemeContext = createContext<TRTThemeContextValue>({
  theme: UserThemes.LIGHT,
  setTheme: () => {}
});
