import React, { useCallback, useContext, useState } from "react";
import { Avatar, Box, Button, Layer, ResponsiveContext, Text } from "grommet";
import AvatarEdit from "react-avatar-edit";
import { useFirebaseContext } from "../../firebase";
import { Group } from "grommet-icons";
import { LoadingButton } from "..";

const AvatarUploaderComponent = ({
  groupName,
  groupId,
  initialAvatarUrl,
  onSaveComplete
}: {
  groupId: string;
  groupName: string;
  initialAvatarUrl: string;
  onSaveComplete: (avatarUrl: string) => void;
}) => {
  const size = useContext(ResponsiveContext);
  const [avatarUrl, setAvatarUrl] = useState(initialAvatarUrl || "");
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showFallback, setShowFallback] = useState<boolean>(!initialAvatarUrl);
  const [preview, setPreview] = useState<any>();
  const { storage } = useFirebaseContext();
  const storageRef = storage.storageRef(groupId);
  const toggleLayer = useCallback(() => setShow(!show), [show, setShow]);

  const onSave = useCallback(async () => {
    setLoading(true);
    const avatarRef = storageRef.child("avatar.jpg");
    const snapshot = await avatarRef.putString(preview, "data_url");
    const downloadUrl = await snapshot.ref.getDownloadURL();

    setAvatarUrl(downloadUrl);
    setShowFallback(false);

    // waiting some time for the image to persist.
    await new Promise((resolve) =>
      setTimeout(() => {
        setShow(false);
        setLoading(false);
        onSaveComplete(downloadUrl);
        resolve(true);
      }, 1000)
    );
  }, [onSaveComplete, preview, storageRef]);

  const useFallback = useCallback(() => {
    if (groupName) {
      setShowFallback(true);
    }
  }, [groupName]);

  let circleSize = "140px";
  if (size === "medium") {
    circleSize = "96px";
  } else if (size === "small") {
    circleSize = "72px";
  }

  return (
    <>
      <Box round="full">
        {!showFallback ? (
          <Avatar
            size={circleSize}
            src={avatarUrl}
            onError={useFallback}
            onClick={toggleLayer}
          />
        ) : (
          <Box
            width={circleSize}
            height={circleSize}
            round="full"
            background="light-2"
            align="center"
            justify="center"
            flex={false}
            onClick={toggleLayer}
          >
            {!groupName && (
              <Group
                color="neutral-3"
                size={size === "small" ? "48px" : "52px"}
              />
            )}
            {groupName && (
              <Text color="neutral-3" size="large">
                {groupName?.charAt(0).toUpperCase()}
              </Text>
            )}
          </Box>
        )}
      </Box>
      {show && (
        <Layer onEsc={() => setShow(false)} onClickOutside={toggleLayer}>
          <Box pad="medium">
            <AvatarEdit width={390} height={295} onCrop={setPreview} />
            <Box pad="small" flex gap="small" direction="row" justify="center">
              <LoadingButton
                primary
                isLoading={loading}
                disabled={!preview}
                label="Save"
                onClick={onSave}
              />
              <Button label="Cancel" onClick={toggleLayer} />
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export const GroupAvatarUploader = React.memo(AvatarUploaderComponent);
