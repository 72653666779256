import { Box } from "grommet";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { AddInvestmentGroupBody } from "./";

export const AddInvestmentGroupDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout
      title="Create Investment Group"
      subTitle="Create a new Investment Group and add new members"
      onClose={onNavigate("/")}
    >
      <Box flex overflow="auto" pad={{ top: "medium" }}>
        <AddInvestmentGroupBody />
      </Box>
    </AppLayout>
  );
};
