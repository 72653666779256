import firebase from "firebase/app";

import { functions } from "../firebase/firebase";

export const toArray = (snapshot: firebase.firestore.QuerySnapshot) => {
  const arr: any[] = [];
  snapshot.forEach((item) => {
    arr.push(item.data());
  });

  return arr;
};

export const getFirebaseFunction = (fnName: string) => {
  if (process?.env?.REACT_APP_EMULATE_FUNCTIONS) {
    console.warn("[TRT] Using firebase emulator");
    functions.useEmulator("localhost", 5001);
  }
  return functions.httpsCallable(fnName);
};
