import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo
} from "react";
import { get } from "lodash";
import numeral from "numeral";
import { findDOMNode } from "react-dom";
import {
  LineChart,
  Line,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { Box, ResponsiveContext, Stack, Text, ThemeContext } from "grommet";
import { normalizeColor } from "grommet/utils";
import ResizeObserver from "resize-observer-polyfill";
import { calcInterval } from "./utils";
import {
  ActivePeriod,
  HistoricalDataForPeriod,
  HistoricalDataPoint
} from "../../types";

let resizeObserver: any;

interface ChartLabelPerformanceProps {
  value: number;
  percent: number;
  background: string;
}

export const ChartLabelPerformance = ({
  value,
  percent,
  background
}: ChartLabelPerformanceProps) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box direction="row" align="center" gap="4px">
      <Box
        round="large"
        background={background}
        width="legend-circle"
        height="legend-circle"
      />
      <Text size={size !== "large" ? "small" : undefined}>
        <b>{numeral(value).format("-$0.[00]a")}</b> (
        {numeral(percent).format("+0,0.00")}
        %)
      </Text>
    </Box>
  );
};

export const ChartTooltip = ({
  label,
  active
}: {
  label: string;
  active: boolean;
}) =>
  active ? (
    <Text color="text-weak" size="small">
      {label}
    </Text>
  ) : null;

interface ChartXAxisLabelProps {
  visibleTicksCount?: number;
  payload?: any;
}

export const ChartXAxisLabel = ({
  visibleTicksCount,
  payload,
  ...rest
}: ChartXAxisLabelProps) => {
  const theme = useContext(ThemeContext);
  const size = useContext(ResponsiveContext);
  return (
    <text
      {...rest}
      fill={normalizeColor("text-weak", theme)}
      // @ts-ignore
      fontSize={size !== "large" ? theme.text.small.size : undefined}
    >
      {get(payload, "value")}
    </text>
  );
};

interface TrtChartProps {
  data?: HistoricalDataForPeriod;
  activePeriod: ActivePeriod;
}

export const TrtChart = ({ data: dataProps, activePeriod }: TrtChartProps) => {
  const { data, goal, summary, label, number } = dataProps || { label: "" };
  const size = useContext(ResponsiveContext);
  const [activeDataIndex, setActiveDataIndex] = useState(null);
  const targetLineRef = useRef<Line>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showDrop, setShowDrop] = useState(false);
  const lineNode = findDOMNode(targetLineRef.current);

  const tickInterval = React.useMemo(
    () => calcInterval(activePeriod),
    [activePeriod]
  );

  const redrawDrop = useCallback(() => {
    setShowDrop(false);
    if (targetLineRef.current) {
      setTimeout(() => setShowDrop(true), 0);
    }
  }, [setShowDrop]);

  useEffect(() => {
    if (targetLineRef.current) {
      redrawDrop();

      if (containerRef?.current) {
        resizeObserver = new ResizeObserver(redrawDrop).observe(
          containerRef.current
        );
      }
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [targetLineRef, goal, redrawDrop]);

  const { top = 0, right = 0 } = lineNode
    ? (lineNode as Element).getBoundingClientRect()
    : {};

  const activeEntry =
    // @ts-ignore - TS thinks activeDataIndex can be null even though it's being checked
    activeDataIndex !== null ? data[activeDataIndex] : summary;

  const onActiveDot = useCallback(
    ({ index }) =>
      index !== activeDataIndex &&
      setTimeout(() => setActiveDataIndex(index), 0),
    [activeDataIndex, setActiveDataIndex]
  );

  const showDots = useMemo(() => {
    let count = 0;
    let showDots = false;

    if (data?.length) {
      for (let i = 0; i < data.length; i++) {
        if (!!data[i].netWorth) {
          count += 1;
        }

        // if there are at least two data points, there's no need to show dots
        if (count === 2) {
          break;
        }
      }
    }

    if (count === 1) {
      showDots = true;
    }

    return showDots;
  }, [data]);

  const onResetActiveDataIndex = useCallback(
    () => setActiveDataIndex(null),
    [setActiveDataIndex]
  );

  let chartWidth = 500;
  if (size === "large") {
    chartWidth = 800;
  } else if (size === "small") {
    chartWidth = 425;
  }
  const fontSize = size !== "large" ? "small" : undefined;

  return (
    <Box fill align="center" ref={containerRef}>
      <Box align="center">
        <Box
          direction="row"
          pad={{ top: "medium" }}
          gap="small"
          margin={{ bottom: "small" }}
        >
          <Box direction="row" align="center" gap="xsmall">
            <Box
              round="large"
              background="graph-2"
              width="legend-circle"
              height="legend-circle"
            />
            <Text size={fontSize}>Your Net Worth</Text>
          </Box>
          <Box direction="row" align="center" gap="xsmall">
            <Box
              round="large"
              background="graph-4"
              width="legend-circle"
              height="legend-circle"
            />
            <Text size={fontSize}>Your Investments</Text>
          </Box>
          <Box direction="row" align="center" gap="xsmall">
            <Box
              round="large"
              background="graph-1"
              width="legend-circle"
              height="legend-circle"
            />
            <Text size={fontSize}>Personal SPY</Text>
          </Box>
        </Box>
      </Box>
      <Box
        width={`${chartWidth}px`}
        pad={{ vertical: "small", horizontal: "medium" }}
        direction="row"
        justify="between"
        align="center"
      >
        {activeEntry && (
          <Box gap="xsmall">
            <Text size={size !== "large" ? "small" : undefined} weight="bold">
              {(activeEntry as HistoricalDataPoint).name || (
                <span>
                  {label.toUpperCase()} {number}
                </span>
              )}
            </Text>
            <Box direction="row" align="center" gap="xsmall">
              <ChartLabelPerformance
                value={get(activeEntry, "netWorth.value")}
                percent={get(activeEntry, "netWorth.changePercentage")}
                background="graph-2"
              />
              <ChartLabelPerformance
                value={get(activeEntry, "investments.value")}
                percent={get(activeEntry, "investments.changePercentage")}
                background="graph-4"
              />
              <ChartLabelPerformance
                value={get(activeEntry, "spy.value")}
                percent={get(activeEntry, "spy.changePercentage")}
                background="graph-1"
              />
            </Box>
          </Box>
        )}
      </Box>
      <Stack anchor="top-right" margin={{ top: "small" }}>
        <LineChart
          width={chartWidth}
          height={260}
          data={data}
          margin={{ top: 5, right: 15, bottom: 5, left: 5 }}
          onMouseLeave={onResetActiveDataIndex}
        >
          <XAxis
            dataKey="label"
            axisLine={false}
            tickLine={false}
            padding={{ left: 25, right: 25 }}
            tick={<ChartXAxisLabel />}
            tickMargin={10}
            interval={tickInterval}
            height={22}
          />
          <YAxis
            type="number"
            domain={["dataMin - 0.5", "dataMax + 10"]}
            hide
          />
          <Tooltip
            isAnimationActive={false}
            // @ts-ignore
            content={<ChartTooltip />}
            offset={4}
            // @ts-ignore
            position={{ y: 0 }}
          />
          <Line
            activeDot={onActiveDot}
            animationDuration={900}
            dot={showDots}
            dataKey="netWorth.cumulativePercentage"
            stroke="#006d90"
            strokeWidth={2}
          />
          <Line
            dataKey="investments.cumulativePercentage"
            // TODOD: use grommet normalizeColor
            stroke="#e67f83"
            strokeWidth={2}
            animationDuration={800}
            dot={showDots}
            activeDot={false}
          />
          <Line
            dataKey="spy.cumulativePercentage"
            stroke="#ffa600"
            strokeWidth={2}
            animationDuration={700}
            dot={showDots}
            activeDot={false}
          />
          <ReferenceLine
            y={0}
            stroke="rgb(111,120,126)"
            strokeDasharray="1, 5.25"
          />
        </LineChart>
        {size === "large" && showDrop && (
          <Box
            align="center"
            width="50px"
            justify="center"
            background="tab-active"
            round="small"
            style={{
              position: "fixed",
              top: top - 8,
              left: right + 4
            }}
          >
            <Text size="small" weight="bold">
              {numeral(goal).format("+$0.[00]a")}
            </Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
