import { useCallback, useContext, useState } from "react";
import {
  Box,
  Button,
  ResponsiveContext,
  TextInput,
  Text,
  TextArea,
  Heading
} from "grommet";
import { Link, Mail } from "grommet-icons";
import { useAsync, useAsyncFn } from "react-use";
import { set } from "lodash";
import { FeedbackContext, LoadingButton } from "../../components";
import { db } from "../../firebase";
import { useAppState, useNavigation } from "../../hooks";
import { InvestmentGroupType, User } from "../../types";
import { validateInvestmentGroup } from "../../components/investment-group/utils";
import { useParams } from "react-router";
import { useEffect } from "react";
import { GroupAvatarUploader } from "../../components/investment-group/avatarUploader";

type Params = {
  investmentGroupId: string;
};

export const UpdateInvestmentGroupBody = () => {
  const { user, updateUser } = useAppState();
  const { investmentGroupId } = useParams<Params>();
  const { navigate } = useNavigation();

  const investmentGroupData = useAsync(async () => {
    const groupRef = await db.getInvestmentGroup(investmentGroupId);
    return groupRef.data() as InvestmentGroupType;
  }, [user]);

  const [investmentGroup, setInvestmentGroup] = useState(
    {} as InvestmentGroupType
  );

  const { sendFeedback } = useContext(FeedbackContext);
  const size = useContext(ResponsiveContext);

  const onUpdateInvestmentGroup = useCallback(
    (field) =>
      ({
        target: { value }
      }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInvestmentGroup = { ...investmentGroup };
        set(newInvestmentGroup, field, value);
        setInvestmentGroup(newInvestmentGroup);
      },
    [investmentGroup]
  );

  const updateAvatarUrl = useCallback(
    (avatarUrl) => {
      setInvestmentGroup({ ...investmentGroup, avatarUrl });
    },
    [investmentGroup]
  );

  const [updatedInvestmentGroup, updateInvestimentGroup] =
    useAsyncFn(async () => {
      const error = validateInvestmentGroup(investmentGroup);
      if (error) {
        sendFeedback({
          message: error,
          type: "error"
        });
        return;
      }
      try {
        updateUser({
          ...(user as User),
          investmentGroups: (user?.investmentGroups || []).map((group) => {
            if (group.id === investmentGroup.id) {
              return {
                ...investmentGroup,
                loading: true
              };
            }

            return group;
          })
        });
        const group = await db.updateInvestmentGroup(investmentGroup);
        updateUser({
          ...(user as User),
          investmentGroupIds: [
            ...(user?.investmentGroupIds || []),
            investmentGroup.id
          ],
          investmentGroups: (user?.investmentGroups || []).map((group) => {
            if (group.id === investmentGroup.id) {
              return investmentGroup;
            }
            return group;
          })
        });
        sendFeedback({
          message: "Investment Group updated successfully",
          type: "success"
        });
        return group;
      } catch (e) {
        sendFeedback({
          message: "Error updating Investment Group",
          type: "error"
        });
      }
    }, [investmentGroup]);

  useEffect(() => {
    if (investmentGroupData.value) {
      setInvestmentGroup(investmentGroupData.value);
    }
  }, [investmentGroupData.value]);

  const linkIconSize = size === "small" ? "24px" : "36px";

  return (
    <>
      <Box
        flex
        overflow="auto"
        align="start"
        pad={{ vertical: "medium", horizontal: size }}
        responsive={false}
      >
        <Box flex={false} fill>
          <Box direction="row" gap="medium" responsive={false} width="large">
            <Box
              align="center"
              gap="small"
              width={size === "small" ? "120px" : "180px"}
            >
              <GroupAvatarUploader
                groupId={investmentGroup.id}
                groupName={investmentGroup.name}
                initialAvatarUrl={investmentGroup.avatarUrl || ""}
                onSaveComplete={updateAvatarUrl}
              />
              <Text size="small" color="text-xweak" textAlign="center">
                Click anywhere on the image to change your avatar
              </Text>
            </Box>
            <Box fill="horizontal" height="150px" gap="small">
              <TextInput
                placeholder="Group name*"
                value={investmentGroup.name}
                onChange={onUpdateInvestmentGroup("name")}
              />
              <TextArea
                placeholder="Group description"
                resize={false}
                fill
                value={investmentGroup.description}
                onChange={onUpdateInvestmentGroup("description")}
              />
            </Box>
          </Box>
          <Heading level={3} margin={{ left: "medium", vertical: "medium" }}>
            Links
          </Heading>
          <Box pad={{ left: "large", top: "small" }} width="large" gap="24px">
            <Box direction="row" align="center" gap="medium">
              <Link size={linkIconSize} />
              <TextInput
                placeholder="group website url"
                value={investmentGroup?.links?.website}
                onChange={onUpdateInvestmentGroup("links.website")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <Mail size={linkIconSize} />
              <TextInput
                placeholder="group email distribution"
                value={investmentGroup?.links?.email}
                onChange={onUpdateInvestmentGroup("links.email")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <img alt="slack logo" src="/slack.svg" width={linkIconSize} />
              <TextInput
                placeholder="group slack url"
                value={investmentGroup?.links?.slack}
                onChange={onUpdateInvestmentGroup("links.slack")}
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              <img alt="slack logo" src="/discord.svg" width={linkIconSize} />
              <TextInput
                placeholder="group discord url"
                value={investmentGroup?.links?.discord}
                onChange={onUpdateInvestmentGroup("links.discord")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        tag="footer"
        justify="end"
        direction="row"
        pad="medium"
        border="top"
        gap="small"
      >
        <Button secondary label="Cancel" onClick={() => navigate("/")} />
        <LoadingButton
          isLoading={updatedInvestmentGroup.loading}
          label="Update"
          primary
          onClick={updateInvestimentGroup}
        />
      </Box>
    </>
  );
};
