import React, {
  useCallback,
  useMemo,
  useContext,
  useState,
  useEffect
} from "react";
import { capitalize, chunk, orderBy } from "lodash";
import {
  Accordion,
  AccordionPanel,
  Anchor,
  Avatar,
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Spinner,
  Tab,
  Tabs,
  Text,
  ThemeContext
} from "grommet";
import { normalizeColor } from "grommet/utils";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { useParams } from "react-router";
import { CaretDownFill, CaretUpFill, Close, Link, Mail } from "grommet-icons";
import md5 from "md5";
import numeral from "numeral";
import { useAsync } from "react-use";
import { isPresent } from "ts-is-present";

import {
  InvestmentGroupLogo,
  ManageMembers,
  RecentActivityMessage
} from "../../components";
import { TRTThemeContext } from "../../context";
import {
  ActivitySnapshot,
  InvestmentGroupMember,
  InvestmentGroupRoles,
  MemberActivities,
  MembersMap,
  RestimatePeriod,
  User,
  UserThemes
} from "../../types";
import { useNavigation, useInvestmentGroup } from "../../hooks";
import { getActivePeriodLabel } from "../../utils";
import { db } from "../../firebase";

const UserLeaderboardLabel = (props: any) => {
  const size = useContext(ResponsiveContext);
  const { x, y, value, width, height } = props;
  const { user, activePeriod, membersMap } = value;
  const isPositive = user?.performance?.[activePeriod] >= 0;
  return (
    <g>
      <foreignObject
        x={isPositive ? x + width - 12 : x - Math.abs(width) - 188}
        y={y - height}
        width={200}
        height={100}
      >
        <Box
          direction={isPositive ? "row" : "row-reverse"}
          align="center"
          gap="small"
        >
          <Avatar
            elevation="small"
            size="36px"
            src={
              membersMap[user.id]?.avatarUrl ||
              `//s.gravatar.com/avatar/${md5(
                membersMap[user.id]?.email || ""
              )}?s=80`
            }
          />
          <Box>
            <Text
              color="text-weak"
              size={size === "small" ? "small" : undefined}
              truncate
              style={{ maxWidth: "144px" }}
            >
              <b>#{user?.position}</b> {membersMap[user.id]?.name}
            </Text>
            <Box
              direction="row"
              align="center"
              justify={isPositive ? "start" : "end"}
              margin={isPositive ? { left: "-6px", top: "-3px" } : undefined}
            >
              {isPositive ? (
                <CaretUpFill color="assets" />
              ) : (
                <CaretDownFill color="liabilities" />
              )}
              <Text weight="bold" color={isPositive ? "assets" : "liabilities"}>
                {numeral((user?.performance?.[activePeriod] || 0) / 100).format(
                  "-0.[00]%"
                )}
              </Text>
            </Box>
          </Box>
        </Box>
      </foreignObject>
    </g>
  );
};

type Params = {
  investmentGroupId: string;
};

export const InvestmentGroupDetailsBody = () => {
  const { navigate } = useNavigation();
  const { theme } = useContext(TRTThemeContext);
  const themeObject = useContext(ThemeContext);
  const tabs = useMemo(() => ["weekly", "monthly", "yearly"], []);
  const [activePeriod, setActivePeriod] = useState<RestimatePeriod>(
    tabs[0] as RestimatePeriod
  );
  const size = useContext(ResponsiveContext);
  const { investmentGroupId } = useParams<Params>();
  const investmentGroup = useInvestmentGroup({
    investmentGroupId
  });

  const [showManageMembers, setShowManageMembers] = useState(false);
  const members = useMemo(
    () =>
      (investmentGroup
        ? investmentGroup.value?.members || []
        : []) as InvestmentGroupMember[],
    [investmentGroup]
  );

  const allMembers = useMemo(
    () =>
      (investmentGroup
        ? investmentGroup.value?.allMembers || []
        : []) as InvestmentGroupMember[],
    [investmentGroup]
  );

  const membersMap = useAsync(async () => {
    if (!investmentGroup.value) {
      return {};
    }

    const allUserIds = allMembers
      .filter((member) => !member.pending && member.role !== "VIEWER")
      ?.map((member: InvestmentGroupMember) => member.id)
      .filter(isPresent);

    const chunkedUsers = chunk(allUserIds, 10);
    const allUserPromises = chunkedUsers.map((userId) =>
      db.getUsersById(userId)
    );
    const allRefs = await Promise.all(allUserPromises);
    const allUsersChunked: User[] = [];
    allRefs.forEach((userRef) => {
      userRef.docs.forEach((userRef) => {
        allUsersChunked.push({ ...(userRef.data() as User), uid: userRef.id });
      });
    });

    return allUsersChunked.reduce((agg, member) => {
      agg[member.uid] = member;
      return agg;
    }, {} as MembersMap);
  }, [investmentGroup]);

  const { administrators, players, viewers } = useMemo(
    () =>
      allMembers.reduce(
        (groupedMembers, member) => {
          if (!member.pending) {
            if (member.role === InvestmentGroupRoles.ADMIN) {
              groupedMembers.administrators.push(member);
            } else if (member.role === InvestmentGroupRoles.PLAYER) {
              groupedMembers.players.push(member);
            } else {
              groupedMembers.viewers.push(member);
            }
          }
          return groupedMembers;
        },
        {
          administrators: [],
          players: [],
          viewers: []
        } as {
          administrators: InvestmentGroupMember[];
          players: InvestmentGroupMember[];
          viewers: InvestmentGroupMember[];
        }
      ),
    [allMembers]
  );

  const { leaderBoardMembers } = useMemo(
    () =>
      members.reduce(
        (groupedMembers, member) => {
          if (!member.pending) {
            if (
              [
                InvestmentGroupRoles.ADMIN,
                InvestmentGroupRoles.PLAYER
              ].includes(member.role)
            ) {
              groupedMembers.leaderBoardMembers.push(member);
            }
          }
          return groupedMembers;
        },
        {
          leaderBoardMembers: []
        } as {
          leaderBoardMembers: InvestmentGroupMember[];
        }
      ),
    [members]
  );

  const leaderboardData = useMemo(
    () =>
      orderBy(leaderBoardMembers, `performance.${activePeriod}`, "desc")
        .slice(0, 3)
        .map((a: InvestmentGroupMember, index) => ({
          ...a,
          position: index + 1
        })),
    [activePeriod, leaderBoardMembers]
  );

  const activeMemberActivities = useMemo(
    () =>
      !Object.keys(membersMap?.value ?? {}).length
        ? ([] as MemberActivities[])
        : orderBy(
            members
              .filter(({ activity }) => activity?.[activePeriod]?.length)
              .reduce((memberActivities, member) => {
                const newMemberActivities = [...memberActivities];
                newMemberActivities.push({
                  member: membersMap?.value?.[member.id ?? ""]!,
                  activities: orderBy(
                    member.activity?.[activePeriod]!,
                    [({ date }) => new Date(date)],
                    ["desc"]
                  )
                });
                return newMemberActivities;
              }, [] as MemberActivities[]),
            "activities.length",
            "desc"
          ),
    [members, activePeriod, membersMap]
  );

  const onHideManageMembers = useCallback(
    () => setShowManageMembers(false),
    []
  );
  const onShowManageMembers = useCallback(() => setShowManageMembers(true), []);

  const totalPlayers = administrators.length + players.length;
  const totalViewers = viewers.length;
  const investmentGroupIconSize = size === "small" ? "52px" : "72px";

  useEffect(() => {
    if (!investmentGroup.value && !investmentGroup.loading) {
      navigate("/not-found");
    }
  }, [investmentGroup, navigate]);

  const onActiveTabChange = (i: number) =>
    setActivePeriod(tabs[i] as RestimatePeriod);

  const usePlaceholder =
    administrators.length === 1 && !players.length && !viewers.length;
  if (investmentGroup.loading || membersMap.loading) {
    return (
      <Box flex overflow="auto">
        <Box
          direction="row"
          justify="center"
          align="center"
          pad={{ top: "medium" }}
          gap="small"
        >
          <Spinner color="dark-1" />
          <Text color="text-weak">Loading Investment Group...</Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box flex pad="medium" overflow="auto">
      <Box flex={false}>
        <Box direction="row" align="start" justify="between">
          <Box direction="row" flex>
            <Box direction="row" align="center" gap="small" responsive={false}>
              <InvestmentGroupLogo
                investmentGroup={investmentGroup.value}
                size={investmentGroupIconSize}
              />
              <Box>
                <Heading level={2} margin="none">
                  {investmentGroup.value?.name}
                </Heading>
                <Text color="text-xweak">
                  {totalViewers
                    ? `${totalPlayers} ${
                        totalPlayers === 1 ? "player" : "players"
                      } • ${totalViewers} ${
                        totalViewers === 1 ? "viewer" : "viewers"
                      }`
                    : `${totalPlayers} ${
                        totalPlayers === 1 ? "player" : "players"
                      }`}
                </Text>
              </Box>
            </Box>
            <Box
              direction="row"
              align="start"
              flex
              justify={size === "small" ? "end" : undefined}
              margin={{ top: "xsmall", left: "small" }}
            >
              {investmentGroup.value?.links.website && (
                <Anchor
                  href={investmentGroup.value?.links.website}
                  target="_blank"
                  icon={<Link size="18px" />}
                />
              )}
              {investmentGroup.value?.links.email && (
                <Anchor
                  href={`mailto: ${investmentGroup.value?.links.email}`}
                  target="_blank"
                  icon={<Mail size="18px" />}
                />
              )}
              {investmentGroup.value?.links.slack && (
                <Anchor
                  href={investmentGroup.value?.links.slack}
                  target="_blank"
                  icon={<img alt="slack logo" src="/slack.svg" width="18px" />}
                />
              )}
              {investmentGroup.value?.links?.discord && (
                <Anchor
                  href={investmentGroup.value?.links.discord}
                  target="_blank"
                  icon={
                    <img alt="discord logo" src="/discord.svg" width="18px" />
                  }
                />
              )}
            </Box>
          </Box>
          {size !== "small" && (
            <Button icon={<Close />} onClick={() => navigate("/")} />
          )}
        </Box>
        {investmentGroup.value?.description && (
          <Paragraph
            color="text-xweak"
            size="large"
            margin={{ bottom: "none", top: "small" }}
          >
            {investmentGroup.value?.description}
          </Paragraph>
        )}
        {usePlaceholder ? (
          <Box pad={{ horizontal: "small" }} align="start">
            <Heading level={2}>Say hello to your new group</Heading>
            <Paragraph color="text-xweak" margin="none">
              Let's invite a few friends to join your group now
            </Paragraph>
            <Button
              primary
              label="Invite Members"
              margin={{ top: "medium" }}
              onClick={onShowManageMembers}
            />
          </Box>
        ) : (
          <Tabs
            flex
            alignControls="start"
            margin={{ top: "medium" }}
            onActive={onActiveTabChange}
          >
            {tabs.map((tab) => (
              <Tab key={`${tab}-tab`} title={capitalize(tab)}>
                <Heading level={size === "small" ? 2 : 3}>Leaderboard</Heading>
                <Box
                  height={leaderboardData.length > 3 ? "220px" : "180px"}
                  style={{ maxWidth: "800px" }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={leaderboardData}
                      layout="vertical"
                      margin={{ top: 12, right: 160, left: 160, bottom: 12 }}
                    >
                      <ReferenceLine
                        x={0}
                        stroke={
                          theme === UserThemes.DARK ? "#d3d3d3" : "#333333"
                        }
                        strokeDasharray="3 3"
                      />
                      <YAxis type="category" dataKey="user" hide />
                      <XAxis type="number" hide domain={[0, 5]} />
                      <Bar
                        dataKey={`performance.${activePeriod}`}
                        label={<UserLeaderboardLabel />}
                        minPointSize={20}
                        barSize={14}
                      >
                        {leaderboardData.map((user) => {
                          const { id } = user;
                          return (
                            <Cell
                              key={`cell-${id}`}
                              fill={
                                (user.performance?.[activePeriod] || 0) >= 0
                                  ? normalizeColor("assets", themeObject)
                                  : normalizeColor("liabilities", themeObject)
                              }
                              // @ts-ignore
                              value={{
                                user,
                                activePeriod,
                                membersMap: membersMap.value || {}
                              }}
                            />
                          );
                        })}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
                <Heading level={size === "small" ? 2 : 3}>
                  Recent Activity
                </Heading>
                {!activeMemberActivities.length && (
                  <Text color="text-weak">
                    Things are quiet in this group. There has been no activity
                    this {getActivePeriodLabel(activePeriod)}.
                  </Text>
                )}
                {activeMemberActivities.length === 1 &&
                  activeMemberActivities[0].activities.map(
                    (activity: ActivitySnapshot) => (
                      <RecentActivityMessage
                        key={`activity_message_${activity.id}`}
                        member={activeMemberActivities[0].member}
                        activity={activity}
                      />
                    )
                  )}
                {activeMemberActivities.length > 1 && (
                  <Accordion width="large" animate={false}>
                    {activeMemberActivities.map(({ member, activities }) => (
                      <AccordionPanel
                        key={`recent_activity_${member.email}`}
                        label={
                          <Box
                            pad={{ vertical: "small" }}
                            responsive={false}
                            direction="row"
                            align="center"
                            gap="small"
                          >
                            <Avatar
                              elevation="small"
                              size="36px"
                              src={
                                member.avatarUrl ||
                                `//s.gravatar.com/avatar/${md5(
                                  member?.email || ""
                                )}?s=80`
                              }
                            />
                            <Text size={size !== "small" ? "large" : undefined}>
                              {member.name} has made <b>{activities.length}</b>{" "}
                              trades
                            </Text>
                          </Box>
                        }
                      >
                        {activities.map((activity) => (
                          <RecentActivityMessage
                            key={`activity_message_${activity.id}`}
                            activity={activity}
                            member={member}
                            showMemberAvatar={false}
                          />
                        ))}
                      </AccordionPanel>
                    ))}
                  </Accordion>
                )}
              </Tab>
            ))}
          </Tabs>
        )}
      </Box>
      {showManageMembers && (
        <ManageMembers
          investmentGroup={investmentGroup.value}
          membersMap={membersMap.value || {}}
          onClose={onHideManageMembers}
        />
      )}
    </Box>
  );
};
