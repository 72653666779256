import { Box } from "grommet";

import { UpdateInvestmentGroupBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const UpdateInvestmentGroupMobile = () => (
  <AppLayoutMobile title="Update Investment Group">
    <Box
      flex
      overflow="auto"
      pad={{ horizontal: "medium", vertical: "small" }}
      responsive={false}
    >
      <UpdateInvestmentGroupBody />
    </Box>
  </AppLayoutMobile>
);
