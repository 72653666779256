import firebase from "firebase/app";

export interface Subscription {
  name: string;
  totalAutomatedInstitutionsAllowed: number;
}

export type InitialLoadStatus = "initial" | "loading" | "done" | "error";

export interface HistoricalDataPointValues {
  cumulativePercentage: number;
  changePercentage: number;
  changeValue: number;
  value: number;
  updatedAt: number;
}

export interface HistoricalSummaryPoint {
  from: number;
  to: number;
  changePercentage: number;
  value: number;
  changeValue: number;
}
export interface HistoricalSummary {
  cash: HistoricalSummaryPoint;
  creditCard: HistoricalSummaryPoint;
  investments: HistoricalSummaryPoint;
  loan: HistoricalSummaryPoint;
  netWorth: HistoricalSummaryPoint;
  spy: HistoricalSummaryPoint;
}

export interface HistoricalDataPoint {
  investments: HistoricalDataPointValues;
  netWorth: HistoricalDataPointValues;
  spy: HistoricalDataPointValues;
  cash: HistoricalDataPointValues;
  name?: string;
}
export interface HistoricalDataForPeriod {
  data: HistoricalDataPoint[];
  label: string;
  number: number;
  goal: number;
  summary?: HistoricalSummary;
}

export interface HistoricalData {
  weekly: HistoricalDataForPeriod;
  monthly: HistoricalDataForPeriod;
  yearly: HistoricalDataForPeriod;
}

export type HistoricalDataPeriod = keyof HistoricalData;
export interface AppState {
  user?: User;
  signOut: () => void;
  status: InitialLoadStatus;
  institutions: Institution[];
  projections: Projection[];
  realEstate: RealEstate[];
  spyPerformance?: PerformanceDetails;
  token?: string;
  subscription: Subscription;
  snapshots?: UserSnapshots;
  historicalData: HistoricalData;
  recentlyAddedInstitutionId?: string;
  restimate?: RestimateDataWithRates;
}

export type AccountSnapshotWithInstitution = {
  institution: Institution;
  account: Account;
  snapshot: AccountSnapshot;
};

type refreshDataArgs = { skipUpdate?: boolean; skipPlaidUpdate?: boolean };
export type AppContextState = AppState & {
  addInstitution: (metadata: any) => void;
  updateInstitution: (institution: Institution) => void;
  removeInstitution: (id: string) => void;
  updateInstitutions: (
    institutions: Institution[],
    data: Partial<Institution>
  ) => void;
  updateRealEstate: (realEstate: RealEstate) => void;
  addRealEstate: (realEstate: RealEstate) => void;
  removeRealEstate: (id: string) => void;
  updateSnapshots: (snapshots: UserSnapshots) => void;
  resetState: () => void;
  updateUser: (user: User) => void;
  reloadUser: () => void;
  refreshData: (args?: refreshDataArgs) => void;
  hasInstitutionsProcessing: boolean;
  hasManualInstitutions: boolean;
  hasManualRealEstate: boolean;
  manualInstitutions: Institution[];
  manualRealEstate: RealEstate[];
  hasSomeManualInvestmentAccount: boolean;
  getAvailableLoans: (existingLoans: string[]) => AccountRow[];
  getAccountRowByAccountId: (id: string) => AccountRow | undefined | null;
  updateBalance: ({
    userId,
    insights
  }: {
    userId: string;
    insights?: Insights;
  }) => void;
  restimate?: RestimateDataWithRates;
  setRestimate: (restimate: RestimateDataWithRates) => void;
};

export enum UserThemes {
  LIGHT = "light",
  DARK = "dark"
}

export interface User {
  assets?: number;
  avatarUrl?: string;
  cash?: number;
  creditCards?: number;
  email: string;
  investments?: number;
  realEstate?: number;
  liabilities?: number;
  loans?: number;
  monthGoal?: number;
  monthRate?: number;
  name: string;
  netWorth?: number;
  performance?: UserPerformance;
  retireIn?: number;
  retireWith?: number;
  saved?: number;
  uid: string;
  updatedAt: firebase.firestore.Timestamp;
  weekGoal?: number;
  weekRate?: number;
  yearGoal?: number;
  yearRate?: number;
  validated?: boolean;
  currentStep?: string;
  receiveRecommendations?: boolean;
  theme?: UserThemes.LIGHT | UserThemes.DARK;
  plan: { totalAutomatedInstitutionsAllowed: number; name: string };
  automatedInstitutionsInUse: number;
  hasAddedAutomatedAccount: boolean;
  admin?: boolean;
  isPro?: boolean;
  investmentGroupIds?: string[];
  investmentGroups: InvestmentGroupType[];
  investmentGroupsMembersMap?: MembersMap;
}

export interface AssetPeriodPerformance {
  count: number;
  currentId: number;
  tempAverage: number;
  average: number;
  best: number;
  tempBest: number;
  worst: number;
  tempWorst: number;
  recentGains: number[];
}

export type AssetClassPerformance = {
  [K in RestimatePeriod]: AssetPeriodPerformance;
};

export type AssetClassPeriod = keyof AssetClassPerformance;

export interface UserPerformance {
  netWorth: AssetClassPerformance;
}

export type UserAssetsAndLiabilitiesTypes =
  | "assets"
  | "liabilities"
  | "cash"
  | "investments"
  | "loans"
  | "creditCards";

export const ACCOUNT_TYPE_DISPLAY_NAME = {
  savings: "Savings",
  checking: "Checking",
  cd: "Certificate of Deposit",
  investment: "Investment",
  "credit-card": "Credit Card",
  "money-market": "Money Market",
  loan: "Loan"
};

export interface Account {
  id: string;
  mask?: string;
  name: string;
  balance: number;
  hidden?: boolean;
  alias?: string;
  type: AccountType;
  customType?: AccountType;
}

export type AccountType = keyof typeof ACCOUNT_TYPE_DISPLAY_NAME;

export type InstitutionType = "manual" | "automated";
export type RealEstateType = "manual";

export type InstitutionStatus = "ok" | "login_error" | "error";
export interface Institution {
  accounts: Account[];
  visibleAccounts: Account[];
  accessToken: string;
  reconnectToken?: string;
  id: string;
  institutionId: string;
  name: string;
  logo?: string;
  type: InstitutionType;
  netWorth: number;
  processing: boolean;
  url?: string;
  default?: boolean;
  status: InstitutionStatus;
  createdAt?: firebase.firestore.Timestamp;
}

export interface RealEstate {
  loans: string[];
  id: string;
  address: string;
  value: number;
  netWorth: number;
  type: RealEstateType;
  alias: string;
  processing: boolean;
  apartmentNumber?: number;
}

export type PerformanceDetails = {
  percent: {
    weekIncrease: number;
    monthIncrease: number;
    yearIncrease: number;
  };
  value: {
    weekIncrease: number;
    monthIncrease: number;
    yearIncrease: number;
  };
};

export interface BalanceChange {
  to: number;
  from: number;
  change: {
    percentage: number;
    value: number;
  };
}

export enum HoldingTypeLabel {
  equity = "shares",
  derivative = "options",
  "mutual fund" = "shares",
  "fixed income" = "shares",
  etf = "shares"
}

export type HoldingTypes =
  | "equity"
  | "derivative"
  | "etf"
  | "fixed income"
  | "mutual fund";

export interface OptionDetail {
  expirationDate: string;
  strikePrice: number;
  optionType: string;
}

export interface HoldingSnapshot {
  currentPercentage?: number;
  balance?: BalanceChange;
  costBasis: number;
  currentValue: number;
  optionDetail: OptionDetail | null;
  quantity: number;
  tickerName?: string;
  tickerSymbol: string;
  type: HoldingTypes;
  id: string;
}

export interface HoldingsSnapshot {
  [tickerSymbol: string]: HoldingSnapshot;
}

export type DepositsAndFeesType = "fee" | "deposit";
export enum ActivityAction {
  BUY = "buy",
  SELL = "sell"
}

export enum ActivityPosition {
  OPEN = "open",
  CLOSE = "close"
}

export interface DepositsAndFeesSnapshot {
  amount: number | string;
  detail: string;
  dividend: boolean;
  date: string;
  type: DepositsAndFeesType;
  tickerSymbol?: string;
  id: string;
  sequence?: number;
}

export interface ActivitySnapshot {
  id: string;
  type: HoldingTypes;
  totalPrice: number | string;
  date: string;
  quantity: number | string;
  optionDetail: OptionDetail | null;
  price: number | string;
  tickerSymbol: string;
  detail: string;
  action: ActivityAction;
  position?: ActivityPosition;
  sequence?: number;
}

export interface AccountSnapshot {
  balance: BalanceChange;
  holdings: HoldingsSnapshot;
  depositsAndFees: DepositsAndFeesSnapshot[];
  activity: ActivitySnapshot[];
}

export interface UserSnapshot {
  type: ActivePeriod;
  id: string;
  updatedAt: number;
  data?: {
    institutions: {
      [institutionId: string]: {
        accounts: {
          [accountId: string]: AccountSnapshot;
        };
      };
    };
    realEstate: {
      [realEstateId: string]: {
        netWorth: BalanceChange;
        value: BalanceChange;
      };
    };
    total: {
      balance: BalanceChange;
      cashBalance: BalanceChange;
      creditCardBalance: BalanceChange;
      investmentBalance: BalanceChange;
      automatedInvestmentBalance: BalanceChange;
      loanBalance: BalanceChange;
      realEstateBalance: BalanceChange;
    };
  };
}

export type UserSnapshots = {
  [key in ActivePeriod]: UserSnapshot;
};

export type ActivePeriod = "weekly" | "monthly" | "yearly" | "daily";

export type RestimatePeriod = "weekly" | "monthly" | "yearly";

export interface RestimateDataForPeriod {
  interval: number;
  start: number;
  end: number;
  goal: number;
  actual: number;
}

export interface RestimateData {
  weekly: RestimateDataForPeriod[];
  monthly: RestimateDataForPeriod[];
  yearly: RestimateDataForPeriod[];
}

export interface RestimateDataWithRates {
  projections: RestimateData;
  weekRate: number;
  monthRate: number;
  yearRate: number;
}

export type State = {
  from?: { pathname: string };
  username?: string;
  googleSignUp?: boolean;
  allowSignUp?: boolean;
};

export interface Projection {
  goal: number;
  start: number;
  end: number;
  interval: number;
}

export interface Customer {
  stripeId: string;
}

export interface AccountRow {
  institution: Institution;
  account: Account;
  label?: string;
  id?: string;
}

export interface AccountInsight {
  balance: number | string;
  type: AccountType;
  hidden: boolean;
  holdings: HoldingSnapshot[] | undefined;
  depositsAndFees: DepositsAndFeesSnapshot[] | undefined;
  activity: ActivitySnapshot[] | undefined;
}

export interface AccountInsights {
  [accountId: string]: AccountInsight;
}

export interface InstitutionInsight {
  [institutionId: string]: {
    createdAt?: firebase.firestore.Timestamp;
    accounts: AccountInsights;
  };
}

export interface RealEstateInsight {
  [realEstateId: string]: {
    value: string | number;
    netWorth: string | number;
  };
}

export interface Insights {
  institutions: InstitutionInsight;
  realEstate: RealEstateInsight;
}

export interface InsightsProps {
  insights: Insights;
  onUpdate: (insights: Insights) => void;
}

export interface DepositsAndFeesRow {
  depositsAndFeesItem: DepositsAndFeesSnapshot;
  accountRow: AccountRow;
  sequence: number;
}

export interface ActivityRow {
  activityItem: ActivitySnapshot;
  accountRow: AccountRow;
  sequence: number;
}

export interface HoldingRow {
  holdingItem: HoldingSnapshot;
  accountRow: AccountRow;
}

export interface QuoteMap {
  [tickerSymbol: string]: number | undefined | null;
}

export type possibleGoalValues = "weekGoal" | "monthGoal" | "yearGoal";

export interface GoalGap {
  [goal: string]: possibleGoalValues;
}

export interface ActivePeriodProps {
  activePeriod: ActivePeriod;
}

export enum InvestmentGroupRoles {
  ADMIN = "ADMIN",
  PLAYER = "PLAYER",
  VIEWER = "VIEWER"
}

export type InvestmentGroupMember = {
  id?: string;
  email?: string;
  role: InvestmentGroupRoles;
  pending: boolean;
  temp?: boolean;
  joinedAt?: { seconds: number };
  invitedAt: { seconds: number };
  performance?: {
    [K in RestimatePeriod]: number;
  };
  activity?: {
    [K in RestimatePeriod]: ActivitySnapshot[];
  };
};

export interface MemberActivities {
  member: User;
  activities: ActivitySnapshot[];
}

export type InvestmentGroupType = {
  id: string;
  name: string;
  avatarUrl?: string;
  description: string;
  loading?: boolean;
  shareSettings?: {
    public: boolean;
    token: string;
  };
  links: {
    slack: string;
    website: string;
    email: string;
    discord: string;
  };
  members: InvestmentGroupMember[];
  allMembers?: InvestmentGroupMember[];
};

export type MembersMap = { [key: string]: User };
