import { useCallback, useContext, useMemo, useState } from "react";
import { Box, Menu, Text } from "grommet";
import {
  ClearOption,
  FormUp,
  Logout,
  SettingsOption,
  Terminal
} from "grommet-icons";
import { UserAvatar } from "./userAvatar";
import { TRTThemeContext, useAppState } from "../context";
import { useNavigation } from "../hooks";
import { UserThemes } from "../types";
import { ProBadge } from "./proBadge";
import { ConfirmResetRestimate } from "./confirmResetRestimate";

export const UserHeaderFooter = () => {
  const { user, signOut } = useAppState();
  const { navigate, onNavigate } = useNavigation();
  const { theme } = useContext(TRTThemeContext);
  const onSignOut = useCallback(() => {
    signOut();
    navigate("/login");
  }, [navigate, signOut]);
  const [showConfirmResetRestimate, setShowConfirmResetRestimate] =
    useState(false);

  const onResetRestimate = useCallback(() => {
    setShowConfirmResetRestimate(true);
  }, [setShowConfirmResetRestimate]);

  const onCloseResetRestimateModal = useCallback(() => {
    setShowConfirmResetRestimate(false);
  }, [setShowConfirmResetRestimate]);

  // @ts-ignore
  // @Alan Souza to send a pr to grommet to fix this
  const dropBackground = { color: "drop", dark: theme === UserThemes.DARK };
  const menuItems = useMemo(() => {
    let items = [
      {
        onClick: onNavigate("/settings"),
        label: (
          <Box direction="row" align="center" gap="xsmall" pad="xsmall">
            <SettingsOption color="menu-icon" size="small" />
            <Text>Settings</Text>
          </Box>
        )
      },
      {
        onClick: onResetRestimate,
        label: (
          <Box direction="row" align="center" gap="xsmall" pad="xsmall">
            <ClearOption color="menu-icon" size="small" />
            <Text>Reset Data</Text>
          </Box>
        )
      },
      {
        onClick: onSignOut,
        label: (
          <Box direction="row" align="center" gap="xsmall" pad="xsmall">
            <Logout color="menu-icon" size="small" />
            <Text>Sign out</Text>
          </Box>
        )
      }
    ];

    if (user?.admin) {
      items = [
        {
          onClick: onNavigate("/admin"),
          label: (
            <Box direction="row" align="center" gap="xsmall" pad="xsmall">
              <Terminal color="menu-icon" size="small" />
              <Text>Admin Portal</Text>
            </Box>
          )
        },
        ...items
      ];
    }

    return items;
  }, [onNavigate, onResetRestimate, onSignOut, user]);
  return (
    <Box direction="row" align="center" justify="between">
      <Menu
        dropProps={{ elevation: "medium" }}
        dropAlign={{ bottom: "bottom" }}
        dropBackground={dropBackground}
        items={menuItems}
      >
        <Box
          margin={{ vertical: "small" }}
          direction="row"
          align="center"
          gap="small"
        >
          <UserAvatar />
          <FormUp />
        </Box>
      </Menu>
      {user?.isPro && <ProBadge />}
      {showConfirmResetRestimate && (
        <ConfirmResetRestimate onClose={onCloseResetRestimateModal} />
      )}
    </Box>
  );
};
