import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { useAppState, useFirebaseFunction, useNavigation } from "../../hooks";
import { RealEstate } from "../../types";
import { FeedbackContext, RealEstateForm } from "../../components";
import { useLoggedUser } from "../../firebase";
import { setQuery, validateRealEstate } from "../../utils";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { Box, Spinner, Text } from "grommet";

type RealEstateParams = {
  realEstateId: string;
};

export const UpdateRealEstateBody = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const {
    refreshData,
    updateRealEstate,
    realEstate,
    status: appStatus
  } = useAppState();
  const { user } = useLoggedUser();
  const { sendFeedback } = useContext(FeedbackContext);
  const { navigateBack, navigate } = useNavigation();
  const { realEstateId } = useParams<RealEstateParams>();

  const existingRealEstate = useMemo(
    () => realEstate.find(({ id }) => id === realEstateId),
    [realEstateId, realEstate]
  );

  const updateManualRealEstate = useFirebaseFunction({
    fnName: "updateManualRealEstate"
  });
  const [status, setStatus] = useState("initial");

  useEffect(() => {
    if (appStatus === "done" && !existingRealEstate) {
      sendFeedback({ message: "Real Estate not found", type: "error" });
      navigate("/");
    }
  }, [appStatus, existingRealEstate, sendFeedback, navigate]);

  const onRealEstateSave = useCallback(
    async (realEstate: RealEstate) => {
      const error = validateRealEstate(realEstate);
      setStatus("saving");

      if (error) {
        sendFeedback({ message: error, type: "error" });
        setStatus("error");
        return;
      }

      updateRealEstate({
        ...realEstate,
        processing: true
      });

      setQuery({
        history,
        pathname: location.pathname,
        params: {
          ...Object.fromEntries(params),
          activePanel: 3,
          activeRealEstate: realEstate.id
        },
        replace: true
      });

      await updateManualRealEstate({
        userId: user?.uid,
        realEstate: realEstate
      });

      await refreshData({ skipUpdate: true });

      setStatus("saved");
      sendFeedback({
        message: "Real estate successfully updated",
        type: "success"
      });

      navigateBack();
    },
    [
      history,
      params,
      location,
      updateManualRealEstate,
      sendFeedback,
      user,
      refreshData,
      updateRealEstate,
      navigateBack
    ]
  );

  if (appStatus !== "done") {
    return (
      <Box
        pad={{ vertical: "medium" }}
        align="center"
        justify="center"
        fill
        gap="small"
      >
        <Spinner color="dark-1" />
        <Text>Loading ...</Text>
      </Box>
    );
  }
  return (
    <RealEstateForm
      realEstate={existingRealEstate}
      onSubmit={onRealEstateSave}
      status={status}
    />
  );
};
