import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Box, Button, CheckBox, Select, Text, TextInput } from "grommet";
import { usePlacesWidget } from "react-google-autocomplete";

import { AccountRow, RealEstate, UserThemes } from "../../types";
import { TRTThemeContext, useAppState } from "../../context";
import { getDefaultRealEstate, NumberText } from "../../utils";
import { useNavigation } from "../../hooks";

import { FormLabel, CurrencyInput, LoadingButton, InstitutionLogo } from "../";

export interface RealEstateFormProps {
  realEstate?: RealEstate;
  onSubmit: (newRealEstate: RealEstate) => void;
  status: string;
}

export const RealEstateForm = ({
  realEstate,
  onSubmit,
  status
}: RealEstateFormProps) => {
  const { theme } = useContext(TRTThemeContext);
  const [tempRealEstate, setTempRealEstate] = useState<RealEstate>(
    realEstate || getDefaultRealEstate()
  );
  const { getAvailableLoans, getAccountRowByAccountId } = useAppState();
  const { onNavigate } = useNavigation();
  const [address, setAddress] = useState<string>(tempRealEstate.address);
  useEffect(() => {
    if (tempRealEstate.address !== address) {
      setTempRealEstate({ ...tempRealEstate, address });
    }
  }, [address, tempRealEstate]);
  useEffect(() => {
    setTempRealEstate(realEstate ? realEstate : getDefaultRealEstate());
  }, [realEstate, address]);
  const { ref: googleAutocompleteRef } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: ({ formatted_address }) => setAddress(formatted_address),
    options: { types: ["address"] }
  });
  const onRealEstateChange = useCallback(
    (field, type?) =>
      ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
        setTempRealEstate({
          ...tempRealEstate,
          [field]: type === "number" ? +value : value
        }),
    [tempRealEstate]
  );
  const onChangeLoans = useCallback(
    ({ value: loans }) =>
      setTempRealEstate({
        ...tempRealEstate,
        loans
      }),
    [tempRealEstate]
  );
  const currentLoansTotal = useMemo(
    () =>
      tempRealEstate.loans.reduce((currentTotal, loanId) => {
        const loan = getAccountRowByAccountId(loanId);
        if (loan) {
          return currentTotal + loan.account.balance;
        }
        return currentTotal;
      }, 0),
    [tempRealEstate.loans, getAccountRowByAccountId]
  );
  const renderLoanOption = useCallback(
    (option: AccountRow) => (
      <Box
        direction="row"
        align="center"
        justify="between"
        pad="small"
        responsive={false}
      >
        <Box direction="row" align="center" gap="small">
          <CheckBox checked={tempRealEstate.loans.includes(option.id ?? "")} />
          <Box direction="row" align="center" gap="xsmall">
            <InstitutionLogo institution={option.institution} size="24px" />
            <Text>{option.label}</Text>
          </Box>
        </Box>
        <NumberText
          value={option.account.balance * -1}
          color={theme === UserThemes.DARK ? "text" : "liabilities"}
          weight="bold"
        />
      </Box>
    ),
    [tempRealEstate.loans, theme]
  );
  const availableLoans = useMemo(
    () => getAvailableLoans(tempRealEstate.loans),
    [getAvailableLoans, tempRealEstate]
  );
  const loansValueLabel = tempRealEstate.loans.length ? (
    <Box
      pad="small"
      direction="row"
      align="center"
      gap="xsmall"
      responsive={false}
    >
      <NumberText
        value={currentLoansTotal * -1}
        color="liabilities"
        weight="bold"
      />
      <Text color="text-weak">in loans</Text>
    </Box>
  ) : undefined;
  return (
    <>
      <Box
        flex
        overflow="auto"
        pad="medium"
        responsive={false}
        width="large"
        gap="medium"
      >
        <Box direction="row" align="center" gap="medium">
          <Box basis="1/2" gap="xsmall">
            <Box gap="xsmall">
              <FormLabel htmlFor="real-estate-address" label="Address*" />
              <TextInput
                id="real-estate-address"
                placeholder="(e.g 3923 Romines Mill Road, Dallas, TX)"
                ref={googleAutocompleteRef}
                type="search"
                defaultValue={tempRealEstate.address}
                onChange={({ target: { value } }) => {
                  if (!value) {
                    setAddress("");
                  }
                }}
              />
            </Box>
          </Box>
          <Box basis="1/2" gap="xsmall">
            <FormLabel htmlFor="real-estate-alias" label="Alias" />
            <TextInput
              id="real-estate-alias"
              placeholder="(e.g primary residence)"
              value={tempRealEstate.alias}
              onChange={onRealEstateChange("alias")}
            />
          </Box>
        </Box>
        <Box direction="row" align="center" gap="medium">
          <Box basis="1/2" gap="xsmall">
            <FormLabel
              htmlFor="real-estate-apt-number"
              label="Apartment Number"
            />
            <TextInput
              type="number"
              id="real-estate-apt-number"
              placeholder="(e.g #4064)"
              value={tempRealEstate.apartmentNumber ?? ""}
              onChange={onRealEstateChange("apartmentNumber", "number")}
            />
          </Box>
          <Box basis="1/2" gap="xsmall">
            <FormLabel htmlFor="real-estate-value" label="Estimated Value*" />
            <CurrencyInput
              id="real-estate-value"
              placeholder="(e.g $1,059.44)"
              value={tempRealEstate.value}
              onChange={onRealEstateChange("value", "number")}
            />
          </Box>
        </Box>
        <Box gap="xsmall">
          <FormLabel htmlFor="real-estate-loan" label="Loans" />
          <Select
            options={availableLoans}
            id="real-estate-loan"
            placeholder="Link loans to your real estate"
            emptySearchMessage="No available loan to link"
            value={tempRealEstate.loans}
            valueLabel={loansValueLabel}
            onChange={onChangeLoans}
            valueKey={{ key: "id", reduce: true }}
            multiple
            closeOnChange={false}
          >
            {renderLoanOption}
          </Select>
        </Box>
      </Box>
      <Box
        tag="footer"
        justify="between"
        direction="row"
        pad="medium"
        border="top"
        gap="small"
        align="center"
      >
        <Text color="text-xweak" textAlign="center">
          <i>
            <b>*</b> denotes required fields
          </i>
        </Text>
        <Box gap="small" direction="row" align="center">
          <Button secondary label="Cancel" onClick={onNavigate("/add")} />
          <LoadingButton
            type="submit"
            primary
            label="Save"
            onClick={(e) => {
              e.preventDefault();
              onSubmit({
                ...tempRealEstate,
                netWorth: tempRealEstate.value - currentLoansTotal
              });
            }}
            isLoading={status === "saving"}
          />
        </Box>
      </Box>
    </>
  );
};
