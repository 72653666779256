import React from "react";

import { AdminLayout } from "../../layouts";
import { PlaidTokensBody } from "./body";

export const PlaidTokensDesktop = () => {
  return (
    <AdminLayout
      title="Plaid Tokens"
      subTitle="Find and remove tokens that are not being used anymore"
    >
      <PlaidTokensBody />
    </AdminLayout>
  );
};
