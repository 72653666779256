import React, { useCallback, useContext, useState } from "react";
import {
  Box,
  CheckBox,
  Heading,
  ResponsiveContext,
  Text,
  TextInput
} from "grommet";
import Skeleton from "react-loading-skeleton";
import { useDebounce, useFirstMountState } from "react-use";
import { ThemeChooser } from "./";
import { useAppState } from "../hooks";
import { User } from "../types";
import { useLoggedUser } from "../firebase";
import { AvatarUploader } from "./avatarUploader";

export const UserProfile = () => {
  const { loading, userRef } = useLoggedUser();
  const { user, updateUser } = useAppState();
  const isFirstMount = useFirstMountState();
  const size = useContext(ResponsiveContext);
  const [name, setName] = useState(user?.name || "");
  const [receiveRecommendations, setReceiveRecommendations] = useState(
    user?.receiveRecommendations || false
  );

  const onNameChange = useCallback(({ currentTarget }) => {
    const { value } = currentTarget;
    setName(value);
  }, []);

  const onRecommendationChange = useCallback(({ currentTarget }) => {
    const { checked } = currentTarget;
    setReceiveRecommendations(checked);
  }, []);

  useDebounce(
    () => {
      if (!isFirstMount) {
        userRef.current?.set(
          {
            name,
            receiveRecommendations
          },
          { merge: true }
        );

        // update user state
        updateUser({
          ...(user as User),
          name,
          receiveRecommendations
        });
      }
    },
    300,
    [name, receiveRecommendations]
  );

  return (
    <Box justify="center" gap="large">
      <Box
        direction="row-responsive"
        align="center"
        justify="center"
        gap="medium"
      >
        <Box gap="small" align="center">
          {!loading && <AvatarUploader />}
          <Text
            size="small"
            color="text-xweak"
            textAlign="center"
            style={{ width: size === "small" ? "180px" : "200px" }}
          >
            Click anywhere on the image to change your avatar
          </Text>
        </Box>
        <Box
          alignSelf="center"
          width="240px"
          margin={size !== "small" ? { top: "-42px" } : { top: "small" }}
        >
          {!loading && (
            <TextInput
              size="large"
              placeholder="Your Name"
              value={name}
              onChange={onNameChange}
            />
          )}
          {loading && <Skeleton height="34px" />}
        </Box>
      </Box>
      <ThemeChooser />
      <Box align="center" gap="medium">
        <Heading level={2} margin="none">
          Recommendations
        </Heading>
        <CheckBox
          checked={receiveRecommendations}
          onChange={onRecommendationChange}
          label={
            <Text textAlign="center">
              Receive product recommendations to help with retirement plans
            </Text>
          }
        />
      </Box>
    </Box>
  );
};
