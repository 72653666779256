import React from "react";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { AddRealEstateBody } from "./";

export const AddRealEstateDesktop = () => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayout
      title="Add Real Estate"
      subTitle="Add your new property details and save"
      onClose={onNavigate("/add")}
    >
      <AddRealEstateBody />
    </AppLayout>
  );
};
