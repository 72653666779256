import React from "react";
import { Box, Button } from "grommet";
import { SearchAdvanced, Bug, Refresh } from "grommet-icons";

import { useNavigation } from "../../hooks";

import { UserAvatar, HeaderButton } from "../";

export const AdminFooterMobile = () => {
  const { onNavigate } = useNavigation();
  return (
    <Box
      background="app-header"
      pad="large"
      elevation="footer"
      direction="row"
      justify="between"
      style={{ position: "relative" }}
    >
      <HeaderButton
        label="User Search"
        icon={<SearchAdvanced />}
        onClick={onNavigate("/admin/user-search")}
      />
      <HeaderButton
        label="Plaid Debugger"
        icon={<Bug />}
        onClick={onNavigate("/admin/plaid-debugger")}
      />
      <HeaderButton
        label="Balance update"
        icon={<Refresh />}
        onClick={onNavigate("/admin/balance-update")}
      />
      <HeaderButton
        label="Plaid Tokens"
        icon={<Bug />}
        onClick={onNavigate("/admin/plaid-tokens")}
      />
      <Button onClick={onNavigate("/user-menu")}>
        <UserAvatar />
      </Button>
    </Box>
  );
};
