import { createContext } from "react";

export type AccessCodeContextValue = [
  string | undefined, // used correct code.
  (value: string) => void // set code
];

export const AccessCodeContext = createContext<
  AccessCodeContextValue | undefined
>(undefined);
