import React, { useCallback, useState } from "react";

import { Subscription } from "../";
import { PLANS } from "../../constants";
import { useAppState, useStepChangeContext } from "../../hooks";
import { OnboardingHeading, OnboardingLayout } from "./";

export const ChoosePlan = () => {
  const { user } = useAppState();
  const [, onStepChange] = useStepChangeContext();
  // TODO - use user current subscription to populate the current plan
  const [currentPlan, setCurrentPlan] = useState(PLANS.BASIC);
  const onPreviousStep = useCallback(
    () => onStepChange("restimate", user!),
    [onStepChange, user]
  );

  const onNextStep = useCallback(() => {
    if (currentPlan === PLANS.BASIC) {
      onStepChange("addInstitution", user!);
    } else {
      onStepChange("addPayment", user!);
    }
  }, [onStepChange, currentPlan, user]);

  return (
    <OnboardingLayout
      onPreviousStep={onPreviousStep}
      onNextStep={onNextStep}
      step="3 of 3 steps"
    >
      <OnboardingHeading
        heading="Choose a Plan"
        description="Try any plan free for 7 days, cancel anytime"
      />
      <Subscription currentPlan={currentPlan} onChange={setCurrentPlan} />
    </OnboardingLayout>
  );
};
