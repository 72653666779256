import { useContext } from "react";
import { ResponsiveContext } from "grommet";

import { AddInvestmentGroupDesktop, AddInvestmentGroupMobile } from "./";

export const AddInvestmentGroup = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <AddInvestmentGroupMobile />
  ) : (
    <AddInvestmentGroupDesktop />
  );
};
