import React, { useCallback, useContext, useMemo, useState } from "react";

import { useAppState, useFirebaseFunction, useNavigation } from "../../hooks";
import { RealEstate } from "../../types";
import { FeedbackContext, RealEstateForm } from "../../components";
import { useLoggedUser } from "../../firebase";
import { setQuery, validateRealEstate } from "../../utils";
import { useHistory, useLocation } from "react-router-dom";

export const AddRealEstateBody = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const { refreshData, addRealEstate } = useAppState();
  const { user } = useLoggedUser();
  const { sendFeedback } = useContext(FeedbackContext);
  const { navigate } = useNavigation();

  const createManualRealEstate = useFirebaseFunction({
    fnName: "createManualRealEstate"
  });
  const [status, setStatus] = useState("initial");

  const onRealEstateSave = useCallback(
    async (realEstate: RealEstate) => {
      const error = validateRealEstate(realEstate);
      setStatus("saving");

      if (error) {
        sendFeedback({ message: error, type: "error" });
        setStatus("error");
        return;
      }

      addRealEstate({
        ...realEstate,
        processing: true
      });

      setQuery({
        history,
        pathname: location.pathname,
        params: {
          ...Object.fromEntries(params),
          activePanel: 3,
          activeRealEstate: realEstate.id
        },
        replace: true
      });

      await createManualRealEstate({
        userId: user?.uid,
        realEstate: realEstate
      });

      await refreshData({ skipUpdate: true });

      setStatus("saved");
      sendFeedback({
        message: "Real estate successfully created",
        type: "success"
      });

      navigate("/add");
    },
    [
      history,
      params,
      location,
      createManualRealEstate,
      navigate,
      sendFeedback,
      user,
      refreshData,
      addRealEstate
    ]
  );

  return <RealEstateForm onSubmit={onRealEstateSave} status={status} />;
};
