import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Anchor,
  Box,
  Heading,
  Layer,
  Paragraph,
  Spinner,
  Tab,
  Tabs,
  Text
} from "grommet";

import { ActivePeriod, HistoricalDataPeriod, Institution } from "../../types";
import {
  useActiveTab,
  useAppState,
  useFirebaseFunction,
  useUserActiveSubscription
} from "../../hooks";
import {
  ManageAssetsLiabilitiesButton,
  NetWorthPerformanceCard,
  RestimateCard,
  TrtChart,
  InvestmentsTab,
  PaymentChooser,
  DowngradeSubscription,
  CancelTRTAccount
} from "../../components";
import { updateInstitution as updateInstitutionOnDB } from "../../firebase/db";
import { paymentFailed } from "../../utils/subscription";
import { useAsync } from "react-use";

export const DashboardBody = () => {
  const [activePeriod, setActivePeriod] = useState<ActivePeriod>("weekly");
  const [downgrading, setDowngrading] = useState(false);
  const [showDowngradeLayer, setShowDowngradeLayer] = useState(false);
  const [showCancelTRTLayer, setShowCancelTRTLayer] = useState(false);
  const {
    historicalData,
    user,
    spyPerformance,
    institutions,
    snapshots,
    status
  } = useAppState();

  const { value: subscription } = useUserActiveSubscription(user);
  const hasPaymentFailed = paymentFailed(subscription);
  const [showPaymentFailureLayer, setShowPaymentFailureLayer] =
    useState(hasPaymentFailed);

  const updateSubscription = useFirebaseFunction({
    fnName: "updateSubscription"
  });

  const tabs = useMemo(() => ["weekly", "monthly", "yearly"], []);
  const chartInstance =
    Boolean(historicalData) && Object.keys(historicalData).length !== 0 ? (
      <TrtChart
        data={historicalData[activePeriod as HistoricalDataPeriod]}
        activePeriod={activePeriod}
      />
    ) : null;

  const price = useAsync(async () => {
    if (subscription) {
      const priceValue = await subscription.price.get();
      return { id: subscription.price.id, ...priceValue.data() };
    }
  }, [subscription]);

  const toggleDowngradeLayer = useCallback(
    (e?) => {
      if (e) {
        e.preventDefault();
      }
      setShowDowngradeLayer(!showDowngradeLayer);
      setShowPaymentFailureLayer(!showPaymentFailureLayer);
    },
    [showDowngradeLayer, showPaymentFailureLayer]
  );

  const toggleCancelTRTLayer = useCallback(
    (e?) => {
      if (e) {
        e.preventDefault();
      }
      setShowCancelTRTLayer(!showCancelTRTLayer);
      setShowPaymentFailureLayer(!showPaymentFailureLayer);
    },
    [showCancelTRTLayer, showPaymentFailureLayer]
  );

  const onDowngradeSubscription = useCallback(
    async (defaultInstitution: Institution) => {
      try {
        setDowngrading(true);
        await updateSubscription({
          subscriptionId: subscription?.id,
          cancelAtPeriodEnd: true
        });
        await updateInstitutionOnDB(
          { ...defaultInstitution, default: true },
          user?.uid
        );
        setDowngrading(false);
        toggleDowngradeLayer();
        setShowPaymentFailureLayer(false);
      } catch (e) {
        setDowngrading(false);
        console.log(e);
      }
    },
    [subscription?.id, toggleDowngradeLayer, updateSubscription, user?.uid]
  );

  useEffect(() => {
    setShowPaymentFailureLayer(hasPaymentFailed);
  }, [hasPaymentFailed]);

  const { onActive: onActivePeriodActive, ...onActivePeriodProps } =
    useActiveTab({ paramName: "activePeriod", maxIndex: 2 });
  const tabsOnActive = useCallback(
    (index) => {
      onActivePeriodActive(index);
      setActivePeriod(tabs[index] as ActivePeriod);
    },
    [setActivePeriod, tabs, onActivePeriodActive]
  );
  const activeDetailProps = useActiveTab({
    paramName: "activeDetail",
    maxIndex: 1
  });

  if (!institutions.length) {
    if (status !== "done") {
      return (
        <Box
          direction="row"
          justify="center"
          align="center"
          pad={{ top: "medium" }}
          gap="small"
        >
          <Spinner color="dark-1" />
          <Text color="text-weak">Loading the latest information...</Text>
        </Box>
      );
    }
    return (
      <Box flex align="center" pad="large" gap="small">
        <Heading level={2} margin="none">
          Let's add some numbers
        </Heading>
        <Paragraph
          margin={{ top: "none", bottom: "small" }}
          color="text-weak"
          textAlign="center"
        >
          In order to see your performance you need to add assets and
          liabilities
        </Paragraph>
        <ManageAssetsLiabilitiesButton />
      </Box>
    );
  }

  return (
    <Box flex overflow="auto" pad={{ vertical: "medium" }} responsive={false}>
      <Box flex={false}>
        <Tabs
          // @ts-ignore - grommet types are wrong
          border="bottom"
          {...onActivePeriodProps}
          onActive={tabsOnActive}
        >
          <Tab title="Weekly">{chartInstance}</Tab>
          <Tab title="Monthly">{chartInstance}</Tab>
          <Tab title="Yearly">{chartInstance}</Tab>
        </Tabs>
        <Box
          direction="row"
          align="start"
          justify="center"
          pad="medium"
          gap="small"
          flex
          responsive={false}
        >
          <Tabs
            // @ts-ignore - grommet types are wrong
            fill={true}
            {...activeDetailProps}
          >
            <Tab title="Overview">
              <Box
                flex
                direction="row"
                justify="center"
                wrap
                pad={{ vertical: "medium" }}
              >
                <NetWorthPerformanceCard
                  snapshots={snapshots}
                  graphRange={activePeriod}
                  spyPerformance={spyPerformance}
                  activePeriod={activePeriod}
                />
                <RestimateCard
                  historicalData={
                    historicalData[activePeriod as HistoricalDataPeriod]
                  }
                  activePeriod={activePeriod}
                  snapshots={snapshots}
                />
              </Box>
            </Tab>
            <Tab title="Investments">
              <Box align="center">
                <InvestmentsTab activePeriod={activePeriod} />
              </Box>
            </Tab>
            {/*<Tab title="Properties">*/}
            {/*  <Box margin={{ top: "medium" }} align="center">*/}
            {/*    <Text textAlign="center">Properties cards to come soon</Text>*/}
            {/*  </Box>*/}
            {/*</Tab>*/}
          </Tabs>
        </Box>
      </Box>
      {showPaymentFailureLayer && (
        <Layer>
          <Box gap="medium" pad={"large"}>
            <Box>
              <Heading level={3} margin="none" responsive={false}>
                Payment Failed
              </Heading>
            </Box>
            <Box
              align="center"
              pad="medium"
              border={{ side: "all", color: "red" }}
              round="small"
              responsive={false}
            >
              <Paragraph alignSelf="start" color="text-weak">
                Your payment failed, update your payment method or downgrade
                your plan to <strong>Basic</strong> so you don't loose your
                data. After x days your account will be automatically changed to{" "}
                <strong>Basic</strong>.
              </Paragraph>
            </Box>
            <Box pad="medium">
              <PaymentChooser
                cardRequired
                price={price.value}
                subscription={subscription}
                onPay={() => setShowPaymentFailureLayer(false)}
              />
            </Box>
            <Box gap="medium">
              <Anchor
                margin={{ left: "small" }}
                href="#"
                label="Downgrade to Basic"
                onClick={toggleDowngradeLayer}
              />
              <Anchor
                color="red"
                margin={{ left: "small" }}
                href="#"
                label="Cancel Account"
                onClick={toggleCancelTRTLayer}
              />
            </Box>
          </Box>
        </Layer>
      )}
      {showDowngradeLayer && (
        <DowngradeSubscription
          loading={downgrading}
          onCancel={toggleDowngradeLayer}
          onConfirm={onDowngradeSubscription}
        />
      )}
      {showCancelTRTLayer && (
        <CancelTRTAccount onCancel={toggleCancelTRTLayer} />
      )}
    </Box>
  );
};
