import React from "react";

import { UpdateInstitutionBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const UpdateInstitutionMobile = () => (
  <AppLayoutMobile title="Update Institution">
    <UpdateInstitutionBody />
  </AppLayoutMobile>
);
