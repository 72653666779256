import { useContext } from "react";
import { Box, ResponsiveContext, Text } from "grommet";
import { parse, format } from "date-fns";

import { ActivitySnapshot, User, UserThemes } from "../../types";
import { TRTThemeContext } from "../../context";

import { InstitutionLogo } from "../institutionLogo";

import { UserActivityLogo } from "./user-activity-logo";

export interface RecentActivityMessageProps {
  activity: ActivitySnapshot;
  member?: User;
  showMemberAvatar?: boolean;
}
export const RecentActivityMessage = ({
  member,
  activity,
  showMemberAvatar = true
}: RecentActivityMessageProps) => {
  const { theme } = useContext(TRTThemeContext);
  const size = useContext(ResponsiveContext);
  const appBackground = theme === UserThemes.DARK ? "dark-1" : "light-1";

  const holding = {
    name: activity.tickerSymbol,
    url: `https://logos.m1finance.com/${activity.tickerSymbol}?size=144`
  };

  return (
    <Box
      margin="small"
      responsive={false}
      direction="row"
      align="center"
      gap="small"
    >
      {showMemberAvatar ? (
        <UserActivityLogo
          user={member}
          holding={holding}
          background={appBackground}
        />
      ) : (
        <InstitutionLogo institution={holding} />
      )}
      <Box align="start" gap="2px">
        <Box
          background={
            activity.position === "open" ? "status-ok" : "status-critical"
          }
          round="8px"
          pad={{ horizontal: "xsmall" }}
          responsive={false}
        >
          <Text color="white" weight="bold" size="small">
            {activity.position}
          </Text>
        </Box>
        <Text size={size !== "small" ? "large" : undefined}>
          <span>
            {member?.name} has {activity.action === "buy" ? "bought" : "sold"}{" "}
            <b>{activity.tickerSymbol}</b>{" "}
            {activity.type === "equity" ? "stock" : null}
            {activity.type === "derivative"
              ? `$${activity.optionDetail?.strikePrice}`
              : ""}
            {activity.type === "derivative"
              ? `${
                  activity.optionDetail?.optionType === "C" ? " call" : " put"
                } options`
              : null}
            {activity.type === "derivative" &&
              activity.optionDetail?.expirationDate &&
              ` expiring ${format(
                parse(
                  activity.optionDetail?.expirationDate ?? "",
                  "yyyy-MM-dd",
                  new Date()
                ),
                "PP"
              )}`}
          </span>
        </Text>
        <Text color="text-xweak" size={size === "small" ? "small" : undefined}>
          {activity.date}
        </Text>
      </Box>
    </Box>
  );
};
