import React from "react";
import { Box } from "grommet";

import { AddAssetsBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const AddAssetsMobile = () => (
  <AppLayoutMobile title="Add Assets">
    <Box
      flex
      overflow="auto"
      pad={{ horizontal: "medium", vertical: "small" }}
      responsive={false}
    >
      <AddAssetsBody />
    </Box>
  </AppLayoutMobile>
);
