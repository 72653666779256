import React from "react";
import { Box, Button, ButtonProps, Heading } from "grommet";

export const CallOutButton = ({
  icon,
  label,
  ...rest
}: ButtonProps & React.HTMLProps<HTMLButtonElement>) => (
  <Button {...rest} hoverIndicator>
    <Box
      width="120px"
      pad="small"
      align="center"
      justify="center"
      gap="small"
      responsive={false}
    >
      {icon}
      <Heading level={4} margin="none">
        {label}
      </Heading>
    </Box>
  </Button>
);
