import React from "react";
import { Box, Button, ButtonType, Text } from "grommet";

export interface HeaderButtonProps extends ButtonType {
  icon: React.ReactElement;
  label: string;
}

export const HeaderButton = ({ icon, label, ...rest }: HeaderButtonProps) => (
  <Button {...rest}>
    <Box align="center" justify="center" gap="small">
      {icon}
      <Text size="small">{label}</Text>
    </Box>
  </Button>
);
