import React, { ReactNode } from "react";
import { Box } from "grommet";

import { FooterMobile, HeaderMobile } from "../../components";

export interface AppLayoutMobileProps {
  children: any;
  title: string;
  header?: ReactNode;
  footer?: ReactNode;
  onLogoClick?: () => void;
}

export const AppLayoutMobile = ({
  children,
  title,
  header,
  footer,
  onLogoClick
}: AppLayoutMobileProps) => {
  return (
    <Box fill>
      {header || <HeaderMobile onLogoClick={onLogoClick} title={title} />}
      <Box flex overflow="auto">{children}</Box>
      {footer || <FooterMobile />}
    </Box>
  );
};
