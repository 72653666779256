import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, Tab, Tabs, Text } from "grommet";

import { useAppState } from "../../context";
import { useNavigation } from "../../hooks";
import { Insights } from "../../types";
import { useLoggedUser } from "../../firebase";
import {
  LoadingButton,
  BalanceTab,
  FeedbackContext,
  DepositFeeTab,
  ActivityTab,
  HoldingsTab
} from "../../components";

import { validateManualInsights, getNewManualInsights } from "./utils";

export const UpdateAssetsBody = () => {
  const [processing, setProcessing] = useState(false);
  const { onNavigate, navigate } = useNavigation();
  const {
    manualInstitutions = [],
    institutions,
    realEstate,
    snapshots,
    hasSomeManualInvestmentAccount,
    hasInstitutionsProcessing,
    refreshData,
    updateBalance,
    status
  } = useAppState();
  const { sendFeedback } = useContext(FeedbackContext);
  const { user } = useLoggedUser();
  const [newManualInsights, setNewManualInsights] = useState(
    getNewManualInsights(institutions, realEstate, snapshots)
  );

  const onUpdate = useCallback(
    (newInsights: Insights) => setNewManualInsights(newInsights),
    []
  );

  useEffect(() => {
    if (status === "done" && !manualInstitutions.length) {
      navigate("/");
      return;
    }
    setNewManualInsights(
      getNewManualInsights(institutions, realEstate, snapshots)
    );
  }, [
    institutions,
    realEstate,
    snapshots,
    manualInstitutions,
    navigate,
    status
  ]);

  const onManualInsightSave = useCallback(async () => {
    setProcessing(true);
    try {
      const insights = await validateManualInsights(
        newManualInsights,
        institutions,
        realEstate
      );
      await updateBalance({
        userId: user?.uid ?? "",
        insights
      });
      await refreshData({ skipUpdate: true });

      sendFeedback({
        message: "Balance & Holdings successfully updated",
        type: "success"
      });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        sendFeedback({
          message: e.message || "Failed to update balance & holdings",
          type: "error"
        });
      }
    }

    setProcessing(false);
  }, [
    newManualInsights,
    refreshData,
    sendFeedback,
    updateBalance,
    user,
    institutions,
    realEstate
  ]);

  return (
    <>
      <Box flex overflow="auto" pad={{ top: "medium" }}>
        {hasSomeManualInvestmentAccount ? (
          <Tabs>
            <Tab title="Balance">
              <BalanceTab insights={newManualInsights} onUpdate={onUpdate} />
            </Tab>
            <Tab title="Deposits & Fees">
              <DepositFeeTab insights={newManualInsights} onUpdate={onUpdate} />
            </Tab>
            <Tab title="Holdings">
              <HoldingsTab insights={newManualInsights} onUpdate={onUpdate} />
            </Tab>
            <Tab title="Activity">
              <ActivityTab insights={newManualInsights} onUpdate={onUpdate} />
            </Tab>
          </Tabs>
        ) : (
          <BalanceTab insights={newManualInsights} onUpdate={onUpdate} />
        )}
      </Box>
      <Box
        tag="footer"
        justify="between"
        direction="row"
        align="center"
        pad="medium"
        border="top"
        flex={false}
      >
        <Text color="text-xweak" textAlign="center">
          <i>
            <b>*</b> denotes required fields
          </i>
        </Text>
        <Box gap="small" direction="row" align="center">
          <Button secondary label="Cancel" onClick={onNavigate("/")} />
          <LoadingButton
            disabled={hasInstitutionsProcessing}
            isLoading={processing}
            label="Save"
            primary
            onClick={onManualInsightSave}
          />
        </Box>
      </Box>
    </>
  );
};
