import { useCallback, useContext, useState, useMemo, useRef } from "react";
import { chunk, isEmpty, orderBy, uniq } from "lodash";
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Heading,
  Menu,
  ResponsiveContext,
  Spinner,
  Text
} from "grommet";
import {
  Edit,
  FormAdd,
  Logout,
  More,
  Share,
  StatusCritical,
  Trash,
  UserAdd,
  View
} from "grommet-icons";
import { useEffectOnce, useAsyncFn } from "react-use";

import {
  ConfirmationModal,
  FeedbackContext,
  InvestmentGroupLogo,
  ManageMembers,
  MenuItemLabel,
  ShareSettings
} from "../index";
import md5 from "md5";
import { TRTThemeContext } from "../../context";
import {
  InvestmentGroupMember,
  InvestmentGroupRoles,
  InvestmentGroupType,
  MembersMap,
  User,
  UserThemes
} from "../../types";
import { useAppState, useNavigation, useFirebaseFunction } from "../../hooks";
import { db } from "../../firebase";
import { isPresent } from "ts-is-present";

export const DeleteInvestmentGroupConfirmation = ({
  loading,
  investmentGroup,
  onCancel,
  onConfirm
}: any) => {
  return (
    <ConfirmationModal
      loading={loading}
      color="status-critical"
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to remove ${investmentGroup.name}?`}
      actionLabel="Remove"
    >
      <Box direction="row" justify="start" align="center">
        <InvestmentGroupLogo investmentGroup={investmentGroup} size="60px" />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            This action cannot be reversed. All members of this group will be
            removed, including other administrators.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const LeaveInvestmentGroupConfirmation = ({
  investmentGroup,
  onCancel,
  onConfirm,
  loading
}: any) => {
  return (
    <ConfirmationModal
      color="status-critical"
      loading={loading}
      onCancel={onCancel}
      onConfirm={onConfirm}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to leave ${investmentGroup.name}?`}
      actionLabel="Leave"
    >
      <Box direction="row" justify="start" align="center">
        <InvestmentGroupLogo investmentGroup={investmentGroup} size="60px" />
        <Box gap="small" margin={{ left: "medium" }} responsive={false}>
          <Text color="text-weak">
            This action cannot be reversed. If you are the only administrator in
            this group, the group will be removed.
          </Text>
        </Box>
      </Box>
    </ConfirmationModal>
  );
};

export const InvestmentGroupMembers = ({
  members,
  membersMap
}: {
  members: InvestmentGroupMember[];
  membersMap: MembersMap;
}) => {
  const size = useContext(ResponsiveContext);
  const { theme } = useContext(TRTThemeContext);
  let borderColor = theme === UserThemes.DARK ? "app-header" : "neutral-3";
  if (size === "small") {
    borderColor = theme === UserThemes.DARK ? "dark-1" : "light-1";
  }
  const activeMembers = useMemo(
    () => members.filter(({ pending }: any) => !pending),
    [members]
  );
  const displayableMembers = useMemo(
    () =>
      orderBy(
        activeMembers.filter(({ role }: any) => role !== "viewer"),
        ["role"]
      ).slice(0, 3),
    [activeMembers]
  );

  return (
    <Box direction="row" align="center">
      {displayableMembers.map((member) => (
        <Box
          key={member.id}
          width="28px"
          height="28px"
          round="full"
          border={{
            side: "all",
            size: "2px",
            color: borderColor
          }}
          margin={{ left: "-9px" }}
          style={{
            overflow: "hidden"
          }}
        >
          <Avatar
            src={
              membersMap[member.id || ""]?.avatarUrl
                ? membersMap[member.id || ""]?.avatarUrl
                : `//s.gravatar.com/avatar/${md5(
                    membersMap[member.id || ""]?.email || ""
                  )}?s=80`
            }
          />
        </Box>
      ))}
      {activeMembers.length - displayableMembers.length > 0 && (
        <Box
          round="full"
          border={{
            side: "all",
            size: "2px",
            color: borderColor
          }}
          margin={{ left: "-9px" }}
          style={{
            overflow: "hidden"
          }}
          background="light-4"
          width="28px"
          height="28px"
          align="center"
          justify="center"
        >
          <Text color="neutral-3" size="xsmall" weight="bold">
            +{activeMembers.length - displayableMembers.length}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export const InvestmentGroup = ({
  getInvestmentGroups,
  investmentGroup,
  membersMap,
  border
}: {
  getInvestmentGroups: () => void;
  investmentGroup: InvestmentGroupType;
  membersMap: MembersMap;
  border?: BoxProps["border"];
}) => {
  const { navigate } = useNavigation();
  const { user, updateUser } = useAppState();
  const size = useContext(ResponsiveContext);
  const leaveGroup = useFirebaseFunction({
    fnName: "leaveGroup"
  });
  const [
    showDeleteInvestmentGroupConfirmation,
    setShowDeleteInvestmentGroupConfirmation
  ] = useState(false);
  const [
    showLeaveInvestmentGroupConfirmation,
    setShowLeaveInvestmentGroupConfirmation
  ] = useState(false);
  const [showManageAccess, setShowManageAccess] = useState(false);
  const [showShareSettings, setShowShareSettings] = useState(false);

  const { sendFeedback } = useContext(FeedbackContext);
  const { theme } = useContext(TRTThemeContext);
  const investmentGroupRef = useRef(null);

  const onRequestToDeleteInvestmentGroup = useCallback(
    () => setShowDeleteInvestmentGroupConfirmation(true),
    [setShowDeleteInvestmentGroupConfirmation]
  );

  const onRequestToLeaveInvestmentGroup = useCallback(
    () => setShowLeaveInvestmentGroupConfirmation(true),
    [setShowLeaveInvestmentGroupConfirmation]
  );

  const [deleteInvestmentGroup, onConfirmDeleteInstitution] =
    useAsyncFn(async () => {
      try {
        updateUser({
          ...(user as User),
          investmentGroups: (user?.investmentGroups || []).map((group) => {
            if (group.id === investmentGroup.id) {
              return {
                ...investmentGroup,
                loading: true
              };
            }
            return group;
          })
        });
        const deleted = await db.deleteInvestmentGroup(investmentGroup);
        if (deleted) {
          updateUser({
            ...(user as User),
            investmentGroupIds: (user?.investmentGroupIds || []).filter(
              (groupId) => groupId !== investmentGroup.id
            ),
            investmentGroups: (user?.investmentGroups || []).filter(
              (group) => group.id !== investmentGroup.id
            )
          });
          sendFeedback({
            message: `Successfully deleted ${investmentGroup?.name}`,
            type: "success"
          });
          navigate(`/`);
          return deleted;
        } else {
          sendFeedback({
            message: `Error deleting ${investmentGroup?.name}`,
            type: "error"
          });
        }
      } catch (e) {
        sendFeedback({
          message: `Error deleting ${investmentGroup?.name}`,
          type: "error"
        });
      }
    }, [investmentGroup]);

  const [leaveInvestmentGroup, onConfirmLeaveInvestmentGroup] =
    useAsyncFn(async () => {
      try {
        // just allow leave investment group if there is another Admin
        const isTheOnlyAdmin =
          !investmentGroup.members
            .filter((member) => {
              return member.id !== user?.uid;
            })
            .some((member) => {
              return (
                member.role === InvestmentGroupRoles.ADMIN && !member.pending
              );
            }) || investmentGroup.members.length === 1;

        if (isTheOnlyAdmin) {
          sendFeedback({
            message: "Define another Admin for the group before leaving",
            type: "error"
          });
          setShowLeaveInvestmentGroupConfirmation(false);
          return;
        }

        const response = await leaveGroup({
          userId: user?.uid,
          groupId: investmentGroup.id
        });
        setShowLeaveInvestmentGroupConfirmation(false);
        if (response.data.success) {
          sendFeedback({
            message: `Successfully left ${investmentGroup?.name}`,
            type: "success"
          });
          updateUser({
            ...(user as User),
            investmentGroupIds: (user?.investmentGroupIds || []).filter(
              (igId) => igId !== investmentGroup.id
            ),
            investmentGroups: (user?.investmentGroups || []).filter(
              (group) => group.id !== investmentGroup.id
            )
          });

          navigate(`/`);
          return response.data.success;
        } else {
          sendFeedback({
            message: `Error leaving ${investmentGroup?.name}`,
            type: "error"
          });
        }
      } catch (e) {
        sendFeedback({
          message: `Error leaving ${investmentGroup?.name}`,
          type: "error"
        });
      }
    }, [investmentGroup]);

  const onCancelDeleteInvestmentGroup = useCallback(
    () => setShowDeleteInvestmentGroupConfirmation(false),
    [setShowDeleteInvestmentGroupConfirmation]
  );

  const onCancelLeaveInvestmentGroup = useCallback(
    () => setShowLeaveInvestmentGroupConfirmation(false),
    [setShowLeaveInvestmentGroupConfirmation]
  );

  const onAccessGroup = useCallback(
    (e) => {
      navigate(`/investment-group/${investmentGroup.id}`);
      e.preventDefault();
    },
    [investmentGroup, navigate]
  );

  const onEditGroup = useCallback(
    (e) => {
      navigate(`/investment-group/${investmentGroup.id}/edit`);
      e.preventDefault();
    },
    [investmentGroup, navigate]
  );

  const onShowManageMembers = useCallback((e) => {
    e.preventDefault();
    setShowManageAccess(true);
  }, []);

  const onShowShareSettings = useCallback((e) => {
    e.preventDefault();
    setShowShareSettings(true);
  }, []);

  const onHideManageMembers = useCallback(() => setShowManageAccess(false), []);
  const onHideShareSettings = useCallback(
    () => setShowShareSettings(false),
    []
  );

  const actions = useMemo(() => {
    const isGroupAdmin =
      investmentGroup.members.find((member) => member.id === user?.uid)
        ?.role === InvestmentGroupRoles.ADMIN;
    return [
      {
        label: (
          <MenuItemLabel
            icon={<View color="menu-icon" size="small" />}
            label="Access Group"
          />
        ),
        onClick: onAccessGroup
      },
      {
        label: (
          <MenuItemLabel
            icon={<UserAdd color="menu-icon" size="small" />}
            label="Manage Members"
          />
        ),
        onClick: onShowManageMembers,
        onlyAdmin: true
      },
      {
        label: (
          <MenuItemLabel
            icon={<Edit color="menu-icon" size="small" />}
            label="Edit Group"
          />
        ),
        onClick: onEditGroup,
        onlyAdmin: true
      },

      {
        label: (
          <MenuItemLabel
            icon={<Share color="menu-icon" size="small" />}
            label="Share settings"
          />
        ),
        onClick: onShowShareSettings,
        admin: true
      },
      {
        label: (
          <MenuItemLabel
            icon={<Logout color="menu-icon" size="small" />}
            label="Leave Group"
          />
        ),
        onClick: onRequestToLeaveInvestmentGroup
      },
      {
        label: (
          <MenuItemLabel
            icon={<Trash color="menu-icon" size="small" />}
            label="Remove Group"
          />
        ),
        onClick: onRequestToDeleteInvestmentGroup,
        onlyAdmin: true
      }
    ].filter((action) => {
      if (!action.onlyAdmin) {
        return true;
      }
      return isGroupAdmin;
    });
  }, [
    investmentGroup.members,
    onAccessGroup,
    onShowManageMembers,
    onEditGroup,
    onShowShareSettings,
    onRequestToLeaveInvestmentGroup,
    onRequestToDeleteInvestmentGroup,
    user?.uid
  ]);

  return (
    <Box
      flex={false}
      border={size === "small" ? "horizontal" : "top"}
      pad={{ bottom: "small" }}
      ref={investmentGroupRef}
    >
      <Box
        direction="row"
        align="center"
        justify="between"
        border={border}
        pad={{
          left: "small",
          right: size === "small" ? "small" : undefined,
          vertical: size === "small" ? "xsmall" : undefined
        }}
        responsive={false}
      >
        <Button onClick={onAccessGroup}>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="9px"
            pad={{ top: "small" }}
          >
            <InvestmentGroupLogo investmentGroup={investmentGroup} />
            <Box align="start">
              <Text
                size={size === "large" ? "large" : undefined}
                truncate
                style={{ maxWidth: "144px" }}
                title={investmentGroup.name}
              >
                {investmentGroup.name}
              </Text>
            </Box>
          </Box>
        </Button>
        {investmentGroup.loading && (
          <Box
            pad="small"
            width={size === "small" ? "36px" : undefined}
            align="center"
            justify="center"
          >
            <Spinner color="dark-1" />
          </Box>
        )}
        {!investmentGroup.loading && (
          <Box
            width={size === "small" ? "36px" : undefined}
            align="center"
            justify="center"
          >
            <Menu
              icon={<More />}
              dropAlign={{ right: "right", top: "bottom" }}
              dropBackground={{
                color: "drop",
                // @ts-ignore
                dark: theme === UserThemes.DARK
              }}
              items={actions}
            />
          </Box>
        )}
      </Box>
      <Heading
        level={6}
        margin={{ top: "xsmall", bottom: "none", horizontal: "small" }}
        responsive={false}
      >
        MEMBERS
      </Heading>
      <Box
        pad={{ horizontal: "medium", vertical: "xsmall" }}
        responsive={false}
      >
        <InvestmentGroupMembers
          members={investmentGroup.members}
          membersMap={membersMap}
        />
      </Box>
      {showDeleteInvestmentGroupConfirmation && (
        <DeleteInvestmentGroupConfirmation
          loading={deleteInvestmentGroup.loading}
          investmentGroup={investmentGroup}
          onCancel={onCancelDeleteInvestmentGroup}
          onConfirm={onConfirmDeleteInstitution}
        />
      )}
      {showLeaveInvestmentGroupConfirmation && (
        <LeaveInvestmentGroupConfirmation
          loading={leaveInvestmentGroup.loading}
          investmentGroup={investmentGroup}
          onCancel={onCancelLeaveInvestmentGroup}
          onConfirm={onConfirmLeaveInvestmentGroup}
        />
      )}
      {showManageAccess && (
        <ManageMembers
          getInvestmentGroups={getInvestmentGroups}
          investmentGroup={investmentGroup}
          membersMap={membersMap}
          onClose={onHideManageMembers}
        />
      )}
      {showShareSettings && (
        <ShareSettings
          investmentGroup={investmentGroup}
          onClose={onHideShareSettings}
        />
      )}
    </Box>
  );
};

export const InvestmentGroups = () => {
  const { user, updateUser } = useAppState();
  const { navigate } = useNavigation();
  const onAddInvestmentGroupClick = useCallback(
    (e) => {
      e.preventDefault();
      navigate("/add-investment-group");
    },
    [navigate]
  );

  const [gettingInvestmentGroups, getInvestmentGroups] =
    useAsyncFn(async () => {
      try {
        if (!user?.investmentGroupIds) {
          return [];
        }

        const groups: InvestmentGroupType[] = [];
        const groupsRefs = await db.getInvestmentGroups(
          user?.investmentGroupIds
        );
        groupsRefs.docs.forEach((groupRef) => {
          groups.push(groupRef.data() as InvestmentGroupType);
        });

        const allUserIds = uniq(
          groups
            .map((group) => group.members)
            .flat()
            .filter((member) => !member.pending && member.role !== "VIEWER")
            .map((member) => member.id)
            .filter(isPresent)
        );

        const chunkedUsers = chunk(allUserIds, 10);
        const allUserPromises = chunkedUsers.map((userId) =>
          db.getUsersById(userId)
        );
        const allRefs = await Promise.all(allUserPromises);
        const allUsers: User[] = [];
        allRefs.forEach((userRef) => {
          userRef.docs.forEach((userRef) => {
            allUsers.push({
              ...(userRef.data() as User),
              uid: userRef.id
            });
          });
        });

        const membersMap = allUsers.reduce((agg, member) => {
          agg[member.uid] = member;
          return agg;
        }, {} as MembersMap);

        updateUser({
          ...user,
          investmentGroups: groups,
          investmentGroupsMembersMap: membersMap
        });
        return groups;
      } catch (e) {
        console.log("error loading groups", e);
      }
    }, [user]);

  useEffectOnce(() => {
    if (!user?.investmentGroups) {
      getInvestmentGroups();
    }
  });

  if (gettingInvestmentGroups.loading) {
    return (
      <Box
        pad={{ vertical: "medium" }}
        align="center"
        justify="center"
        fill
        gap="small"
      >
        <Spinner color="dark-1" />
        <Text>Loading ...</Text>
      </Box>
    );
  }

  if (isEmpty(user?.investmentGroups))
    return (
      <Box
        pad={{ vertical: "medium" }}
        align="center"
        justify="center"
        fill
        gap="small"
      >
        {user?.isPro && (
          <Box gap="small">
            <Text>No investment group added</Text>
            <Button
              size="small"
              primary
              onClick={onAddInvestmentGroupClick}
              label="Add new group"
            />
          </Box>
        )}
        {!user?.isPro && (
          <Box pad="small" flex align="center" gap="small">
            <Text textAlign="center">
              Investment Groups are available just for PRO users
            </Text>
            <Button
              style={{ maxWidth: "max-content" }}
              size="small"
              primary
              onClick={() =>
                navigate("/settings?activePanel=4&activeSetting=3")
              }
              label="Become a PRO"
            />
          </Box>
        )}
      </Box>
    );

  const totalGroups = user?.investmentGroups.length || 0;
  return (
    <Box>
      <Box
        direction="row"
        align="center"
        justify="between"
        pad={{ vertical: "small", horizontal: "small" }}
        responsive={false}
        border="top"
      >
        <Text color="text-weak">
          {totalGroups > 1 ? `${totalGroups} groups` : "1 group"}
        </Text>
        {user?.isPro && (
          <Button onClick={onAddInvestmentGroupClick}>
            <Box direction="row" align="center">
              <FormAdd />
              <Text size="small" weight="bold">
                Add new group
              </Text>
            </Box>
          </Button>
        )}
      </Box>
      {user?.investmentGroups?.map((investmentGroup: any) => (
        <InvestmentGroup
          getInvestmentGroups={getInvestmentGroups}
          key={investmentGroup.id}
          investmentGroup={investmentGroup}
          membersMap={user?.investmentGroupsMembersMap || {}}
        />
      ))}
    </Box>
  );
};
