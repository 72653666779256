import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Box, Button, Heading, ResponsiveContext, TextInput } from "grommet";

import { remove } from "lodash";
import { useParams } from "react-router";
import { useAppState, useFirebaseFunction, useNavigation } from "../../hooks";
import { Institution } from "../../types";
import {
  AccountCard,
  AddAccountButton,
  FeedbackContext,
  InstitutionLogo,
  LoadingButton
} from "../../components";
import { updateInstitution as updateInstitutionOnDB } from "../../firebase/db";
import { useLoggedUser } from "../../firebase";
import { getUserAssetsAndLiabilities } from "../../shared/accounts";
import {
  getDefaultInstitution,
  generatePlaceholderAccount,
  validateInstitution
} from "../../utils";

type Params = {
  institutionId: string;
};

export const UpdateInstitutionBody = () => {
  const { institutions, refreshData, updateInstitution } = useAppState();
  const { user } = useLoggedUser();
  const size = useContext(ResponsiveContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const { navigate, onNavigate } = useNavigation();
  const [institutionName, setInstitutionName] = useState("");
  const { institutionId: id } = useParams<Params>();
  const [accountTypeChanges, setAccountTypeChanges] = useState({
    changes: {},
    institutionId: ""
  });
  const adjustPersonalSpyForUser = useFirebaseFunction({
    fnName: "adjustPersonalSpyForUser"
  });

  const [internalInstitution, setInternalInstitution] = useState<Institution>(
    getDefaultInstitution()
  );
  const [status, setStatus] = React.useState("initial");

  useEffect(() => {
    const institution = institutions.find((i) => i.id === id);
    if (institution) {
      setInternalInstitution(institution);
      setInstitutionName(institution.name);
    }
  }, [institutions, id]);

  const onAccountChange = useCallback(
    (id, key, value) => {
      const newInstitution = { ...internalInstitution };

      newInstitution.accounts = newInstitution.accounts.map((a) => {
        if (a.id === id) {
          if (key === "customType") {
            setAccountTypeChanges({
              institutionId: internalInstitution.id,
              changes: {
                ...accountTypeChanges.changes,
                [id]: {
                  from: a.customType || a.type,
                  to: value
                }
              }
            });
          }

          return { ...a, [key]: value };
        }

        return a;
      });

      setInternalInstitution(newInstitution);
    },
    [accountTypeChanges, internalInstitution]
  );

  const tempInstitution = useMemo(
    () => ({
      ...internalInstitution,
      name: institutionName
    }),
    [internalInstitution, institutionName]
  );

  const onAddAccount = useCallback(() => {
    const newInstitution = { ...internalInstitution };
    newInstitution.accounts = [...internalInstitution.accounts];
    newInstitution.accounts.push(
      generatePlaceholderAccount(newInstitution.accounts.length + 1)
    );

    setInternalInstitution(newInstitution);
  }, [setInternalInstitution, internalInstitution]);

  const onRemoveAccount = useCallback(
    (idToBeRemoved) => {
      const newInstitution = { ...internalInstitution };
      if (newInstitution.accounts.length - 1 > 0) {
        remove(newInstitution.accounts, ({ id }) => id === idToBeRemoved);

        setInternalInstitution(newInstitution);
      } else {
        sendFeedback({
          message: "You need at least one account per institution",
          type: "error"
        });
      }
    },
    [setInternalInstitution, internalInstitution, sendFeedback]
  );

  const onInstitutionSave = useCallback(async () => {
    const error = validateInstitution(internalInstitution);
    setStatus("saving");

    if (error) {
      sendFeedback({ message: error, type: "error" });
      setStatus("error");
      return;
    }

    const accounts = internalInstitution.accounts.map((a) => ({
      ...a,
      balance: Number(a.balance ? a.balance : 0)
    }));

    const { assets, liabilities } = getUserAssetsAndLiabilities(accounts);
    const netWorth = assets - liabilities;
    let institutionToSave = { ...internalInstitution, accounts, netWorth };

    if (institutions.some((i) => i.id === internalInstitution.id)) {
      updateInstitution({ ...institutionToSave, processing: true });

      await updateInstitutionOnDB(institutionToSave, user?.uid);

      if (Object.entries(accountTypeChanges.changes).length) {
        await adjustPersonalSpyForUser({ accountTypeChanges });
      }

      await refreshData({ skipPlaidUpdate: true });

      updateInstitution({
        ...institutionToSave,
        processing: false
      });

      sendFeedback({
        message: `${institutionName} successfully updated`,
        type: "success"
      });
    }

    navigate("/add");
  }, [
    adjustPersonalSpyForUser,
    accountTypeChanges,
    institutions,
    institutionName,
    internalInstitution,
    navigate,
    sendFeedback,
    refreshData,
    updateInstitution,
    user
  ]);

  return (
    <>
      <Box
        flex
        overflow="auto"
        align="start"
        pad={{ vertical: "medium", horizontal: size }}
        responsive={false}
      >
        <Box flex={false}>
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="medium"
            width="large"
            margin={{ top: "small" }}
          >
            <InstitutionLogo
              size={size !== "small" ? "large" : "72px"}
              institution={tempInstitution}
            />
            <TextInput
              placeholder="Institution name"
              size={size}
              value={institutionName}
              // @ts-ignore // grommet types are missing this prop
              type="search"
              readOnly={true}
            />
          </Box>
          <Box pad={{ horizontal: "small" }}>
            <Heading level={3}>Accounts</Heading>
            <Box
              direction="row"
              align={size !== "large" ? "center" : "start"}
              justify={size !== "large" ? "center" : "start"}
              wrap
              style={{ maxWidth: "1360px" }}
            >
              {(internalInstitution.accounts || []).map((account) => (
                <AccountCard
                  key={account.id}
                  account={account}
                  onChange={onAccountChange}
                  onRemove={onRemoveAccount}
                  automated={internalInstitution.type === "automated"}
                />
              ))}
              {internalInstitution.type === "manual" && (
                <Box
                  width="medium"
                  margin={{
                    top: size !== "small" ? "medium" : undefined,
                    left: size === "large" ? "small" : undefined
                  }}
                  align={size !== "large" ? "center" : "start"}
                >
                  <AddAccountButton onClick={onAddAccount} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        tag="footer"
        justify="end"
        direction="row"
        pad="medium"
        border="top"
        gap="small"
      >
        <Button secondary label="Cancel" onClick={onNavigate("/add")} />
        <LoadingButton
          type="submit"
          primary
          label="Save"
          onClick={onInstitutionSave}
          isLoading={status === "saving"}
        />
      </Box>
    </>
  );
};
