import React, {
  ChangeEvent,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect
} from "react";

import { HoldingRow, InsightsProps, QuoteMap } from "../../types";
import { useAppState } from "../../context";
import {
  getAccountName,
  getInstitutionHoldingRows,
  NumberText
} from "../../utils";
import {
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text
} from "grommet";
import { InstitutionHoldingsLogo } from "../institutionLogo";
import { HoldingBadge } from "../holdingBadge";
import { CurrencyInput } from "../currencyInput";
import { getTickerSymbolsFromInsights } from "../../pages/updateAssets/utils";
import { useFirebaseFunction } from "../../hooks";

interface InstitutionAccountHoldingRowProps {
  row: HoldingRow;
  onUpdate: (holdingRow: HoldingRow) => void;
  disabled: boolean;
  quoteMap: QuoteMap;
}

const TICKER_LOGO_URL = process.env.REACT_APP_TICKER_LOGO_URL;
const TICKER_LOGO_URL_FALLBACK = process.env.REACT_APP_TICKER_LOGO_URL_FALLBACK;

const InstitutionAccountHoldingRow = ({
  row,
  onUpdate,
  disabled,
  quoteMap
}: InstitutionAccountHoldingRowProps) => {
  const [newCurrentValue, setNewCurrentValue] = useState(
    `${row.holdingItem.currentValue / row.holdingItem.quantity}`
  );
  const size = useContext(ResponsiveContext);
  const accountNameTitle = `${
    row.accountRow.institution.name
  } - ${getAccountName(row.accountRow.account)}`;
  const onUpdateHoldingCurrentValue = useCallback(
    (value: string) => {
      setNewCurrentValue(value);
      const newRow = { ...row };
      newRow.holdingItem = {
        ...row.holdingItem,
        currentValue: row.holdingItem.quantity * +value
      };
      onUpdate(newRow);
    },
    [onUpdate, row, setNewCurrentValue]
  );
  const onCurrentValueChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onUpdateHoldingCurrentValue(value);
    },
    [onUpdateHoldingCurrentValue]
  );
  useEffect(() => {
    if (
      row.holdingItem.type !== "derivative" &&
      quoteMap[row.holdingItem.tickerSymbol] &&
      +newCurrentValue !== quoteMap[row.holdingItem.tickerSymbol]
    ) {
      onUpdateHoldingCurrentValue(`${quoteMap[row.holdingItem.tickerSymbol]}`);
    }
  }, [quoteMap, onUpdateHoldingCurrentValue, row.holdingItem, newCurrentValue]);
  return (
    <TableRow>
      <TableCell>
        <Box direction="row" gap="small" align="center">
          <InstitutionHoldingsLogo
            size="32px"
            institution={row.accountRow.institution}
            background="app"
            holding={{
              name: row.holdingItem.tickerSymbol,
              url: TICKER_LOGO_URL?.replace(
                "#TICKER#",
                row.holdingItem.tickerSymbol
              ),
              fallBackUrl: `${TICKER_LOGO_URL_FALLBACK}?tickerSymbol=${row.holdingItem.tickerSymbol}`
            }}
          />
          <Box>
            {size === "large" && (
              <Text
                color="text-weak"
                size="small"
                truncate
                style={{ maxWidth: "400px" }}
                title={accountNameTitle}
              >
                {accountNameTitle}
              </Text>
            )}
            <Heading margin="none" level={4}>
              <b>{row.holdingItem.tickerSymbol}</b>
            </Heading>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <HoldingBadge
          optionDetail={row.holdingItem.optionDetail}
          type={row.holdingItem.type}
          quantity={row.holdingItem.quantity}
        />
      </TableCell>
      <TableCell>
        <Heading level={5} margin="none">
          <NumberText
            value={row.holdingItem.currentValue / row.holdingItem.quantity}
          />
        </Heading>
      </TableCell>
      <TableCell>
        <CurrencyInput
          value={newCurrentValue}
          onChange={onCurrentValueChange}
          disabled={disabled}
        />
      </TableCell>
    </TableRow>
  );
};

export const HoldingsTab = ({ insights, onUpdate }: InsightsProps) => {
  const [importing, setImporting] = useState(false);
  const [quoteMap, setQuoteMap] = useState<QuoteMap>({});
  const { manualInstitutions, institutions, snapshots } = useAppState();
  const fetchTickerSymbolQuote = useFirebaseFunction({
    fnName: "fetchTickerSymbolQuote"
  });

  const onUpdateHoldings = useCallback(
    (holdingRow: HoldingRow) => {
      const newInsights = { ...insights };
      const {
        institution: { id: institutionId },
        account: { id: accountId }
      } = holdingRow.accountRow;
      newInsights.institutions[institutionId].accounts[accountId].holdings =
        newInsights.institutions[institutionId].accounts[
          accountId
        ].holdings?.map((holdingSnapshot) => {
          if (holdingSnapshot.id === holdingRow.holdingItem.id) {
            return holdingRow.holdingItem;
          }
          return holdingSnapshot;
        }) || [];
      onUpdate(newInsights);
    },
    [insights, onUpdate]
  );

  const onImportFromYahoo = useCallback(async () => {
    setImporting(true);

    const tickerSymbols = getTickerSymbolsFromInsights(insights, institutions);
    if (tickerSymbols.length) {
      const {
        data: { quoteMap, error }
      } = await fetchTickerSymbolQuote({
        tickerSymbols
      });
      if (error) {
        console.error("Could not fetch quotes for activity", error);
      }
      setQuoteMap(quoteMap);
      setImporting(false);
    }
  }, [insights, fetchTickerSymbolQuote, institutions]);

  const holdingRows = useMemo(
    () =>
      getInstitutionHoldingRows(manualInstitutions, snapshots).map(
        (holdingRow) => (
          <InstitutionAccountHoldingRow
            key={`institution-row-${holdingRow.holdingItem.id}`}
            row={holdingRow}
            onUpdate={onUpdateHoldings}
            disabled={importing}
            quoteMap={quoteMap}
          />
        )
      ),
    [manualInstitutions, snapshots, onUpdateHoldings, importing, quoteMap]
  );
  return (
    <Box fill align="center">
      {holdingRows.length ? (
        <Box pad="medium" width="large">
          <Box pad={{ vertical: "small" }}>
            <Button
              size="small"
              color="#4A00A0"
              alignSelf="center"
              icon={<img alt="yahoo logo" src="/yahoo.svg" width="24px" />}
              label={
                <Box flex>
                  {importing ? "Importing..." : "Import from Yahoo"}
                </Box>
              }
              onClick={onImportFromYahoo}
              disabled={importing}
              style={{ width: "180px" }}
            />
            <Paragraph
              size="small"
              alignSelf="center"
              color="text-weak"
              textAlign="center"
              margin={{ top: "xsmall" }}
            >
              Options and futures currently not supported
            </Paragraph>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell />
                <TableCell>
                  <Heading level={5} margin="none">
                    Holding
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    Current
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    New
                  </Heading>
                </TableCell>
              </TableRow>
              {holdingRows}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Paragraph
          color="text-weak"
          textAlign="center"
          margin={{ top: "large" }}
        >
          No holdings for manual institutions. Add one or more by navigating to
          the Activity tab
        </Paragraph>
      )}
    </Box>
  );
};
