import { useCallback, useContext, useMemo, useState } from "react";
import {
  Box,
  FormField,
  Text,
  TextInput,
  ResponsiveContext,
  Button
} from "grommet";
import { useFirebaseContext } from "../firebase";
import { LoadingButton } from "../components/button";
import { useNavigation } from "../hooks";
import { FeedbackContext } from "../components/feedback";
import { useAsync } from "react-use";
import { useLocation } from "react-router";
import { LogoFull } from "../components/logo";

export const ResetPassword = ({ from }: { from?: string }) => {
  const size = useContext(ResponsiveContext);
  const { sendFeedback } = useContext(FeedbackContext);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { auth } = useFirebaseContext();
  const { navigate } = useNavigation();

  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const code = params.get("oobCode");

  const onChangeHandler = (event: any) => {
    const { name, value } = event.target;
    if (name === "password") {
      setPassword(value);
    }
  };

  const resetPassword = useCallback(async () => {
    try {
      setIsLoading(true);
      await auth.confirmPasswordReset(code, password);
      sendFeedback({
        type: "success",
        message: "Password successfuly reset"
      });
      setTimeout(() => navigate("/login"));
    } catch (e) {
      setIsLoading(false);
      if (e instanceof Error) {
        sendFeedback({
          type: "error",
          message: e.message
        });
      }
    }
  }, [auth, code, navigate, password, sendFeedback]);

  const verification = useAsync(async () => {
    try {
      const email = await auth.verifyResetPasswordCode(code);
      const providers = await auth.fetchSignInMethodsForEmail(email);

      return { email, providers };
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
      sendFeedback({
        type: "error",
        message: "Failed to verify code"
      });
    }
  }, []);

  const resetPasswordCard = (
    <Box align="center" gap="large" width="medium">
      <Button href="https://theretirementtracker.com" target="_blank">
        <LogoFull color="plain" size="large" />
      </Button>
      <Box gap="large" width="medium">
        {(verification.value?.providers?.length ?? 0) > 0 && (
          <Box
            align="center"
            pad="medium"
            border={{ side: "all", color: "status-warning" }}
            round="small"
            responsive={false}
          >
            <Text textAlign="center">
              This user is currently linked with a Google account, updating the
              password will unlink and transform into a password account.
            </Text>
          </Box>
        )}
        {error && (
          <Box align="center">
            <Text color="status-critical" textAlign="center">
              {error}
            </Text>
          </Box>
        )}
        <FormField label="Username">
          <TextInput
            type="email"
            name="email"
            placeholder="username"
            value={verification.value?.email || ""}
            readOnly
          />
        </FormField>
        <FormField label="New Password">
          <TextInput
            name="password"
            type="password"
            placeholder="password"
            onChange={(event) => onChangeHandler(event)}
          />
        </FormField>

        <Box direction="row" align="center" justify="center" gap="small">
          <LoadingButton
            type="submit"
            primary
            disabled={!verification.value?.email}
            label="Reset Password"
            onClick={resetPassword}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );

  return size === "small" ? (
    <Box fill align="center" justify="center">
      {resetPasswordCard}
    </Box>
  ) : (
    <Box
      fill
      align="center"
      justify="center"
      style={{
        background:
          "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.3), rgba(255,255,255,0.1)), url(/lake.jpg) no-repeat center center",
        backgroundSize: "cover"
      }}
    >
      <Box
        round="small"
        pad="large"
        elevation="large"
        align="center"
        gap="small"
        background={{ color: "white", opacity: "strong" }}
      >
        {resetPasswordCard}
      </Box>
    </Box>
  );
};
