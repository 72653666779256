import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import {
  Accordion,
  AccordionPanel,
  Box,
  Heading,
  InfiniteScroll,
  Meter,
  ResponsiveContext,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text
} from "grommet";
import { orderBy } from "lodash";

import {
  Account,
  ActivePeriodProps,
  ActivityPosition,
  BalanceChange,
  HoldingSnapshot,
  Institution
} from "../types";
import {
  getAccountName,
  getActivePeriodLabel,
  getNumberTextColor,
  NumberText
} from "../utils";

import {
  DashboardCard,
  getActivityAmountLabel,
  InstitutionHoldingsLogo,
  InstitutionLogo,
  HoldingBadge
} from ".";
import {
  GroupedActivity,
  GroupedDepositsAndFees,
  GroupedHolding,
  useAppState,
  useSnapshot
} from "../hooks";

export interface BalanceRowProps {
  institution: Institution;
  account: Account;
  balance: BalanceChange;
}

export interface HoldingRowProps {
  holding: HoldingSnapshot;
}

interface HoldingPanelProps {
  groupedHolding: GroupedHolding;
}

interface HoldingPanelBodyProps {
  holdings: HoldingSnapshot[];
}

const TICKER_LOGO_URL = process.env.REACT_APP_TICKER_LOGO_URL;
const TICKER_LOGO_URL_FALLBACK = process.env.REACT_APP_TICKER_LOGO_URL_FALLBACK;

const BalanceRow = ({ institution, account, balance }: BalanceRowProps) => {
  const accountName = getAccountName(account);
  return (
    <TableRow>
      <TableCell>
        <Box align="center">
          <InstitutionLogo size="24px" institution={institution} />
          <Text
            size="small"
            style={{ maxWidth: "80px" }}
            truncate
            title={accountName}
          >
            {accountName}
          </Text>
        </Box>
      </TableCell>
      <TableCell>
        <NumberText value={balance.from} type="currency" />
      </TableCell>
      <TableCell>
        <NumberText value={balance.to} type="currency" />
      </TableCell>
      <TableCell>
        <Box>
          <NumberText
            value={balance.change.value}
            type="currency"
            color={getNumberTextColor(balance.change.value)}
          />
          <NumberText
            value={balance.change.percentage}
            type="percentage"
            color={getNumberTextColor(balance.change.value)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

const BalanceCard = ({ activePeriod }: ActivePeriodProps) => {
  const size = useContext(ResponsiveContext);
  const { total, accountSnapshots } = useSnapshot(activePeriod);
  return (
    <DashboardCard
      title="Balance"
      description={`How your accounts performed this ${getActivePeriodLabel(
        activePeriod
      )}`}
      highlight={
        <Box align="end" margin={{ right: "small" }} responsive={false}>
          <NumberText
            value={total?.investmentBalance?.to || 0}
            type="currency"
            weight="bold"
          />
          <NumberText
            color={getNumberTextColor(
              total?.investmentBalance?.change?.value || 0
            )}
            value={{
              value: total?.investmentBalance?.change?.value || 0,
              percentage: total?.investmentBalance?.change?.percentage || 0
            }}
            size={size !== "large" ? "small" : undefined}
          />
        </Box>
      }
    >
      <Box flex overflow="auto">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell />
              <TableCell>
                <Heading level={5} margin="none">
                  From
                </Heading>
              </TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  To
                </Heading>
              </TableCell>
              <TableCell>
                <Heading level={5} margin="none">
                  Change
                </Heading>
              </TableCell>
            </TableRow>
            {accountSnapshots.map(({ institution, account, snapshot }) => (
              <BalanceRow
                key={`balance_${institution.id}_${account.id}`}
                institution={institution}
                account={account}
                balance={snapshot.balance}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </DashboardCard>
  );
};

const HoldingRow = ({ holding }: HoldingRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <HoldingBadge
          optionDetail={holding.optionDetail}
          type={holding.type}
          quantity={holding.quantity}
        />
      </TableCell>
      <TableCell>
        <Box>
          <Text size="small" color="text-xweak">
            from{" "}
            <NumberText
              color="text-weak"
              value={Math.abs(holding.costBasis)}
              weight="bold"
            />
          </Text>
          <Text size="small" color="text-xweak">
            to{" "}
            <NumberText
              color="text-weak"
              value={Math.abs(holding.currentValue)}
              weight="bold"
            />
          </Text>
          <NumberText
            color={getNumberTextColor(holding.currentPercentage || 0)}
            value={{
              value: holding.currentValue - holding.costBasis,
              percentage: holding.currentPercentage || 0
            }}
            size="small"
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box align="end">
          <Text size="small" color="text-xweak">
            from{" "}
            <NumberText
              color="text-weak"
              value={Math.abs(holding.balance?.from ?? 0)}
              weight="bold"
            />
          </Text>
          <Text size="small" color="text-xweak">
            to{" "}
            <NumberText
              color="text-weak"
              value={Math.abs(holding.balance?.to ?? 0)}
              weight="bold"
            />
          </Text>
          <NumberText
            color={getNumberTextColor(holding.balance?.change.percentage ?? 0)}
            value={holding.balance?.change || 0}
            size="small"
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

const HoldingPanelBody = ({
  activePeriod,
  holdings
}: HoldingPanelBodyProps & ActivePeriodProps) => {
  // @ts-ignore
  return (
    <Box border="top">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Heading level={5} margin="none">
                Position
              </Heading>
            </TableCell>
            <TableCell>
              <Heading level={5} margin="none">
                Total
              </Heading>
            </TableCell>
            <TableCell>
              <Box align="end">
                <Heading level={5} margin="none">
                  {getActivePeriodLabel(activePeriod)}
                </Heading>
              </Box>
            </TableCell>
          </TableRow>
          {holdings.map((holding) => (
            <HoldingRow key={holding.id} holding={holding} />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const HoldingPanel = ({
  activePeriod,
  groupedHolding: {
    weight,
    institution,
    tickerSymbol,
    totalBalance,
    activePeriodBalance,
    holdings
  }
}: HoldingPanelProps & ActivePeriodProps) => {
  const holdingPanelHeader = (
    <Box
      flex
      pad={{ vertical: "small", left: "medium" }}
      direction="row"
      justify="between"
      align="center"
      responsive={false}
    >
      <Box direction="row" align="center" gap="small" responsive={false}>
        <InstitutionHoldingsLogo
          size="38px"
          institution={institution}
          holding={{
            name: tickerSymbol,
            url: TICKER_LOGO_URL?.replace("#TICKER#", tickerSymbol),
            fallBackUrl: `${TICKER_LOGO_URL_FALLBACK}?tickerSymbol=${tickerSymbol}`
          }}
        />
        <Box align="start">
          <Heading margin="none" level={4}>
            <b>{tickerSymbol}</b>
          </Heading>
          <Box margin={{ top: "xsmall" }} gap="1px">
            <Meter
              type="bar"
              size="xsmall"
              thickness="xsmall"
              value={weight}
              max={100}
              round
              color="meter"
              background="meter-background"
            />
            <Text size="small" color="text-weak">
              <NumberText
                size="small"
                value={weight}
                type="percentage"
                weight="bold"
              />{" "}
              weight
            </Text>
          </Box>
        </Box>
      </Box>
      <Box direction="row" gap="small" align="center" responsive={false}>
        <Box align="center" width="60px">
          <NumberText
            color={getNumberTextColor(totalBalance.change.percentage || 0)}
            value={totalBalance.change.percentage || 0}
            type="percentage"
          />
          <Text size="small" color="text-xweak">
            since <b>open</b>
          </Text>
        </Box>
        <Box align="center" width="60px">
          <NumberText
            color={getNumberTextColor(
              activePeriodBalance.change.percentage || 0
            )}
            value={activePeriodBalance.change.percentage || 0}
            type="percentage"
          />
          <Text size="small" color="text-xweak">
            this <b>{getActivePeriodLabel(activePeriod)}</b>
          </Text>
        </Box>
      </Box>
    </Box>
  );
  return (
    <AccordionPanel label={holdingPanelHeader}>
      <HoldingPanelBody activePeriod={activePeriod} holdings={holdings} />
    </AccordionPanel>
  );
};

interface TransactionRowProps {
  transaction: GroupedDepositsAndFees;
  hideTransactionType?: boolean;
}

interface InstitutionAccountDetailHeaderProps {
  institution: Institution;
  account: Account;
  tickerSymbol?: string;
  detail?: string | ReactElement;
}

const InstitutionAccountDetailHeader = ({
  institution,
  account,
  tickerSymbol,
  detail
}: InstitutionAccountDetailHeaderProps) => {
  const size = useContext(ResponsiveContext);
  const elements = [];
  if (tickerSymbol) {
    elements.push(<b key="ticketSymbol-header">{tickerSymbol}</b>);
  }
  if (detail) {
    elements.push(<span key="detail">{detail}</span>);
  }
  return (
    <Box
      direction="row"
      align="center"
      gap="small"
      responsive={false}
      flex={false}
      pad={{ left: "small", vertical: "xsmall" }}
    >
      {size === "large" && (
        <Box width="72px" align="center" justify="center">
          {tickerSymbol ? (
            <InstitutionHoldingsLogo
              size="38px"
              institution={institution}
              holding={{
                name: tickerSymbol,
                url: TICKER_LOGO_URL?.replace("#TICKER#", tickerSymbol),
                fallBackUrl: `${TICKER_LOGO_URL_FALLBACK}?tickerSymbol=${tickerSymbol}`
              }}
            />
          ) : (
            <InstitutionLogo institution={institution} />
          )}
        </Box>
      )}
      <Box flex>
        <Text size="small" color="text-xweak">
          {institution.name} - {getAccountName(account)}
        </Text>
        <Text
          size="small"
          title={typeof detail === "string" ? detail : undefined}
          style={{ maxWidth: "100px" }}
          truncate
        >
          {elements.reduce((textNode, element, index) => {
            if (index === elements.length - 1) {
              textNode = [...textNode, element];
            } else {
              textNode = [...textNode, element, <span key="divider"> - </span>];
            }
            return textNode;
          }, [] as ReactElement[])}
        </Text>
      </Box>
    </Box>
  );
};

const TransactionRow = ({
  transaction: { transaction, institution, account },
  hideTransactionType = false
}: TransactionRowProps) => (
  <TableRow>
    <TableCell>
      <InstitutionAccountDetailHeader
        institution={institution}
        account={account}
        detail={transaction.detail}
        tickerSymbol={transaction.tickerSymbol}
      />
    </TableCell>
    <TableCell>
      <Box>
        <NumberText
          color="text-weak"
          weight="bold"
          value={transaction.amount}
        />
        {!hideTransactionType && (
          <Text color="text-xweak" size="small">
            {transaction.dividend ? "dividends" : transaction.type}
          </Text>
        )}
      </Box>
    </TableCell>
    <TableCell>
      <Text color="text-weak" size="small">
        {transaction.date}
      </Text>
    </TableCell>
  </TableRow>
);

const DepositsCard = ({ activePeriod }: ActivePeriodProps) => {
  const { deposits } = useSnapshot(activePeriod);
  return (
    <DashboardCard
      title="Deposits"
      description={`New money in your investment accounts this ${getActivePeriodLabel(
        activePeriod
      )}`}
      highlight={
        <Box align="end" margin={{ right: "small" }} responsive={false}>
          <NumberText
            value={deposits.total.cash + deposits.total.dividends}
            weight="bold"
          />
          <Text color="text-xweak" size="small">
            <b>total</b> deposits
          </Text>
        </Box>
      }
    >
      <Box flex>
        <Box
          align="center"
          justify="center"
          height="72px"
          direction="row"
          gap="large"
          responsive={false}
          border="bottom"
        >
          <Box align="center" justify="center" responsive={false}>
            <NumberText
              color="text"
              size="large"
              value={deposits.total.cash}
              weight="bold"
            />
            <Text color="text-weak" size="small">
              <b>deposits</b> made
            </Text>
          </Box>
          <Box align="center" justify="center" responsive={false}>
            <NumberText
              color="text"
              size="large"
              value={deposits.total.dividends}
              weight="bold"
            />
            <Text color="text-weak" size="small">
              <b>dividends</b> received
            </Text>
          </Box>
        </Box>
        <Box flex overflow="auto">
          {deposits.transactions.length ? (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Heading level={5} margin="none">
                      WHAT
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading level={5} margin="none">
                      WHEN
                    </Heading>
                  </TableCell>
                </TableRow>
                <InfiniteScroll items={deposits.transactions}>
                  {(transaction: GroupedDepositsAndFees) => (
                    <TransactionRow
                      key={`deposit_row_${transaction.transaction.id}`}
                      transaction={transaction}
                    />
                  )}
                </InfiniteScroll>
              </TableBody>
            </Table>
          ) : (
            <Box flex align="center" justify="center">
              <Text color="text-weak" textAlign="center">
                No deposit this {getActivePeriodLabel(activePeriod)} yet
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </DashboardCard>
  );
};

interface ActivityRowProps {
  activity: GroupedActivity;
}

const ActivityRow = ({
  activity: { activity, institution, account }
}: ActivityRowProps) => (
  <TableRow>
    <TableCell>
      <InstitutionAccountDetailHeader
        institution={institution}
        account={account}
        tickerSymbol={activity.tickerSymbol || activity.detail}
      />
    </TableCell>
    <TableCell>
      <Box gap="xsmall" responsive={false}>
        <HoldingBadge
          optionDetail={activity.optionDetail}
          type={activity.type}
          quantity={activity.quantity}
        />
        <Box direction="row" align="center" gap="small">
          <Box
            background={
              activity.position === ActivityPosition.OPEN
                ? "status-ok"
                : "status-critical"
            }
            round="small"
            width="40px"
            align="center"
          >
            <Text color="white" size="small">
              {activity.position}
            </Text>
          </Box>
          <Box
            direction="row"
            align="center"
            gap="xsmall"
            responsive={false}
            width="72px"
          >
            <NumberText
              size="small"
              value={Math.abs(Number(activity.totalPrice))}
              weight="bold"
            />
            <Text size="small" color="text-xweak">
              {getActivityAmountLabel(activity)?.toLowerCase()}
            </Text>
          </Box>
        </Box>
      </Box>
    </TableCell>
    <TableCell>
      <Text color="text-weak" size="small">
        {activity.date}
      </Text>
    </TableCell>
  </TableRow>
);

const ActivityCard = ({ activePeriod }: ActivePeriodProps) => {
  const { activity } = useSnapshot(activePeriod);
  return (
    <DashboardCard
      title="Activity"
      description={`Your trades this ${getActivePeriodLabel(activePeriod)}`}
      highlight={
        <Box align="end" margin={{ right: "small" }} responsive={false}>
          <Text color="text-weak" weight="bold">
            {activity.length}
          </Text>
          <Text color="text-xweak" size="small">
            trades
          </Text>
        </Box>
      }
    >
      <Box flex overflow="auto">
        {activity.length ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    WHAT
                  </Heading>
                </TableCell>
                <TableCell>
                  <Heading level={5} margin="none">
                    WHEN
                  </Heading>
                </TableCell>
              </TableRow>
              <InfiniteScroll items={activity}>
                {(currentActivity: GroupedActivity) => (
                  <ActivityRow
                    key={`activity_row_${currentActivity.activity.id}`}
                    activity={currentActivity}
                  />
                )}
              </InfiniteScroll>
            </TableBody>
          </Table>
        ) : (
          <Box align="center" justify="center" flex>
            <Text color="text-weak" textAlign="center">
              No activity this {getActivePeriodLabel(activePeriod)} yet
            </Text>
          </Box>
        )}
      </Box>
    </DashboardCard>
  );
};

const FeesCard = ({ activePeriod }: ActivePeriodProps) => {
  const { fees } = useSnapshot(activePeriod);
  const { institutions } = useAppState();
  const { totalFees, totalCards } = useMemo(() => {
    const totalKeys = Object.keys(fees.total);

    const totalFees = totalKeys.reduce((total, institutionTotalKey) => {
      total += fees.total[institutionTotalKey];
      return total;
    }, 0);

    if (totalKeys.length <= 1) {
      return { totalFees, totalCards: undefined, show: false };
    }

    const totalCards = totalKeys.slice(0, 2).map((institutionId) => {
      const institution = institutions.find(
        (institution) => institution.id === institutionId
      );
      return (
        <Box
          align="center"
          justify="center"
          responsive={false}
          key={`fee_total_${institutionId}`}
        >
          <NumberText
            color="text"
            size="large"
            value={fees.total[institutionId]}
            weight="bold"
          />
          <Text color="text-weak" size="small">
            {institution?.name}
          </Text>
        </Box>
      );
    });

    return { totalCards, totalFees };
  }, [fees.total, institutions]);
  return (
    <DashboardCard
      title="Fees"
      description={`Money you paid to brokers this ${getActivePeriodLabel(
        activePeriod
      )}`}
      highlight={
        <Box align="end" margin={{ right: "small" }} responsive={false}>
          <NumberText value={totalFees} weight="bold" />
          <Text color="text-xweak" size="small">
            <b>total</b> fees
          </Text>
        </Box>
      }
    >
      <Box flex>
        {totalCards && (
          <Box
            align="center"
            justify="center"
            height="72px"
            direction="row"
            gap="large"
            responsive={false}
            border="bottom"
          >
            {totalCards}
          </Box>
        )}
        <Box flex overflow="auto">
          {fees.transactions.length ? (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    <Heading level={5} margin="none">
                      WHAT
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading level={5} margin="none">
                      WHEN
                    </Heading>
                  </TableCell>
                </TableRow>
                <InfiniteScroll items={fees.transactions}>
                  {(transaction: GroupedDepositsAndFees) => (
                    <TransactionRow
                      key={`fee_row_${transaction.transaction.id}`}
                      transaction={transaction}
                      hideTransactionType={true}
                    />
                  )}
                </InfiniteScroll>
              </TableBody>
            </Table>
          ) : (
            <Box flex align="center" justify="center">
              <Text color="text-weak" textAlign="center">
                No fees this {getActivePeriodLabel(activePeriod)} yet
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </DashboardCard>
  );
};

const HoldingsCard = ({ activePeriod }: ActivePeriodProps) => {
  const { groupedHoldings } = useSnapshot(activePeriod);

  const holdingsSortOptions = useMemo(
    () => [
      {
        label: "Since open",
        value: "sinceOpen"
      },
      {
        label: `This ${getActivePeriodLabel(activePeriod)}`,
        value: "activePeriod"
      },
      {
        label: "Weight",
        value: "weight"
      }
    ],
    [activePeriod]
  );

  const [holdingsOrderBy, setHoldingsOrderBy] = useState(
    holdingsSortOptions[1]
  );

  const onHoldingsOrderChange = useCallback(
    ({ option }) => setHoldingsOrderBy(option),
    []
  );

  const orderByOption = useMemo(() => {
    if (holdingsOrderBy.value === "activePeriod") {
      return [
        "activePeriodBalance.change.percentage",
        "totalBalance.change.percentage"
      ];
    }
    if (holdingsOrderBy.value === "weight") {
      return ["weight", "totalBalance.change.percentage"];
    }
    return [
      "totalBalance.change.percentage",
      "activePeriodBalance.change.percentage"
    ];
  }, [holdingsOrderBy]);

  const orderGroupedHolding = useMemo(
    () => orderBy(groupedHoldings, orderByOption, ["desc", "desc"]),
    [groupedHoldings, orderByOption]
  );

  useEffect(
    () => setHoldingsOrderBy(holdingsSortOptions[1]),
    [activePeriod, holdingsSortOptions]
  );

  return (
    <DashboardCard
      title="Holdings"
      description={`${orderGroupedHolding.length} unique holdings`}
      highlight={
        <Box
          align="end"
          gap="xsmall"
          width="120px"
          margin={{ right: "xsmall" }}
        >
          <Text size="small" color="text-weak">
            Sort by
          </Text>
          <Select
            size="small"
            options={holdingsSortOptions}
            value={holdingsOrderBy}
            onChange={onHoldingsOrderChange}
            labelKey="label"
            valueKey="value"
            valueLabel={
              <Text
                size="small"
                weight="bold"
                margin={{ left: "small", vertical: "xsmall" }}
              >
                {holdingsOrderBy.label}
              </Text>
            }
          />
        </Box>
      }
    >
      <Box flex overflow={{ vertical: "auto", horizontal: "hidden" }}>
        <Accordion>
          {orderGroupedHolding.map((groupedHolding) => (
            <HoldingPanel
              key={groupedHolding.id}
              activePeriod={activePeriod}
              groupedHolding={groupedHolding}
            />
          ))}
        </Accordion>
      </Box>
    </DashboardCard>
  );
};

export const InvestmentsTab = ({ activePeriod }: ActivePeriodProps) => (
  <Box
    direction="row"
    wrap
    width="100%"
    flex
    align="center"
    justify="center"
    responsive={false}
    pad={{ top: "small", bottom: "medium" }}
    style={{ maxWidth: "1600px" }}
  >
    <BalanceCard activePeriod={activePeriod} />
    <HoldingsCard activePeriod={activePeriod} />
    <DepositsCard activePeriod={activePeriod} />
    <FeesCard activePeriod={activePeriod} />
    <ActivityCard activePeriod={activePeriod} />
  </Box>
);
