import React from "react";
import { Box, Text } from "grommet";

import { InstitutionLogo } from "./";
import { Institution, Account } from "../types";
import { getAccountName } from "../utils";

export const InstitutionAccountOption = ({
  institution,
  account
}: {
  institution: Institution;
  account: Account;
}) => {
  const accountName = getAccountName(account);
  return (
    <Box
      direction="row"
      align="center"
      gap="small"
      pad="xsmall"
      responsive={false}
    >
      <InstitutionLogo size="24px" institution={institution} />
      <Text
        size="small"
        style={{ maxWidth: "80px" }}
        truncate
        title={accountName}
      >
        {accountName}
      </Text>
    </Box>
  );
};
