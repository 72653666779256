import { InvestmentGroupType } from "../../types";

export const validateInvestmentGroup = (
  investmentGroup: InvestmentGroupType
) => {
  if (!investmentGroup.name) {
    return "You need to enter a group name";
  }
  return false;
};
