import { v4 as uuidv4 } from "uuid";
import {
  Account,
  AccountType,
  Institution,
  InstitutionInsight,
  InstitutionStatus,
  InstitutionType
} from "../types";

export const generatePlaceholderAccount = (i: number) => ({
  id: uuidv4(),
  balance: 0,
  name: `Account ${i}`,
  type: "checking" as AccountType
});

export const getDefaultInstitution = () => ({
  accessToken: "",
  id: "",
  institutionId: "",
  name: "",
  type: "manual" as InstitutionType,
  accounts: [
    {
      balance: 0,
      id: uuidv4(),
      name: "Account 1",
      type: "checking" as AccountType
    }
  ],
  visibleAccounts: [],
  netWorth: 0,
  processing: false,
  status: "ok" as InstitutionStatus
});

export const validateInstitution = (institution: Institution) => {
  if (!institution.id) {
    return "You need to select a valid institution";
  }
  const hasMissingAccountName = institution.accounts.some(({ name }) => !name);
  if (hasMissingAccountName) {
    return "Accounts need to have a name";
  }
  return undefined;
};

export const findAccountInsideInstitutions = (
  institutions: InstitutionInsight,
  accountId: string
): Account | undefined => {
  let account = undefined;
  Object.keys(institutions).some((institutionKey) => {
    const institutionAccount = institutions[institutionKey].accounts[accountId];
    if (institutionAccount) {
      account = institutionAccount;
      return true;
    }
    return false;
  });
  return account;
};
