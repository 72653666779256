import React, { useCallback, useContext, useState } from "react";
import Confetti from "react-confetti";
import { Box, Button, Heading, ResponsiveContext } from "grommet";

import { OnboardingLayout, OnboardingHeading } from "./";
import {
  useAddPlaidInstitution,
  useAppState,
  useNavigation,
  useStepChangeContext
} from "../../hooks";
import { useFirebaseContext } from "../../firebase";

export const OnboardingAddInstitution = () => {
  const { user, token } = useAppState();
  const { db } = useFirebaseContext();
  const { navigate } = useNavigation();
  const [, onStepChange] = useStepChangeContext();
  const [showConfetti, setShowConfetti] = useState(true);
  const size = useContext(ResponsiveContext);

  const onRemoveConfetti = useCallback(() => setShowConfetti(false), []);

  const onExit = useCallback(
    async (success: boolean, institutionId?: string) => {
      const inviteRef = await db.getInvestmentGroupInviteByEmail(user?.email);

      // we use the first invite we find
      const inviteId = inviteRef.docs?.[0]?.id;
      if (success) {
        if (inviteId) {
          navigate(`/join/${inviteId}`);
        } else if (size === "small") {
          navigate("/institutions");
        } else {
          navigate(
            `/dashboard?activePanel=2&activeInstitution=${institutionId ?? ""}`
          );
        }
        onStepChange("completed", user!);
      }
    },
    [navigate, onStepChange, size, user, db]
  );

  const { open, ready } = useAddPlaidInstitution({ user, onExit, token });

  return (
    <>
      {showConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={1000}
          gravity={0.2}
          onConfettiComplete={onRemoveConfetti}
        />
      )}
      <OnboardingLayout>
        <OnboardingHeading
          heading="Congratulations"
          description="You have made it! Would like to add your first automated institution now?"
        />
        <Box
          margin={{ vertical: "medium" }}
          gap="medium"
          responsive={false}
          align="center"
        >
          <Button
            size={size !== "small" ? "large" : undefined}
            primary
            disabled={!ready}
            label="Sure, add one now"
            onClick={() => {
              open();
            }}
          />
          <Heading level={3} margin="none">
            or
          </Heading>
          <Button
            size={size !== "small" ? "large" : undefined}
            label="No, I will add later"
            onClick={() => {
              onExit(true);
            }}
          />
        </Box>
      </OnboardingLayout>
    </>
  );
};
