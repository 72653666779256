import React, { useContext } from "react";
import { Anchor, Box, ResponsiveContext, Text } from "grommet";

import { LogoFull, UserAvatar } from "../index";
import { useLoggedUser } from "../../firebase";
import { useAppState } from "../../hooks";

export const OnboardingHeader = () => {
  const size = useContext(ResponsiveContext);
  const { signOut } = useLoggedUser();
  const { user } = useAppState();
  return (
    <Box
      tag="header"
      direction="row"
      align="center"
      justify="between"
      pad={{ horizontal: "medium", vertical: "18px" }}
      border="bottom"
    >
      <LogoFull color="logo" size={size === "small" ? "small" : undefined} />
      <Box direction="row" align="center" justify="center" gap="small">
        <Text>Not You?</Text>
        <Anchor href="#" onClick={(e) => {
          e.preventDefault();
          signOut();
        }} label="Sign out" />
        <UserAvatar user={user} showName={false} />
      </Box>
    </Box>
  );
};
