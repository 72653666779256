import React from "react";

import { AdminLayout } from "../../layouts";
import { BalanceUpdateBody } from "./body";

export const BalanceUpdateDesktop = () => {
  return (
    <AdminLayout
      title="Balance Update"
      subTitle="Investigate possible issues while updating balance for a specific user"
    >
      <BalanceUpdateBody />
    </AdminLayout>
  );
};
