import { useState, useCallback, useContext } from "react";
import {
  Box,
  Heading,
  RangeInput,
  ResponsiveContext,
  Text,
  TextInput
} from "grommet";
import Skeleton from "react-loading-skeleton";
import numeral from "numeral";
import { useDebounce, useSetState } from "react-use";

import { RestimateFullPlan } from "./restimateFullPlan";
import { useLoggedUser } from "../firebase";
import { useAppState, useFirebaseFunction } from "../hooks";
import { User } from "../types";
import { FeedbackContext } from "./feedback";

import { CallToAction, CurrencyInput, LoadingButton } from ".";

export const RetirementEstimator = () => {
  const size = useContext(ResponsiveContext);
  const { sendFeedback } = useContext(FeedbackContext);

  const {
    user,
    updateUser,
    restimate: savedRestimate,
    setRestimate
  } = useAppState();
  const { loading } = useLoggedUser();

  const { retireIn = 10, retireWith = 15000 } = user || {};

  const [userState, setUserState] = useSetState({
    retireIn,
    retireWith
  });

  const restimate = savedRestimate || {
    projections: { weekly: [], monthly: [], yearly: [] },
    weekRate: 0,
    monthRate: 0,
    yearRate: 0
  };

  const [showFullPlan, setShowFullPlan] = useState(false);
  const onCloseFullPlan = useCallback(() => setShowFullPlan(false), []);
  const onShowFullPlan = useCallback(() => setShowFullPlan(true), []);

  const [savingRestimate, setSavingRestimate] = useState(false);
  const calculateGoals = useFirebaseFunction({ fnName: "calculateGoals" });

  useDebounce(
    () => {
      const saveData = async () => {
        setSavingRestimate(true);

        const response = await calculateGoals({
          userId: user?.uid,
          retireIn: userState.retireIn,
          retireWith: userState.retireWith
        });

        if (response.data) {
          const {
            weekRate,
            monthRate,
            yearRate,
            weekProjections,
            monthProjections,
            yearProjections
          } = response.data;
          setRestimate({
            projections: {
              weekly: weekProjections,
              monthly: monthProjections,
              yearly: yearProjections
            },
            weekRate: weekRate || 0,
            monthRate: monthRate || 0,
            yearRate: yearRate || 0
          });

          updateUser({
            ...(user as User),
            ...{
              ...userState
            }
          });
        } else {
          sendFeedback({
            type: "error",
            message: "Error saving new goals"
          });
        }

        setSavingRestimate(false);
      };

      if (
        !loading &&
        !savingRestimate &&
        (user?.retireIn !== userState?.retireIn ||
          user?.retireWith !== userState?.retireWith)
      ) {
        saveData();
      }
    },
    1000,
    [userState]
  );

  const fontSize = size === "small" ? "small" : undefined;
  return (
    <>
      <Box>
        <table>
          <tbody>
            {loading && (
              <>
                <tr>
                  <td width="385px">
                    <Skeleton height="70px" />
                  </td>
                </tr>
                <tr>
                  <td width="385px">
                    <Skeleton height="70px" />
                  </td>
                </tr>
                <tr>
                  <td width="385px">
                    <Skeleton height="70px" />
                  </td>
                </tr>
              </>
            )}
            {!loading && (
              <>
                <tr>
                  <td align="right">
                    <Text
                      weight="bold"
                      style={{ whiteSpace: "nowrap" }}
                      margin="none"
                      size={size}
                    >
                      Retire in
                    </Text>
                  </td>
                  <td>
                    <Box width="small" margin={{ horizontal: "small" }}>
                      <RangeInput
                        min="5"
                        max="50"
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setUserState({ retireIn: Number(value) });
                        }}
                        value={userState.retireIn}
                      />
                    </Box>
                  </td>
                  <td>
                    <Box width="100px" pad={{ top: "small" }}>
                      {!loading && (
                        <TextInput
                          value={userState.retireIn}
                          onChange={(e) => {
                            const { value } = e.currentTarget;
                            setUserState({ retireIn: Number(value) });
                          }}
                          type="number"
                        />
                      )}
                      {loading && <Skeleton height="34px" />}
                      <Text>years</Text>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td align="right">
                    <Text
                      weight="bold"
                      style={{ whiteSpace: "nowrap" }}
                      margin="none"
                      size={size}
                    >
                      Retire With
                    </Text>
                  </td>
                  <td>
                    <Box width="small" margin={{ horizontal: "small" }}>
                      <RangeInput
                        min="0"
                        max="100000"
                        step={1000}
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setUserState({ retireWith: Number(value) });
                        }}
                        value={userState.retireWith}
                      />
                    </Box>
                  </td>
                  <td>
                    <Box width="100px" pad={{ top: "small" }}>
                      <CurrencyInput
                        value={userState.retireWith}
                        onChange={({ target: { value } }) =>
                          setUserState({ retireWith: Number(value) })
                        }
                      />
                      <Text>per month</Text>
                    </Box>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
        <Box
          margin={{ top: "medium" }}
          direction="row"
          align="center"
          justify="center"
        >
          <Box pad={{ right: "medium" }}>
            <Heading
              textAlign="center"
              color="status-ok"
              level={1}
              margin="none"
            >
              {numeral(userState.retireWith * 200).format("$0.0a")}
            </Heading>
            <Text
              size={fontSize}
              color="text-weak"
              textAlign="center"
              margin={{ top: "xxsmall" }}
            >
              Total <b>Net Worth</b> in {userState.retireIn} years
            </Text>
          </Box>
        </Box>
        <Box align="center" gap="large" margin={{ top: "medium" }}>
          <LoadingButton
            label="View Projections"
            margin={{ top: "medium" }}
            onClick={() => {
              onShowFullPlan();
            }}
          />

          <CallToAction
            href="https://medium.com/@theretirementtracker/all-about-restimate-f40c222330b4"
            text="Want learn more about Restimate?"
            linkText="Read this post"
            target="_blank"
          />
        </Box>
      </Box>
      {showFullPlan && (
        <RestimateFullPlan
          projectionsAndRates={restimate}
          onClose={onCloseFullPlan}
          isLoading={savingRestimate}
        />
      )}
    </>
  );
};
