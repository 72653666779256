export const addElementToArray = (
  array: any[],
  index: number,
  element: any
) => {
  return [...array.slice(0, index), element, ...array.slice(index)];
};

export const replaceElementInArray = (
  array: any[],
  index: number,
  element: any
) => {
  return [...array.slice(0, index), element, ...array.slice(index + 1)];
};
