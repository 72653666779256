import React from "react";

import { Properties } from "../components";

import { AppLayoutMobile } from "./layouts";

export const PropertiesMobile = () => (
  <AppLayoutMobile title="Properties">
    <Properties />
  </AppLayoutMobile>
);
