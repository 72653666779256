import React from "react";

import { InvestmentGroups } from "../components";

import { AppLayoutMobile } from "./layouts";

export const InvestmentGroupsMobile = () => (
  <AppLayoutMobile title="Investment Groups">
    <InvestmentGroups />
  </AppLayoutMobile>
);
