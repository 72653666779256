import { Box } from "grommet";

import { JoinInvestmentGroupBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const JoinInvestmentGroupMobile = () => (
  <AppLayoutMobile title="Join Investment Group">
    <Box flex overflow="auto" pad="small" responsive={false}>
      <JoinInvestmentGroupBody />
    </Box>
  </AppLayoutMobile>
);
