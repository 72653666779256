import { Box } from "grommet";

import { InvestmentGroupDetailsBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const InvestmentGroupDetailsMobile = () => (
  <AppLayoutMobile title="Investment Group">
    <Box flex>
      <InvestmentGroupDetailsBody />
    </Box>
  </AppLayoutMobile>
);
