import { InstitutionInsight, RealEstate, RealEstateType } from "../types";
import { v4 as uuidv4 } from "uuid";
import { findAccountInsideInstitutions } from "./institution";

export const defaultRealEstate = {
  address: "",
  type: "manual" as RealEstateType,
  loans: [],
  netWorth: 0,
  value: 0,
  alias: "",
  processing: false
};

export const getDefaultRealEstate = () =>
  ({
    ...defaultRealEstate,
    id: uuidv4()
  } as RealEstate);

export const validateRealEstate = (realEstate: RealEstate) => {
  if (!realEstate.address) {
    return "You need to enter a valid address";
  }
  if (!realEstate.value) {
    return "You need to enter an estimated value";
  }
  return undefined;
};

export const getRealEstateName = (realEstate: RealEstate): string =>
  realEstate?.alias || realEstate?.address;

export const getNewRealEstateNetWorth = (
  realEstate: RealEstate,
  institutions: InstitutionInsight
) => {
  if (!realEstate.loans || !realEstate.loans.length) {
    return realEstate.value;
  }
  const loansTotal = realEstate.loans.reduce((total, loanId) => {
    const loan = findAccountInsideInstitutions(institutions, loanId);
    return total + (loan?.balance! ?? 0);
  }, 0);
  return realEstate.value - loansTotal;
};
