import {
  format,
  getDay,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isWeekend,
  subDays
} from "date-fns";

export const startOfTodayInUTC = () => {
  const now = new Date();
  const nowInUTC = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  const base = format(nowInUTC, "yyyy-MM-dd");
  return new Date(`${base}T00:00:00Z`);
};

export const dayOfWeekInEnglish = (d: Date) => {
  const translation = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  return translation[getDay(d)] || "Unknown";
};

export const getPreviousFriday = (startDate: Date) => {
  let referenceDate = startDate;

  if (isSunday(referenceDate)) {
    return subDays(referenceDate, 9);
  }

  if (isSaturday(referenceDate)) {
    return subDays(referenceDate, 9);
  }

  if (isFriday(referenceDate)) {
    return subDays(referenceDate, 7);
  }

  while (!isFriday(referenceDate)) {
    referenceDate = subDays(referenceDate, 1);
    if (isWeekend(startDate)) {
      return subDays(referenceDate, 7);
    }
  }

  return referenceDate;
};

export const getPreviousMonday = (startDate: Date) => {
  let referenceDate = startDate;

  while (!isMonday(referenceDate)) {
    referenceDate = subDays(referenceDate, 1);
  }

  return referenceDate;
};
