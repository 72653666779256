import { v4 as uuidv4 } from "uuid";

import { getFirebaseFunction } from "../../firebase/utils";
import {
  AccountInsight,
  AccountInsights,
  ActivityPosition,
  ActivitySnapshot,
  HoldingSnapshot,
  HoldingsSnapshot,
  Insights,
  Institution,
  InstitutionInsight,
  QuoteMap,
  RealEstate,
  RealEstateInsight,
  UserSnapshots
} from "../../types";
import { getNewRealEstateNetWorth } from "../../utils";

const getHoldingsFromSnapshot = (holdingsSnapshots?: HoldingsSnapshot) =>
  holdingsSnapshots
    ? Object.keys(holdingsSnapshots).map((k) => holdingsSnapshots[k])
    : [];

export const getNewManualInsights = (
  institutions: Institution[],
  realEstate: RealEstate[],
  snapshots?: UserSnapshots
) => {
  const newManualInsights = institutions.reduce(
    (newManualInsights, institution) => {
      newManualInsights.institutions[institution.id] = {
        createdAt: institution.createdAt,
        accounts: institution.accounts.reduce(
          (newAccountManualInsights, account) => {
            newAccountManualInsights[account.id] = {
              balance: account.balance,
              type: account.customType || account.type,
              hidden: Boolean(account.hidden),
              activity: [],
              depositsAndFees: [],
              holdings: getHoldingsFromSnapshot(
                snapshots?.weekly?.data?.institutions[institution.id].accounts[
                  account.id
                ]?.holdings
              )
            };
            return newAccountManualInsights;
          },
          {} as AccountInsights
        )
      };
      return newManualInsights;
    },
    {
      institutions: {} as InstitutionInsight,
      realEstate: {} as RealEstateInsight
    }
  );

  realEstate.forEach((realEstate) => {
    newManualInsights.realEstate[realEstate.id] = {
      value: realEstate.value,
      netWorth: realEstate.netWorth
    };
  });

  return newManualInsights;
};

const findExistingHolding = (
  holdings: HoldingSnapshot[],
  activity: ActivitySnapshot
): HoldingSnapshot | undefined =>
  holdings.find(
    ({ quantity, tickerSymbol, type, optionDetail }) =>
      tickerSymbol === activity.tickerSymbol &&
      type === activity.type &&
      optionDetail?.expirationDate === activity.optionDetail?.expirationDate &&
      optionDetail?.strikePrice === activity.optionDetail?.strikePrice &&
      optionDetail?.optionType === activity.optionDetail?.optionType
  );

const createNewHoldingFromActivity = (
  activity: ActivitySnapshot,
  quotePrice: number | undefined | null
): HoldingSnapshot => ({
  id: uuidv4(),
  quantity: Number(activity.quantity),
  tickerSymbol: activity.tickerSymbol,
  type: activity.type,
  optionDetail: activity.optionDetail,
  currentValue: quotePrice
    ? Number(activity.quantity) * quotePrice
    : Number(activity.totalPrice),
  costBasis: Number(activity.totalPrice)
});

const getNewHoldingsFromAccountInsight = (
  accountInsight: AccountInsight,
  quoteMap: QuoteMap
) => {
  const newHoldings = [...(accountInsight.holdings ?? [])];

  const originalHoldingMap = new Map();

  accountInsight.activity?.forEach((activity) => {
    // we can't use the price for options contracts
    const shouldUsePrice = activity.type !== "derivative";
    const quotePrice = shouldUsePrice
      ? quoteMap[activity.tickerSymbol]
      : undefined;
    const existingHolding = findExistingHolding(newHoldings, activity);
    if (existingHolding && !originalHoldingMap.has(existingHolding.id)) {
      originalHoldingMap.set(existingHolding.id, { ...existingHolding });
    }
    if (activity.position === ActivityPosition.OPEN) {
      if (existingHolding) {
        existingHolding.quantity += Number(activity.quantity);
        existingHolding.costBasis += Number(activity.totalPrice);
        existingHolding.currentValue = quotePrice
          ? existingHolding.quantity * quotePrice
          : existingHolding.currentValue + Number(activity.totalPrice);
      } else {
        newHoldings.push(createNewHoldingFromActivity(activity, quotePrice));
      }
    } else if (
      activity.position === ActivityPosition.CLOSE &&
      existingHolding
    ) {
      if (
        Math.abs(existingHolding.quantity) < Math.abs(Number(activity.quantity))
      ) {
        const originalHolding = originalHoldingMap.get(
          existingHolding.id
        ) as HoldingSnapshot;
        existingHolding.quantity = originalHolding.quantity;
        existingHolding.costBasis = originalHolding.costBasis;
        existingHolding.currentValue = quotePrice
          ? existingHolding.quantity * quotePrice
          : originalHolding.currentValue;
        throw new Error(
          `You can only sell a quantity of ${originalHolding.quantity} for ${existingHolding.tickerSymbol}`
        );
      } else if (
        Math.abs(existingHolding.quantity) ===
        Math.abs(Number(activity.quantity))
      ) {
        newHoldings.splice(newHoldings.indexOf(existingHolding), 1);
      } else {
        const originalHolding = originalHoldingMap.get(
          existingHolding.id
        ) as HoldingSnapshot;
        const currentCostBasis =
          originalHolding.costBasis / originalHolding.quantity;
        existingHolding.quantity -= Number(activity.quantity);
        existingHolding.costBasis = existingHolding.quantity * currentCostBasis;
        existingHolding.currentValue = quotePrice
          ? existingHolding.quantity * quotePrice
          : existingHolding.currentValue - Number(activity.totalPrice);
      }
    } else {
      throw new Error(`You don't own ${activity.tickerSymbol} in this account`);
    }
  });

  return newHoldings;
};

export const getTickerSymbolsFromInsights = (
  institutionInsight: Insights,
  institutions: Institution[]
) =>
  Object.keys(institutionInsight.institutions).reduce(
    (tickerSymbols, institutionKey) => {
      const institution = institutions.find(({ id }) => id === institutionKey);
      if (institution?.type === "manual") {
        Object.keys(
          institutionInsight.institutions[institutionKey].accounts
        ).forEach((accountKey) => {
          const account =
            institutionInsight.institutions[institutionKey].accounts[
              accountKey
            ];
          account.activity?.forEach(({ tickerSymbol }) => {
            if (tickerSymbols.indexOf(tickerSymbol) < 0) {
              tickerSymbols.push(tickerSymbol);
            }
          });
          account.holdings?.forEach(({ tickerSymbol }) => {
            if (tickerSymbols.indexOf(tickerSymbol) < 0) {
              tickerSymbols.push(tickerSymbol);
            }
          });
        });
      }
      return tickerSymbols;
    },
    [] as string[]
  );

// make sure to convert balance to number
// performs field validation
export const validateManualInsights = async (
  insights: Insights,
  institutions: Institution[],
  realEstate: RealEstate[]
) => {
  const newInstitutionInsight = { ...insights };
  const tickerSymbols = getTickerSymbolsFromInsights(insights, institutions);
  let quoteMap = {};
  if (tickerSymbols.length) {
    const {
      data: { quoteMap: qMap, error }
    } = await getFirebaseFunction("fetchTickerSymbolQuote")({
      tickerSymbols
    });
    if (error) {
      console.error("Could not fetch quotes for activity", error);
    }
    quoteMap = qMap;
  }
  Object.keys(newInstitutionInsight.institutions).forEach((institutionKey) =>
    Object.keys(
      newInstitutionInsight.institutions[institutionKey].accounts
    ).forEach((accountKey) => {
      const accountInsight =
        newInstitutionInsight.institutions[institutionKey].accounts[accountKey];
      accountInsight.balance = Number(
        newInstitutionInsight.institutions[institutionKey].accounts[accountKey]
          .balance
      );
      if (
        accountInsight.depositsAndFees &&
        accountInsight.depositsAndFees.length
      ) {
        const hasMissingData = accountInsight.depositsAndFees.some(
          ({ detail, amount }) => !amount || !detail
        );
        if (hasMissingData) {
          throw new Error("Deposits & Fees: required fields missing");
        }
        accountInsight.depositsAndFees = accountInsight.depositsAndFees.map(
          ({ sequence, ...d }) => ({
            ...d,
            amount: Number(d.amount),
            dividend: Boolean(d.tickerSymbol) && d.type === "deposit"
          })
        );
      }

      if (accountInsight.activity && accountInsight.activity.length) {
        const hasMissingData = accountInsight.activity.some(
          ({ tickerSymbol, price, optionDetail }) => {
            let missing = !tickerSymbol || !price;
            if (optionDetail) {
              missing =
                missing ||
                !optionDetail.strikePrice ||
                !optionDetail.expirationDate;
            }
            return missing;
          }
        );
        if (hasMissingData) {
          throw new Error("Activity: required fields missing");
        }
        accountInsight.activity = accountInsight.activity.map(
          ({ sequence, ...a }) => {
            const activity = {
              ...a,
              price: Number(a.price),
              quantity: Number(a.quantity),
              totalPrice: Number(a.price) * Number(a.quantity)
            };
            if (activity.optionDetail) {
              activity.optionDetail.strikePrice = Number(
                activity.optionDetail.strikePrice
              );
            }
            activity.totalPrice = Number(a.quantity) * Number(a.price);
            activity.detail = `${activity.position} - ${activity.action} ${activity.quantity} ${activity.tickerSymbol} @ ${activity.totalPrice}`;
            return activity;
          }
        );

        accountInsight.holdings = getNewHoldingsFromAccountInsight(
          accountInsight,
          quoteMap
        );
      }
    })
  );

  Object.keys(newInstitutionInsight.realEstate).forEach((realEstateId) => {
    const matchingRealEstate = realEstate.find(({ id }) => id === realEstateId);
    if (matchingRealEstate) {
      matchingRealEstate.value = Number(
        newInstitutionInsight.realEstate[realEstateId].value
      );
      newInstitutionInsight.realEstate[realEstateId].netWorth =
        getNewRealEstateNetWorth(
          matchingRealEstate,
          newInstitutionInsight.institutions
        );
    }
    newInstitutionInsight.realEstate[realEstateId].value = Number(
      newInstitutionInsight.realEstate[realEstateId].value
    );
  });

  return newInstitutionInsight;
};
