import React from "react";
import { render } from "react-dom";
import Root from "./root";
import reportWebVitals from "./reportWebVitals";

// needed to support mobile without having to account for browser header on mobile
// see https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
}

window.addEventListener("resize", appHeight);
appHeight();

render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
