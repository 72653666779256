import { useAsync } from "react-use";
import { db } from "../firebase";
import { User } from "../types";

export const useUserActiveSubscription = (user?: User) => {
  const subscription = useAsync(async () => {
    if (user) {
      return await db.getUserSubscription(user.uid);
    }
  }, [user]);

  return subscription;
};
