import React from "react";

import { useNavigation } from "../../hooks";
import { AppLayout } from "../layouts";

import { UpdateRealEstateBody } from "./";

export const UpdateRealEstateDesktop = () => {
  const { onNavigateBack } = useNavigation();
  return (
    <AppLayout
      title="Update Real Estate"
      subTitle="Change your new property details and save"
      onClose={onNavigateBack}
    >
      <UpdateRealEstateBody />
    </AppLayout>
  );
};
