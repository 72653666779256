import { useAsync } from "react-use";
import { useAppState, useFirebaseFunction } from ".";
import { InvestmentGroupType } from "../types";
import {
  getInvestmentGroupFromCache,
  getInvestmentGroupKey,
  setInvestmentGroupInCache
} from "../cache";

interface UseInvestmentGroupProps {
  investmentGroupId: string;
}

export const useInvestmentGroup = ({
  investmentGroupId
}: UseInvestmentGroupProps) => {
  const { user } = useAppState();
  const investmentGroupKey = getInvestmentGroupKey(
    investmentGroupId,
    user?.uid ?? ""
  );
  const fetchInvestmentGroup = useFirebaseFunction({
    fnName: "fetchInvestmentGroup"
  });
  return useAsync<() => Promise<InvestmentGroupType>>(async () => {
    let data;
    try {
      data = getInvestmentGroupFromCache(investmentGroupKey);
      if (!data) {
        const { data: rData } = await fetchInvestmentGroup({
          investmentGroupId
        });
        setInvestmentGroupInCache(investmentGroupKey, rData);
        data = rData;
      }
    } catch (e) {
      // probably the data structure changed and the cache still has the old data
      // TODO: probably safe to remove this after awhile
      const { data: rData } = await fetchInvestmentGroup({ investmentGroupId });
      setInvestmentGroupInCache(investmentGroupKey, rData);
      data = rData;
    }
    return data;
  }, [investmentGroupId, investmentGroupKey]);
};
