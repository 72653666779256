import React, { useContext } from "react";
import { ResponsiveContext } from "grommet";

import { AddInstitutionDesktop, AddInstitutionMobile } from "./";

export const AddInstitution = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <AddInstitutionMobile />
  ) : (
    <AddInstitutionDesktop />
  );
};
