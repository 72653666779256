import React from "react";
import { Box, Button, Heading, Text } from "grommet";
import { Next } from "grommet-icons";
import Skeleton from "react-loading-skeleton";

type PlanCardButtonProps = {
  selected?: boolean;
  loading?: boolean;
  footer?: React.ReactNode;
  planBackground: string;
  planType: string;
  planPrice: string;
  planFeatures: {
    title: string | React.ReactNode;
    description: string;
  }[];
  planPricePeriod: string;
  onSelect: () => void;
};

export const PlanCardButton = ({
  selected,
  loading,
  footer,
  planBackground,
  planType,
  planPrice,
  planFeatures = [],
  planPricePeriod,
  onSelect
}: PlanCardButtonProps) => {
  return (
    <Box margin="small" responsive={false} flex={false}>
      <Box
        round="8px"
        border={{ side: "all", color: planBackground }}
        width="medium"
        responsive={false}
      >
        <Box
          gap="small"
          background={planBackground}
          round={{ size: "6px", corner: "top" }}
          direction="row"
          align="center"
          justify="between"
          pad={{ horizontal: "medium", vertical: "small" }}
          responsive={false}
        >
          {loading && <Skeleton height="40px" width="280px" />}
          {!loading && (
            <Heading level={3} margin="none" responsive={false}>
              <b>{planType}</b>
            </Heading>
          )}
          {!loading && (
            <Box align="end">
              <Text size="large" weight="bold">
                {planPrice}
              </Text>
              <Text size="small">{planPricePeriod}</Text>
            </Box>
          )}
        </Box>
        <Box flex pad="medium">
          {loading && <Skeleton height="25px" />}
          {!loading && (
            <Heading alignSelf="center" level={4} margin="none">
              Features
            </Heading>
          )}
          <Box
            flex
            align="start"
            pad={{ vertical: "small", horizontal: "medium" }}
            gap="medium"
            responsive={false}
          >
            {(planFeatures || []).map(({ title, description }, index) => (
              <Box
                key={index}
                direction="row"
                align="center"
                justify="center"
                gap="xsmall"
              >
                {loading && <Skeleton height="35px" width="280px" />}
                {!loading && (
                  <>
                    <Next size="small" color="selected" />
                    <Box align="start">
                      <Text textAlign="center">{title}</Text>
                      <Text size="small" color="text-xweak">
                        {description}
                      </Text>
                    </Box>
                  </>
                )}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          border="top"
          align="center"
          pad="small"
          justify="between"
          direction="row"
          responsive={false}
        >
          {loading && <Skeleton height="20px" width="300px" />}
          {!loading && (
            <>
              <Text>{footer}</Text>
              <Button
                style={{ visibility: selected ? "hidden" : "unset" }}
                onClick={onSelect}
                secondary
                size="small"
                label="Choose"
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
