import React from "react";
import { AppLayoutMobile, AppLayoutMobileProps } from "../../layouts";
import { AdminFooterMobile } from "../../../components";
import { useNavigation } from "../../../hooks";

export const AdminLayoutMobile = (props: AppLayoutMobileProps) => {
  const { onNavigate } = useNavigation();
  return (
    <AppLayoutMobile
      {...props}
      onLogoClick={onNavigate("/admin")}
      footer={<AdminFooterMobile />}
    />
  );
};
