import React, { useCallback, useState } from "react";
import { Box, BoxProps, Text } from "grommet";
import { Organization } from "grommet-icons";

interface PlaceholderProps {
  size: "large" | "small";
}

export const InstitutionPlaceholder = ({ size }: PlaceholderProps) => {
  const boxSize = size === "large" ? "96px" : "72px";
  return (
    <Box
      flex={false}
      background="light-2"
      align="center"
      justify="center"
      width={boxSize}
      height={boxSize}
      round="full"
    >
      <Organization
        color="neutral-3"
        size={size === "large" ? "48px" : undefined}
      />
    </Box>
  );
};

interface InstitutionLogoProps {
  institution: any;
  size?: any;
  style?: any;
}

export const InstitutionLogo = ({
  institution,
  size,
  ...rest
}: InstitutionLogoProps & BoxProps) => {
  const [showFallback, setShowFallback] = useState(0);
  const onShowFallback = useCallback(
    (e) => setShowFallback(showFallback + 1),
    [setShowFallback, showFallback]
  );
  if (!institution || !institution.name)
    return <InstitutionPlaceholder size={size} />;

  const { logo, name, url, fallBackUrl } = institution;
  const boxSize = size === "large" ? "96px" : size || "36px";
  const fontSize = size === "large" ? "21px" : "18px";

  const fallBackLimit = fallBackUrl ? 2 : 1;

  const fallbackBox = (
    <Box
      flex={false}
      background="light-2"
      align="center"
      justify="center"
      width={boxSize}
      height={boxSize}
      round="full"
      {...rest}
    >
      <Text size={fontSize} weight="bold" color="neutral-3">
        {name.charAt(0).toUpperCase()}
      </Text>
    </Box>
  );

  if (showFallback >= fallBackLimit) {
    return fallbackBox;
  }

  if (logo) {
    return (
      <Box
        flex={false}
        width={boxSize}
        align="center"
        justify="center"
        {...rest}
        style={{ ...rest.style, overflow: "hidden" }}
      >
        <img
          width="100%"
          alt={`${name} logo`}
          src={`data:image/png;base64, ${logo}`}
          onError={onShowFallback}
        />
      </Box>
    );
  }

  if (url) {
    return (
      <Box
        flex={false}
        width={boxSize}
        height={boxSize}
        align="center"
        justify="center"
        round="large"
        {...rest}
        style={{ ...rest.style, overflow: "hidden" }}
        background="light-3"
      >
        <img
          width={boxSize}
          alt={`${name} logo`}
          src={showFallback === 1 ? fallBackUrl : url}
          onError={onShowFallback}
          style={{ objectPosition: "center" }}
        />
      </Box>
    );
  }

  return fallbackBox;
};

interface InstitutionHoldingsLogoProps {
  institution: any;
  holding: any;
  size?: any;
  background?: string;
}

export const InstitutionHoldingsLogo = ({
  institution,
  holding,
  size,
  background = "card"
}: InstitutionHoldingsLogoProps) => {
  return (
    <Box direction="row" align="center">
      <InstitutionLogo
        institution={institution}
        size={size}
        border={{ side: "all", size: "2px", color: background }}
      />
      <InstitutionLogo
        institution={holding}
        size={size}
        margin={{ left: "-12px" }}
        border={{ side: "all", size: "2px", color: background }}
      />
    </Box>
  );
};
