import React from "react";
import { Box, Button, Heading, Image, Paragraph } from "grommet";
import { LogoFull } from "../components";
import { useNavigation } from "../hooks";

export const NotFound = () => {
  const { onNavigate } = useNavigation();
  return (
    <Box fill align="center" pad={{ top: "xlarge" }} background="light-4">
      <LogoFull color="logo" />
      <Heading
        level={2}
        margin={{ top: "large", bottom: "small" }}
        responsive={false}
      >
        <b>Not found</b>
      </Heading>
      <Paragraph color="text-weak" margin="none">
        The page you are looking for does not exist or is not available
      </Paragraph>
      <Image
        alt="not found"
        src="/not-found.svg"
        width="300px"
        margin={{ vertical: "large" }}
      />
      <Button
        primary
        label="Take me to safety"
        margin={{ top: "medium", bottom: "large" }}
        onClick={onNavigate("/")}
      />
    </Box>
  );
};
