import { Box } from "grommet";

import { AddInvestmentGroupBody } from "./";
import { AppLayoutMobile } from "../layouts";

export const AddInvestmentGroupMobile = () => (
  <AppLayoutMobile title="Create Investment Group">
    <Box flex overflow="auto" responsive={false}>
      <AddInvestmentGroupBody />
    </Box>
  </AppLayoutMobile>
);
