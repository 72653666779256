import { useCallback, useEffect, useMemo, useState } from "react";
import { getProjections } from "../firebase/db";
import {
  AppState,
  ActivePeriod,
  Institution,
  InitialLoadStatus,
  PerformanceDetails,
  UserSnapshots,
  Subscription,
  RealEstate,
  RestimateDataWithRates,
  RestimateDataForPeriod
} from "../types";
import { useFirebaseFunction } from ".";
import { useLoggedUser } from "../firebase";
import { PLANS } from "../constants";
import { useNavigation } from "./";
import { initialHistoricalData } from "../context";
import { getLoadInitialDataCache, setLoadInitialDataCache } from "../cache";

const zero = {
  percent: { weekIncrease: 0, monthIncrease: 0, yearIncrease: 0 },
  value: { weekIncrease: 0, monthIncrease: 0, yearIncrease: 0 }
};

interface UseAppStateProps {
  graphRange?: ActivePeriod;
}

export interface LoadInitialDataResponse {
  institutions: Institution[];
  realEstate: RealEstate[];
  spyPerformance: PerformanceDetails;
  historicalData: any;
  snapshots: UserSnapshots;
  subscription: Subscription;
  restimate: RestimateDataWithRates;
}

export const isInitialDataValid = (data: any) => {
  return Boolean(data?.institutions) && Boolean(data?.subscription);
};

const defaultData = {
  institutions: [] as Institution[],
  realEstate: [] as RealEstate[],
  spyPerformance: zero,
  subscription: {
    name: PLANS.BASIC,
    totalAutomatedInstitutionsAllowed: 1
  },
  snapshots: {} as UserSnapshots,
  historicalData: initialHistoricalData,
  restimate: {
    projections: {
      weekly: [] as RestimateDataForPeriod[],
      monthly: [] as RestimateDataForPeriod[],
      yearly: [] as RestimateDataForPeriod[]
    },
    weekRate: 0,
    monthRate: 0,
    yearRate: 0
  }
};

export const useLoadInitialData = (props: UseAppStateProps = {}): AppState => {
  const { graphRange } = props;
  const { user, signOut } = useLoggedUser();
  const [status, setStatus] = useState<InitialLoadStatus>("initial");
  const { navigate } = useNavigation();

  const [initialData, setInitialData] = useState(defaultData);
  const loadInitialData = useFirebaseFunction({
    fnName: "loadInitialData"
  });

  const dev = process?.env?.REACT_APP_EMULATE_FUNCTIONS;
  const [projections, setProjections] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      if (user) {
        setStatus("loading");
        const loadInitialDataFromCache = getLoadInitialDataCache(user.uid);

        let data: LoadInitialDataResponse = loadInitialDataFromCache;
        if (!data) {
          let res = await loadInitialData({
            userId: user.uid,
            skipUpdate: true
          });
          data = res?.data;
        }

        if (isInitialDataValid(data)) {
          setLoadInitialDataCache(user.uid, data);
          setInitialData(data);
          setStatus("done");
        } else if (data && user.validated) {
          setStatus("error");
          navigate("/error");
        } else {
          setStatus("initial");
        }
      }
    };

    if (status === "initial") {
      loadData().catch((e) => console.error(e));
    }
  }, [
    dev,
    loadInitialData,
    setInitialData,
    initialData,
    navigate,
    status,
    user
  ]);

  useEffect(() => {
    if (user) {
      getProjections(user.uid).then((projectionsDb) => {
        projectionsDb.forEach((p) => {
          if (p.id === graphRange) {
            setProjections(p.data().projections);
          }
        });
      });
    }
  }, [user, graphRange]);

  const localSignOut = useCallback(() => {
    setStatus("initial");
    setInitialData(defaultData);
    signOut();
  }, [signOut]);

  return useMemo(
    () => ({
      user,
      signOut: localSignOut,
      ...initialData,
      status,
      projections
    }),
    [initialData, status, user, projections, localSignOut]
  );
};
