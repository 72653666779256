import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { omit } from "lodash";
import { PLANS } from "../constants";
import { InvestmentGroupType, User } from "../types";
import { db } from "./firebase";

// User API
export const createUser = ({
  id,
  email,
  name,
  accessCode
}: {
  id: string;
  email: string;
  name: string;
  accessCode?: string;
}) =>
  db
    .collection("users")
    .doc(id)
    .set({
      email,
      name,
      accessCode: accessCode || "",
      validated: false,
      currentStep: "validateEmail",
      signupDate: firebase.firestore.Timestamp.fromDate(new Date()),
      automatedInstitutionsInUse: 0
    });
export const getAccessCodeCount = (accessCode: string) =>
  db.collection("users").where("accessCode", "==", accessCode).get();

export const getUserById = (id: string) => db.collection("users").doc(id);

export const getUserByEmail = (email: string) =>
  db.collection("users").where("email", "==", email).get();

export const getUsersById = async (userIds: string[]) => {
  const docs = db
    .collection("users")
    .where(firebase.firestore.FieldPath.documentId(), "in", userIds);
  const users = await docs.get();
  return users;
};

export const getAccessCode = (id: string) =>
  db.collection("access-code").doc(id);

export const createFutureUser = ({
  email,
  name
}: {
  email: string;
  name: string;
}) =>
  db.collection("future-users").doc(uuidv4()).set({
    email,
    name,
    createdAt: Date.now()
  });

interface getNLastQuotesProps {
  initialDate: Date;
  endDate?: Date;
  sort?: "asc" | "desc";
  type?: "dayly" | "weekly" | "monthly";
}

export const getNLastQuotes = ({
  initialDate,
  sort,
  type
}: getNLastQuotesProps) => {
  const _type = type || "daily";
  return db
    .collection("spy-data")
    .where("date", ">=", firebase.firestore.Timestamp.fromDate(initialDate))
    .where("type", "==", _type)
    .orderBy("date", sort || "desc")
    .get();
};

// TODO: refactor type to match above
interface getNLastSnapshotsProps {
  initialDate: Date;
  endDate?: Date;
  userId?: string;
  sort?: "asc" | "desc";
  type?: "aggregate" | "weekAggregate" | "monthAggregate" | "yearAggregate";
}

export const getNLastSnapshots = ({
  initialDate,
  endDate,
  userId,
  sort,
  type
}: getNLastSnapshotsProps) => {
  const _type = type || "aggregate";
  if (endDate) {
    return db
      .collection("users")
      .doc(userId)
      .collection("snapshots")
      .where("date", ">=", firebase.firestore.Timestamp.fromDate(initialDate))
      .where("date", "<=", firebase.firestore.Timestamp.fromDate(endDate))
      .where("type", "==", _type)
      .orderBy("date", sort || "desc")
      .get();
  }

  return db
    .collection("users")
    .doc(userId)
    .collection("snapshots")
    .where("date", ">=", firebase.firestore.Timestamp.fromDate(initialDate))
    .where("type", "==", _type)
    .orderBy("date", sort || "desc")
    .get();
};

export const getProjections = async (userId: string) => {
  return db.collection("users").doc(userId).collection("projections").get();
};

export const getUserInstitutions = (userId: string) => {
  return db.collection("users").doc(userId).collection("institutions").get();
};

export const addInstitution = async (institution: any, userId?: string) => {
  return db
    .collection("users")
    .doc(userId)
    .collection("institutions")
    .doc(institution.id)
    .set(institution);
};

export const updateInstitution = async (institution: any, userId?: string) => {
  const normalizedInstitution = omit(institution, ["createdAt"]);
  return db
    .collection("users")
    .doc(userId)
    .collection("institutions")
    .doc(institution.id)
    .set(normalizedInstitution, { merge: true });
};

export const deleteInstitution = async (institution: any, userId?: string) => {
  const userRef = db.collection("users").doc(userId);
  await userRef.collection("institutions").doc(institution.id).delete();

  if (institution.type === "automated") {
    const { automatedInstitutionsInUse } = (await userRef.get()).data() as any;
    userRef.set(
      {
        automatedInstitutionsInUse: automatedInstitutionsInUse - 1
      },
      { merge: true }
    );
  }
};

// stripe
export const getCustomerByUserId = (id: string) =>
  db.collection("customers").doc(id);

export const getSubscriptionsByUserId = (id: string) =>
  db.collection("customers").doc(id).collection("subscriptions");

// get active subscription
export const getUserSubscription = async (id: string): Promise<any> => {
  const subscriptionsDocs = db
    .collection("customers")
    .doc(id)
    .collection("subscriptions")
    .where("status", "in", ["active", "trialing", "past_due", "unpaid"]);

  const subscriptions = await subscriptionsDocs.get();
  return subscriptions.docs?.[0]?.id
    ? {
        ...subscriptions.docs?.[0]?.data(),
        id: subscriptions.docs?.[0]?.id
      }
    : null;
};

export const getUserPlan = async (id: string) => {
  const subscription = await getUserSubscription(id);
  const totalAutomatedInstitutionsAllowed =
    subscription.role === PLANS.PRO ? 1000 : 1;

  return {
    name: subscription.role,
    totalAutomatedInstitutionsAllowed
  };
};

export const createInvestmentGroup = (investmentGroup: InvestmentGroupType) => {
  // adding group to members
  investmentGroup?.members.forEach(async (member) => {
    const userDoc = db.collection("users").doc(member.id);
    const userData = await userDoc.get();
    const { investmentGroupIds = [] } = userData.data() as User;
    investmentGroupIds.push(investmentGroup.id);
    await userDoc.set(
      {
        investmentGroupIds
      },
      {
        merge: true
      }
    );
  });

  return db
    .collection("investment-groups")
    .doc(investmentGroup.id)
    .set({
      ...investmentGroup,
      createdAt: Date.now()
    });
};

export const updateInvestmentGroup = (investmentGroup: InvestmentGroupType) =>
  db
    .collection("investment-groups")
    .doc(investmentGroup.id)
    .set(
      {
        ...investmentGroup,
        updatedAt: Date.now()
      },
      { merge: true }
    );

export const getInvestmentGroups = async (groupIds: string[]) => {
  const docs = db
    .collection("investment-groups")
    .where(firebase.firestore.FieldPath.documentId(), "in", groupIds);
  const investmentGroups = await docs.get();
  return investmentGroups;
};

export const getInvestmentGroupByToken = async (token: string) =>
  db
    .collection("investment-groups")
    .where("shareSettings.token", "==", token)
    .get();

export const getInvestmentGroupInvite = async (inviteId: string) => {
  const doc = db.collection("investment-groups-invites").doc(inviteId);
  const invite = await doc.get();
  return invite;
};

export const getInvestmentGroupInviteByEmail = async (email: string) =>
  db
    .collection("investment-groups-invites")
    .where("member.email", "==", email)
    .get();

export const getInvestmentGroup = async (groupId: string) => {
  const doc = db.collection("investment-groups").doc(groupId);
  const investmentGroup = await doc.get();
  return investmentGroup;
};

export const deleteInvestmentGroup = async (
  investmentGroup: InvestmentGroupType
) => {
  try {
    const doc = db.collection("investment-groups").doc(investmentGroup.id);
    await doc.delete();

    // removing group from members
    investmentGroup.members.forEach(async (member) => {
      const userDoc = db.collection("users").doc(member.id);
      const userData = await userDoc.get();
      const { investmentGroupIds = [] } = (userData.data() as User) || {};
      await userDoc.set(
        {
          investmentGroupIds: investmentGroupIds?.filter(
            (group) => group !== investmentGroup.id
          )
        },
        {
          merge: true
        }
      );
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const getFastProperties = async () => {
  const doc = await db.collection("fast-properties").doc("general").get();
  return doc.data();
};

export const dbConnection = db;
