import React, { useContext } from "react";
import { ResponsiveContext } from "grommet";

import { InvestmentDepositsDesktop, InvestmentDepositsMobile } from ".";

export const AdminInvestmentDeposits = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <InvestmentDepositsMobile />
  ) : (
    <InvestmentDepositsDesktop />
  );
};
