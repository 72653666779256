import { Box, Heading, Paragraph } from "grommet";
import { useAsync } from "react-use";
import { useParams, useLocation, Redirect } from "react-router-dom";
import { LogoFull } from "../components";
import { useFirebaseContext } from "../firebase";
import { useAppState } from "../context";

type Params = {
  code: string;
};
export const Validation = () => {
  const { user } = useAppState();
  const { db } = useFirebaseContext();
  const { code } = useParams<Params>();
  const location = useLocation();

  const state = useAsync(async () => {
    const userRef = await db.getUserById(code);
    const userData = await userRef.get();
    const user = userData.data();
    if (user) {
      try {
        await userRef.set(
          {
            validated: true,
            currentStep: "completeProfile"
          },
          { merge: true }
        );
        return { success: true };
      } catch (e) {
        console.log(e);
        return {
          error: "Invalid code!"
        };
      }
    }
    return {
      error: "Invalid code!"
    };
  }, [db, code]);

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
    );
  }

  if (user.uid !== code) {
    return (
      <Redirect
        to={{
          pathname: "/logout",
          state: { from: location }
        }}
      />
    );
  }

  return (
    <Box fill align="center" justify="center">
      <Box width="medium" align="center" gap="small">
        <LogoFull color="plain" size="large" />
        <Heading
          textAlign="center"
          level={2}
          margin={{ top: "small", bottom: "none" }}
        >
          {state.loading && <b>Validating your account.</b>}
          {state.value?.success && (
            <b>Your account is validated, you may close this window.</b>
          )}
          {state.value?.error && <b>Error validating your account.</b>}
        </Heading>
        {state.value?.error && (
          <Paragraph textAlign="center" color="text-weak" margin="none">
            {state.value?.error}
          </Paragraph>
        )}
      </Box>
    </Box>
  );
};
