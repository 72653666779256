import React, { useContext } from "react";
import { Box, ResponsiveContext, Text } from "grommet";

export const MenuItemLabel = ({
  icon,
  label
}: {
  icon: React.ReactElement;
  label: string;
}) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      direction="row"
      align="center"
      gap="small"
      pad={size === "small" ? "xsmall" : undefined}
      responsive={false}
    >
      {icon}
      <Text>{label}</Text>
    </Box>
  );
};
