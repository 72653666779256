import React, { useCallback, useContext, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Box, ThemeContext } from "grommet";
import { Feedback, PrivateRoute } from "./components";
import { UserThemes } from "./types";
import { TRTThemeContext, useAppState, StepChangeContext } from "./context";
import {
  AddAssets,
  AddInstitution,
  UpdateInstitution,
  Dashboard,
  Error,
  InstitutionsMobile,
  Landing,
  Login,
  Logout,
  NetWorthMobile,
  Settings,
  Validation,
  UpdateAssets,
  ResetPassword,
  AdminDashboard,
  AdminPlaidDebugger,
  AdminBalanceUpdate,
  AdminPlaidTokens,
  AdminInvestmentDeposits,
  UserMenuMobile,
  AdminUserSearch,
  AddInvestmentGroup,
  InvestmentGroupDetails,
  UpdateInvestmentGroup,
  AddRealEstate,
  PropertiesMobile,
  InvestmentGroupsMobile,
  UpdateRealEstate,
  JoinInvestmentGroup,
  JoinPublicInvestmentGroup
} from "./pages";
import { useLoggedUser } from "./firebase";
import { normalizeColor } from "grommet/utils";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

const App = () => {
  const { loading, error } = useLoggedUser();
  const appState = useAppState();
  const { user } = appState;
  const [localTheme, setLocalTheme] = useState(undefined);
  const theme = useContext<any>(ThemeContext);

  const setTheme = useCallback(
    (newTheme) => setLocalTheme(newTheme),
    [setLocalTheme]
  );

  const [currentStep, setCurrentStep] = useState(
    user?.currentStep || "validateEmail"
  );

  useEffect(() => {
    if (user?.currentStep) {
      setCurrentStep(user?.currentStep);
    }
  }, [user?.currentStep]);

  const currentTheme = localTheme || (user?.theme ?? UserThemes.LIGHT);
  const isDark = currentTheme === UserThemes.DARK;

  let appBackground = isDark ? "dark-1" : "light-1";

  useEffect(() => {
    const styleNode = document.getElementById("google-places-widget-styles");
    if (styleNode) {
      styleNode.innerHTML = `
          .pac-container {
              background: ${normalizeColor(
                theme.global.drop.background,
                theme,
                isDark
              )};
              border-radius: ${theme.global.control.border.radius};
            }
          .pac-item {
              color: ${normalizeColor("text-weak", theme, isDark)};
              border: none;
              padding: 4px 8px;
              cursor: pointer;
            }
          .pac-item:hover {
              background-color: ${normalizeColor("active", theme, isDark)};
            }
          .pac-item-selected {
              background-color: ${normalizeColor("active", theme, isDark)};
            }
          .pac-item-query {
            color: ${normalizeColor("text", theme, isDark)};
          }
        `;
    }
  }, [isDark, theme]);
  // only removes the splash screen when the user is loaded...
  if (!loading || error) {
    document.querySelector("#loading-container")?.remove();
    document.querySelector("body")?.removeAttribute("class");
  }

  return (
    <StepChangeContext.Provider value={[currentStep, setCurrentStep]}>
      <TRTThemeContext.Provider value={{ theme: currentTheme, setTheme }}>
        <Feedback>
          <Elements stripe={stripePromise}>
            <Box fill background={appBackground}>
              <Switch>
                <Route path="/validate/:code">
                  <Validation />
                </Route>
                <Route path="/access">
                  <Landing />
                </Route>
                <Route path="/logout">
                  <Logout />
                </Route>
                <Route path="/reset-password">
                  <ResetPassword />
                </Route>
                <PrivateRoute error={error} path="/login">
                  <Login />
                </PrivateRoute>
                <PrivateRoute error={error} path="/error">
                  <Error />
                </PrivateRoute>
                <PrivateRoute error={error} path="/settings">
                  <Settings />
                </PrivateRoute>
                <PrivateRoute error={error} path="/add">
                  <AddAssets />
                </PrivateRoute>
                <PrivateRoute error={error} path="/update">
                  <UpdateAssets />
                </PrivateRoute>
                <PrivateRoute error={error} path="/add-institution">
                  <AddInstitution />
                </PrivateRoute>
                <PrivateRoute error={error} path="/add-investment-group">
                  <AddInvestmentGroup />
                </PrivateRoute>
                <PrivateRoute
                  error={error}
                  path="/investment-group/:investmentGroupId/edit"
                >
                  <UpdateInvestmentGroup />
                </PrivateRoute>
                <PrivateRoute
                  error={error}
                  path="/investment-group/:investmentGroupId"
                >
                  <InvestmentGroupDetails />
                </PrivateRoute>
                <PrivateRoute error={error} path="/join/:inviteId">
                  <JoinInvestmentGroup />
                </PrivateRoute>
                <PrivateRoute error={error} path="/group/join/:token">
                  <JoinPublicInvestmentGroup />
                </PrivateRoute>
                <PrivateRoute error={error} path="/add-real-estate">
                  <AddRealEstate />
                </PrivateRoute>
                <PrivateRoute
                  error={error}
                  path="/update-institution/:institutionId"
                >
                  <UpdateInstitution />
                </PrivateRoute>
                <PrivateRoute
                  error={error}
                  path="/update-real-estate/:realEstateId"
                >
                  <UpdateRealEstate />
                </PrivateRoute>
                <PrivateRoute error={error} path="/dashboard">
                  <Dashboard />
                </PrivateRoute>
                <PrivateRoute error={error} path="/institutions">
                  <InstitutionsMobile />
                </PrivateRoute>
                <PrivateRoute error={error} path="/properties">
                  <PropertiesMobile />
                </PrivateRoute>
                <PrivateRoute error={error} path="/investment-groups">
                  <InvestmentGroupsMobile />
                </PrivateRoute>
                <PrivateRoute error={error} path="/net-worth">
                  <NetWorthMobile />
                </PrivateRoute>
                <PrivateRoute error={error} path="/user-menu">
                  <UserMenuMobile />
                </PrivateRoute>
                <PrivateRoute error={error} path="/admin/plaid-debugger">
                  <AdminPlaidDebugger />
                </PrivateRoute>
                <PrivateRoute error={error} path="/admin/balance-update">
                  <AdminBalanceUpdate />
                </PrivateRoute>
                <PrivateRoute error={error} path="/admin/plaid-tokens">
                  <AdminPlaidTokens />
                </PrivateRoute>
                <PrivateRoute error={error} path="/admin/investment-deposits">
                  <AdminInvestmentDeposits />
                </PrivateRoute>
                <PrivateRoute error={error} path="/admin/user-search">
                  <AdminUserSearch />
                </PrivateRoute>
                <PrivateRoute error={error} path="/admin">
                  <AdminDashboard />
                </PrivateRoute>
                <PrivateRoute error={error} path="/">
                  <Dashboard />
                </PrivateRoute>
              </Switch>
            </Box>
          </Elements>
        </Feedback>
      </TRTThemeContext.Provider>
    </StepChangeContext.Provider>
  );
};

export default App;
