import { useContext } from "react";
import { UserContext } from "./user-context";
import { User } from "../types";
interface HookResponse {
  userRef: any;
  googleUser: any;
  loading: boolean;
  user?: User;
  error: string | undefined;
  signOut: () => void;
  refetchUser: () => Promise<User>;
  updateTrtUser: (user: any) => void;
}

export const useLoggedUser = (): HookResponse => {
  const contextValue = useContext(UserContext);
  return contextValue;
};
