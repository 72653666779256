import { useContext } from "react";
import { ResponsiveContext } from "grommet";

import { JoinInvestmentGroupDesktop, JoinInvestmentGroupMobile } from "./";

export const JoinInvestmentGroup = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <JoinInvestmentGroupMobile />
  ) : (
    <JoinInvestmentGroupDesktop />
  );
};
