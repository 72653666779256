import React from "react";

import { AdminLayout } from "../../layouts";
import { PlaidDebuggerBody } from "./body";

export const PlaidDebuggerDesktop = () => {
  return (
    <AdminLayout
      title="Plaid Debugger"
      subTitle="Investigate possible issues with plaid given an access token"
    >
      <PlaidDebuggerBody />
    </AdminLayout>
  );
};
