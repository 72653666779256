import React, { useContext } from "react";
import { Box, ResponsiveContext } from "grommet";

import {
  CompleteProfile,
  OnboardingHeader,
  ValidateEmail,
  Restimate,
  ChoosePlan,
  OnboardingAddInstitution,
  OnboardingAddPayment
} from "../components/onboarding";
import { useStepChangeContext } from "../hooks";

const ONBOARDING_STEPS = {
  validateEmail: ValidateEmail,
  completeProfile: CompleteProfile,
  restimate: Restimate,
  choosePlan: ChoosePlan,
  addInstitution: OnboardingAddInstitution,
  addPayment: OnboardingAddPayment
};

export const Onboarding = () => {
  const [currentStep] = useStepChangeContext();
  const size = useContext(ResponsiveContext);
  let boxSize = "100%";
  if (size === "large") {
    boxSize = "xlarge";
  }

  // @ts-ignore
  const Component = ONBOARDING_STEPS[currentStep] || ValidateEmail;

  return (
    <Box fill align="center" justify="start" background="onboarding-fill">
      <Box
        width={boxSize}
        flex
        style={{ maxWidth: "1200px" }}
        background="onboarding-background"
        elevation="small"
      >
        <OnboardingHeader />
        <Component />
      </Box>
    </Box>
  );
};
