import { useCallback, useContext } from "react";
import { StepChangeContext, useAppState } from "../context";
import { useLoggedUser } from "../firebase";
import { User } from "../types";

export const useStepChangeContext = (): [
  string,
  (value: string, user: User) => void
] => {
  const { updateUser } = useAppState();
  const [currentStep, setCurrentStep] = useContext(StepChangeContext);
  const { userRef } = useLoggedUser();

  const setStepAndSave = useCallback(
    (nextStep, user) => {
      userRef.current?.set(
        {
          currentStep: nextStep
        },
        { merge: true }
      );
      setCurrentStep(nextStep);
      if (user) {
        updateUser({ ...user, currentStep: nextStep });
      }
    },
    [setCurrentStep, userRef, updateUser]
  );
  return [currentStep, setStepAndSave];
};
