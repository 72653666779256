import React, { useContext } from "react";
import { ResponsiveContext } from "grommet";

import { UpdateInstitutionDesktop, UpdateInstitutionMobile } from "./";

export const UpdateInstitution = () => {
  const size = useContext(ResponsiveContext);
  return size === "small" ? (
    <UpdateInstitutionMobile />
  ) : (
    <UpdateInstitutionDesktop />
  );
};
