import React, { useContext } from "react";
import { Box, ResponsiveContext, Text } from "grommet";
import { Atm, CreditCard, Currency, Home, Optimize } from "grommet-icons";
import numeral from "numeral";

import { useAppState } from "../../hooks";
import { NumberText } from "../../utils";

export interface NetWorthCardProps {
  icon: React.ReactElement;
  netWorth: number;
  type: string;
}

export const NetWorthCard = ({ icon, netWorth, type }: NetWorthCardProps) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      flex={false}
      align="center"
      justify="center"
      pad="12px"
      border="all"
      round="small"
      width={size === "medium" ? "100px" : "120px"}
      gap="xsmall"
      margin="8px"
    >
      {icon}
      <Text weight="bold" size={size === "large" ? "xlarge" : "large"}>
        {numeral(netWorth).format("$0.[00]a")}
      </Text>
      <Text size={size === "large" ? "medium" : "small"}>in {type}</Text>
    </Box>
  );
};

export const NetWorthDetails = () => {
  const size = useContext(ResponsiveContext);
  const { user } = useAppState();
  return (
    <Box flex={false}>
      <Box
        align="center"
        pad={{ top: size === "small" ? "medium" : undefined }}
      >
        <Text color="text-weak">
          <NumberText
            value={
              user?.assets && user?.liabilities
                ? (user?.liabilities / user?.assets) * 100
                : 0
            }
            type="percentage"
            color="text"
            weight="bold"
          />{" "}
          Debt to Asset ratio
        </Text>
      </Box>
      <Box
        direction="row"
        pad={{ horizontal: "small", top: "small", bottom: "large" }}
        align="center"
        justify="center"
        wrap
      >
        {user?.cash! !== 0 && (
          <NetWorthCard
            type="cash"
            netWorth={user?.cash ?? 0}
            icon={<Currency size="large" color="assets" />}
          />
        )}
        {user?.investments! !== 0 && (
          <NetWorthCard
            type="investments"
            netWorth={user?.investments ?? 0}
            icon={<Optimize size="large" color="assets" />}
          />
        )}
        {user?.realEstate! !== 0 && (
          <NetWorthCard
            type="real estate"
            netWorth={user?.realEstate ?? 0}
            icon={<Home size="large" color="assets" />}
          />
        )}
        {user?.creditCards! !== 0 && (
          <NetWorthCard
            type="credit cards"
            netWorth={user?.creditCards ?? 0}
            icon={<CreditCard size="large" color="liabilities" />}
          />
        )}
        {user?.loans! !== 0 && (
          <NetWorthCard
            type="loans"
            netWorth={user?.loans ?? 0}
            icon={<Atm size="large" color="liabilities" />}
          />
        )}
      </Box>
    </Box>
  );
};
