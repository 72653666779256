import React, { useCallback, useMemo, useState } from "react";
import { Box, Select, Text } from "grommet";
import { StatusCritical } from "grommet-icons";
import { useAppState } from "../hooks";

import { ConfirmationModal, InstitutionOption } from "./";
import { Institution } from "../types";

const renderInstitutionOption = (
  option: Institution,
  index: number,
  options: Institution[],
  state: any
) => <InstitutionOption state={state} institution={option} />;

export const DowngradeSubscription = ({
  onCancel,
  onConfirm,
  loading
}: {
  loading?: boolean;
  onCancel: () => void;
  onConfirm: (defaultInstitution: Institution) => void;
}) => {
  const { institutions = [] } = useAppState();
  const automatedInstitutions = useMemo(
    () => institutions.filter(({ type }) => type === "automated"),
    [institutions]
  );
  const [
    selectedAutomatedInstitution,
    setSelectedAutomatedInstitution
  ] = useState(automatedInstitutions[0]);
  const onAutomatedInstitutionSelected = useCallback(
    ({ option }) => setSelectedAutomatedInstitution(option),
    []
  );
  return (
    <ConfirmationModal
      color="status-critical"
      onCancel={onCancel}
      onConfirm={() => onConfirm(selectedAutomatedInstitution)}
      icon={<StatusCritical size="large" />}
      title={`Are you sure you want to downgrade?`}
      actionLabel="Confirm"
      loading={loading}
    >
      <Box gap="medium" responsive={false}>
        <Text color="text-weak">
          By downgrading to the basic plan all your institutions will be
          automatically converted to manual. All future updates will have to be
          manually provided, otherwise balances will not update.
        </Text>
        {automatedInstitutions.length > 1 && (
          <>
            <Text color="text-weak">
              Choose an institution to keep as automated:
            </Text>
            <Select
              options={automatedInstitutions}
              value={selectedAutomatedInstitution}
              valueKey="id"
              valueLabel={
                <InstitutionOption institution={selectedAutomatedInstitution} />
              }
              onChange={onAutomatedInstitutionSelected}
            >
              {renderInstitutionOption}
            </Select>
          </>
        )}
      </Box>
    </ConfirmationModal>
  );
};
